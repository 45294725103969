import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createArraySelector } from 'geotask/core/selectors/selector-factories';
import * as taskAdditionalFields from '../reducers/task-additional-field.reducer';

const selectAdditionalFieldsState = createFeatureSelector<taskAdditionalFields.TaskAdditionalFieldsState>(
  taskAdditionalFields.taskAdditionalFieldsFeatureKey
);

export const selectEntities = createSelector(selectAdditionalFieldsState, taskAdditionalFields.selectEntities);

export const selectAll = createArraySelector(selectAdditionalFieldsState, taskAdditionalFields.selectAll);
export const selectAllActive = createArraySelector(selectAll, (fields) => fields.filter((field) => field.active));
