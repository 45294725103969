import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { Id } from 'geotask/core/schemas/id.schema';
import { AuthSelectors, RouterSelectors } from 'geotask/core/selectors';
import { createArraySelector } from 'geotask/core/selectors/selector-factories';
import { FieldValueTypeCode, isTerminalStatusCode, TaskStatusCode } from 'geotask/core/models';
import { TaskAdditionalField, TaskAdditionalFieldType } from 'geotask/dictionaries/models';
import {
  AreaSelectors,
  TaskAdditionalFieldSelectors,
  TaskAdditionalFieldTypeSelectors,
  TaskKindSelectors,
  TaskPrioritySelectors,
  TaskStatusSelectors,
  TaskTypeSelectors,
  IkeaServiceKindSelectors,
} from 'geotask/dictionaries/selectors';
import { TaskWithDictionaries } from '../models';
import * as fromTask from '../reducers/task.reducer';

export const selectTasksState = createFeatureSelector<fromTask.TasksState>(fromTask.tasksFeatureKey);
export const selectEntities = createSelector(selectTasksState, fromTask.selectEntities);
export const selectAll = createSelector(selectTasksState, fromTask.selectAll);
export const selectByOrderId = createSelector(selectAll, (taskEntities) => {
  const dictionary: { [orderId: number]: number[] | undefined } = {};
  for (const task of taskEntities) {
    const tasks = new Set(dictionary[task.orderId]);
    tasks.add(task.id);
    dictionary[task.orderId] = Array.from(tasks).sort();
  }
  return dictionary;
});

export const selectTaskWithDictionaries = createSelector(
  selectEntities,
  TaskTypeSelectors.selectEntities,
  TaskKindSelectors.selectDictionary,
  TaskStatusSelectors.selectEntities,
  TaskPrioritySelectors.selectEntities,
  TaskAdditionalFieldSelectors.selectEntities,
  TaskAdditionalFieldTypeSelectors.selectEntities,
  IkeaServiceKindSelectors.selectServiceKindsByIdsSelector,
  (tasks, types, kinds, statuses, priorities, taskAdditionalFields, taskAdditionalFieldTypes, ikeaServiceKinds) =>
    (taskId: number): TaskWithDictionaries | undefined => {
      const task = tasks[taskId];
      if (!task) {
        return undefined;
      }
      const type = types[task.typeId];
      const kind = task.kindId != null ? kinds[task.kindId] : undefined;
      const status = statuses[task.statusId];
      const priority = priorities[task.priorityId];
      const serviceTypes = task.serviceTypeIds ? ikeaServiceKinds(task.serviceTypeIds) : [];
      const servicesTooltip = serviceTypes.length > 0 ? serviceTypes.map(({ name }) => name).join(', ') : '';
      if (!type || !status || !priority) {
        return undefined;
      }
      return {
        ...task,
        serviceTypes,
        servicesTooltip,
        type,
        kind,
        status,
        priority,
        additionalFields: Object.fromEntries(
          Object.entries(task.additionalFields ?? {}).map(([fieldId, serializedValue]) => [
            fieldId,
            parseValue(serializedValue, parseInt(fieldId, 10), taskAdditionalFields, taskAdditionalFieldTypes),
          ])
        ),
      };
    }
);

export const selectTaskWithDictionariesById = (taskId: number) =>
  createSelector(selectTaskWithDictionaries, (getTask) => getTask(taskId));

export const selectTasksWithDictionariesByIds = (taskIds: readonly number[]) =>
  createSelector(selectTaskWithDictionaries, (getTask) =>
    taskIds.reduce<TaskWithDictionaries[]>((tasks, taskId) => {
      const task = getTask(taskId);
      if (task) {
        tasks.push(task);
      }
      return tasks;
    }, [])
  );

export const selectTasksWithDictionaries = createSelector(
  selectTaskWithDictionaries,
  (getTask) =>
    (taskIds: readonly number[]): TaskWithDictionaries[] =>
      taskIds.reduce<TaskWithDictionaries[]>((tasks, taskId) => {
        const task = getTask(taskId);
        if (task) {
          tasks.push(task);
        }
        return tasks;
      }, [])
);

export const createSelectorForTasksWithDictionaries = (selectTaskIds: Selector<object, readonly number[]>) =>
  createArraySelector(selectTaskIds, selectTasksWithDictionaries, (taskIds, getTasks) => getTasks(taskIds));

export const selectTaskByIdParam = createSelector(
  RouterSelectors.selectIdParam,
  selectTaskWithDictionaries,
  (id, getTask) => (id ? getTask(id) : undefined)
);

const selectEditedTaskAreaId = createSelector(selectTaskByIdParam, (task) => task?.areaId ?? null);
const selectEditedTaskStatus = createSelector(selectTaskByIdParam, (task) => task?.status);
export const selectEditedTaskIsInExecutorAreas = createSelector(
  AreaSelectors.selectExecutorAreaIds,
  selectEditedTaskAreaId,
  (executorAreaIds, taskAreaId) => (taskAreaId != null && executorAreaIds.includes(taskAreaId)) || taskAreaId == null
);
export const selectIsEditedTaskTerminated = createSelector(selectEditedTaskStatus, (taskStatus) =>
  isTerminalStatusCode(taskStatus?.code)
);
export const selectEditedTaskManuallyCreated = createSelector(selectTaskByIdParam, (task) =>
  task?.manuallyCreated ? task?.manuallyCreated : false
);

export const selectEditedTaskStatusIsRevoked = createSelector(
  selectTaskByIdParam,
  (task) => task?.status.code === TaskStatusCode.Revoked
);

export const selectIsEditedTaskReadonlyForCurrentUser = createSelector(
  RouterSelectors.selectQueryParam('disabled'),
  AuthSelectors.selectIsReadOnly,
  AuthSelectors.selectIsCoordinator,
  AuthSelectors.selectIsTaskReadOnly,
  selectEditedTaskIsInExecutorAreas,
  (disabled, isReadOnlyUser, isCoordinatorUser, isTaskReadOnly, isTaskInExecutorAreas) =>
    disabled === 'true' || isReadOnlyUser || isCoordinatorUser || isTaskReadOnly || !isTaskInExecutorAreas
);

export const selectIsEditedTaskReadonlyWithoutSavingForCurrentUser = createSelector(
  RouterSelectors.selectQueryParam('disabled'),
  AuthSelectors.selectIsReadOnly,
  AuthSelectors.selectIsTaskReadOnly,
  selectEditedTaskIsInExecutorAreas,
  (disabled, isReadOnlyUser, isTaskReadOnly, isTaskInExecutorAreas) =>
    disabled === 'true' || isReadOnlyUser || isTaskReadOnly || !isTaskInExecutorAreas
);

/**
 * Selects whether task associated with ID route param is not editable
 */
export const selectIsTaskFormDisabled = createSelector(
  selectIsEditedTaskReadonlyForCurrentUser,
  (isEditedTaskReadonlyForCurrentUser) => isEditedTaskReadonlyForCurrentUser
);

function parseValue(
  value: string,
  taskAdditionalFieldId: Id,
  taskAdditionalFields: Dictionary<TaskAdditionalField>,
  taskAdditionalFieldTypes: Dictionary<TaskAdditionalFieldType>
) {
  const taskAdditionalField = taskAdditionalFields[taskAdditionalFieldId];
  const taskAdditionalFieldType = taskAdditionalField
    ? taskAdditionalFieldTypes[taskAdditionalField.fieldTypeId]
    : undefined;
  if (!taskAdditionalField || !taskAdditionalFieldType) {
    return null;
  }
  let parsedValue = null;
  switch (taskAdditionalFieldType.code) {
    case FieldValueTypeCode.Text:
    case FieldValueTypeCode.DictionaryList:
      parsedValue = value;
      break;
    case FieldValueTypeCode.Number:
      const numberValue = Number(value);
      parsedValue = isNaN(numberValue) ? null : numberValue;
      break;
    case FieldValueTypeCode.Boolean:
      switch (value) {
        case 'true':
          parsedValue = true;
          break;
        case 'false':
          parsedValue = false;
          break;
        default:
          parsedValue = null;
          break;
      }
      break;
    case FieldValueTypeCode.DictionaryCheckbox:
      parsedValue = value.split(',');
      break;
  }
  return parsedValue;
}
