import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, rootEffectsInit } from '@ngrx/effects';
import { fromEvent, map, switchMap } from 'rxjs';

import { NAVIGATOR, NavigatorTokenType, WINDOW, WindowTokenType } from '@glb/shared/web-api-browser';
import { BrowserActions } from '../actions';

@Injectable()
export class LanguageEffects {
  // noinspection JSUnusedGlobalSymbols
  languageChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rootEffectsInit),
      switchMap(() =>
        fromEvent(this.window, 'languagechange').pipe(
          map(() => BrowserActions.languageChanged({ language: this.navigator.language }))
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    @Inject(WINDOW) private readonly window: WindowTokenType,
    @Inject(NAVIGATOR) private readonly navigator: NavigatorTokenType
  ) {}
}
