import { DateTime } from 'luxon';

export const HTML_FMT = {
  TIME: 'HH:mm',
  TIME_SECONDS: 'HH:mm:ss',
  DATE: 'yyyy-LL-dd',
} as const;

export const toDateTime = (date: string | Date): DateTime =>
  typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);

export const parseUtcTimeWithSeconds = (timeWithSeconds: string) =>
  DateTime.fromFormat(timeWithSeconds, HTML_FMT.TIME_SECONDS, { zone: 'utc' }).toLocal();

export const toOnlyDateString = (date: Date): string => date.toLocaleDateString().split('.').reverse().join('-');
