import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { z } from 'zod';

import { DEFAULT_TASK_KIND_ICON, FormTemplateEntity, TaskKind } from 'geotask/dictionaries/models';
import { parseWith } from '@glb/util/rx-operators';
import { notNull } from '@glb/util/functional';
import { AreaListRowWebObjectSchema } from '../schemas/area-list-row-web-object.schema';
import { DispatcherNotificationStatusWebObjectSchema } from '../schemas/dispatcher-notification-status-web-object.schema';
import { ExecutorRoleObjectSchema } from '../schemas/executor-role-object.schema';
import { FormTemplateFieldTypeWebObjectSchema } from '../schemas/form-template-field-type-web-object.schema';
import { FTDictionaryWithValuesWebObjectSchema } from '../schemas/f-t-dictionary-with-values-web-object.schema';
import { NoteTypeWebObjectSchema } from '../schemas/note-type-web-object.schema';
import { OrderStatusWebObjectSchema } from '../schemas/order-status-web-object.schema';
import { PlaceTypeWebObjectSchema } from '../schemas/place-type-web-object.schema';
import { TaskAdditionalFieldTypeWebObjectSchema } from '../schemas/task-additional-field-type-web-object.schema';
import { SkillWebObjectSchema } from '../schemas/skill-web-object.schema';
import { TaskKindWebObject, TaskKindWebObjectSchema } from '../schemas/task-kind-web-object.schema';
import { TaskPriorityWebObjectSchema } from '../schemas/task-priority-web-object.schema';
import { TaskStatusWebObjectSchema } from '../schemas/task-status-web-object.schema';
import { TaskTypeWebObjectSchema } from '../schemas/task-type-web-object.schema';
import { UnavailabilityTypeWebObjectSchema } from '../schemas/unavailability-type-web-object.schema';
import { Id } from 'geotask/core/schemas/id.schema';
import { IkeaServiceKindWebObjectSchema } from 'geotask/backend/schemas/ikea-service-kind-web-object.schema';
import { CompaniesWebObjectSchema } from 'geotask/backend/schemas/companies-web-object.schema';

const GetDictionariesMapSchema = z.object({
  ['note/getNoteTypes']: NoteTypeWebObjectSchema.array().nullish(),
  ['task/getTaskTypes']: TaskTypeWebObjectSchema.array(),
  ['task/getTaskStatuses']: TaskStatusWebObjectSchema.array(),
  ['task/getTaskPriorities']: TaskPriorityWebObjectSchema.array(),
  ['taskKind/getTaskKinds']: TaskKindWebObjectSchema.array(),
  ['capacity/getCapacityConfiguration']: IkeaServiceKindWebObjectSchema.array(),
  ['places/getPlaceTypes']: PlaceTypeWebObjectSchema.array(),
  ['skill/getSkills']: SkillWebObjectSchema.array().nullish(),
  ['glbform/template/getFormTemplateFieldTypes']: FormTemplateFieldTypeWebObjectSchema.array().nullish(),
  ['glbform/template/getAllDictionaries']: FTDictionaryWithValuesWebObjectSchema.array(),
  ['executor/getAllRoles']: ExecutorRoleObjectSchema.array(),
  ['area/getAreas']: AreaListRowWebObjectSchema.array().nullish(),
  ['orderStatus/getAll']: OrderStatusWebObjectSchema.array(),
  ['team/unavailability/getUnavailabilityTypes']: UnavailabilityTypeWebObjectSchema.array(),
  ['dispatcherNotification/statuses']: DispatcherNotificationStatusWebObjectSchema.array().nullish(),
  ['additionalFieldTypes']: TaskAdditionalFieldTypeWebObjectSchema.array(),
  ['companiesDictionary/getValues']: CompaniesWebObjectSchema.array(),
});

@Injectable({
  providedIn: 'root',
})
export class MultidictionaryApiService {
  readonly dictionaries$ = this.httpClient.get('@backend/multidictionary/getDictionaries').pipe(
    parseWith(GetDictionariesMapSchema),
    map((dictionaries) => ({
      taskTypes: dictionaries['task/getTaskTypes'].map(({ isEmergency, ...type }) => ({
        ...type,
        emergency: isEmergency ?? false,
      })),
      taskStatuses: dictionaries['task/getTaskStatuses'],
      taskPriorities: dictionaries['task/getTaskPriorities'],
      taskKinds: dictionaries['taskKind/getTaskKinds'].map((kind) => parseTaskKind(kind)),
      ikeaServices: dictionaries['capacity/getCapacityConfiguration'],
      skills: dictionaries['skill/getSkills'],
      areas: dictionaries['area/getAreas'],
      unavailabilityTypes: dictionaries['team/unavailability/getUnavailabilityTypes'],
      placeTypes: dictionaries['places/getPlaceTypes'],
      orderStatuses: dictionaries['orderStatus/getAll'],
      noteTypes: dictionaries['note/getNoteTypes'],
      roles: dictionaries['executor/getAllRoles'],
      formTemplateFields: dictionaries['glbform/template/getFormTemplateFieldTypes'],
      formTemplates: dictionaries['taskKind/getTaskKinds'].reduce((formTemplates: FormTemplateEntity[], taskKind) => {
        if (taskKind.formTemplate) {
          formTemplates.push({ ...taskKind.formTemplate, description: taskKind.formTemplate.description || '' });
        }
        return formTemplates;
      }, []),
      formTemplateDictionaries: dictionaries['glbform/template/getAllDictionaries'].map((formTemplateDictionary) => ({
        id: formTemplateDictionary.id,
        name: formTemplateDictionary.name,
        description: formTemplateDictionary.description || '',
        values: formTemplateDictionary.values.reduce<Record<Id, string>>(
          (values, value) => ({
            ...values,
            [value.id]: value.name,
          }),
          {}
        ),
      })),
      dispatcherNotificationStatuses: dictionaries['dispatcherNotification/statuses'],
      additionalFieldTypes: dictionaries.additionalFieldTypes.map((additionalFieldType) => ({
        ...additionalFieldType,
        dictionaryRequired: additionalFieldType.dictionaryRequired ?? false,
      })),
      companies: dictionaries['companiesDictionary/getValues'],
    }))
  );

  constructor(private readonly httpClient: HttpClient) {}
}

function parseTaskKind(kind: TaskKindWebObject): TaskKind {
  return {
    id: kind.id,
    name: kind.name,
    durationInMinutes: kind.duration,
    typeId: kind.type.id,
    priorityId: kind.priority.id,
    requiredDateValue: kind.requiredDateValue,
    requiredDateUnit: kind.requiredDateUnit,
    skillIds: kind.skills.map((skill) => skill.id),
    description: kind.description,
    formTemplateId: kind.formTemplate?.id ?? null,
    periodicity: parseKindPeriodicity(kind),
    icon: kind.icon ?? DEFAULT_TASK_KIND_ICON,
  };
}

function parseKindPeriodicity(kind: TaskKindWebObject): TaskKind['periodicity'] {
  if (kind.repeatValue && kind.repeatUnit && kind.repeatFromType) {
    return {
      repeat: {
        value: kind.repeatValue,
        unit: kind.repeatUnit,
      },
      repeatFromType: kind.repeatFromType,
    };
  }
  if (kind.weeksOfMonth) {
    return {
      weeksOfMonth: kind.weeksOfMonth,
    };
  }
  return null;
}
