import { ChangeDetectorRef, Directive, Input, Optional, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from 'geotask/core/services/auth.service';
import { ConditionalBaseDirective } from '@glb/util/angular';

@Directive({
  selector: '[glbIfLoggedIn]',
})
export class IfLoggedInDirective extends ConditionalBaseDirective {
  @Input()
  set glbIfLoggedInThen(template: TemplateRef<undefined>) {
    this.nextThenTemplate(template);
  }

  @Input()
  set glbIfLoggedInElse(template: TemplateRef<undefined>) {
    this.nextElseTemplate(template);
  }

  constructor(
    private readonly authService: AuthService,
    vc: ViewContainerRef,
    cd: ChangeDetectorRef,
    @Optional() template: TemplateRef<undefined>
  ) {
    super(vc, cd, template);
  }

  protected getObservableCondition() {
    return this.authService.canAccessApplication$;
  }
}
