import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { ExecutorUnavailability } from 'geotask/entities/models';

export const loadExecutorUnavailabilitiesSuccess = createAction(
  '[Executors/API] Load Executor Unavailabilities Success',
  props<{ executorUnavailabilities: ExecutorUnavailability[] }>()
);
export const loadExecutorUnavailabilitiesFailure = createAction(
  '[Executors/API] Load Executor Unavailabilities Failure',
  props<{ error: ApiError }>()
);

export const addUnavailabilitySuccess = createAction(
  '[Executors/API] Add Unavailability Success',
  props<{ executorUnavailabilities: ExecutorUnavailability[] }>()
);
export const addUnavailabilityFailure = createAction(
  '[Executors/API] Add Unavailability Failure',
  props<{ error: ApiError }>()
);

export const deleteUnavailabilitiesSuccess = createAction(
  '[Executors/API] Delete Unavailabilities Success',
  props<{ unavailabilityIds: readonly number[] }>()
);
export const deleteUnavailabilitiesFailure = createAction(
  '[Executors/API] Delete Unavailabilities Failure',
  props<{ error: ApiError }>()
);
