import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createArraySelector } from 'geotask/core/selectors/selector-factories';
import * as taskAdditionalFieldTypes from '../reducers/task-additional-field-type.reducer';

const selectFieldValueTypeState = createFeatureSelector<taskAdditionalFieldTypes.TaskAdditionalFieldTypesState>(
  taskAdditionalFieldTypes.taskAdditionalFieldTypesFeatureKey
);

export const selectEntities = createSelector(selectFieldValueTypeState, taskAdditionalFieldTypes.selectEntities);

export const selectAll = createArraySelector(selectFieldValueTypeState, taskAdditionalFieldTypes.selectAll);
