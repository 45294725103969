import { Component } from '@angular/core';

@Component({
  selector: 'glb-page-not-found',
  template: `
    <h1 class="mat-title">{{ 'core.page-not-found.header' | translate }}</h1>
    <a mat-flat-button color="primary" routerLink="/">
      {{ 'core.page-not-found.go-back' | translate }}
    </a>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        height: calc(100% - 2rem);
        width: calc(100% - 2rem);
      }
    `,
  ],
})
export class PageNotFoundComponent {}
