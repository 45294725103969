import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createArraySelector, createEntityByIdSelector } from 'geotask/core/selectors/selector-factories';
import * as fromNoteType from '../reducers/note-type.reducer';
import { NoteTypeCode } from 'geotask/core/models';

const selectNoteTypeState = createFeatureSelector<fromNoteType.NoteTypeState>(fromNoteType.noteTypeFeatureKey);
export const selectDictionary = createSelector(selectNoteTypeState, fromNoteType.selectEntities);
export const selectAll = createArraySelector(selectNoteTypeState, fromNoteType.selectAll);
export const selectNoteTypeById = createEntityByIdSelector(selectDictionary);

const NoteTypeCodes = [NoteTypeCode.Client, NoteTypeCode.Other, NoteTypeCode.Problem];

const CommentTypeCode = NoteTypeCode.Comment;

export const selectAllNoteType = createArraySelector(selectAll, (types) =>
  types.filter((type) => NoteTypeCodes.includes(type.code))
);
