import { FactoryProvider } from '@angular/core';
import { JWT_OPTIONS, JwtConfig } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { defaultIfEmpty, lastValueFrom, take } from 'rxjs';

import { Environment, ENVIRONMENT } from '../environment.token';
import { AuthSelectors } from '../selectors';

export function JWT_OPTIONS_FACTORY(store$: Store, environment: Environment): JwtConfig {
  const tokenGetter = () => lastValueFrom(store$.select(AuthSelectors.selectToken).pipe(take(1), defaultIfEmpty(null)));
  const { jwt } = environment;
  return {
    tokenGetter,
    headerName: 'X-AUTH-TOKEN',
    authScheme: '',
    skipWhenExpired: true,
    ...jwt,
  };
}

export const JWT_OPTIONS_FACTORY_PROVIDER: FactoryProvider = {
  provide: JWT_OPTIONS,
  useFactory: JWT_OPTIONS_FACTORY,
  deps: [Store, ENVIRONMENT],
};
