import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';

import { TaskStatusCode } from 'geotask/core/models';
import { createArraySelector, createEntityByIdSelector } from 'geotask/core/selectors/selector-factories';
import { filterNonNullable } from '@glb/util/rx-operators';
import { TaskStatus } from '../models';
import * as fromTaskStatus from '../reducers/task-status.reducer';

const selectTaskStatusState = createFeatureSelector<fromTaskStatus.TaskStatusesState>(
  fromTaskStatus.taskStatusesFeatureKey
);

export const selectEntities = createSelector(selectTaskStatusState, fromTaskStatus.selectEntities);
export const selectStatusIdByCodeDictionary = createSelector(selectTaskStatusState, fromTaskStatus.selectByCode);
export const selectTaskStatusById = createEntityByIdSelector(selectEntities);

export const selectStatusIdByCode = (statusCode: TaskStatusCode) =>
  createSelector(selectStatusIdByCodeDictionary, (statusIdByCode) => statusIdByCode[statusCode]);

const _selectTaskStatusNewId = selectStatusIdByCode(TaskStatusCode.New);

export const selectTaskStatusNewId = pipe(select(_selectTaskStatusNewId), filterNonNullable());

const _selectTaskStatusPlannedId = selectStatusIdByCode(TaskStatusCode.Planned);

export const selectTaskStatusPlannedId = pipe(select(_selectTaskStatusPlannedId), filterNonNullable());

export const selectStatusByCode = (code: TaskStatusCode) =>
  createSelector(selectStatusIdByCodeDictionary, selectEntities, (byCode, statusDictionary) => {
    const statusId = byCode[code];
    if (!statusId) {
      return undefined;
    }
    return statusDictionary[statusId];
  });

const _selectTaskStatusNew = selectStatusByCode(TaskStatusCode.New);

export const selectTaskStatusNew = pipe(select(_selectTaskStatusNew), filterNonNullable());

export const selectStatusesByCodes = (codes: readonly TaskStatusCode[]) =>
  createArraySelector(selectStatusIdByCodeDictionary, selectEntities, (byCode, statusDictionary) =>
    codes.reduce((statuses: TaskStatus[], code) => {
      const statusId = byCode[code];
      if (!statusId) {
        return statuses;
      }
      const status = statusDictionary[statusId];
      if (status) {
        statuses.push(status);
      }
      return statuses;
    }, [])
  );

export const selectStatusIdsByCodes = (codes: readonly TaskStatusCode[]) =>
  createArraySelector(selectStatusesByCodes(codes), (statuses) => statuses.map((status) => status.id));

export const selectStatusesByCodesGroupingByCode = (codes: readonly TaskStatusCode[]) =>
  createSelector(selectStatusIdByCodeDictionary, selectEntities, (byCode, statusDictionary) =>
    codes.reduce<Partial<Record<TaskStatusCode, TaskStatus>>>((statuses, code) => {
      const statusId = byCode[code];
      if (!statusId) {
        return statuses;
      }
      const status = statusDictionary[statusId];
      if (!status) {
        return statuses;
      }
      return { ...statuses, [code]: status };
    }, {})
  );

export const selectAll = createArraySelector(selectTaskStatusState, fromTaskStatus.selectAll);
