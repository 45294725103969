import { z } from 'zod';

import { DictionaryEntrySchema } from 'geotask/core/schemas/dictionary-entry.schema';

export const IkeaServiceKindWebObjectSchema = DictionaryEntrySchema.extend({
  durationInMinutes: z.number().nullable(),
  skill: z.number().nullable(),
  kindId: z.number().nullable(),
  availableForManualTask: z.boolean(),
  isService: z.boolean(),
  isDelivery: z.boolean(),
  isPickUp: z.boolean(),
});
export type IkeaServiceKindWebObject = z.infer<typeof IkeaServiceKindWebObjectSchema>;
