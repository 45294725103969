import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { NormalizedTeamWebObject } from 'geotask/backend/models/normalized-team-web-object';
import type { Page } from 'geotask/backend/schemas/page.schema';
import type { TasksForReportPageWebObject } from 'geotask/backend/schemas/tasks-for-report-page-web-object.schema';

export const loadTasksReportSuccess = createAction(
  '[Tasks Report API] Load Task Report Success',
  props<{
    page: TasksForReportPageWebObject;
    teams: Page<NormalizedTeamWebObject>['content'];
  }>()
);
export const loadTasksReportFailure = createAction(
  '[Tasks Report API] Load Task Report Failure',
  props<{ error: ApiError }>()
);

export const exportToCsvFileSuccess = createAction('[Tasks Report API] Export To Csv File Success');
export const exportToCsvFileFailure = createAction(
  '[Tasks Report API] Export To Csv File Failure',
  props<{ error: ApiError }>()
);
