import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createArraySelector, createEntitiesByIdsSelector } from 'geotask/core/selectors/selector-factories';
import * as fromRole from '../reducers/role.reducer';

const selectRoleState = createFeatureSelector<fromRole.RolesState>(fromRole.rolesFeatureKey);
export const selectEntities = createSelector(selectRoleState, fromRole.selectEntities);
export const selectAll = createSelector(selectRoleState, fromRole.selectAll);
export const selectRolesByIds = createEntitiesByIdsSelector(selectEntities);
export const selectRoleCodesByIds = (roleIds: readonly number[]) =>
  createArraySelector(selectRolesByIds(roleIds), (roles) => roles.map((role) => role.code));
