import { OrderWebObject } from 'geotask/backend/schemas/order-web-object.schema';
import { Customer, OrderEntity } from 'geotask/entities/models';

export function mapToOrderEntity({ customer, ...order }: OrderWebObject): OrderEntity {
  return {
    id: order.id,
    invoiceNumber: order.invoiceNumber,
    externalId: order.externalId,
    orderStatusId: order.orderStatusId,
    description: order.description,
    modificationTime: order.modificationTime,
    contactPerson: order.contactPerson,
    customerName: order.customerName,
    externalNumber: order.externalNumber,
    phoneNumber: order.phoneNumber,
    providerName: order.providerName,
    customerId: customer?.id || null,
    address: order.address || null,
    sacId: order.sacId,
  };
}

export function normalizeOrders(backendOrders: OrderWebObject[]): { orders: OrderEntity[]; customers: Customer[] } {
  const customers: Customer[] = [];
  const orders: OrderEntity[] = [];
  backendOrders.forEach((backendOrder) => {
    orders.push(mapToOrderEntity(backendOrder));
    const { customer } = backendOrder;
    if (customer) {
      customers.push({
        id: customer.id,
        externalId: customer.externalId,
        placesCount: customer.placesCount,
        name: customer.name,
        active: customer.active,
        anonymized: customer.anonymized,
      });
    }
  });
  return { customers, orders };
}
