export enum OptimizationStatusCode {
  Waiting = 'WAITING',
  Loading = 'LOADING',
  Preprocessing = 'PREPROCESSING',
  Initiating = 'INITIATING',
  Improving = 'IMPROVING',
  Finalizing = 'FINALIZING',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  ZeroResult = 'ZERO_RESULT',
  FailedTaskStatusChanged = 'FAILED_TASK_STATUS_CHANGED',
  FailedServerError = 'FAILED_SERVER_ERROR',

  // statuses for API limits:
  FailedOverTaskDailyLimit = 'FAILED_OVER_TASK_DAILY_LIMIT',
  FailedOverTaskMonthlyLimit = 'FAILED_OVER_TASK_MONTHLY_LIMIT',
  FailedOverTaskOptimizationTimeLimit = 'FAILED_OVER_TASK_OPTIMIZATION_TIME_LIMIT',
  FailedOverOptimizationTimeLimit = 'FAILED_OVER_OPTIMIZATION_TIME_LIMIT',
  FailedOverOptimizationTaskCountLimit = 'FAILED_OVER_OPTIMIZATION_TASK_COUNT_LIMIT',
  FailedOverOptimizationWithRouteTaskLimit = 'FAILED_OVER_OPTIMIZTATION_WITH_ROUTE_TASK_LIMIT',
}

export const optimizationPendingStatusCodes = [
  OptimizationStatusCode.Waiting,
  OptimizationStatusCode.Loading,
  OptimizationStatusCode.Preprocessing,
  OptimizationStatusCode.Initiating,
  OptimizationStatusCode.Improving,
  OptimizationStatusCode.Finalizing,
] as const;
