import { UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { defaultIfEmpty, take } from 'rxjs';

import { RoleCode } from '../models';
import { AuthService } from '../services/auth.service';

export function hasRole(role: RoleCode, defaultValue: boolean | UrlTree = false) {
  return () => {
    const authService = inject(AuthService);
    return authService.hasRole(role).pipe(take(1), defaultIfEmpty(defaultValue));
  };
}
