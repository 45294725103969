import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MultidictionaryApiActions } from '../actions';
import { OrderStatus } from '../models';

export const orderStatusesFeatureKey = 'dictionaries.orderStatuses';

export type OrderStatusesState = EntityState<OrderStatus>;

export const adapter: EntityAdapter<OrderStatus> = createEntityAdapter<OrderStatus>();

export const initialState: OrderStatusesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) => adapter.setAll(action.orderStatuses, state))
);

export const { selectEntities, selectAll } = adapter.getSelectors();
