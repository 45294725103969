import type { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import type { TeamUnavailabilityDefinition } from '../models';

export const addTeamUnavailabilityDefinition = createAction(
  '[TeamUnavailabilityDefinition/API] Add Team Unavailability Definition',
  props<{
    readonly teamUnavailabilityDefinition: TeamUnavailabilityDefinition;
  }>()
);

export const updateTeamUnavailabilityDefinition = createAction(
  '[TeamUnavailabilityDefinition/API] Update Team Unavailability Definition',
  props<{
    readonly teamUnavailabilityDefinition: Update<TeamUnavailabilityDefinition>;
  }>()
);

export const upsertTeamUnavailabilityDefinitions = createAction(
  '[TeamUnavailabilityDefinition/API] Upsert Team Unavailability Definitions',
  props<{
    readonly teamUnavailabilityDefinitions: TeamUnavailabilityDefinition[];
  }>()
);

export const removeTeamUnavailabilityDefinition = createAction(
  '[TeamUnavailabilityDefinition/API] Remove Team Unavailability Definition',
  props<{
    readonly teamUnavailabilityDefinitionId: number;
  }>()
);
