import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'glb-simple-nav-item',
  template: `
    <a mat-list-item [routerLink]="routerLinkCommands" (click)="onClick()">
      <div matListIcon>
        <ng-content></ng-content>
      </div>
      <span matLine>{{ linkName }}</span>
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleNavItemComponent {
  @Input() routerLinkCommands: any[] | string | null | undefined;
  @Input() linkName: string | null | undefined;

  constructor(private readonly matSidenav: MatSidenav) {}

  onClick() {
    return this.matSidenav.close();
  }
}
