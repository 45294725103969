import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { ModulesConfiguration } from '../models';

export const loadModulesSuccess = createAction(
  '[Modules API] Load Modules Success',
  props<{ modules: ModulesConfiguration }>()
);

export const loadModulesFailure = createAction('[Modules API] Load Modules Failure', props<{ error: ApiError }>());
