import { map } from 'rxjs';
import { z } from 'zod';

export function cloneArray<T>() {
  return map((array: readonly T[]) => Array.from(array));
}

export function parseWith<TZodType extends z.ZodType<TValue>, TValue = z.infer<TZodType>>(schema: TZodType) {
  return map((value: unknown) => schema.parse(value));
}
