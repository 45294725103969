import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { TaskAdditionalFieldWebObject } from 'geotask/backend/schemas/task-additional-field-web-object.schema';
import { SettingsApiActions } from 'geotask/settings/actions';
import { comparing } from '@glb/util/functional';
import { TaskAdditionalFieldsApiActions } from '../actions';
import { TaskAdditionalField } from '../models';

export const taskAdditionalFieldsFeatureKey = 'dictionaries.taskAdditionalFields';

export type TaskAdditionalFieldsState = EntityState<TaskAdditionalField>;

export const adapter: EntityAdapter<TaskAdditionalField> = createEntityAdapter<TaskAdditionalField>({
  sortComparer: comparing('orderNumber'),
});

export const initialState: TaskAdditionalFieldsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    TaskAdditionalFieldsApiActions.loadTaskAdditionalFieldsSuccess,
    (state, { taskAdditionalFields }): TaskAdditionalFieldsState => setAll(state, taskAdditionalFields)
  ),
  on(
    SettingsApiActions.saveSettingsSuccess,
    (state, { settings: { taskAdditionalFields } }): TaskAdditionalFieldsState => setAll(state, taskAdditionalFields)
  )
);

function setAll(
  state: TaskAdditionalFieldsState,
  taskAdditionalFields: readonly TaskAdditionalFieldWebObject[]
): TaskAdditionalFieldsState {
  const entities = taskAdditionalFields.map(mapToTaskAdditionalFieldEntity);
  return adapter.setAll(entities, state);
}

function mapToTaskAdditionalFieldEntity(field: TaskAdditionalFieldWebObject): TaskAdditionalField {
  return {
    id: field.id,
    name: field.name,
    fieldTypeId: field.fieldTypeId,
    dictionaryTypeId: field.dictionaryTypeId,
    active: field.active,
    visibleInMobile: field.visibleInMobile,
    orderNumber: field.orderNumber,
  };
}

export const { selectEntities, selectAll } = adapter.getSelectors();
