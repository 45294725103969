import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { MultidictionaryApiActions } from '../actions';
import { TaskAdditionalFieldType } from '../models';

export const taskAdditionalFieldTypesFeatureKey = 'dictionaries.taskAdditionalFieldTypes';

export type TaskAdditionalFieldTypesState = EntityState<TaskAdditionalFieldType>;

export const adapter: EntityAdapter<TaskAdditionalFieldType> = createEntityAdapter();

export const initialState: TaskAdditionalFieldTypesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    MultidictionaryApiActions.loadDictionariesSuccess,
    (state, action): TaskAdditionalFieldTypesState => adapter.setAll(action.additionalFieldTypes, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
