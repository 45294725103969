import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';

import { SidenavFacade } from '../../services/sidenav.facade';
import { AppSettingsSelectors } from 'geotask/dictionaries/selectors';
import { Version } from 'geotask/core/services/version';

@Component({
  selector: 'glb-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SidenavFacade],
})
export class SidenavComponent {
  @Input()
  closeAll: Observable<boolean> = of(true);

  readonly mobileAppVersion$ = this.store$.select(AppSettingsSelectors.selectMobileAppVersion);

  constructor(private readonly store$: Store, readonly version: Version) {}
}
