import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRouter from '../reducers/router';
import { createArraySelector } from 'geotask/core/selectors/selector-factories';

export const selectRouterState = createFeatureSelector<fromRouter.MergedRouteReducerState | undefined>(
  fromRouter.routerFeatureKey
);

export const selectRoute = createSelector(selectRouterState, (routerState) => routerState?.state);

export const selectQueryParams = createSelector(selectRoute, (route) => route?.queryParams ?? {});

export const selectQueryParam = (key: string) =>
  createSelector(selectQueryParams, (queryParams): string | string[] | undefined => queryParams[key]);

export const selectParams = createSelector(selectRoute, (route) => route?.params ?? {});

export const selectParam = (key: string) => createSelector(selectParams, (params): string | undefined => params[key]);

export const selectData = createSelector(selectRoute, (route) => route?.data ?? {});

export const selectUrl = createSelector(selectRoute, (route) => route?.url ?? '');

export const selectPathSegments = createArraySelector(selectUrl, (url) => {
  const end = url.indexOf('?');
  const withoutQueryParams = url.substring(0, end < 0 ? url.length : end);
  return withoutQueryParams.split('/');
});
export const selectLastSegmentOfPath = createSelector(selectPathSegments, (segments) =>
  segments.length > 0 ? segments[segments.length - 1] : null
);

export const selectIdParam = createSelector(
  selectParam('id'),
  (paramValue) => (paramValue && parseInt(paramValue, 10)) || null
);

export const selectIsUrlIncludingNew = createSelector(selectUrl, (url) => url.includes('/new'));
export const selectIsUrlNotIncludingNew = createSelector(selectUrl, (url) => !url.includes('/new'));

export const selectSingleIntegerQueryParam = (key: string, radix = 10) =>
  createSelector(selectQueryParam(key), (paramValue) =>
    paramValue && !Array.isArray(paramValue) ? parseInt(paramValue, radix) : null
  );
