import { z } from 'zod';
import { IdSchema } from 'geotask/core/schemas/id.schema';
import { NonEmptyStringSchema } from 'geotask/core/schemas/non-empty-string.schema';

export const FTDictionaryValueWebObjectSchema = z.object({
  id: IdSchema,
  name: NonEmptyStringSchema,
});

export const FTDictionaryWithValuesWebObjectSchema = z.object({
  id: IdSchema,
  name: NonEmptyStringSchema,
  description: z.string().nullable(),
  values: FTDictionaryValueWebObjectSchema.array(),
});

export type FTDictionaryWithValuesWebObject = z.infer<typeof FTDictionaryWithValuesWebObjectSchema>;
