import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BackendApiActions } from 'geotask/core/actions';
import { ENVIRONMENT, Environment } from 'geotask/core/environment.token';
import { NotificationsService } from 'geotask/core/services/notifications.service';

@Injectable()
export class BackendRequestInterceptor implements HttpInterceptor {
  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly store$: Store,
    @Inject(ENVIRONMENT) private readonly environment: Environment
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith('@backend')) {
      return next.handle(request);
    }
    return next
      .handle(
        request.clone({
          url: request.url.replace('@backend', this.environment.backend.urlPrefix),
          withCredentials: true,
        })
      )
      .pipe(
        tap({
          error: (error: unknown) => {
            if (error instanceof HttpErrorResponse) {
              this.tapError(error);
            } else {
              console.error('Received error is not HttpErrorResponse', error);
            }
          },
        })
      );
  }

  private tapError(error: HttpErrorResponse) {
    if (error.status === 0) {
      this.notificationsService.openInstantToastr({
        messageCode: 'core.backend.connection-timeout',
        type: 'error',
      });
    } else if (error.status === HttpStatusCode.Unauthorized) {
      this.store$.dispatch(BackendApiActions.unauthorizedResponse());
    }
  }
}
