import { createReducer, on, StoreConfig } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { mapToWorkDayEntity } from 'geotask/core/models/map-to-work-day-entity';
import * as DashboardSchedulerActions from 'geotask/dashboard-scheduler/actions/scheduler-data.actions';
import * as TeamContextMenuActions from 'geotask/dashboard-scheduler/actions/team-context-menu.actions';
import * as ScheduleApiActions from 'geotask/schedule/actions/schedule-api.actions';
import * as AppointmentsApiActions from 'geotask/tasks/actions/appointments-api.actions';
import * as TaskEditorTasksApiActions from 'geotask/tasks/actions/tasks-api.actions';
import * as TasksReportApiActions from 'geotask/tasks-report/actions/tasks-report-api.actions';
import * as TrackSummaryApiActions from 'geotask/track-summary/actions/track-summary-api.actions';
import { stateBroadcastMetaReducer } from 'geotask/util/state-broadcast-meta-reducer';
import { WorkDayActions } from '../actions';
import { WorkDayEntity } from '../models';

export const workDaysFeatureKey = 'entities.workDays';

export type WorkDaysState = EntityState<WorkDayEntity>;

export const adapter: EntityAdapter<WorkDayEntity> = createEntityAdapter<WorkDayEntity>();

export const initialState: WorkDaysState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    WorkDayActions.upsertWorkDay,
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    TaskEditorTasksApiActions.loadCopiedTaskSuccess,
    TaskEditorTasksApiActions.saveNewTaskSuccess,
    (state, action): WorkDaysState => (action.workDay ? adapter.upsertOne(action.workDay, state) : state)
  ),
  on(
    WorkDayActions.upsertWorkDays,
    ScheduleApiActions.updateScheduleSuccess,
    TrackSummaryApiActions.loadTrackSummarySuccess,
    ScheduleApiActions.loadScheduleSuccess,
    (state, { workDays }): WorkDaysState => adapter.upsertMany(workDays, state)
  ),
  on(
    DashboardSchedulerActions.loadSchedulerDataSuccess,
    DashboardSchedulerActions.refreshSchedulerDataSuccess,
    (state, { data: { workDays } }): WorkDaysState => adapter.upsertMany(workDays, state)
  ),
  on(
    TasksReportApiActions.loadTasksReportSuccess,
    (state, { page: { workDays } }): WorkDaysState =>
      adapter.upsertMany(
        workDays.map((workDay) => mapToWorkDayEntity(workDay)),
        state
      )
  ),
  on(ScheduleApiActions.removeWorkDaySuccess, (state, action): WorkDaysState => adapter.removeMany(action.ids, state)),
  on(
    TeamContextMenuActions.requestLocalOptimize,
    (state, action): WorkDaysState =>
      adapter.updateOne(
        {
          id: action.workDayId,
          changes: { lockByOptimization: true },
        },
        state
      )
  ),
  on(
    TeamContextMenuActions.localOptimizeSuccess,
    TeamContextMenuActions.localOptimizeFailure,
    (state, action): WorkDaysState =>
      adapter.updateOne(
        {
          id: action.workDayId,
          changes: { lockByOptimization: false },
        },
        state
      )
  ),
  on(AppointmentsApiActions.assignAppointmentForExistingTaskSuccess, (state, { workDay }): WorkDaysState => {
    if (!workDay) {
      return state;
    }
    return adapter.upsertOne(
      {
        id: workDay.id,
        executorIds: workDay.executorIds,
        lockByOptimization: !!workDay.optimizationSessionId,
        skillIds: workDay.skillIds,
        taskIds: workDay.taskIds,
        teamId: workDay.teamId,
        workEnd: workDay.workEnd,
        workStart: workDay.workStart,
        editable: workDay.editable,
      },
      state
    );
  })
);

export function featureConfigFactory(
  broadcastChannel: BroadcastChannel | null,
  location: Location,
  window: Window
): StoreConfig<WorkDaysState> {
  const metaReducers = broadcastChannel
    ? [
        stateBroadcastMetaReducer({
          location,
          window,
          broadcastChannel,
          initialState,
          featureKey: workDaysFeatureKey,
          syncAction: WorkDayActions.syncWorkDayEntitiesState,
        }),
      ]
    : [];
  return {
    metaReducers,
  };
}

export const { selectEntities, selectAll } = adapter.getSelectors();
