import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Executor, TeamEntity, WorkDayEntity } from 'geotask/entities/models';

export const loadScheduleSuccess = createAction(
  '[Schedule API] Load Schedule Success',
  props<{
    workDays: WorkDayEntity[];
    teams: TeamEntity[];
    executors: Executor[];
  }>()
);
export const loadScheduleFailure = createAction('[Schedule API] Load Schedule Failure', props<{ error: ApiError }>());

export const updateScheduleSuccess = createAction(
  '[Schedule API] Update Schedule Success',
  props<{ workDays: WorkDayEntity[] }>()
);
export const updateScheduleFailure = createAction(
  '[Schedule API] Update Schedule Failure',
  props<{ error: ApiError }>()
);

export const removeWorkDaySuccess = createAction('[Schedule API] Remove Work Days Success', props<{ ids: number[] }>());
export const removeWorkDayFailure = createAction(
  '[Schedule API] Remove Work Days Failure',
  props<{ error: ApiError }>()
);
