import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { mapToOrderEntity, normalizeOrders } from 'geotask/core/models/map-normalized-order-to-entities';
import { TasksApiActions as CustomersTasksApiActions } from 'geotask/customers/actions/customers-api.actions';
import * as TasksApiActions from 'geotask/dashboard-list/actions/tasks-api.actions';
import { NotesApiActions } from 'geotask/notes/actions';
import * as OrderEditorOrdersApiActions from 'geotask/orders/actions/orders-api.actions';
import { TasksApiActions as PlaceEditorTasksApiActions } from 'geotask/places/actions';
import * as TaskEditorNoteApiActions from 'geotask/tasks/actions/note-api.actions';
import * as TaskEditorTasksApiActions from 'geotask/tasks/actions/tasks-api.actions';
import * as TasksReportApiActions from 'geotask/tasks-report/actions/tasks-report-api.actions';
import * as TrackSummaryApiActions from 'geotask/track-summary/actions/track-summary-api.actions';
import { OrderActions } from '../actions';
import { OrderEntity } from '../models';

export const ordersFeatureKey = 'entities.orders';

export type OrdersState = EntityState<OrderEntity>;

export const adapter: EntityAdapter<OrderEntity> = createEntityAdapter<OrderEntity>();

export const initialState: OrdersState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    TaskEditorNoteApiActions.loadCopiedNoteSuccess,
    TaskEditorTasksApiActions.loadCopiedTaskSuccess,
    TaskEditorTasksApiActions.saveNewTaskSuccess,
    OrderEditorOrdersApiActions.loadOrderSuccess,
    (state, action): OrdersState => (action.order ? adapter.upsertOne(action.order, state) : state)
  ),
  on(
    OrderActions.upsertOrders,
    TrackSummaryApiActions.loadTrackSummarySuccess,
    (state, action): OrdersState => adapter.upsertMany(action.orders, state)
  ),
  on(
    CustomersTasksApiActions.loadOnGoingTasksSuccess,
    CustomersTasksApiActions.loadTasksHistorySuccess,
    CustomersTasksApiActions.loadNotTerminatedTasksSuccess,
    PlaceEditorTasksApiActions.loadOnGoingTasksSuccess,
    PlaceEditorTasksApiActions.loadTasksHistorySuccess,
    (state, { page: { orders } }): OrdersState => {
      const { orders: entities } = normalizeOrders(orders);
      return adapter.upsertMany(entities, state);
    }
  ),
  on(
    TasksApiActions.loadTasksPageSuccess,
    (state, action): OrdersState => adapter.upsertMany(action.page.orders, state)
  ),
  on(
    TasksReportApiActions.loadTasksReportSuccess,
    (state, action): OrdersState =>
      adapter.upsertMany(
        action.page.orders.map(
          (order): OrderEntity => ({
            id: order.id,
            orderStatusId: order.orderStatusId,
            description: order.description,
            modificationTime: order.modificationTime,
            customerName: order.customerName,
            customerId: order.customer?.id ?? null,
            externalNumber: order.externalNumber,
            invoiceNumber: order.invoiceNumber,
            contactPerson: order.contactPerson,
            phoneNumber: order.phoneNumber,
            externalId: order.externalId,
            providerName: order.providerName,
            address: order.address ?? null,
            sacId: order.sacId,
          })
        ),
        state
      )
  ),
  on(
    OrderEditorOrdersApiActions.cancelOrderSuccess,
    OrderEditorOrdersApiActions.closeOrderSuccess,
    OrderEditorOrdersApiActions.updateOrderSuccess,
    (state, action): OrdersState => adapter.updateOne(action.order, state)
  ),
  on(NotesApiActions.loadNoteSuccess, (state, { noteForEditor: { order } }): OrdersState => {
    if (!order) {
      return state;
    }
    return adapter.upsertOne(mapToOrderEntity(order), state);
  })
);

export const { selectEntities } = adapter.getSelectors();
