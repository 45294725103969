import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ReasonCode } from 'geotask/core/models';
import { ReasonCodesApiActions } from '../actions';
import { ReasonCodeModel } from '../models';

export const reasonCodesFeatureKey = 'dictionaries.reasonCodes';

export type ReasonCodeIdByCode = { [reasonCode in ReasonCode]?: number };

export interface ReasonCodeState extends EntityState<ReasonCodeModel> {
  byCode: ReasonCodeIdByCode;
}

export const adapter: EntityAdapter<ReasonCodeModel> = createEntityAdapter<ReasonCodeModel>();

export const initialState: ReasonCodeState = adapter.getInitialState({
  byCode: {},
});

export const reducer = createReducer(
  initialState,
  on(ReasonCodesApiActions.loadReasonCodesSuccess, (state, action) =>
    adapter.setAll(action.reasonCodes, {
      ...state,
      byCode: Object.fromEntries(action.reasonCodes.map((status) => [status.code, status.id])),
    })
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
export const selectByCode = (state: ReasonCodeState) => state.byCode;
