import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Executor } from 'geotask/entities/models';

export const loadExecutorDataSuccess = createAction(
  '[Executors/Executors API] Load Executor Data Success',
  props<{ executor: Executor }>()
);
export const loadExecutorDataFailure = createAction(
  '[Executors/Executors API] Load Executor Data Failure',
  props<{ error: ApiError }>()
);

export const loadExecutorsSuccess = createAction(
  '[Executors/Executors API] Load Executors Success',
  props<{
    page: {
      pageNumber: number;
      pageSize: number;
      totalElements: number;
      executors: Executor[];
    };
  }>()
);
export const loadExecutorsFailure = createAction(
  '[Executors/Executors API] Load Executors Failure',
  props<{ error: ApiError }>()
);

export const saveExecutorDataSuccess = createAction(
  '[Executors/Executors API] Save Executor Data Success',
  props<{ login: string }>()
);
export const saveExecutorDataFailure = createAction(
  '[Executors/Executors API] Save Executor Data Failure',
  props<{ error: ApiError }>()
);

export const deactivateExecutorsSuccess = createAction('[Executors/Executors API] Deactivate executors Success');
export const deactivateExecutorsFailure = createAction(
  '[Executors/Executors API] Deactivate executors Failure',
  props<{ error: ApiError }>()
);
