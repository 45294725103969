import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

interface TokenResponse {
  token: string;
  daysUntilPasswordExpires: number | null;
}

interface Credentials {
  login: string;
  password: string | null;
  isSSO?: boolean;
  idToken?: string | null;
}

interface ChangePasswordData extends Credentials {
  passwordNew: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private readonly httpClient: HttpClient) {}

  generateAuthToken(credentials: Credentials) {
    return this.httpClient.post<TokenResponse>('@backend/auth/token', credentials);
  }

  refreshAuthToken() {
    return this.httpClient.post<TokenResponse>('@backend/auth/refresh', null).pipe(map(({ token }) => token));
  }

  changePassword(changePasswordData: ChangePasswordData) {
    return this.httpClient.post<void>('@backend/auth/changePassword', changePasswordData);
  }

  logoutAndInvalidateToken() {
    return this.httpClient.post<void>('@backend/auth/logout', null);
  }
}
