import { createEffect } from '@ngrx/effects';
import { Inject, Injectable, NgZone, Optional } from '@angular/core';

import { LOCATION, LocationTokenType } from '@glb/shared/web-api-browser';
import { StateSyncBaseEffects } from 'geotask/core/effects/state-sync-base.effects';
import { OptionalInjectionTokenType } from '@glb/util/types';
import * as WorkdayActions from '../actions/work-day.actions';
import { WorkDaysState } from '../reducers/work-day.reducer';
import { WORK_DAYS_STATE_SYNC_CHANNEL } from '../sync-channel';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class WorkDaysStateSyncEffects extends StateSyncBaseEffects<WorkDaysState> {
  syncWorkDayEntitiesState$ = createEffect(() => {
    return this.getEffect();
  });

  constructor(
    ngZone: NgZone,
    @Inject(LOCATION)
    location: LocationTokenType,
    @Optional()
    @Inject(WORK_DAYS_STATE_SYNC_CHANNEL)
    broadcastChannel: OptionalInjectionTokenType<typeof WORK_DAYS_STATE_SYNC_CHANNEL>
  ) {
    super(ngZone, location, broadcastChannel);
  }

  protected stateSyncAction(newState: WorkDaysState) {
    return WorkdayActions.syncWorkDayEntitiesState({ newState });
  }
}
