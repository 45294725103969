export enum TaskTypeCode {
  Planned = 'Planned',
  Emergency = 'Emergency',
  AdHoc = 'AdHoc',
  Service = 'Service',
  Transport = 'Transport',
  Combined = 'Combined',
  Diamond = 'Diamond',
  Circle = 'Circle',
  Square = 'Square',
  Triangle = 'Triangle',
  Heart = 'Heart',
  Iks = 'Iks',
  Radioactive = 'Radioactive',
  Ring = 'Ring',
  Sign = 'Sign',
  Star = 'Star',
}
