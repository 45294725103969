import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, rootEffectsInit } from '@ngrx/effects';
import { DOCUMENT } from '@angular/common';
import { map, switchMap, tap } from 'rxjs/operators';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { AppNameApiService } from 'geotask/backend/services/app-name-api.service';
import { CONSOLE, ConsoleTokenType } from '@glb/shared/web-api-browser';
import { AppNameApiActions } from '../actions';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AppInfoEffects {
  loadAppNameFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppNameApiActions.loadAppNameFailure),
        tap(({ error }) => this.console.error('Error during loading application name:', error))
      );
    },
    { dispatch: false }
  );

  loadAppNameSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppNameApiActions.loadAppNameSuccess),
        tap(({ appName }) => {
          const appFavicon = this.document.getElementById('appFavicon');
          if (appFavicon) {
            appFavicon.setAttribute('href', `assets/icons/logos/${appName}.png`);
          } else if (ngDevMode) {
            this.console.error(`Couldn't find app favicon element!`);
          }
        })
      );
    },
    { dispatch: false }
  );

  loadAppName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(rootEffectsInit),
      switchMap(() =>
        this.appNameService.getAppName().pipe(
          map(({ name: appName }) => AppNameApiActions.loadAppNameSuccess({ appName })),
          catchErrorToApiFailureAction(AppNameApiActions.loadAppNameFailure, 'Error during loading application name')
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly appNameService: AppNameApiService,
    @Inject(CONSOLE) private readonly console: ConsoleTokenType
  ) {}
}
