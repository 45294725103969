import { createAction, props } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { IkeaServiceKind } from '../../dictionaries/models/ikea-service-kind.model';

export const changePageNumber = createAction(
  '[ServiceKinds/IkeaServiceKindList] Change Page Number',
  props<Readonly<{ pageNumber: number }>>()
);

export const changeFilteredNamePrefix = createAction(
  '[ServiceKinds/IkeaServiceKindList] Change Filtered Name',
  props<Readonly<{ namePrefix: string }>>()
);

export const changeFilteredCodePrefix = createAction(
  '[ServiceKinds/IkeaServiceKindList] Change Filtered Code',
  props<Readonly<{ codePrefix: string }>>()
);

export const changeFilteredDuration = createAction(
  '[ServiceKinds/IkeaServiceKindList] Change Filtered Duration',
  props<Readonly<{ duration: number }>>()
);

export const changeFilteredSkills = createAction(
  '[ServiceKinds/IkeaServiceKindList] Change Filtered Skills',
  props<Readonly<{ skillsIds: number[] }>>()
);

export const changeFilteredTaskKinds = createAction(
  '[ServiceKinds/IkeaServiceKindList] Change Filtered Task Kinds',
  props<Readonly<{ kindIds: number[] }>>()
);

export const loadServices = createAction('[ServiceKinds/IkeaServiceKindList] Load Services List');

export const changeSort = createAction('[ServiceKinds/IkeaServiceKindList] Change Sort', props<{ sort: Sort }>());

export const addTaskKind = createAction('[ServiceKinds/IkeaServiceKindList] Add Service Kind');

export const editServiceKind = createAction(
  '[ServiceKinds/IkeaServiceKindList] Edit Service Kind',
  props<{ serviceKindId: number }>()
);

export const toggleServiceSelection = createAction(
  '[ServiceKinds/IkeaServiceKindList]  Toggle Service Selection',
  props<Readonly<{ serviceId: number }>>()
);

export const upsertServices = createAction(
  '[ServiceKinds/API] Upsert Services',
  props<{ services: IkeaServiceKind[] }>()
);
