import { Action, UPDATE } from '@ngrx/store';
import { defaultMergeReducer, LocalStorageConfig } from 'ngrx-store-localstorage';

interface UpdateReducersAction {
  readonly type: typeof UPDATE;
  readonly features: readonly string[];
}

const mergeReducerFactory = (featureKey: string) => (state: any, rehydratedState: any, action: Action) => {
  if (action.type === UPDATE && !(action as UpdateReducersAction).features.includes(featureKey)) {
    return state;
  }
  return defaultMergeReducer(state, rehydratedState, action);
};

export const rootStorageSyncConfig = {
  rehydrate: true,
  removeOnUndefined: true,
  restoreDates: false,
};

const featureStorageKeySerializer = (featureKey: string) => (key: string) => `${featureKey}.${key}`;

export const storageSyncConfig = (
  featureKey: string,
  {
    rehydrate = rootStorageSyncConfig.rehydrate,
    storageKeySerializer = featureStorageKeySerializer(featureKey),
    removeOnUndefined = rootStorageSyncConfig.removeOnUndefined,
    mergeReducer = mergeReducerFactory(featureKey),
    restoreDates = rootStorageSyncConfig.restoreDates,
    ...otherProperties
  }: LocalStorageConfig
): LocalStorageConfig => {
  return {
    rehydrate,
    storageKeySerializer,
    removeOnUndefined,
    mergeReducer,
    restoreDates,
    ...otherProperties,
  };
};
