import { createAction, props } from '@ngrx/store';
import type { ApiError } from 'geotask/backend/models';

export const deleteNoteAttachmentSuccess = createAction(
  '[Attachments API] Delete Note Attachment Success',
  props<{ noteId: number; attachmentId: number }>()
);
export const deleteNoteAttachmentFailure = createAction(
  '[Attachments API] Delete Note Attachment Failure',
  props<{ error: ApiError }>()
);
