import { z } from 'zod';

import { Unwrapped } from '@glb/util/types';
import { SettingName } from 'geotask/core/models';

const AppSettingSchema = z
  .object({
    name: z.nativeEnum(SettingName),
    value: z
      .string()
      .nullable()
      .transform((value) => value ?? ''),
  })
  .brand<'AppSetting'>();
type AppSettingSchema = typeof AppSettingSchema;

export type AppSetting = z.TypeOf<AppSettingSchema>;

export function createAppSetting(setting: Unwrapped<AppSettingSchema>['_input']) {
  return AppSettingSchema.parse(setting);
}
