import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatSidenav } from '@angular/material/sidenav';

import { Routes } from 'geotask/core/models';
import { Version } from 'geotask/core/services/version';
import { AuthSelectors } from 'geotask/core/selectors';

@Component({
  selector: 'glb-account-action-list',
  templateUrl: './account-action-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountActionListComponent {
  Routes = Routes;
  readonly loggedExecutor$ = this.store$.select(AuthSelectors.selectUserLogin);

  constructor(private readonly store$: Store, private matSidenav: MatSidenav, readonly version: Version) {}

  changePasswordClicked() {
    return this.matSidenav.close();
  }
}
