import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { TasksPageResponse } from 'geotask/backend/services/tasks-api.service';
import type { ContactPerson, Place } from 'geotask/entities/models';

export const PlacesApiActions = createActionGroup({
  source: 'Places/Places API',
  events: {
    'Save Place Success': props<{
      place: Place;
      contactPersonsToUpdate: ContactPerson[];
      contactPersonIdsToRemove: number[];
    }>(),
    'Save Place Failure': props<{ error: ApiError }>(),

    'Toggle Place Active Success': props<{ id: number; active: boolean }>(),
    'Toggle Place Active Failure': props<{ error: ApiError }>(),

    'Load Place Success': props<{ place: Place; contactPersons: ContactPerson[] }>(),
    'Load Place Failure': props<{ error: ApiError }>(),
  },
});

export const TasksApiActions = createActionGroup({
  source: 'Places/Tasks API',
  events: {
    'Load on going tasks success': props<{ page: TasksPageResponse }>(),
    'Load tasks history success': props<{ page: TasksPageResponse }>(),
  },
});
