import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { not, or, urlTree } from '@glb/util/angular';
import { AuthenticatedGuard } from './core/guards/authenticated.guard';
import { hasRole } from './core/guards/has-role.guard';
import { LoggedOutGuard } from './core/guards/logged-out.guard';
import { moduleEnabled } from './core/guards/module-enabled.guard';
import { HomePageGuard } from './core/guards/home-page.guard';
import { ModuleName, RoleCode, Routes as AppRoutes } from './core/models';
import { PageNotFoundComponent } from './page-not-found.component';
import { TerrainTemplateEmptyComponent } from './terrain-template-empty.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `/${AppRoutes.Dashboard}`,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.Help,
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  {
    path: AppRoutes.MobileHelp,
    loadChildren: () => import('./help-mobile/help-mobile.module').then((m) => m.HelpMobileModule),
  },
  {
    path: AppRoutes.Login,
    canActivate: [LoggedOutGuard],
    canActivateChild: [LoggedOutGuard],
    loadChildren: () => import(/* webpackPrefetch: true */ './login').then((m) => m.LoginModule),
  },
  {
    path: '',
    canActivateChild: [AuthenticatedGuard],
    children: [
      {
        path: AppRoutes.Dashboard,
        loadChildren: () => import(/* webpackPrefetch: 1 */ './dashboard').then((m) => m.DashboardModule),
        canActivate: [HomePageGuard],
        canLoad: [AuthenticatedGuard, HomePageGuard, not(hasRole(RoleCode.TasksReadOnly))],
      },
      {
        path: AppRoutes.TerrainTemplate,
        component: TerrainTemplateEmptyComponent,
      },
      {
        path: AppRoutes.Tasks,
        loadChildren: () => import(/* webpackPrefetch: true */ './tasks').then((m) => m.TasksModule),
        canLoad: [AuthenticatedGuard],
      },
      {
        path: AppRoutes.Orders,
        loadChildren: () => import('./orders').then((m) => m.OrdersModule),
        canLoad: [AuthenticatedGuard],
      },
      {
        path: AppRoutes.Schedule,
        loadChildren: () => import('./schedule').then((m) => m.ScheduleModule),
        canActivate: [
          not(hasRole(RoleCode.ReadOnly)),
          not(hasRole(RoleCode.TasksReadOnly)),
          moduleEnabled(ModuleName.Teams),
        ],
        canLoad: [
          AuthenticatedGuard,
          not(hasRole(RoleCode.ReadOnly)),
          moduleEnabled(ModuleName.Teams),
          not(hasRole(RoleCode.TasksReadOnly)),
        ],
        canActivateChild: [
          not(hasRole(RoleCode.ReadOnly)),
          not(hasRole(RoleCode.TasksReadOnly)),
          moduleEnabled(ModuleName.Teams),
        ],
      },
      {
        path: AppRoutes.Notes,
        loadChildren: () => import('./notes').then((m) => m.NotesModule),
        canActivate: [moduleEnabled(ModuleName.Notes)],
        canLoad: [AuthenticatedGuard, moduleEnabled(ModuleName.Notes)],
        canActivateChild: [moduleEnabled(ModuleName.Notes)],
      },
      {
        path: AppRoutes.Reports,
        canLoad: [not(hasRole(RoleCode.TasksReadOnly))],
        children: [
          {
            path: AppRoutes.TasksReport,
            loadChildren: () => import('./tasks-report').then((m) => m.TasksReportModule),
            canLoad: [AuthenticatedGuard],
          },
          {
            path: AppRoutes.GpsReport,
            loadChildren: () => import('./gps-report').then((m) => m.GpsReportModule),
            canLoad: [AuthenticatedGuard],
          },
          {
            path: AppRoutes.ProductivityReport,
            loadChildren: () => import('./productivity-report').then((m) => m.ProductivityReportModule),
            canLoad: [AuthenticatedGuard],
          },
          {
            path: AppRoutes.RodoReport,
            loadChildren: () => import('./rodo-report').then((m) => m.RodoReportModule),
            canActivate: [moduleEnabled(ModuleName.ReportRodo)],
            canLoad: [AuthenticatedGuard, moduleEnabled(ModuleName.ReportRodo)],
            canActivateChild: [moduleEnabled(ModuleName.ReportRodo)],
          },
          {
            path: AppRoutes.FormsReport,
            loadChildren: () => import('./forms-report').then((m) => m.FormsReportModule),
            canLoad: [AuthenticatedGuard],
          },
          {
            path: AppRoutes.CarTrackingReport,
            loadChildren: () => import('./car-tracking-report').then((m) => m.CarTrackingReportModule),
            canActivate: [moduleEnabled(ModuleName.CarTracking)],
            canLoad: [AuthenticatedGuard, moduleEnabled(ModuleName.CarTracking)],
            canActivateChild: [moduleEnabled(ModuleName.CarTracking)],
          },
          {
            path: AppRoutes.OptimizationsReport,
            loadChildren: () => import('./optimizations-report').then((m) => m.OptimizationsReportModule),
            canLoad: [AuthenticatedGuard],
          },
          {
            path: AppRoutes.DispatcherNotificationsReport,
            loadChildren: () =>
              import('./dispatcher-notifications-report').then((m) => m.DispatcherNotificationsReportModule),
            canLoad: [AuthenticatedGuard],
          },
        ],
      },
      {
        path: AppRoutes.Settings,
        loadChildren: () => import('./settings').then((m) => m.SettingsModule),
        canActivate: [hasRole(RoleCode.Admin)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin)],
      },
      {
        path: AppRoutes.Executors,
        loadChildren: () => import('./executors').then((m) => m.ExecutorsModule),
        canLoad: [AuthenticatedGuard, not(hasRole(RoleCode.TasksReadOnly))],
      },
      {
        path: AppRoutes.Teams,
        loadChildren: () => import('./teams').then((m) => m.TeamsModule),
        canActivate: [moduleEnabled(ModuleName.Teams), not(hasRole(RoleCode.TasksReadOnly))],
        canLoad: [AuthenticatedGuard, moduleEnabled(ModuleName.Teams), not(hasRole(RoleCode.TasksReadOnly))],
        canActivateChild: [moduleEnabled(ModuleName.Teams), not(hasRole(RoleCode.TasksReadOnly))],
      },
      {
        path: AppRoutes.Areas,
        loadChildren: () => import('./areas').then((m) => m.AreasModule),
        canActivate: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Areas)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Areas)],
        canActivateChild: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Areas)],
      },
      {
        path: AppRoutes.Customers,
        loadChildren: () => import('./customers').then((m) => m.CustomersModule),
        canActivate: [moduleEnabled(ModuleName.Places)],
        canLoad: [AuthenticatedGuard, moduleEnabled(ModuleName.Places)],
        canActivateChild: [moduleEnabled(ModuleName.Places)],
      },
      {
        path: AppRoutes.Places,
        loadChildren: () => import('./places').then((m) => m.PlacesModule),
        canActivate: [moduleEnabled(ModuleName.Places)],
        canLoad: [AuthenticatedGuard, moduleEnabled(ModuleName.Places)],
        canActivateChild: [moduleEnabled(ModuleName.Places)],
      },
      {
        path: AppRoutes.TaskKinds,
        loadChildren: () => import('./task-kinds').then((m) => m.TaskKindsModule),
        canActivate: [hasRole(RoleCode.Admin)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin)],
        canActivateChild: [hasRole(RoleCode.Admin)],
      },
      {
        path: AppRoutes.CapacityConfiguration,
        loadChildren: () => import('./capacity-configuration').then((m) => m.CapacityConfigurationModule),
        canActivate: [hasRole(RoleCode.Admin)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin)],
        canActivateChild: [hasRole(RoleCode.Admin)],
      },
      {
        path: AppRoutes.PostalCodesAfterSales,
        loadChildren: () => import('./postal-codes-after-sales').then((m) => m.PostalCodesModule),
        canActivate: [hasRole(RoleCode.Admin)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin)],
        canActivateChild: [hasRole(RoleCode.Admin)],
      },
      {
        path: AppRoutes.PostalCodesFurniture,
        loadChildren: () => import('./postal-codes-furniture').then((m) => m.PostalCodesModule),
        canActivate: [hasRole(RoleCode.Admin)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin)],
        canActivateChild: [hasRole(RoleCode.Admin)],
      },
      {
        path: AppRoutes.PostalCodesKitchen,
        loadChildren: () => import('./postal-codes-kitchen').then((m) => m.PostalCodesModule),
        canActivate: [hasRole(RoleCode.Admin)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin)],
        canActivateChild: [hasRole(RoleCode.Admin)],
      },
      {
        path: AppRoutes.Skills,
        loadChildren: () => import('./skills').then((m) => m.SkillsModule),
        canActivate: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Skills)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Skills)],
        canActivateChild: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Skills)],
      },
      {
        path: AppRoutes.Companies,
        loadChildren: () => import('./companies').then((m) => m.CompaniesModule),
        canActivate: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Skills)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Skills)],
        canActivateChild: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Skills)],
      },
      {
        path: AppRoutes.PlaceTypes,
        loadChildren: () => import('./place-types').then((m) => m.PlaceTypesModule),
        canActivate: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Places)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Places)],
        canActivateChild: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.Places)],
      },
      {
        path: AppRoutes.SystemDictionaries,
        loadChildren: () => import('./system-dictionaries').then((m) => m.SystemDictionariesModule),
        canActivate: [hasRole(RoleCode.Admin)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin)],
        canActivateChild: [hasRole(RoleCode.Admin)],
      },
      {
        path: AppRoutes.FormTemplates,
        loadChildren: () => import('./form-templates').then((m) => m.FormTemplatesModule),
        canActivate: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.FormTemplates)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin), moduleEnabled(ModuleName.FormTemplates)],
        canActivateChild: [hasRole(RoleCode.Admin), moduleEnabled(ModuleName.FormTemplates)],
      },
      {
        path: AppRoutes.Loader,
        loadChildren: () => import('./loader').then((m) => m.LoaderModule),
        canActivate: [not(hasRole(RoleCode.ReadOnly))],
        canLoad: [AuthenticatedGuard, not(hasRole(RoleCode.ReadOnly))],
        canActivateChild: [not(hasRole(RoleCode.ReadOnly))],
      },
      {
        path: AppRoutes.OptimizationPlans,
        loadChildren: () => import('./optimization-plans').then((m) => m.OptimizationPlansModule),
        canActivate: [hasRole(RoleCode.Admin)],
        canLoad: [AuthenticatedGuard, hasRole(RoleCode.Admin)],
        canActivateChild: [hasRole(RoleCode.Admin)],
      },
      {
        path: AppRoutes.AppointmentSlots,
        loadChildren: () => import('./appointment-slots').then((m) => m.AppointmentSlotsModule),
        canActivate: [moduleEnabled(ModuleName.Appointments)],
        canLoad: [AuthenticatedGuard, moduleEnabled(ModuleName.Appointments)],
        canActivateChild: [moduleEnabled(ModuleName.Appointments)],
      },
      {
        path: AppRoutes.AppointmentNotifications,
        loadChildren: () => import('./notification-configuration').then((m) => m.NotificationConfigurationModule),
        canActivate: [moduleEnabled(ModuleName.Appointments)],
        canLoad: [AuthenticatedGuard, moduleEnabled(ModuleName.Appointments)],
        canActivateChild: [moduleEnabled(ModuleName.Appointments)],
      },
      {
        path: AppRoutes.Admin,
        loadChildren: () => import('./root-user-dashboard').then((m) => m.RootUserDashboardModule),
        canActivate: [or(hasRole(RoleCode.SuperUser), urlTree(['/']))],
        canLoad: [AuthenticatedGuard, or(hasRole(RoleCode.SuperUser), urlTree(['/']))],
        canActivateChild: [or(hasRole(RoleCode.SuperUser), urlTree(['/']))],
      },
      {
        // Needed for hash routing
        path: 'error',
        canActivate: [LoggedOutGuard],
        canActivateChild: [LoggedOutGuard],
        loadChildren: () => import(/* webpackPrefetch: true */ './login').then((m) => m.LoginModule),
      },
      {
        // Needed for hash routing
        path: 'state',
        canActivate: [LoggedOutGuard],
        canActivateChild: [LoggedOutGuard],
        loadChildren: () => import(/* webpackPrefetch: true */ './login').then((m) => m.LoginModule),
      },
      {
        // Needed for hash routing
        path: 'code',
        canActivate: [LoggedOutGuard],
        canActivateChild: [LoggedOutGuard],
        loadChildren: () => import(/* webpackPrefetch: true */ './login').then((m) => m.LoginModule),
      },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
