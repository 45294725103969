import { ActionReducer, createReducer, on } from '@ngrx/store';

import { AuthApiActions, LOGOUT_ACTIONS } from '../actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  token: string | null;
}

export const initialState: AuthState = {
  token: null,
};

export const reducer: ActionReducer<AuthState> = createReducer(
  initialState,
  on(
    AuthApiActions.logInSuccess,
    AuthApiActions.refreshAuthTokenSuccess,
    (_state, { token }): AuthState => ({ token })
  ),
  on(
    ...LOGOUT_ACTIONS,
    (state): AuthState => ({
      ...state,
      token: null,
    })
  )
);
