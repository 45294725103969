import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MultidictionaryApiActions } from '../actions';
import { IkeaServiceKind } from '../models/ikea-service-kind.model';
import * as ServiceKindApiActions from 'geotask/capacity-configuration/actions/service-kinds-api.actions';
import * as ServicesKindActions from 'geotask/capacity-configuration/actions/ikea-service-kind-list.actions';

export const ikeaServiceKindsFeatureKey = 'dictionaries.ikeaServiceKind';

export type IkeaServiceKindsState = EntityState<IkeaServiceKind>;

export const adapter: EntityAdapter<IkeaServiceKind> = createEntityAdapter<IkeaServiceKind>();

export const initialState: IkeaServiceKindsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) =>
    adapter.setAll(action.ikeaServicesKind, state)
  ),
  on(
    ServicesKindActions.upsertServices,
    (state, { services }): IkeaServiceKindsState => adapter.upsertMany(services, state)
  ),
  on(ServiceKindApiActions.updateServiceKindSuccess, (state, action) => adapter.updateOne(action.serviceKind, state))
);

export const { selectEntities, selectTotal, selectAll } = adapter.getSelectors();
