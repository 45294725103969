import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createArraySelector, createEntityByIdSelector } from 'geotask/core/selectors/selector-factories';
import * as fromPlaceType from '../reducers/place-type.reducer';

export const selectPlaceTypeState = createFeatureSelector<fromPlaceType.PlaceTypesState>(
  fromPlaceType.placeTypesFeatureKey
);
export const selectDictionary = createSelector(selectPlaceTypeState, fromPlaceType.selectEntities);
export const selectEntities = createSelector(selectPlaceTypeState, fromPlaceType.selectEntities);
export const selectAll = createArraySelector(selectPlaceTypeState, fromPlaceType.selectAll);
export const selectPlaceTypeById = createEntityByIdSelector(selectEntities);
export const selectActivePlaceTypes = createArraySelector(selectAll, (placeType) =>
  placeType.filter((team) => team.active)
);
