import { InjectionToken } from '@angular/core';

export interface DateFormats {
  display: {
    input: any;
  };
  parse: {
    dateInput: any;
  };
}

export const DATE_FORMATS = new InjectionToken<DateFormats>('Datetimepicker formats');
