import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createArraySelector } from 'geotask/core/selectors/selector-factories';
import * as fromFormTemplate from '../reducers/form-template.reducer';

const selectFormTemplateState = createFeatureSelector<fromFormTemplate.FormTemplatesState>(
  fromFormTemplate.formTemplatesFeatureKey
);

export const selectEntities = createSelector(selectFormTemplateState, fromFormTemplate.selectEntities);

export const selectAll = createArraySelector(selectFormTemplateState, fromFormTemplate.selectAll);

const selectFormTemplatesByNameDictionary = createSelector(selectAll, (formTemplates) =>
  formTemplates.reduce(
    (
      dictionary: { [formTemplateName: string]: readonly number[] | undefined },
      { id: formTemplateId, name: formTemplateName }
    ) => {
      formTemplateName = formTemplateName.toLocaleLowerCase().trim();
      let ids = dictionary[formTemplateName] ?? [];
      if (!ids.includes(formTemplateId)) {
        ids = [...ids, formTemplateId];
      }
      return {
        ...dictionary,
        [formTemplateName]: ids,
      };
    },
    {}
  )
);

export const selectFormTemplatesIdsByName = (name: string) => {
  name = name.toLocaleLowerCase().trim();
  return createSelector(selectFormTemplatesByNameDictionary, (dictionary) => dictionary[name] ?? []);
};
