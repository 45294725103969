import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject, switchMap } from 'rxjs';

import { ConditionalBaseDirective } from '@glb/util/angular';
import { RoleCode } from 'geotask/core/models';
import { AuthService } from 'geotask/core/services/auth.service';

@Directive({
  selector: '[glbIfHasAnyOfRoles]',
  standalone: true,
})
export class IfHasAnyOfRolesDirective extends ConditionalBaseDirective implements OnDestroy {
  @Input()
  set glbIfHasAnyOfRoles(roles: RoleCode[]) {
    this.roleSubject.next(roles);
  }

  private readonly roleSubject = new ReplaySubject<RoleCode[]>(1);

  constructor(
    private readonly authService: AuthService,
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<any>,
    changeDetector: ChangeDetectorRef
  ) {
    super(viewContainer, changeDetector, templateRef);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.roleSubject.complete();
  }

  protected getObservableCondition() {
    return this.roleSubject.asObservable().pipe(switchMap((roles) => this.authService.hasAnyOfRoles(roles)));
  }
}
