export enum UnavailabilityRepetitiveness {
  EveryDay = 'EVERY_DAY',
  EveryMonday = 'EVERY_MONDAY',
  EveryTuesday = 'EVERY_TUESDAY',
  EveryWednesday = 'EVERY_WEDNESDAY',
  EveryThursday = 'EVERY_THURSDAY',
  EveryFriday = 'EVERY_FRIDAY',
  EverySaturday = 'EVERY_SATURDAY',
  EverySunday = 'EVERY_SUNDAY',
}

export const REPETITIVENESS_DAYS_OF_WEEK = {
  [UnavailabilityRepetitiveness.EveryDay]: new Set([1, 2, 3, 4, 5, 6, 7]),
  [UnavailabilityRepetitiveness.EveryMonday]: new Set([1]),
  [UnavailabilityRepetitiveness.EveryTuesday]: new Set([2]),
  [UnavailabilityRepetitiveness.EveryWednesday]: new Set([3]),
  [UnavailabilityRepetitiveness.EveryThursday]: new Set([4]),
  [UnavailabilityRepetitiveness.EveryFriday]: new Set([5]),
  [UnavailabilityRepetitiveness.EverySaturday]: new Set([6]),
  [UnavailabilityRepetitiveness.EverySunday]: new Set([7]),
} as const;
