import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as fromModules from '../reducers/modules.reducer';
import { ModuleName } from '../models';
import { createArraySelector } from 'geotask/core/selectors/selector-factories';

export const selectModulesState = createFeatureSelector<fromModules.ModulesState>('modules');

export const selectIsModuleEnabled = (moduleName: ModuleName) =>
  createSelector(selectModulesState, (modules) => modules?.[moduleName]);
const selectAreModulesEnabled = (moduleNames: readonly ModuleName[]) =>
  createArraySelector(selectModulesState, (modules) => moduleNames.map((moduleName) => modules?.[moduleName]));

export const areModulesEnabled = (moduleNames: readonly ModuleName[]) =>
  pipe(
    select(selectAreModulesEnabled(moduleNames)),
    filter((modules): modules is boolean[] =>
      modules.every((module): module is boolean => typeof module === 'boolean')
    ),
    map((modules) => modules.every((module) => module))
  );

export const selectTeamsModuleEnabled = createSelector(
  selectIsModuleEnabled(ModuleName.Teams),
  (moduleEnabled) => moduleEnabled ?? false
);
