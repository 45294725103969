import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { createPlace, PlacesApiService } from 'geotask/backend/services/places-api.service';
import { TaskCreatorActions, TaskEditorActions } from 'geotask/tasks/actions';
import * as PlacesApiActions from '../actions/places-api.actions';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class PlacesEffects {
  readonly loadPlaces$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TaskEditorActions.taskEditorOpen, TaskCreatorActions.taskCreatorOpened),
      switchMap(() =>
        this.placesApiService.loadAllPlacesPages().pipe(
          map((places) =>
            PlacesApiActions.loadAllPlacesSuccess({
              places: places.map(createPlace),
            })
          ),
          catchErrorToApiFailureAction(PlacesApiActions.loadAllPlacesFailure)
        )
      )
    );
  });

  constructor(private actions$: Actions, private placesApiService: PlacesApiService) {}
}
