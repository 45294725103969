import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SwitchableComponent } from '@glb/util/angular';

@Component({
  selector: 'glb-composite-nav-item',
  templateUrl: './composite-nav-item.component.html',
  styleUrls: ['./composite-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositeNavItemComponent extends SwitchableComponent {
  @Input() iconName: string | null | undefined;
  @Input() linkName: string | null | undefined;

  constructor() {
    super();
    this.opened = false;
  }
}
