import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DateTime, DurationLike } from 'luxon';
import { NEVER, timer } from 'rxjs';
import { concatMap, map, mergeMap, switchMap } from 'rxjs/operators';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { AuthApiService } from 'geotask/backend/services/auth-api.service';
import { AuthActions, AuthApiActions, BackendApiActions, BrowserActions } from '../actions';
import { Routes } from '../models';
import { AuthSelectors } from '../selectors';
import { NotificationsService } from '../services/notifications.service';

const REFRESH_BEFORE_EXPIRATION: DurationLike = { seconds: 30 };

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AuthEffects {
  authTokenExpired$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BrowserActions.authTokenExpired),
        mergeMap(() =>
          this.notificationsService.openToastr({
            messageCode: 'core.token-expired',
            type: 'warning',
          })
        )
      );
    },
    { dispatch: false }
  );

  tokenExpirationDateChange$ = createEffect(() => {
    return this.store$.select(AuthSelectors.selectTokenExpirationDate).pipe(
      switchMap((expirationDate) => {
        if (!expirationDate) {
          return NEVER;
        }
        return timer(expirationDate).pipe(map(() => BrowserActions.authTokenExpired()));
      })
    );
  });

  upcomingAuthTokenExpiration$ = createEffect(() => {
    return this.store$.select(AuthSelectors.selectTokenExpirationDate).pipe(
      switchMap((expirationDate) => {
        if (!expirationDate) {
          return NEVER;
        }
        const expirationDateTime = DateTime.fromJSDate(expirationDate);
        if (expirationDateTime <= DateTime.local()) {
          return NEVER;
        }
        const beforeExpiration = expirationDateTime.minus(REFRESH_BEFORE_EXPIRATION);
        return timer(beforeExpiration.toJSDate()).pipe(map(() => AuthActions.upcomingAuthTokenExpiration()));
      })
    );
  });

  refreshAuthToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.upcomingAuthTokenExpiration),
      switchMap(() =>
        this.authApiService.refreshAuthToken().pipe(
          map((token) => AuthApiActions.refreshAuthTokenSuccess({ token })),
          catchErrorToApiFailureAction(AuthApiActions.refreshAuthTokenFailure)
        )
      )
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      concatMap(() =>
        this.authApiService.logoutAndInvalidateToken().pipe(
          map(() => AuthApiActions.logoutSuccess()),
          catchErrorToApiFailureAction(AuthApiActions.logoutFailure, 'Unknown error occurred during logging out')
        )
      )
    );
  });

  logoutEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BackendApiActions.unauthorizedResponse, BrowserActions.authTokenExpired),
        mergeMap(() => this.router.navigate(['/', Routes.Login]))
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly router: Router,
    private readonly notificationsService: NotificationsService,
    private readonly authApiService: AuthApiService
  ) {}
}
