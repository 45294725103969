import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Customer, OrderEntity, Place, TaskEntity, TeamEntity } from 'geotask/entities/models';

export const loadTasksPageSuccess = createAction(
  '[Tasks API] Load Tasks Page Success',
  props<{
    readonly page: {
      readonly pageNumber: number;
      readonly pageSize: number;
      readonly totalElements: number;
      readonly tasks: TaskEntity[];
      readonly places: Place[];
      readonly orders: OrderEntity[];
      readonly customers: Customer[];
      readonly teams: TeamEntity[];
    };
  }>()
);
export const loadTasksPageFailure = createAction('[Tasks API] Load Tasks Page Failure', props<{ error: ApiError }>());
