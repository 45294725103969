import { z } from 'zod';

import { FieldValueTypeCode } from 'geotask/core/models';
import { DictionaryEntrySchema } from 'geotask/core/schemas/dictionary-entry.schema';

export const TaskAdditionalFieldTypeWebObjectSchema = DictionaryEntrySchema.extend({
  name: z.string().nullable(),
  code: z.nativeEnum(FieldValueTypeCode),
  dictionaryRequired: z.boolean().nullable(),
});
