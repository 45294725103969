import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  createNgModule,
  Injector,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { filterFalsy, filterTruthy } from '@glb/util/rx-operators';
import { combineLatest, from, map, Subject, switchMap } from 'rxjs';

import { ModuleName } from './core/models';
import { ModulesService } from './core/services/modules.service';
import { AuthService } from './core/services/auth.service';
import { ToolbarPortalService } from './core/services/toolbar-portal.service';
import { AppSelectors, AuthSelectors } from './core/selectors';

@UntilDestroy()
@Component({
  selector: 'glb-geotask-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  @ViewChild('notificationsAnchor', { read: ViewContainerRef, static: true })
  notificationsAnchor!: ViewContainerRef;

  @ViewChild('notificationsToggleAnchor', { read: ViewContainerRef })
  notificationsToggleAnchor!: ViewContainerRef;

  @ViewChild('notificationsSidenav', { static: true })
  notificationsSidenav!: MatSidenav;

  protected sidenavMenuCloseAllSubject = new Subject<boolean>();

  readonly vm$ = this.store$.select(AppSelectors.selectRootViewModel);

  readonly toolbarContent$ = this.toolbarPortalService.portal$;

  readonly terrainOnly$ = this.store$.select(AuthSelectors.selectIsTerrainOnly);
  readonly shouldCreatNotificationsToggleAnchor$ = this.store$.select(
    AuthSelectors.selectIsNotTerrainOnlyAndIsLoggedIn
  );

  constructor(
    private readonly toolbarPortalService: ToolbarPortalService,
    private readonly store$: Store,
    private readonly injector: Injector,
    private readonly modulesService: ModulesService,
    private readonly authService: AuthService
  ) {
    this.sidenavMenuCloseAllSubject.next(true);
  }

  ngAfterViewInit() {
    this.authService.canAccessApplication$.pipe(filterFalsy(), untilDestroyed(this)).subscribe(() => {
      this.notificationsSidenav.close();
      this.notificationsAnchor.clear();
      this.notificationsToggleAnchor.clear();
    });

    combineLatest([
      this.modulesService.isModuleEnabled(ModuleName.DispatcherNotifications),
      this.shouldCreatNotificationsToggleAnchor$,
    ])
      .pipe(
        map(
          ([isDispatcherModuleEnabled, shouldCreatNotificationsToggleAnchor]) =>
            isDispatcherModuleEnabled && shouldCreatNotificationsToggleAnchor
        ),
        filterTruthy(),
        switchMap(() => from(import('./dispatcher-notifications'))),
        untilDestroyed(this)
      )
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ({ DispatcherNotificationsModule }) => {
          const ngModuleRef = createNgModule(DispatcherNotificationsModule, this.injector);
          const componentRef = this.notificationsAnchor.createComponent(
            DispatcherNotificationsModule.getNotificationsComponent(),
            { ngModuleRef }
          );
          const toggleComponentRef = this.notificationsToggleAnchor.createComponent(
            DispatcherNotificationsModule.getToggleComponent(),
            { ngModuleRef }
          );
          toggleComponentRef.instance.setSidenav(this.notificationsSidenav);
          toggleComponentRef.instance.setDispatcherNotifications(componentRef.instance);
        }
      );
  }
}
