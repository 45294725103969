export enum LoaderDataType {
  Tasks = 'tasks',
  Teams = 'teams',
  Executors = 'executors',
  Customers = 'customers',
  Locations = 'locations',
  PostalCodes = 'postal-codes',
  PostalCodesKitchen = 'postal-codes/kitchen',
  PostalCodesFurnitureAssembly = 'postal-codes/furniture',
  PostalCodesAfterSales = 'postal-codes/after-sales',
}
