import { EqualsFn, RangeOf } from '@glb/util/types';

export const referenceEquality = (value: unknown, anotherValue: unknown) => value === anotherValue;

export const deepEqualArrays = <TElement>(
  a: readonly TElement[],
  b: readonly TElement[],
  elementComparator: EqualsFn<TElement> = referenceEquality
) => a === b || (a.length === b.length && a.every((val, idx) => elementComparator(val, b[idx])));

export const equalsSets = <T>(a: Set<T>, b: Set<T>) =>
  a === b || (a.size === b.size && Array.from(a).every((val) => b.has(val)));

export const dateEquals = <TDate extends Date | null | undefined>(date: TDate, anotherDate: TDate) =>
  date === anotherDate || date?.valueOf() === anotherDate?.valueOf();

export const rangeEquals =
  <TRangeType>(equalsFn: EqualsFn<TRangeType>): EqualsFn<RangeOf<TRangeType>> =>
  (a, b) =>
    equalsFn(a.start, b.start) && equalsFn(a.end, b.end);
