import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';

export const requestEndPlanning = createAction(
  '[DashboardScheduler/TeamContextMenu] Request end planning',
  props<{ teamIds: number[]; from: string; to: string }>()
);

export const endPlanningSuccess = createAction('[End Planning API] End Planning Success');
export const endPlanningFailure = createAction(
  '[End Planning API] End Plannings Failure',
  props<{ error: ApiError }>()
);

export const requestLocalOptimize = createAction(
  '[DashboardScheduler/TeamContextMenu] Request local optimize',
  props<{
    startDate: string;
    endDate: string;
    teamId: number;
    workDayId: number;
  }>()
);

export const localOptimizeSuccess = createAction(
  '[Local Optimize API] Local optimize success',
  props<{
    workDayId: number;
    unassignedTaskIds: readonly number[];
  }>()
);
export const localOptimizeFailure = createAction(
  '[Local Optimize API] Local optimize failure',
  props<{
    error: ApiError;
    workDayId: number;
  }>()
);

export const editTeam = createAction(
  '[DashboardScheduler/TeamContextMenu] Edit team',
  props<{ readonly teamId: number; readonly teamName: string }>()
);
export const editTeamUnavailabilities = createAction(
  '[DashboardScheduler/TeamContextMenu] Edit Team Unavailabilities',
  props<{ readonly teamId: number; readonly teamName: string }>()
);

export const openTrackSummary = createAction(
  '[DashboardScheduler/TeamContextMenu] Open Track Summary',
  props<{ readonly teamId: number }>()
);

interface RouteToShow {
  readonly teamId: number;
  readonly taskIds: readonly number[];
}

export const showTeamRoutes = createAction(
  '[DashboardScheduler/TeamContextMenu] Show Team Routes',
  props<{
    readonly routes: readonly RouteToShow[];
  }>()
);

export const assignSelectedTasks = createAction('[DashboardScheduler/TeamContextMenu] Assign Selected Tasks');
