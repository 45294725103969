import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Executor, TeamEntity } from 'geotask/entities/models';
import type { CarTrackingReportWebObject } from '../services/car-tracking-report-api.service';

export const loadTeamsForCarTrackingReportSuccess = createAction(
  '[Car Tracking Report API] Load Teams For Car Tracking Report Success',
  props<{ teams: TeamEntity[]; executors: Executor[] }>()
);

export const loadTeamsForCarTrackingReportFailure = createAction(
  '[Car Tracking Report API] Load Teams For Car Tracking Report Failure',
  props<{ error: ApiError }>()
);

export const loadCarTrackingReportSuccess = createAction(
  '[Car Tracking Report API] Load Car Tracking Report Success',
  props<{ report: CarTrackingReportWebObject }>()
);

export const loadCarTrackingReportFailure = createAction(
  '[Car Tracking Report API] Load Car Tracking Report Failure',
  props<{ error: ApiError }>()
);
