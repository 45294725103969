import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';

export const modifySmsLimitSuccess = createAction(
  '[Settings API] Modify SMS Limit Success',
  props<{ newSmsLimit: number }>()
);
export const modifySmsLimitFailure = createAction(
  '[Settings API] Modify SMS Limit Failure',
  props<{ error: ApiError }>()
);

export const modifyNotificationsHoursSuccess = createAction(
  '[Settings API] Modify Notifications Hours Success',
  props<{ notificationsHours: string }>()
);
export const modifyNotificationsHoursFailure = createAction(
  '[Settings API] Modify Notifications Hours Failure',
  props<{ error: ApiError }>()
);
