import { ArrayElementTypeOf } from '@glb/util/types';
import { isInValues } from '@glb/util/type-guards';

export enum TaskStatusCode {
  New = 'New',
  Accepted = 'Accepted',
  Assigned = 'Assigned',
  Planned = 'Planned',
  Rejected = 'Rejected',
  Drive = 'Drive',
  Started = 'Started',
  Comeback = 'ComeBack',
  Done = 'Done',
  Undone = 'Undone',
  Cancelled = 'Cancelled',
  MissingInformation = 'MissingInformation',
  Verified = 'Verified',
  ReceivedAtHub = 'ReceivedAtHub',
  LoadedOnDeliveryTruck = 'LoadedOnDeliveryTruck',
  InTheBase = 'InTheBase',
  Revoked = 'Revoked',
}

export const NEW_STATUS_CODES = [TaskStatusCode.New, TaskStatusCode.Rejected] as const;
export type NewStatusCode = ArrayElementTypeOf<typeof NEW_STATUS_CODES>;
export const isNewStatusCode = isInValues(NEW_STATUS_CODES);

export const ASSIGNED_STATUS_CODES = [TaskStatusCode.Assigned, TaskStatusCode.Accepted] as const;
export type AssignedStatusCode = ArrayElementTypeOf<typeof ASSIGNED_STATUS_CODES>;
export const isAssignedStatusCode = isInValues(ASSIGNED_STATUS_CODES);

export const WORKING_STATUS_CODES = [TaskStatusCode.Drive, TaskStatusCode.Started, TaskStatusCode.Comeback] as const;
export type WorkingStatusCode = ArrayElementTypeOf<typeof WORKING_STATUS_CODES>;
export const isWorkingStatusCode = isInValues(WORKING_STATUS_CODES);

export const STATUSES_FOR_OPTIMIZATION = [
  TaskStatusCode.New,
  TaskStatusCode.Verified,
  TaskStatusCode.ReceivedAtHub,
  TaskStatusCode.Planned,
  TaskStatusCode.Assigned,
  TaskStatusCode.Accepted,
  TaskStatusCode.Rejected,
] as const;
export type StatusForOptimization = ArrayElementTypeOf<typeof STATUSES_FOR_OPTIMIZATION>;
export const isStatusForOptimization = isInValues(STATUSES_FOR_OPTIMIZATION);

export const TERMINAL_STATUS_CODES = [TaskStatusCode.InTheBase, TaskStatusCode.Cancelled] as const;
export type TerminalStatusCode = ArrayElementTypeOf<typeof TERMINAL_STATUS_CODES>;
export const isTerminalStatusCode = isInValues(TERMINAL_STATUS_CODES);

export const APPOINTMENT_ENABLED_STATUS_CODES = [
  TaskStatusCode.Verified,
  TaskStatusCode.ReceivedAtHub,
  TaskStatusCode.New,
  TaskStatusCode.Rejected,
  TaskStatusCode.Planned,
  TaskStatusCode.Assigned,
  TaskStatusCode.Accepted,
] as const;
export type AppointmentEnabledStatusCode = ArrayElementTypeOf<typeof APPOINTMENT_ENABLED_STATUS_CODES>;
export const isAppointmentEnabledStatusCode = isInValues(APPOINTMENT_ENABLED_STATUS_CODES);

export const STATUS_ONLY_EDITABLE_STATUS_CODES = [
  TaskStatusCode.Done,
  TaskStatusCode.Comeback,
  TaskStatusCode.Cancelled,
  TaskStatusCode.Undone,
  TaskStatusCode.InTheBase,
  TaskStatusCode.Revoked,
] as const;
export const isOnlyStatusAndDescriptionEditable = isInValues(STATUS_ONLY_EDITABLE_STATUS_CODES);
export const isTerminalStatus = isInValues(STATUS_ONLY_EDITABLE_STATUS_CODES);

export const LOCK_EDITABLE_STATUS_CODES = [
  TaskStatusCode.Assigned,
  TaskStatusCode.Accepted,
  TaskStatusCode.Planned,
] as const;
export type LockEditableStatusCode = ArrayElementTypeOf<typeof LOCK_EDITABLE_STATUS_CODES>;
export const isLockEditableStatusCode = isInValues(LOCK_EDITABLE_STATUS_CODES);

export type MovableTaskStatusCode = Exclude<TaskStatusCode, WorkingStatusCode | TerminalStatusCode>;

export const ONGOING_TASK_STATUSES = [
  TaskStatusCode.New,
  TaskStatusCode.Planned,
  TaskStatusCode.Rejected,
  TaskStatusCode.Assigned,
  TaskStatusCode.Accepted,
  TaskStatusCode.Drive,
  TaskStatusCode.Started,
  TaskStatusCode.Comeback,
] as const;

export const COORDINATOR_TASK_STATUS_ALLOWED_CODES = [
  TaskStatusCode.Planned,
  TaskStatusCode.Accepted,
  TaskStatusCode.Assigned,
  TaskStatusCode.Rejected,
  TaskStatusCode.Drive,
  TaskStatusCode.Started,
  TaskStatusCode.Comeback,
  TaskStatusCode.Done,
  TaskStatusCode.Undone,
  TaskStatusCode.Cancelled,
  TaskStatusCode.MissingInformation,
  TaskStatusCode.LoadedOnDeliveryTruck,
  TaskStatusCode.InTheBase,
] as const;
export const isCoordinatorTaskStatusAllowedCode = isInValues(COORDINATOR_TASK_STATUS_ALLOWED_CODES);
