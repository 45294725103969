import { ClassProvider, Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

const enum TranslationCode {
  NextPageLabelCode = 'core.paginator.next-page-label',
  PreviousPageLabelCode = 'core.paginator.previous-page-label',
  FirstPageLabelCode = 'core.paginator.first-page-label',
  LastPageLabelCode = 'core.paginator.last-page-label',
  ItemsPerPageLabelCode = 'core.paginator.items-per-page-label',
  EmptyRangeLabelCode = 'core.paginator.empty-range-label',
  RangeLabelCode = 'core.paginator.range-label',
}

const codes = [
  TranslationCode.NextPageLabelCode,
  TranslationCode.PreviousPageLabelCode,
  TranslationCode.FirstPageLabelCode,
  TranslationCode.LastPageLabelCode,
  TranslationCode.ItemsPerPageLabelCode,
] as const;

type Translations = Record<typeof codes[number], string>;

@UntilDestroy()
@Injectable()
export class GeoTaskPaginatorIntl extends MatPaginatorIntl {
  constructor(private readonly translate: TranslateService) {
    super();
    this.subscribeForTranslations();
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant(TranslationCode.EmptyRangeLabelCode, { length });
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return this.translate.instant(TranslationCode.RangeLabelCode, { start: startIndex + 1, end: endIndex, length });
  };

  private subscribeForTranslations() {
    this.translate
      .stream([...codes])
      .pipe(untilDestroyed(this))
      .subscribe((translations: Translations) => {
        this.nextPageLabel = translations[TranslationCode.NextPageLabelCode];
        this.previousPageLabel = translations[TranslationCode.PreviousPageLabelCode];
        this.firstPageLabel = translations[TranslationCode.FirstPageLabelCode];
        this.lastPageLabel = translations[TranslationCode.LastPageLabelCode];
        this.itemsPerPageLabel = translations[TranslationCode.ItemsPerPageLabelCode];
        this.changes.next();
      });
  }
}

export const MAT_PAGINATOR_INTL_CLASS_PROVIDER: ClassProvider = {
  provide: MatPaginatorIntl,
  useClass: GeoTaskPaginatorIntl,
};
