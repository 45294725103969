import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as OptimizationSessionsApiActions from 'geotask/root-optimization-sessions/actions/optimization-sessions-api.actions';
import { OptimizationSession } from 'geotask/entities/models';
export const optimizationSessionFeatureKey = 'entities.optimizationSession';

export type OptimizationSessionState = EntityState<OptimizationSession>;

export const adapter: EntityAdapter<OptimizationSession> = createEntityAdapter<OptimizationSession>();

export const initialState: OptimizationSessionState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(OptimizationSessionsApiActions.loadOptimizationSessionsListSuccess, (state, action) =>
    adapter.upsertMany(action.sessions, state)
  )
);

export const { selectEntities } = adapter.getSelectors();
