import { isInValues } from '@glb/util/type-guards';

export enum RoleCode {
  Dispatcher = 'ROLE_DISPATCHER',
  Terrain = 'ROLE_TERRAIN',
  Admin = 'ROLE_ADMIN',
  SuperUser = 'ROLE_SUPER_USER',
  ReadOnly = 'ROLE_READ_ONLY',
  LocalAdmin = 'ROLE_LOCAL_ADMIN',
  TasksReadOnly = 'ROLE_TASKS_READ_ONLY',
  Coordinator = 'ROLE_COORDINATOR',
  ReturnPick = 'ROLE_RETURN_PICKUP',
}

export const LOCAL_ADMIN_ALLOWED_ROLE_CODES = [
  RoleCode.Terrain,
  RoleCode.ReadOnly,
  RoleCode.Dispatcher,
  RoleCode.LocalAdmin,
  RoleCode.Coordinator,
  RoleCode.ReturnPick,
  RoleCode.TasksReadOnly,
] as const;
export const isLocalAdminAllowedRoleCode = isInValues(LOCAL_ADMIN_ALLOWED_ROLE_CODES);
