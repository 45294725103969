import { ActionType, createAction, props } from '@ngrx/store';

export const loadNote = createAction('[Notes/NoteEditor] Load Note');
export const saveNote = createAction(
  '[Notes/NoteEditor] Save Note',
  props<{ details: { id: number; finished: boolean; content: string; typeId: number } }>()
);
export const saveNoteAndCreateTask = createAction(
  '[Notes/NoteEditor] Save Note And Create Task',
  props<{ details: ActionType<typeof saveNote>['details'] }>()
);
