import { concatLatestFrom } from '@ngrx/effects';
import {
  concatMap,
  distinctUntilChanged,
  filter,
  first,
  map,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  pipe,
  throwError,
} from 'rxjs';

import { ArrayElementTypeOf, EqualsFn, FalsyValue } from '@glb/util/types';
import { deepEqualArrays, equalsSets, notNull } from '@glb/util/functional';

export function filterNonNullable<TValue>(): OperatorFunction<TValue, NonNullable<TValue>> {
  return filter(notNull);
}

export function firstNonNullable<TValue>(defaultValue?: NonNullable<TValue>) {
  return first<TValue, NonNullable<TValue>>(notNull, defaultValue);
}

export function filterTruthy<TValue>() {
  return filter((value: TValue): value is Exclude<TValue, FalsyValue> => !!value);
}

export function filterFalsy<TValue>() {
  return filter((value: TValue): value is Extract<TValue, FalsyValue> => !value);
}

export function distinctArraysUntilChanged<TArray extends readonly any[]>(
  elementComparator?: EqualsFn<ArrayElementTypeOf<TArray>>
) {
  return distinctUntilChanged<TArray>((x, y) => deepEqualArrays(x, y, elementComparator));
}

export function distinctSetUntilChanged<T>() {
  return distinctUntilChanged<Set<T>>(equalsSets);
}

/**
 * This function must return Observable which will return boolean value synchronously, otherwise it won't work
 */
type ObservableFactory<in T> = (value: T) => Observable<boolean>;

export function filterByLatestFrom<T>(observableFactory: ObservableFactory<T>): MonoTypeOperatorFunction<T> {
  return pipe(
    concatLatestFrom((value) => observableFactory(value)),
    filter(([, condition]) => condition),
    map(([value]) => value)
  );
}

export function throwIfNullable<T>(errorFactory: () => any) {
  return concatMap((value: T | null | undefined) => (value ? of(value) : throwError(errorFactory)));
}
