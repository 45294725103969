import {
  CanActivateChildFn,
  CanActivateFn,
  CanDeactivateFn,
  CanLoadFn,
  CanMatchFn,
  Router,
  UrlTree,
} from '@angular/router';
import { EnvironmentInjector, inject } from '@angular/core';
import { concatMap, defer, from, isObservable, last, map, of, takeWhile } from 'rxjs';

import { isBoolean } from '@glb/util/type-guards';
import { UnwrapAsync } from '@glb/util/types';

type RouteGuardFn = CanActivateFn | CanActivateChildFn | CanLoadFn | CanMatchFn | CanDeactivateFn<any>;

type RouteGuardReturnType = ReturnType<RouteGuardFn>;

export function not<TParameters extends unknown[]>(guard: (...args: TParameters) => RouteGuardReturnType) {
  return (...args: TParameters) => {
    const injector = inject(EnvironmentInjector);
    return injector.runInContext(() => {
      const result$ = wrapIntoObservable(guard(...args));
      return result$.pipe(map((value) => negateValue(value)));
    });
  };
}

export function urlTree(...params: Parameters<Router['createUrlTree']>) {
  return () => {
    const router = inject(Router);
    return router.createUrlTree(...params);
  };
}

export function or<TParams extends unknown[]>(...guards: ((...params: TParams) => RouteGuardReturnType)[]) {
  return (...args: TParams) => {
    const injector = inject(EnvironmentInjector);
    return from(guards).pipe(
      concatMap((guard) => defer(() => injector.runInContext(() => wrapIntoObservable(guard(...args))))),
      takeWhile((result) => result === false, true),
      last()
    );
  };
}

function wrapIntoObservable(result: RouteGuardReturnType) {
  if (isObservable(result)) {
    return result;
  }
  if (isBoolean(result) || result instanceof UrlTree) {
    return of(result);
  }
  return from(result);
}

function negateValue(value: UnwrapAsync<RouteGuardReturnType>) {
  if (isBoolean(value)) {
    return !value;
  } else {
    return value;
  }
}
