import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { CustomerSummaryWebObject } from 'geotask/backend/schemas/customer-summary-web-object.schema';
import type { Page } from 'geotask/backend/schemas/page.schema';
import type { PlaceFullInfoWebObject } from 'geotask/backend/schemas/place-full-info-web-object.schema';
import type { TasksPageResponse } from 'geotask/backend/services/tasks-api.service';
import type { ContactPerson, Customer, Place } from 'geotask/entities/models';

export const CustomersApiActions = createActionGroup({
  source: 'Customers/Customers API',
  events: {
    'Load customers page success': props<{
      page: {
        pageNumber: number;
        pageSize: number;
        totalElements: number;
        content: Customer[];
        places: Place[];
        contactPersons: ContactPerson[];
      };
    }>(),
    'Load customers page failure': props<{ error: ApiError }>(),

    'Load customer summary success': props<{ customerSummary: CustomerSummaryWebObject }>(),
    'Load customer summary failure': props<{ error: ApiError }>(),

    'Save customer success': props<{ customerSummary: CustomerSummaryWebObject }>(),
    'Save customer failure': props<{ error: ApiError }>(),

    'Activate customer success': props<{ customerId: number; mainPlaceId: number }>(),
    'Activate customer failure': props<{ error: ApiError }>(),

    'Deactivate customer success': props<{ customerId: number }>(),
    'Deactivate customer failure': props<{ error: ApiError }>(),
  },
});

export const TasksApiActions = createActionGroup({
  source: 'Customers/Tasks API',
  events: {
    'Load on going tasks success': props<{ page: TasksPageResponse }>(),
    'Load tasks history success': props<{ page: TasksPageResponse }>(),
    'Load not terminated tasks success': props<{ page: TasksPageResponse }>(),

    'Add Task Success': props<{ count: number }>(),
    'Add Task Failure': props<{ error: ApiError }>(),
  },
});

export const PlacesApiActions = createActionGroup({
  source: 'Customers/Places API',
  events: {
    'Load customer places page success': props<{ page: Page<PlaceFullInfoWebObject> }>(),
    'Load all customer places success': props<{ places: PlaceFullInfoWebObject[]; customerId: number }>(),
    'Load all customer places failure': props<{ error: ApiError }>(),
  },
});
