import { NgModule } from '@angular/core';

import { IfHasRoleDirective } from './directives/if-has-role.directive';
import { IfHasNotRoleDirective } from './directives/if-has-not-role.directive';
import { NavigateBackDirective } from './directives/navigate-back.directive';
import { ToolbarContentDirective } from './directives/toolbar-content.directive';
import { ParentErrorStateMatcherDirective } from './directives/parent-error-state-matcher.directive';
import { NavigateOnDblclickDirective } from './directives/navigate-on-dblclick.directive';
import { MinDateDirective } from './directives/min-date.directive';
import { MaxDateDirective } from './directives/max-date.directive';
import { ForOfPlaceTypeDirective } from './directives/for-of-place-type.directive';
import { ParentHavingErrorStateMatcherDirective } from './directives/parent-having-error-state-matcher.directive';
import { ForOfTaskKindDirective } from './directives/for-of-task-kind.directive';
import { ForOfTaskStatusDirective } from './directives/for-of-task-status.directive';
import { ForOfTaskTypeDirective } from './directives/for-of-task-type.directive';
import { ForOfTaskPriorityDirective } from './directives/for-of-task-priority.directive';
import { IfHasAnyOfRolesDirective } from './directives/if-has-any-of-roles.directive';
import { IfHasNotAnyOfRolesDirective } from './directives/if-has-not-any-of-roles.directive';

@NgModule({
  imports: [
    IfHasRoleDirective,
    IfHasNotRoleDirective,
    IfHasAnyOfRolesDirective,
    IfHasNotAnyOfRolesDirective,
    ToolbarContentDirective,
    NavigateBackDirective,
    ParentErrorStateMatcherDirective,
    ParentHavingErrorStateMatcherDirective,
    NavigateOnDblclickDirective,
    MinDateDirective,
    MaxDateDirective,
    ForOfPlaceTypeDirective,
    ForOfTaskKindDirective,
    ForOfTaskStatusDirective,
    ForOfTaskTypeDirective,
    ForOfTaskPriorityDirective,
  ],
  exports: [
    IfHasRoleDirective,
    IfHasNotRoleDirective,
    IfHasAnyOfRolesDirective,
    IfHasNotAnyOfRolesDirective,
    ToolbarContentDirective,
    NavigateBackDirective,
    ParentErrorStateMatcherDirective,
    ParentHavingErrorStateMatcherDirective,
    NavigateOnDblclickDirective,
    MinDateDirective,
    MaxDateDirective,
    ForOfPlaceTypeDirective,
    ForOfTaskKindDirective,
    ForOfTaskStatusDirective,
    ForOfTaskTypeDirective,
    ForOfTaskPriorityDirective,
  ],
})
export class SharedModule {}
