import { Provider } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@angular/material/paginator';

class GeoTaskDialogConfig extends MatDialogConfig {
  override disableClose = true;
  override closeOnNavigation = true;
}

const formFieldDefaultOptions: MatFormFieldDefaultOptions = { appearance: 'outline' };

const paginatorDefaultOptions: MatPaginatorDefaultOptions = {
  showFirstLastButtons: true,
  pageSizeOptions: [100, 250, 500, 1000],
  formFieldAppearance: 'legacy',
};

export const MAT_DEFAULT_OPTIONS_PROVIDERS: Provider[] = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: formFieldDefaultOptions,
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useFactory: () => new GeoTaskDialogConfig(),
  },
  {
    provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
    useValue: paginatorDefaultOptions,
  },
];
