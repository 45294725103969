import type { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Customer, Executor, OrderEntity, Place, TaskEntity } from 'geotask/entities/models';

export const loadOrdersPageSuccess = createAction(
  '[Orders API] Load Orders Page Success',
  props<{
    page: {
      pageNumber: number;
      pageSize: number;
      totalElements: number;
      ids: number[];
    };
  }>()
);
export const loadOrdersPageFailure = createAction('[Orders API] Load Orders Page Failure');

export const loadOrderSuccess = createAction(
  '[Orders API] Load Order Success',
  props<{
    order: OrderEntity;
    customer: Customer | null;
    places: Place[];
    tasks: TaskEntity[];
    executors: Executor[];
  }>()
);
export const loadOrderFailure = createAction('[Orders API] Load Order Failure', props<{ error: ApiError }>());

export const cancelOrderSuccess = createAction(
  '[Orders API] Cancel Order Success',
  props<{ order: Update<OrderEntity>; tasks: Update<TaskEntity>[] }>()
);
export const cancelOrderFailure = createAction('[Orders API] Cancel Order Failure', props<{ error: ApiError }>());

export const closeOrderSuccess = createAction(
  '[Orders API] Close Order Success',
  props<{ order: Update<OrderEntity>; tasks: Update<TaskEntity>[] }>()
);
export const closeOrderFailure = createAction('[Orders API] Close Order Failure', props<{ error: ApiError }>());

export const updateOrderSuccess = createAction(
  '[Orders API] Update Order Success',
  props<{ customer: Customer | null; order: Update<OrderEntity> }>()
);
export const updateOrderFailure = createAction('[Orders API] Update Order Failure', props<{ error: ApiError }>());
