import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { ModulesApiService } from 'geotask/backend/services/modules-api.service';
import { filterTruthy } from '@glb/util/rx-operators';
import { AuthService } from '../services/auth.service';
import { ModulesService } from '../services/modules.service';
import { ModulesActions, ModulesApiActions } from '../actions';

@Injectable()
export class ModulesEffects {
  // noinspection JSUnusedGlobalSymbols
  readonly loadModules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ModulesActions.loadModules),
      switchMap(() =>
        this.modulesApiService.modules$.pipe(
          map((modules) =>
            ModulesApiActions.loadModulesSuccess({
              modules: {
                taskImporter: modules.taskImporterModule,
                notes: modules.notesModule,
                appointments: modules.appointmentModule,
                areas: modules.areaModule,
                teams: modules.teamModule,
                places: modules.placeModule,
                skills: modules.skillModule,
                optimizations: modules.optimizeModule,
                formTemplates: modules.formTemplatesModule,
                reportRodo: modules.reportRodoModule,
                taskStatusHistoryEditor: modules.taskStatusHistoryEditorModule,
                areasEditable: modules.areaEditableModule,
                carTracking: modules.carTrackingModule,
                dispatcherNotifications: modules.dispatcherNotificationModule,
                localOptimize: modules.localOptimizeModule,
                companies: modules.companiesModule,
              },
            })
          ),
          catchErrorToApiFailureAction(
            ModulesApiActions.loadModulesFailure,
            'Error during loading modules configuration'
          )
        )
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  readonly authenticated$ = createEffect(() => {
    return this.authService.canAccessFullApplication$.pipe(map(() => ModulesActions.loadModules()));
  });

  constructor(
    private readonly actions$: Actions,
    private readonly modulesService: ModulesService,
    private readonly authService: AuthService,
    private readonly modulesApiService: ModulesApiService
  ) {}
}
