import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';

import * as HistoryActions from '../actions/history.actions';
import * as HistorySelectors from '../selectors/history.selectors';

@Injectable()
export class HistoryEffects {
  // noinspection JSUnusedGlobalSymbols
  readonly navigateBack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(HistoryActions.navigateBack),
        concatLatestFrom(() => this.store$.select(HistorySelectors.selectUrlToNavigateBack)),
        concatMap(([, url]) => from(this.router.navigateByUrl(url ?? '/')).pipe(catchError(() => EMPTY)))
      );
    },
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly router: Router, private readonly store$: Store) {}
}
