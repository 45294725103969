import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { TaskStatusCode } from 'geotask/core/models';
import { MultidictionaryApiActions } from '../actions';
import { TaskStatus } from '../models';

export const taskStatusesFeatureKey = 'dictionaries.taskStatuses';

export type TaskStatusIdByCode = { [statusCode in TaskStatusCode]?: number };

export interface TaskStatusesState extends EntityState<TaskStatus> {
  byCode: TaskStatusIdByCode;
}

export const adapter: EntityAdapter<TaskStatus> = createEntityAdapter<TaskStatus>();

export const initialState: TaskStatusesState = adapter.getInitialState({
  // additional entity state properties
  byCode: {},
});

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) =>
    adapter.setAll(action.taskStatuses, {
      ...state,
      byCode: Object.fromEntries(action.taskStatuses.map((status) => [status.code, status.id])),
    })
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
export const selectByCode = (state: TaskStatusesState) => state.byCode;
