import type { Provider } from '@angular/core';

import { isFunction } from '@glb/util/type-guards';

export const isBroadcastChannelSupported = isFunction(globalThis.BroadcastChannel);

const _provideIf =
  (condition: boolean) =>
  <TProvider extends Provider>(provider: TProvider) =>
    condition ? provider : [];

export const provideIfBroadcastChannelSupported = _provideIf(isBroadcastChannelSupported);
