import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';

export const loadAppNameSuccess = createAction(
  '[AppName API] Load Application Name Success',
  props<{ appName: string }>()
);

export const loadAppNameFailure = createAction(
  '[AppName API] Load Application Name Failure',
  props<{ error: ApiError }>()
);
