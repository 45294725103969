import { createAction, props } from '@ngrx/store';

import type { TeamUnavailabilityOccurrence } from '../models';

export const retainTeamUnavailabilityOccurrences = createAction(
  '[TeamUnavailabilityOccurrence/API] Retain Team Unavailability Occurrences',
  props<{
    retain: {
      teamUnavailabilityDefinitionId: number;
      occurrences: TeamUnavailabilityOccurrence[];
    };
  }>()
);
