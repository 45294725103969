import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';

@Directive()
export abstract class ConditionalBaseDirective<T = undefined> implements OnInit, OnDestroy {
  protected thenViewRef: EmbeddedViewRef<T> | null = null;
  protected elseViewRef: EmbeddedViewRef<T> | null = null;
  protected context?: T;

  private readonly thenTemplateRefSubject: BehaviorSubject<TemplateRef<T> | null>;
  private readonly elseTemplateRefSubject = new BehaviorSubject<TemplateRef<T> | null>(null);

  private subscription = Subscription.EMPTY;

  protected constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly changeDetector: ChangeDetectorRef,
    @Optional() template: TemplateRef<T> | null
  ) {
    this.thenTemplateRefSubject = new BehaviorSubject<TemplateRef<T> | null>(template);
  }

  ngOnInit() {
    this.subscription = combineLatest([
      this.getObservableCondition(),
      this.thenTemplateRefSubject,
      this.elseTemplateRefSubject,
    ]).subscribe(([conditionFulfilled, thenTemplateRef, elseTemplateRef]) => {
      this.updateView(conditionFulfilled, thenTemplateRef, elseTemplateRef);
    });
  }

  ngOnDestroy() {
    this.thenTemplateRefSubject.complete();
    this.elseTemplateRefSubject.complete();
    this.subscription.unsubscribe();
  }

  protected nextThenTemplate(template: TemplateRef<T> | null) {
    this.thenViewRef = null;
    this.thenTemplateRefSubject.next(template);
  }

  protected nextElseTemplate(template: TemplateRef<T> | null) {
    this.elseViewRef = null;
    this.elseTemplateRefSubject.next(template);
  }

  protected updateView(
    conditionFulfilled: boolean,
    thenTemplateRef: TemplateRef<T> | null,
    elseTemplateRef: TemplateRef<T> | null
  ) {
    if (conditionFulfilled) {
      this.updateOnConditionFulfilled(thenTemplateRef);
    } else {
      this.updateOnConditionNotFulfilled(elseTemplateRef);
    }
  }

  private updateOnConditionFulfilled(thenTemplate: TemplateRef<T> | null) {
    if (!this.thenViewRef) {
      this.viewContainer.clear();
      this.elseViewRef = null;
      if (thenTemplate) {
        this.thenViewRef = this.viewContainer.createEmbeddedView(thenTemplate, this.context);
        this.changeDetector.markForCheck();
      }
    }
  }

  private updateOnConditionNotFulfilled(elseTemplate: TemplateRef<T> | null) {
    if (!this.elseViewRef) {
      this.viewContainer.clear();
      this.thenViewRef = null;
      if (elseTemplate) {
        this.elseViewRef = this.viewContainer.createEmbeddedView(elseTemplate, this.context);
        this.changeDetector.markForCheck();
      }
    }
  }

  protected abstract getObservableCondition(): Observable<boolean>;
}
