import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { catchError, concatMap, of } from 'rxjs';
import { z } from 'zod';

import { CONSOLE, ConsoleTokenType } from '@glb/shared/web-api-browser';
import { parseWith } from '@glb/util/rx-operators';

const GoogleMapsConfigJsonSchema = z.object({
  apiKey: z.string(),
  channel: z.string().optional(),
  client: z.string().optional(),
  version: z.string().optional(),
  language: z.string().optional(),
  region: z.string().optional(),
});

type GoogleMapsConfigJson = z.infer<typeof GoogleMapsConfigJsonSchema>;

const CONFIG_URL = 'config/google-maps.config.json';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  constructor(
    private readonly ngZone: NgZone,
    private readonly httpClient: HttpClient,
    @Inject(CONSOLE) private readonly console: ConsoleTokenType
  ) {}

  load() {
    return this.ngZone.runOutsideAngular(() =>
      this.httpClient.get(CONFIG_URL).pipe(
        parseWith(GoogleMapsConfigJsonSchema),
        catchError((error: unknown) => this.handleConfigLoadError(error)),
        concatMap((googleMapsConfig) => loadGoogleMapsJsApi(googleMapsConfig))
      )
    );
  }

  private handleConfigLoadError(error: unknown) {
    this.console.error(`Error during fetching '${CONFIG_URL}'`, error);
    const emptyConfiguration: GoogleMapsConfigJson = { apiKey: '' };
    return of(emptyConfiguration);
  }
}

function loadGoogleMapsJsApi(googleMapsConfig: GoogleMapsConfigJson) {
  return new Loader({
    libraries: ['drawing', 'geometry'],
    nonce: 'geotask',
    ...googleMapsConfig,
  }).load();
}
