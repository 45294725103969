import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { normalizeOrders } from 'geotask/core/models/map-normalized-order-to-entities';
import {
  CustomersApiActions,
  TasksApiActions as CustomersTasksApiActions,
} from 'geotask/customers/actions/customers-api.actions';
import * as DashboardListTasksApiActions from 'geotask/dashboard-list/actions/tasks-api.actions';
import { OrderActions } from 'geotask/entities/actions';
import { Customer } from 'geotask/entities/models';
import * as OrderEditorOrdersApiActions from 'geotask/orders/actions/orders-api.actions';
import * as AutocompleteContactPersonActions from 'geotask/shared/autocomplete-contact-person/autocomplete-contact-person.actions';
import * as AutocompleteCustomerActions from 'geotask/shared/autocomplete-customer/autocomplete-customer.actions';
import * as AutocompletePlacesActions from 'geotask/shared/autocomplete-place/autocomplete-place.actions';
import { NoteApiActions, TasksApiActions as TaskEditorTasksApiActions } from 'geotask/tasks/actions';
import * as TasksReportApiActions from 'geotask/tasks-report/actions/tasks-report-api.actions';
import * as TrackSummaryApiActions from 'geotask/track-summary/actions/track-summary-api.actions';

export const customersFeatureKey = 'entities.customers';

export type CustomersState = EntityState<Customer>;

export const adapter = createEntityAdapter<Customer>();

export const initialState: CustomersState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    (state, { customer }): CustomersState => (customer ? adapter.upsertOne(customer, state) : state)
  ),
  on(
    TrackSummaryApiActions.loadTrackSummarySuccess,
    OrderActions.upsertOrders,
    AutocompletePlacesActions.loadPlaceAutocompletePromptsSuccess,
    (state, { customers }): CustomersState => adapter.upsertMany(customers, state)
  ),
  on(
    DashboardListTasksApiActions.loadTasksPageSuccess,
    (state, { page: { customers } }): CustomersState => adapter.upsertMany(customers, state)
  ),
  on(
    TasksReportApiActions.loadTasksReportSuccess,
    (state, { page: { orders } }): CustomersState =>
      adapter.upsertMany(
        orders.reduce<Customer[]>((customers, order) => {
          const { customer } = order;
          if (customer == null) {
            return customers;
          }
          return customers.concat({
            id: customer.id,
            name: customer.name,
            externalId: customer.externalId,
            active: customer.active,
            anonymized: customer.anonymized,
            placesCount: customer.placesCount,
          });
        }, []),
        state
      )
  ),
  on(
    NoteApiActions.loadCopiedNoteSuccess,
    OrderEditorOrdersApiActions.loadOrderSuccess,
    OrderEditorOrdersApiActions.updateOrderSuccess,
    (state, { customer }): CustomersState => (customer ? adapter.upsertOne(customer, state) : state)
  ),
  on(
    CustomersApiActions.loadCustomersPageSuccess,
    (state, { page: { content: customers } }): CustomersState => adapter.upsertMany(customers, state)
  ),
  on(
    AutocompleteCustomerActions.loadCustomerAutocompletePromptsSuccess,
    AutocompleteContactPersonActions.loadContactPersonAutocompletePromptsSuccess,
    (state, { customers }): CustomersState => adapter.upsertMany(customers, state)
  ),
  on(
    CustomersApiActions.loadCustomerSummarySuccess,
    CustomersApiActions.saveCustomerSuccess,
    (state, { customerSummary: { customer } }): CustomersState =>
      !state.entities[customer.id]
        ? adapter.addOne(
            {
              id: customer.id,
              name: customer.name,
              externalId: customer.externalId,
              placesCount: null,
              active: customer.active,
              anonymized: customer.anonymized,
            },
            state
          )
        : adapter.updateOne(
            {
              id: customer.id,
              changes: {
                name: customer.name,
                externalId: customer.externalId,
                active: customer.active,
              },
            },
            state
          )
  ),
  on(
    CustomersTasksApiActions.loadOnGoingTasksSuccess,
    CustomersTasksApiActions.loadTasksHistorySuccess,
    CustomersTasksApiActions.loadNotTerminatedTasksSuccess,
    (state, { page: { orders } }): CustomersState => {
      const { customers } = normalizeOrders(orders);
      return adapter.upsertMany(customers, state);
    }
  ),
  on(
    CustomersApiActions.deactivateCustomerSuccess,
    (state, { customerId }): CustomersState =>
      adapter.updateOne(
        {
          id: customerId,
          changes: { active: false },
        },
        state
      )
  ),
  on(
    CustomersApiActions.activateCustomerSuccess,
    (state, { customerId }): CustomersState =>
      adapter.updateOne(
        {
          id: customerId,
          changes: { active: true },
        },
        state
      )
  )
);

export const { selectEntities } = adapter.getSelectors();
