import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TimeModelAdapter } from '@glb/shared/picker/time/model';

@Injectable()
export abstract class DatetimeModelAdapter<TDateTime, TDate = unknown, TTime = unknown> {
  // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
  constructor(protected _dateAdapter: DateAdapter<TDate>, protected _timeAdapter: TimeModelAdapter<TTime>) {}

  getYear(value: TDateTime): number {
    return this._dateAdapter.getYear(this.getDate(value));
  }

  getMonth(value: TDateTime): number {
    return this._dateAdapter.getMonth(this.getDate(value));
  }

  getDayOfMonth(value: TDateTime): number {
    return this._dateAdapter.getDate(this.getDate(value));
  }

  getHour(value: TDateTime): number {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._timeAdapter.getHour(this.getTime(value))!;
  }

  getMinute(value: TDateTime): number {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._timeAdapter.getMinute(this.getTime(value))!;
  }

  defaultTime() {
    return this._timeAdapter.default();
  }

  defaultDate() {
    return this._dateAdapter.today();
  }

  abstract isValid(value: TDateTime): boolean;
  abstract format(value: TDateTime, format: Record<string, unknown> | string): string;
  abstract sameDate(value: TDateTime | null, otherValue: TDateTime | null): boolean;
  abstract parse(value: string, parseFormat: Record<string, unknown> | string): TDateTime | null;
  abstract getDate(value: TDateTime): TDate;
  abstract getTime(value: TDateTime): TTime;
  abstract createDatetime(date: TDate, time: TTime): TDateTime;
}
