import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from 'geotask/shared';
import { IfModuleEnabledModule } from 'geotask/shared/if-module-enabled';
import { IfLoggedInModule } from 'geotask/shared/if-logged-in';
import { AccountActionListComponent } from './components/account-action-list/account-action-list.component';
import { CompositeNavItemComponent } from './components/composite-nav-item/composite-nav-item.component';
import { NavListComponent } from './components/nav-list/nav-list.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SupportActionListComponent } from './components/support-action-list/support-action-list.component';
import { SimpleNavItemComponent } from './components/simple-nav-item.component';

const MATERIAL_MODULES = [MatListModule, MatIconModule, MatDividerModule];
const EXPORTED_DECLARATIONS = [SidenavComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    MATERIAL_MODULES,
    IfLoggedInModule,
    IfModuleEnabledModule,
  ],
  declarations: [
    EXPORTED_DECLARATIONS,
    AccountActionListComponent,
    NavListComponent,
    SupportActionListComponent,
    SimpleNavItemComponent,
    CompositeNavItemComponent,
  ],
  exports: EXPORTED_DECLARATIONS,
})
export class SidenavModule {}
