import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createArraySelector } from 'geotask/core/selectors/selector-factories';
import * as fromSystemDict from '../reducers/companies.reducer';

const selectSkillState = createFeatureSelector<fromSystemDict.CompaniesState>(fromSystemDict.companiesFeatureKey);
export const selectEntities = createSelector(selectSkillState, fromSystemDict.selectEntities);
export const selectAll = createArraySelector(selectSkillState, fromSystemDict.selectAll);

export const selectCompanyById = (id: number) =>
  createSelector(selectAll, (values) => {
    return values?.find((value) => value.id === id);
  });
