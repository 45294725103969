<mat-nav-list
  *glbIfHasAnyOfRoles="[
    Roles.Admin,
    Roles.SuperUser,
    Roles.LocalAdmin,
    Roles.Dispatcher,
    Roles.ReadOnly,
    Roles.Coordinator,
    Roles.TasksReadOnly
  ]"
>
  <h1 matSubheader>{{ appName$ | async }}</h1>

  <glb-simple-nav-item
    [linkName]="names.dashboard | translate"
    [routerLinkCommands]="links.dashboard"
    *glbIfHasNotRole="Roles.TasksReadOnly"
  >
    <mat-icon svgIcon="schedule"></mat-icon>
  </glb-simple-nav-item>

  <glb-simple-nav-item [linkName]="names.orders | translate" [routerLinkCommands]="links.orders">
    <mat-icon>{{ icons.orders }}</mat-icon>
  </glb-simple-nav-item>

  <ng-container *glbIfModuleEnabled="Modules.Teams">
    <glb-simple-nav-item
      *glbIfHasAnyOfRoles="[Roles.Admin, Roles.LocalAdmin, Roles.Dispatcher, Roles.Coordinator]"
      [linkName]="names.schedule | translate"
      [routerLinkCommands]="links.schedule"
    >
      <mat-icon>{{ icons.schedule }}</mat-icon>
    </glb-simple-nav-item>
  </ng-container>

  <ng-container *glbIfHasAnyOfRoles="[Roles.Admin, Roles.LocalAdmin, Roles.Dispatcher, Roles.ReadOnly]">
    <glb-simple-nav-item
      *glbIfModuleEnabled="Modules.Notes"
      [linkName]="names.notes | translate"
      [routerLinkCommands]="links.notes"
    >
      <mat-icon>{{ icons.notes }}</mat-icon>
    </glb-simple-nav-item>
  </ng-container>

  <glb-simple-nav-item
    *glbIfModuleEnabled="Modules.Places"
    [linkName]="names.customers | translate"
    [routerLinkCommands]="links.customers"
  >
    <mat-icon>people</mat-icon>
  </glb-simple-nav-item>

  <glb-composite-nav-item
    [closed]="closeAll | async"
    [linkName]="names.reports | translate"
    [iconName]="icons.reports"
    *glbIfHasAnyOfRoles="[Roles.Admin, Roles.LocalAdmin, Roles.Dispatcher, Roles.ReadOnly]"
  >
    <glb-simple-nav-item
      [linkName]="names.taskReport | translate"
      [routerLinkCommands]="links.tasksReport"
    ></glb-simple-nav-item>
    <glb-simple-nav-item
      [linkName]="names.formReport | translate"
      [routerLinkCommands]="links.formReport"
    ></glb-simple-nav-item>
    <glb-simple-nav-item
      [linkName]="names.gpsReport | translate"
      [routerLinkCommands]="links.gpsReport"
    ></glb-simple-nav-item>
    <glb-simple-nav-item
      [linkName]="names.productivityReport | translate"
      [routerLinkCommands]="links.productivityReport"
    ></glb-simple-nav-item>
    <glb-simple-nav-item
      *glbIfModuleEnabled="Modules.CarTracking"
      [linkName]="names.carTrackingReport | translate"
      [routerLinkCommands]="links.carTrackingReport"
    ></glb-simple-nav-item>
    <glb-simple-nav-item
      [linkName]="names.rodoReport | translate"
      [routerLinkCommands]="links.rodoReport"
    ></glb-simple-nav-item>
    <glb-simple-nav-item
      [linkName]="names.optimizationsReport | translate"
      [routerLinkCommands]="links.optimizationsReport"
    ></glb-simple-nav-item>
    <glb-simple-nav-item
      [linkName]="names.dispatcherNotificationsReport | translate"
      [routerLinkCommands]="links.dispatcherNotificationsReport"
    ></glb-simple-nav-item>
  </glb-composite-nav-item>

  <glb-composite-nav-item
    [closed]="closeAll | async"
    *glbIfHasAnyOfRoles="[Roles.Admin, Roles.LocalAdmin]"
    [linkName]="names.settings | translate"
    [iconName]="icons.settings"
  >
    <glb-simple-nav-item
      *glbIfHasRole="Roles.Admin"
      [linkName]="names.configuration | translate"
      [routerLinkCommands]="links.configuration"
    ></glb-simple-nav-item>

    <glb-simple-nav-item
      [linkName]="names.executors | translate"
      [routerLinkCommands]="links.executors"
    ></glb-simple-nav-item>

    <glb-simple-nav-item
      *glbIfModuleEnabled="Modules.Teams"
      [linkName]="names.teams | translate"
      [routerLinkCommands]="links.teams"
    ></glb-simple-nav-item>

    <ng-container *glbIfHasRole="Roles.Admin">
      <glb-simple-nav-item
        *glbIfModuleEnabled="Modules.Areas"
        [linkName]="names.areas | translate"
        [routerLinkCommands]="links.areas"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        [linkName]="names.taskKinds | translate"
        [routerLinkCommands]="links.taskKinds"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        *glbIfModuleEnabled="Modules.Skills"
        [linkName]="names.skills | translate"
        [routerLinkCommands]="links.skills"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        [linkName]="names.companies | translate"
        [routerLinkCommands]="links.companies"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        *glbIfModuleEnabled="Modules.Places"
        [linkName]="names.placeTypes | translate"
        [routerLinkCommands]="links.placeTypes"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        [linkName]="names.systemDictionaries | translate"
        [routerLinkCommands]="links.systemDictionaries"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        *glbIfModuleEnabled="Modules.FormTemplates"
        [linkName]="names.formTemplates | translate"
        [routerLinkCommands]="links.formTemplates"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        *glbIfModuleEnabled="[Modules.Optimizations, Modules.Areas]"
        [linkName]="names.optimizationPlans | translate"
        [routerLinkCommands]="links.optimizationPlans"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        [linkName]="names.capacityConfiguration | translate"
        [routerLinkCommands]="links.capacityConfiguration"
      ></glb-simple-nav-item>

      <glb-composite-nav-item
        [closed]="closeAll | async"
        *glbIfHasAnyOfRoles="[Roles.Admin]"
        [linkName]="names.postalCodes | translate"
      >
        <glb-simple-nav-item
          [linkName]="names.postalCodesKitchen | translate"
          [routerLinkCommands]="links.postalCodesKitchen"
        >
        </glb-simple-nav-item>
        <glb-simple-nav-item
          [linkName]="names.postalCodesFurniture | translate"
          [routerLinkCommands]="links.postalCodesFurniture"
        ></glb-simple-nav-item>
        <glb-simple-nav-item
          [linkName]="names.postalCodesAfterSales | translate"
          [routerLinkCommands]="links.postalCodesAfterSales"
        ></glb-simple-nav-item>
      </glb-composite-nav-item>

      <glb-simple-nav-item
        *glbIfModuleEnabled="Modules.Appointments"
        [linkName]="names.appointmentsNotifications | translate"
        [routerLinkCommands]="links.appointmentsNotifications"
      ></glb-simple-nav-item>

      <glb-simple-nav-item
        *glbIfModuleEnabled="Modules.Appointments"
        [linkName]="names.appointmentSlots | translate"
        [routerLinkCommands]="links.appointmentSlots"
      ></glb-simple-nav-item>
    </ng-container>
  </glb-composite-nav-item>

  <glb-composite-nav-item
    [closed]="closeAll | async"
    *glbIfHasAnyOfRoles="[Roles.Admin, Roles.LocalAdmin, Roles.Dispatcher]"
    [linkName]="names.loader | translate"
    [iconName]="icons.loader"
  >
    <glb-simple-nav-item
      [linkName]="names.tasksLoader | translate"
      [routerLinkCommands]="links.tasksLoader"
    ></glb-simple-nav-item>
    <ng-container *glbIfModuleEnabled="Modules.Teams">
      <glb-simple-nav-item
        *glbIfHasAnyOfRoles="[Roles.Admin, Roles.LocalAdmin]"
        [linkName]="names.teamsLoader | translate"
        [routerLinkCommands]="links.teamsLoader"
      ></glb-simple-nav-item>
    </ng-container>
    <glb-simple-nav-item
      *glbIfHasAnyOfRoles="[Roles.Admin, Roles.LocalAdmin]"
      [linkName]="names.executorsLoader | translate"
      [routerLinkCommands]="links.executorsLoader"
    ></glb-simple-nav-item>
    <ng-container *glbIfModuleEnabled="Modules.Places">
      <glb-simple-nav-item
        *glbIfHasRole="Roles.Admin"
        [linkName]="names.customersLoader | translate"
        [routerLinkCommands]="links.customersLoader"
      ></glb-simple-nav-item>
    </ng-container>

    <glb-composite-nav-item
      [closed]="closeAll | async"
      *glbIfHasAnyOfRoles="[Roles.Admin]"
      [linkName]="names.postalCodesLoader | translate"
    >
      <glb-simple-nav-item
        [linkName]="names.kitchenPostalCodesLoader | translate"
        [routerLinkCommands]="links.kitchenPostalCodesLoader"
      >
      </glb-simple-nav-item>
      <glb-simple-nav-item
        [linkName]="names.assemblyPostalCodesLoader | translate"
        [routerLinkCommands]="links.assemblyPostalCodesLoader"
      ></glb-simple-nav-item>
      <glb-simple-nav-item
        [linkName]="names.afterSalesPostalCodesLoader | translate"
        [routerLinkCommands]="links.afterSalesPostalCodesLoader"
      ></glb-simple-nav-item>
    </glb-composite-nav-item>
  </glb-composite-nav-item>
</mat-nav-list>
