import { createAction, props } from '@ngrx/store';
import type { ChangedAppSettings, ChangedDispatcherNotificationSettings } from '../models';
import type { TaskAdditionalFieldsFormRawValue } from 'geotask/task-additional-fields-config/models/task-additional-fields-form.model';

export const upsertChangedAppSettings = createAction(
  '[Settings] Upsert Changed App Settings',
  props<{ changedSettings: ChangedAppSettings }>()
);

export const changeDispatcherNotificationsSettings = createAction(
  '[Settings] Change Dispatcher Notifications Settings',
  props<{ changedDispatcherNotifications: ChangedDispatcherNotificationSettings }>()
);

export const clearChangedSettings = createAction('[Settings] Clear Changed Settings');

export const saveChangedSettings = createAction(
  '[Settings] Save Changed Settings',
  props<{ additionalTaskFields: TaskAdditionalFieldsFormRawValue }>()
);

export const openMapViewConfigurationDialog = createAction(
  '[Settings/MapViewConfiguration] Open Map View Configuration Dialog'
);
