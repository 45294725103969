import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { LOGOUT_ACTIONS } from 'geotask/core/actions';
import * as CarTrackingReportApiActions from 'geotask/car-tracking-report/actions/car-tracking-report-api.actions';
import * as DashboardListTasksApiActions from 'geotask/dashboard-list/actions/tasks-api.actions';
import * as RouteApiActions from 'geotask/dashboard-map/actions/route-api.actions';
import * as SchedulerDataActions from 'geotask/dashboard-scheduler/actions/scheduler-data.actions';
import * as ScheduleApiActions from 'geotask/schedule/actions/schedule-api.actions';
import * as TaskEditorTasksApiActions from 'geotask/tasks/actions/tasks-api.actions';
import * as TasksReportApiActions from 'geotask/tasks-report/actions/tasks-report-api.actions';
import * as DeleteDataApiActions from 'geotask/root-delete-data/actions/delete-data-api.actions';
import { TeamActions, TeamApiActions } from '../actions';
import { TeamEntity } from '../models';

export const teamsFeatureKey = 'entities.teams';

export interface TeamsState extends EntityState<TeamEntity> {
  inExecutorAreas: readonly number[];
}

export const adapter: EntityAdapter<TeamEntity> = createEntityAdapter<TeamEntity>();

export const initialState: TeamsState = adapter.getInitialState({
  inExecutorAreas: [],
});

export const reducer = createReducer(
  initialState,
  on(RouteApiActions.loadTeamRoutesSuccess, (state, action): TeamsState => adapter.updateMany(action.teams, state)),
  on(
    TeamActions.upsertTeams,
    ScheduleApiActions.loadScheduleSuccess,
    CarTrackingReportApiActions.loadTeamsForCarTrackingReportSuccess,
    (state, { teams }): TeamsState => adapter.upsertMany(teams, state)
  ),
  on(
    SchedulerDataActions.loadSchedulerDataSuccess,
    SchedulerDataActions.refreshSchedulerDataSuccess,
    (state, { data: { teams } }): TeamsState => adapter.upsertMany(teams, state)
  ),
  on(
    DashboardListTasksApiActions.loadTasksPageSuccess,
    (state, action): TeamsState => adapter.upsertMany(action.page.teams, state)
  ),
  on(
    TasksReportApiActions.loadTasksReportSuccess,
    (state, { teams }): TeamsState =>
      adapter.upsertMany(
        teams.map(
          (team): TeamEntity => ({
            id: team.id,
            name: team.name,
            workStart: team.workStart,
            workEnd: team.workEnd,
            weightCapacity: team.weightCapacity,
            volumeCapacity: team.volumeCapacity,
            taskLimit: team.taskLimit,
            startLocation: team.startLocation,
            endLocation: team.endLocation,
            startLocationAddress: team.startLocationAddress,
            endLocationAddress: team.endLocationAddress,
            areaIds: team.areaIds,
            active: team.active,
            operatingArea: team.operatingArea,
            skillIds: team.skillIds,
          })
        ),
        state
      )
  ),
  on(
    TeamActions.upsertTeam,
    TeamApiActions.loadTeamAfterExecutorSaveSuccess,
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    (state, action): TeamsState => (action.team ? adapter.upsertOne(action.team, state) : state)
  ),
  on(
    TeamApiActions.loadTeamsInExecutorAreasSuccess,
    (state, action): TeamsState =>
      adapter.upsertMany(action.teams, {
        ...state,
        inExecutorAreas: action.teams.map((team) => team.id),
      })
  ),
  on(
    ...LOGOUT_ACTIONS,
    DeleteDataApiActions.deleteAllResourcesSuccess,
    (state): TeamsState => ({
      ...state,
      inExecutorAreas: [],
    })
  ),
  on(
    DeleteDataApiActions.deleteSelectedResourcesSuccess,
    (state, action): TeamsState => adapter.removeMany(action.teamIds, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
