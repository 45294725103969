import { z } from 'zod';

import { Reveal } from '@glb/util/types';
import { IdSchema } from './id.schema';

export const DictionaryEntrySchema = z.object({
  id: IdSchema,
  name: z.string(),
  code: z.string(),
});

type DictionaryEntryBase = z.infer<typeof DictionaryEntrySchema>;

export type DictionaryEntry<C extends string | number = string> = string extends C
  ? DictionaryEntryBase
  : Reveal<Omit<DictionaryEntryBase, 'code'> & { code: C }>;
