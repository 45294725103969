import { FactoryProvider, InjectionToken } from '@angular/core';

export type LanguageChangeCallback = (language: string) => void;
export const LANGUAGE_CHANGE_CALLBACK = new InjectionToken<LanguageChangeCallback>('Language change callback');

type Factory = ((...args: any[]) => LanguageChangeCallback) & { deps: any[] };
export function provideLanguageChangeCallback(callbackFactory: Factory): FactoryProvider {
  return {
    provide: LANGUAGE_CHANGE_CALLBACK,
    multi: true,
    useFactory: callbackFactory,
    deps: callbackFactory.deps,
  };
}
