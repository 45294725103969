import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Executor, TaskEntity } from 'geotask/entities/models';
import type { FormTemplateEntity } from 'geotask/dictionaries/models';

export const loadFormReportPageSuccess = createAction(
  '[Forms Report API] Load Form Report Success',
  props<{
    page: {
      pageNumber: number;
      pageSize: number;
      totalElements: number;
      tasks: TaskEntity[];
      formTemplate: FormTemplateEntity;
      executors: Executor[];
    };
  }>()
);
export const loadFormReportPageFailure = createAction(
  '[Forms Report API] Load FormReport Failure',
  props<{ error: ApiError }>()
);

export const exportToCsvFileSuccess = createAction('[Forms Report API] Export To Csv File Success');
export const exportToCsvFileFailure = createAction(
  '[Forms Report API] Export To Csv File Failure',
  props<{ error: ApiError }>()
);
