import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch('config/msal-config.json')
  .then((response) => response.json())
  .then((config) => {
    (window as any).msalConfig = config;
  })
  .catch((err) => {
    console.error(err);
  })
  .finally(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
