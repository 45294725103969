import { createReducer, on, StoreConfig } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as CarTrackingReportApiActions from 'geotask/car-tracking-report/actions/car-tracking-report-api.actions';
import { TasksApiActions as CustomerTasksApiActions } from 'geotask/customers/actions/customers-api.actions';
import * as TasksApiActions from 'geotask/dashboard-list/actions/tasks-api.actions';
import * as DashboardSchedulerActions from 'geotask/dashboard-scheduler/actions/scheduler-data.actions';
import * as SchedulerTasksApiActions from 'geotask/dashboard-scheduler/actions/tasks-api.actions';
import * as DashboardTasksApiActions from 'geotask/dashboard/actions/tasks-api.actions';
import * as FormsReportApiActions from 'geotask/forms-report/actions/forms-report-api.actions';
import * as DispatcherNotificationsApiActions from 'geotask/dispatcher-notifications/actions/dispatcher-notifications-api.actions';
import * as DispatcherNotificationsReportApiActions from 'geotask/dispatcher-notifications-report/actions/notifications-report-api.actions';
import * as OrderEditorOrdersApiActions from 'geotask/orders/actions/orders-api.actions';
import { TasksApiActions as PlaceEditorTasksApiActions } from 'geotask/places/actions';
import * as DeleteDataApiActions from 'geotask/root-delete-data/actions/delete-data-api.actions';
import * as AppointmentsApiActions from 'geotask/tasks/actions/appointments-api.actions';
import * as TaskEditorAttachmentsApiActions from 'geotask/tasks/actions/attachments-api.actions';
import * as TaskEditorTasksApiActions from 'geotask/tasks/actions/tasks-api.actions';
import * as TasksReportApiActions from 'geotask/tasks-report/actions/tasks-report-api.actions';
import * as TrackSummaryApiActions from 'geotask/track-summary/actions/track-summary-api.actions';
import { distinct, notEqualTo } from '@glb/util/functional';
import { stateBroadcastMetaReducer } from 'geotask/util/state-broadcast-meta-reducer';
import { TaskActions } from '../actions';
import { TaskEntity } from '../models';

export const tasksFeatureKey = 'entities.tasks';

export type TasksState = EntityState<TaskEntity>;

export const adapter: EntityAdapter<TaskEntity> = createEntityAdapter<TaskEntity>();

export const initialState: TasksState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(TaskEditorTasksApiActions.saveNewTaskSuccess, (state, action): TasksState => adapter.addOne(action.task, state)),
  on(
    TaskActions.updateTask,
    TaskEditorTasksApiActions.saveTaskSuccess,
    (state, action): TasksState => adapter.updateOne(action.task, state)
  ),
  on(
    TaskEditorAttachmentsApiActions.uploadAttachmentsSuccess,
    (state, action): TasksState =>
      adapter.mapOne(
        {
          id: action.taskId,
          map: (task) => ({
            ...task,
            attachmentIds: (task.attachmentIds ?? [])
              .concat(action.attachments.map((attachment) => attachment.id))
              .filter(distinct()),
          }),
        },
        state
      )
  ),
  on(
    TaskEditorAttachmentsApiActions.deleteTaskAttachmentSuccess,
    (state, action): TasksState =>
      adapter.mapOne(
        {
          id: action.taskId,
          map: (task) => ({
            ...task,
            attachmentIds: task.attachmentIds?.filter(notEqualTo(action.attachmentId)),
          }),
        },
        state
      )
  ),
  on(
    OrderEditorOrdersApiActions.cancelOrderSuccess,
    OrderEditorOrdersApiActions.closeOrderSuccess,
    (state, action): TasksState => adapter.updateMany(action.tasks, state)
  ),
  on(
    SchedulerTasksApiActions.tasksLockSuccess,
    (state, { taskIds }): TasksState =>
      adapter.updateMany(
        taskIds.map((id) => ({ id, changes: { lockedToTeam: true } })),
        state
      )
  ),
  on(
    SchedulerTasksApiActions.tasksUnlockSuccess,
    (state, { taskIds }): TasksState =>
      adapter.updateMany(
        taskIds.map((id) => ({ id, changes: { lockedToTeam: false } })),
        state
      )
  ),
  on(
    TaskActions.upsertTask,
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    TaskEditorTasksApiActions.loadCopiedTaskSuccess,
    (state, action): TasksState => adapter.upsertOne(action.task, state)
  ),
  on(
    AppointmentsApiActions.assignAppointmentForExistingTaskSuccess,
    (state, { task }): TasksState =>
      adapter.updateOne(
        {
          id: task.id,
          changes: {
            statusId: task.statusId,
            appointmentStartDate: task.appointmentStartDate,
            appointmentEndDate: task.appointmentEndDate,
            plannedStartDate: task.plannedStartDate,
            plannedEndDate: task.plannedEndDate,
            workDayId: task.workDayId,
            teamId: task.teamId,
          },
        },
        state
      )
  ),
  on(
    TaskActions.upsertTasks,
    TrackSummaryApiActions.loadTrackSummarySuccess,
    OrderEditorOrdersApiActions.loadOrderSuccess,
    DispatcherNotificationsApiActions.loadNotificationsGroupSuccess,
    DispatcherNotificationsReportApiActions.loadNotificationsReportSuccess,
    (state, { tasks }): TasksState => adapter.upsertMany(tasks, state)
  ),
  on(
    CarTrackingReportApiActions.loadCarTrackingReportSuccess,
    (state, { report: { tasks } }): TasksState => adapter.upsertMany(tasks, state)
  ),
  on(
    DashboardSchedulerActions.loadSchedulerDataSuccess,
    DashboardSchedulerActions.refreshSchedulerDataSuccess,
    (state, { data: { tasks } }): TasksState => adapter.upsertMany(tasks, state)
  ),
  on(
    CustomerTasksApiActions.loadOnGoingTasksSuccess,
    CustomerTasksApiActions.loadTasksHistorySuccess,
    CustomerTasksApiActions.loadNotTerminatedTasksSuccess,
    PlaceEditorTasksApiActions.loadOnGoingTasksSuccess,
    PlaceEditorTasksApiActions.loadTasksHistorySuccess,
    (state, { page: { content: tasks } }): TasksState => adapter.upsertMany(tasks, state)
  ),
  on(
    TasksApiActions.loadTasksPageSuccess,
    FormsReportApiActions.loadFormReportPageSuccess,
    (state, action): TasksState => adapter.upsertMany(action.page.tasks, state)
  ),
  on(TasksReportApiActions.loadTasksReportSuccess, (state, action): TasksState => {
    return adapter.upsertMany(
      action.page.content.map(
        (task): TaskEntity => ({
          id: task.id,
          // eslint-disable-next-line id-blacklist
          number: task.number,
          areaId: task.areaId,
          typeId: task.typeId,
          priorityId: task.priorityId,
          statusId: task.statusId,
          orderId: task.orderId,
          lockedToTeam: task.lockedToTeam,
          kindId: task.kindId,
          workDayId: task.workDayId,
          teamId: task.teamId,
          modificationTime: task.modificationTime,
          description: task.description,
          skillIds: task.skillIds,
          optimizationSessionId: task.optimizationSessionId,
          attachmentIds: task.attachmentIds,
          additionalFields: task.taskAdditionalFieldValueWebObjects ?? {},
          address: task.address,
          longitude: task.longitude,
          latitude: task.latitude,
          requiredStartDate: task.requiredStartDate,
          requiredEndDate: task.requiredEndDate,
          realStartDate: task.realStartDate,
          realEndDate: task.realEndDate,
          plannedStartDate: task.plannedStartDate,
          plannedEndDate: task.plannedEndDate,
          manuallyCreated: task.manuallyCreated,
          orderExternalNumber: task.orderExternalNumber,
          serviceTypeIds: task.serviceTypeIds,
          executorId: task.executorId,
          sacId: task.sacId,
        })
      ),
      state
    );
  }),
  on(DeleteDataApiActions.deleteAllTasksSuccess, (state): TasksState => adapter.removeAll(state)),
  on(
    DeleteDataApiActions.deleteSelectedTasksSuccess,
    (state, action): TasksState => adapter.removeMany(action.ids, state)
  )
);

export function featureConfigFactory(
  broadcastChannel: BroadcastChannel | null,
  location: Location,
  window: Window
): StoreConfig<TasksState> {
  const metaReducers = broadcastChannel
    ? [
        stateBroadcastMetaReducer({
          location,
          window,
          broadcastChannel,
          featureKey: tasksFeatureKey,
          initialState,
          syncAction: TaskActions.syncTaskEntitiesState,
        }),
      ]
    : [];
  return {
    metaReducers,
  };
}

export const { selectEntities, selectAll } = adapter.getSelectors();
