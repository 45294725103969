export enum ModuleName {
  TaskImporter = 'taskImporter',
  Areas = 'areas',
  AreasEditable = 'areasEditable',
  Skills = 'skills',
  Companies = 'companies',
  LocalOptimize = 'localOptimize',
  Optimizations = 'optimizations',
  Teams = 'teams',
  CarTracking = 'carTracking',
  Appointments = 'appointments',
  FormTemplates = 'formTemplates',
  Notes = 'notes',
  TaskStatusHistoryEditor = 'taskStatusHistoryEditor',
  Places = 'places',
  ReportRodo = 'reportRodo',
  DispatcherNotifications = 'dispatcherNotifications',
}

export type ModulesConfiguration = Record<ModuleName, boolean>;
