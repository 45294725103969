import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthSelectors } from 'geotask/core/selectors';
import { Routes } from '../models';
import * as fromProgressBarVisible from '../reducers/progress-bar-visible.reducer';
import { selectLastSegmentOfPath } from './router.selectors';

const selectProgressBarVisible = createFeatureSelector<fromProgressBarVisible.ProgressBarVisibleState>(
  fromProgressBarVisible.progressBarVisibleFeatureKey
);

const selectProgressBarDisplayProperty = createSelector(selectProgressBarVisible, (visible) =>
  visible ? null : 'none'
);

const selectIsExternalMapTab = createSelector(
  selectLastSegmentOfPath,
  (lastSegmentOfPath) => lastSegmentOfPath === '' || lastSegmentOfPath === 'map'
);

export const selectIsNotExternalMapTab = createSelector(
  selectIsExternalMapTab,
  (isExternalMapTab) => !isExternalMapTab
);

const selectHomeButtonVisible = createSelector(
  selectLastSegmentOfPath,
  AuthSelectors.selectIsTaskReadOnly,
  (lastSegmentOfPath, isTaskReadonly) =>
    lastSegmentOfPath !== Routes.Dashboard && lastSegmentOfPath !== Routes.Login && !isTaskReadonly
);

export const selectRootViewModel = createSelector(
  selectProgressBarDisplayProperty,
  selectIsExternalMapTab,
  selectHomeButtonVisible,
  (progressBarDisplay, externalMapTab, homeButtonVisible) => ({
    progressBarDisplay,
    externalMapTab,
    homeButtonVisible,
  })
);
