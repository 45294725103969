import { ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { z } from 'zod';

import { validatingDeserializer } from '@glb/util/functional';
import { rootStorageSyncConfig } from 'geotask/util/storage-sync';
import { AppNameApiActions } from '../actions';
import * as fromAuth from './auth.reducer';
import * as fromHistory from './history.reducer';
import * as fromModules from './modules.reducer';
import * as fromProgressBarVisible from './progress-bar-visible.reducer';
import * as fromRouter from './router';

export const appNameFeatureKey = 'appName';

export interface AppState {
  [appNameFeatureKey]: string;
  router: fromRouter.MergedRouteReducerState;

  [fromProgressBarVisible.progressBarVisibleFeatureKey]: fromProgressBarVisible.ProgressBarVisibleState;
  [fromAuth.authFeatureKey]: fromAuth.AuthState;
  [fromModules.modulesFeatureKey]: fromModules.ModulesState;
  [fromHistory.historyFeatureKey]: fromHistory.HistoryState;
}

export const reducers: ActionReducerMap<AppState> = {
  [appNameFeatureKey]: createReducer(
    '',
    on(AppNameApiActions.loadAppNameSuccess, (_state, action): AppState['appName'] => action.appName)
  ),
  router: fromRouter.reducer,

  [fromProgressBarVisible.progressBarVisibleFeatureKey]: fromProgressBarVisible.reducer,
  [fromAuth.authFeatureKey]: fromAuth.reducer,
  [fromModules.modulesFeatureKey]: fromModules.reducer,
  [fromHistory.historyFeatureKey]: fromHistory.reducer,
};

export const localStorageSyncMetaReducer: MetaReducer<AppState> = (reducer) =>
  localStorageSync({
    ...rootStorageSyncConfig,
    keys: [
      {
        [fromAuth.authFeatureKey]: {
          deserialize: validatingDeserializer(
            z.object({
              token: z.string().nullable(),
            })
          )(fromAuth.initialState),
        },
      },
    ],
  })(reducer);

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncMetaReducer];
