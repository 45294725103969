import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { NotificationConfigurationWebObject } from 'geotask/backend/schemas/notification-configuration-web-object.schema';
import * as ConfigurationApiActions from 'geotask/notification-configuration/actions/configuration-api.actions';
import { NotificationConfigurationEntity } from '../models/notification-configuration.model';

export const notificationConfigurationFeatureKey = 'notificationConfiguration';

export type NotificationConfigurationState = EntityState<NotificationConfigurationEntity>;

export const adapter = createEntityAdapter<NotificationConfigurationEntity>();

export const initialState: NotificationConfigurationState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    ConfigurationApiActions.loadConfigurationSuccess,
    (state, { notificationConfiguration }): NotificationConfigurationState =>
      adapter.upsertOne(getNotificationConfigurationEntity(notificationConfiguration), state)
  ),
  on(
    ConfigurationApiActions.loadListSuccess,
    ConfigurationApiActions.loadConfigurationsInitDataSuccess,
    (state, { notificationConfigurations }): NotificationConfigurationState =>
      adapter.setAll(notificationConfigurations.map(getNotificationConfigurationEntity), state)
  )
);

function getNotificationConfigurationEntity(
  notificationConfiguration: NotificationConfigurationWebObject
): NotificationConfigurationEntity {
  return {
    ...notificationConfiguration,
    isEnabled: notificationConfiguration.isEnabled ?? false,
  };
}

export const { selectEntities, selectAll } = adapter.getSelectors();
