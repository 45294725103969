import { FilterPredicate } from '@glb/util/types';

export const notIn = <T>(values: Iterable<T> | readonly T[]) => {
  const cache = new Set<T>(values);
  return (value: T) => !cache.has(value);
};

export const distinct = <T>(identityProvider?: (value: T) => any) => {
  const cache = new Set<any>();
  return (value: T) => {
    const valueIdentity = identityProvider ? identityProvider(value) : value;
    const seenAlready = cache.has(valueIdentity);
    cache.add(valueIdentity);
    return !seenAlready;
  };
};

export const not =
  <T>(predicate: (value: T) => boolean) =>
  (value: T) =>
    !predicate(value);

export const notNull = <T>(value: T): value is NonNullable<T> => value != null;
export const equalTo =
  <T>(value: T) =>
  (otherValue: T) =>
    otherValue === value;
export const notEqualTo = <T>(value: T) => not(equalTo(value));

export const everyPredicate =
  <T>(...predicates: FilterPredicate<T>[]): FilterPredicate<T> =>
  (value, index, array) =>
    predicates.every((predicate) => predicate(value, index, array));

export const isEven = (_value: any, index: number) => index % 2 === 0;
export const isOdd = (_value: any, index: number) => index % 2 === 1;
