import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { TeamUnavailabilityOccurrence } from '../models';
import { TeamUnavailabilityDefinitionActions, TeamUnavailabilityOccurrenceActions } from '../actions';
import * as DashboardSchedulerActions from 'geotask/dashboard-scheduler/actions/scheduler-data.actions';

export const teamUnavailabilityOccurrencesFeatureKey = 'teamUnavailabilityOccurrences';

export type TeamUnavailabilityOccurrencesState = EntityState<TeamUnavailabilityOccurrence>;

export const adapter: EntityAdapter<TeamUnavailabilityOccurrence> = createEntityAdapter<TeamUnavailabilityOccurrence>();

export const initialState: TeamUnavailabilityOccurrencesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    DashboardSchedulerActions.loadSchedulerDataSuccess,
    DashboardSchedulerActions.refreshSchedulerDataSuccess,
    (state, { data: { teamUnavailabilityOccurrences } }): TeamUnavailabilityOccurrencesState =>
      adapter.upsertMany(teamUnavailabilityOccurrences, state)
  ),
  on(
    TeamUnavailabilityOccurrenceActions.retainTeamUnavailabilityOccurrences,
    (state, { retain }): TeamUnavailabilityOccurrencesState => {
      state = adapter.removeMany(byUnavailabilityDefinitionId(retain.teamUnavailabilityDefinitionId), state);
      return adapter.upsertMany(retain.occurrences, state);
    }
  ),
  on(
    TeamUnavailabilityDefinitionActions.removeTeamUnavailabilityDefinition,
    (state, { teamUnavailabilityDefinitionId }): TeamUnavailabilityOccurrencesState =>
      adapter.removeMany(byUnavailabilityDefinitionId(teamUnavailabilityDefinitionId), state)
  ),
  on(
    TeamUnavailabilityDefinitionActions.updateTeamUnavailabilityDefinition,
    (state, { teamUnavailabilityDefinition }): TeamUnavailabilityOccurrencesState =>
      adapter.removeMany(byUnavailabilityDefinitionId(+teamUnavailabilityDefinition.id), state)
  )
);

function byUnavailabilityDefinitionId(definitionId: number) {
  return (occurrence: TeamUnavailabilityOccurrence) => occurrence.definitionId === definitionId;
}

export const { selectEntities, selectAll } = adapter.getSelectors();
