import { ActionReducer } from '@ngrx/store';
import { ROUTER_NAVIGATED, RouterNavigatedAction, routerReducer } from '@ngrx/router-store';

import { MergedRoute, MergedRouteReducerState } from './merged-route';

export const routerFeatureKey = 'router';
export const reducer: ActionReducer<MergedRouteReducerState> = (routerState, action) => {
  let nextRouterState = routerReducer(routerState, action);
  switch (action.type) {
    case ROUTER_NAVIGATED:
      const { payload } = action as RouterNavigatedAction<MergedRoute>;
      nextRouterState = {
        ...nextRouterState,
        state: payload.routerState,
        navigationId: payload.event.id,
      };
      break;
  }
  return nextRouterState;
};

export * from './merged-route-serializer';
export * from './merged-route';
