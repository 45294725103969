/* eslint-disable id-blacklist */

interface TypeofMap {
  string: string;
  number: number;
  boolean: boolean;
  function: (...args: any[]) => any;
}

type TypeOfReturn = keyof TypeofMap;

const typeofGuard =
  <T extends TypeOfReturn>(type: T) =>
  (value: any): value is TypeofMap[T] =>
    typeof value === type;

export const isString = typeofGuard('string');
export const isNumber = typeofGuard('number');
export const isBoolean = typeofGuard('boolean');
export const isFunction = typeofGuard('function');

export const isInValues = <T>(values: Iterable<T>) => {
  const valuesSet = new Set(values);
  return (value: any): value is T => valuesSet.has(value);
};
