export enum DispatcherNotificationTypeCode {
  NotFinishedCodeTask = 'NOT_FINISHED_CODE_TASK',
  NewNote = 'NEW_NOTE',
  RejectedCodeTask = 'REJECTED_CODE_TASK',
  NewTaskCreatedFromMobile = 'NEW_TASK_CREATED_FROM_MOBILE',
  NewHighTask = 'NEW_HIGH_TASK',
  AdditionalInstallService = 'ADDITIONAL_INSTALL_SERVICE',
  InvalidGeolocation = 'INVALID_GEOLOCATION',
  ScheduledTaskChanged = 'SCHEDULED_TASK_CHANGED',
}
