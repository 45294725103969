import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { of } from 'rxjs';

import { ExecutorStatusCode } from 'geotask/core/models';
import { ISO8601Timestamp } from 'geotask/core/schemas/iso-8601-timestamp.schema';
import { LatLng } from 'geotask/core/schemas/lat-lng.schema';
import { SerializedDate } from 'geotask/core/schemas/serialized-date.schema';
import { Role } from 'geotask/dictionaries/models';
import { ExecutorsFilter } from '../models/executor-filters.model';
import { ExecutorBaseInfoWebObject } from '../schemas/executor-base-info-web-object.schema';
import { NormalizedExecutorWebObject } from '../schemas/normalized-executor-web-object.schema';
import { Page } from '../schemas/page.schema';
import { createPageableParams } from './create-pageable-params';
import { createExecutorFilterParams } from './create-executor-filter-params';
import { RangeOf } from '@glb/util/types';

export interface ExecutorStatusWebObject {
  id: number;
  login: string;
  name: string;
  active: boolean;
  domainAccount: boolean;
  statusCode: ExecutorStatusCode;
  lastTimeOnline: ISO8601Timestamp;
  longitude: number | null;
  latitude: number | null;
  teamId: number | null;
}

export interface ExecutorUnavailabilityWebObject {
  id: number;
  teamId: number;
  date: SerializedDate;
}

@Injectable({
  providedIn: 'root',
})
export class ExecutorsApiService {
  readonly areaIdsOfLoggedExecutor$ = this.httpClient.get<readonly number[]>(
    `@backend/executor/getAreaIdsOfLoggedExecutor`
  );

  constructor(private readonly httpClient: HttpClient) {}

  getExecutorById(executorId: number) {
    return this.httpClient.get<NormalizedExecutorWebObject>(`@backend/v2/executors/${executorId}`);
  }

  getActiveTerrainExecutorsByAreaIds(areaIds: readonly number[]) {
    return this.httpClient.get<NormalizedExecutorWebObject[]>(
      `@backend/v2/executors/getActiveTerrainExecutorsByAreaIds`,
      {
        params: {
          id: areaIds,
        },
      }
    );
  }

  getExecutorsByIds(executorIds: readonly number[]) {
    if (executorIds.length === 0) {
      return of([]);
    }
    return this.httpClient.get<ExecutorBaseInfoWebObject[]>(`@backend/v2/executors/getExecutorsByIds`, {
      params: {
        id: executorIds,
      },
    });
  }

  getExecutorsByLogins(executorLogins: readonly string[]) {
    if (executorLogins.length === 0) {
      return of([]);
    }
    return this.httpClient.get<ExecutorBaseInfoWebObject[]>(`@backend/v2/executors/byLogin`, {
      params: {
        login: executorLogins,
      },
    });
  }

  getExecutorsPage(filter: Readonly<ExecutorsFilter>) {
    return this.httpClient.get<Page<NormalizedExecutorWebObject>>(`@backend/v2/executors`, {
      params: createExecutorsParams(filter),
    });
  }

  getRodoReportExecutorsPage(filters: Readonly<ExecutorsFilter>) {
    return this.httpClient.get<Page<NormalizedExecutorWebObject>>(`@backend/v2/reports/rodo/executors`, {
      params: createExecutorsParams(filters),
    });
  }

  validateExecutor(executor: ValidateExecutorBody) {
    return this.httpClient.post<ValidateNotificationResponse>(`@backend/executor/validateNotification`, executor);
  }

  updateOrInsertExecutor(update: UpdateOrInsertExecutorBody) {
    return this.httpClient.post(`@backend/ikea/executor/updateOrInsertExecutor`, update);
  }

  updateExecutor(update: UpdateOrInsertExecutorBody) {
    return this.httpClient.post(`@backend/ikea/executor/updateExecutor`, update);
  }

  deactivateExecutors(executorIds: number[]) {
    return this.httpClient.post(`@backend/ikea/executor/deactivateExecutors`, executorIds);
  }

  getStatusMap(executor: { id: number; active: boolean; roles: Role[] }) {
    return this.httpClient.post<GetStatusMapResponse>('@backend/executor/getStatusMap', executor);
  }

  isLoginUnique(login: string) {
    return this.httpClient.get<number | null>('@backend/v2/executors/loginValidation', { params: { login } });
  }

  getExecutorStatuses(teamsIds: readonly number[], areaIds: readonly number[]) {
    return this.httpClient.post<ExecutorStatusWebObject[]>('@backend/v2/executors/statuses', {
      teamsIds,
      areaIds,
    });
  }

  getExecutorUnavailabilities(executorId: number, from: Date, to: Date) {
    return this.httpClient.get<ExecutorUnavailabilityWebObject[]>(
      `@backend/v2/executors/${executorId}/unavailabilities`,
      {
        params: {
          from: DateTime.fromJSDate(from).toISODate(),
          to: DateTime.fromJSDate(to).toISODate(),
        },
      }
    );
  }

  getUnavailabilities(executorIds: number[], { startDate, endDate }: { startDate: Date; endDate: Date }) {
    return this.httpClient.get<ExecutorUnavailabilityWebObject[]>(`@backend/v2/executors/unavailabilities`, {
      params: {
        ids: executorIds,
        from: DateTime.fromJSDate(startDate).toISODate(),
        to: DateTime.fromJSDate(endDate).toISODate(),
      },
    });
  }

  addUnavailability(executorId: number, date: RangeOf<any>) {
    return this.httpClient.post<ExecutorUnavailabilityWebObject[]>(
      `@backend/v2/executors/${executorId}/unavailabilities`,
      {
        start: DateTime.fromJSDate(date.start).toISODate(),
        end: DateTime.fromJSDate(date.end).toISODate(),
      }
    );
  }

  deleteUnavailabilities(unavailabilitiesIds: readonly number[]) {
    return this.httpClient.post<void>(`@backend/v2/executors/unavailabilities/delete`, unavailabilitiesIds);
  }
}

function createExecutorsParams({
  pageNumber,
  pageSize,
  namePrefix,
  loginPrefix,
  startLocationAddressPrefix,
  endLocationAddressPrefix,
  areasIds,
  rolesIds,
  costAreasIds,
  active,
  domainAccount,
  sort,
  lastTimeOnline,
}: Readonly<ExecutorsFilter>) {
  const params = createExecutorFilterParams({
    namePrefix,
    loginPrefix,
    startLocationAddressPrefix,
    endLocationAddressPrefix,
    areasIds,
    rolesIds,
    costAreasIds,
    active,
    domainAccount,
    lastTimeOnline,
  });
  // Pageable params
  Object.assign(params, createPageableParams({ pageNumber, pageSize, sort }));

  return params;
}

export interface ValidateExecutorBody {
  id: number;
  active: boolean;
  roles: Role[];
}

export interface UpdateOrInsertExecutorBody {
  id: number | null;
  name: string;
  active: boolean;
  domainAccount: boolean;
  domainLogin: string | null;
  login: string;
  phoneNumber: string | null;
  email: string | null;
  workStartTime: string | null;
  workEndTime: string | null;
  startLocationAddress: string;
  startLocationLatitude: number | null;
  startLocationLongitude: number | null;
  endLocationAddress: string;
  endLocationLatitude: number | null;
  endLocationLongitude: number | null;
  roles: {
    id: number;
    name: string;
    code: string;
  }[];
  areas: {
    id: number;
    name: string;
    code: string;
  }[];
  costArea: {
    id: number;
    name: string;
    code: string;
  } | null;
  skills: {
    id: number;
    name: string;
  }[];
  volumeCapacity: number | null;
  weightCapacity: number | null;
  taskLimit: number | null;
  operatingArea: CircleOperatingArea | PolygonOperatingArea | null;
  pin: string | null;
  password: string | null;
  company: {
    id: string;
    name: string;
  } | null;
}

interface CircleOperatingArea {
  polygonEnabled: false;
  lat: number;
  lng: number;
  address: string;
  radius: number;
}

interface PolygonOperatingArea {
  polygonEnabled: true;
  operatingAreaPolygon: LatLng[];
}

interface ValidateNotificationResponse {
  dates: string | null;
  name: string | null;
  valid: boolean;
  workingToday: boolean;
}

export type GetStatusMapResponse = {
  status: string;
  count: number;
}[];
