import { InjectionToken, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateModuleConfig, TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { EffectsModule } from '@ngrx/effects';
import { from } from 'rxjs';

import { TimeModelAdapter } from '@glb/shared/picker/time/model';
import { LanguageChangedEffects } from './effects';
import { LanguageService } from './services/language.service';
import { LanguageChangeCallback, provideLanguageChangeCallback } from './services/language-change-callback.token';
import { DEFAULT_LANGUAGE, isSupportedLang } from './langs';

function UPDATE_TRANSLATE_SERVICE_FACTORY(translate: TranslateService): LanguageChangeCallback {
  return (language) => {
    translate.currentLang = ''; // Hack to force load translations
    translate.use(language);
  };
}
UPDATE_TRANSLATE_SERVICE_FACTORY.deps = [TranslateService];

function ROOT_LANGUAGE_CHANGED_CALLBACK_FACTORY(
  translateService: TranslateService,
  dateAdapter: DateAdapter<unknown>,
  timeModelAdapter: TimeModelAdapter<unknown>
): LanguageChangeCallback {
  return (language) => {
    translateService.use(language);
    dateAdapter.setLocale(language);
    timeModelAdapter.locale = language;
  };
}
ROOT_LANGUAGE_CHANGED_CALLBACK_FACTORY.deps = [TranslateService, DateAdapter, TimeModelAdapter];

class GeoTaskTranslateLoader extends TranslateLoader {
  constructor(private readonly path: string) {
    super();
  }

  override getTranslation(lang: string) {
    return from(
      import(
        /* webpackInclude: /(en|pl)\.json$/ */
        /* webpackChunkName: "i18n/[request]" */
        `../${this.path}/${lang}.json`
      )
    );
  }
}

const I18N_MODULE_NAME = new InjectionToken<string>('I18n module name');

const COMMON_CONFIG: TranslateModuleConfig = {
  extend: true,
  defaultLanguage: DEFAULT_LANGUAGE,
};

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: () => new GeoTaskTranslateLoader('core/i18n'),
      },
      ...COMMON_CONFIG,
    }),
  ],
  exports: [TranslateModule],
})
export class GeotaskI18nRootModule {}

@NgModule({
  imports: [
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (module: string) => new GeoTaskTranslateLoader(`${module}/i18n`),
        deps: [I18N_MODULE_NAME],
      },
      ...COMMON_CONFIG,
    }),
    EffectsModule.forFeature([LanguageChangedEffects]),
  ],
  exports: [TranslateModule],
})
export class GeotaskI18nChildModule {}

@NgModule({})
export class GeotaskI18nModule {
  static forRoot(): ModuleWithProviders<GeotaskI18nRootModule> {
    return {
      ngModule: GeotaskI18nRootModule,
      providers: [
        provideLanguageChangeCallback(ROOT_LANGUAGE_CHANGED_CALLBACK_FACTORY),
        {
          provide: LOCALE_ID,
          useFactory: (languageService: LanguageService) => {
            const language = languageService.language;
            return isSupportedLang(language) ? language : DEFAULT_LANGUAGE;
          },
          deps: [LanguageService],
        },
      ],
    };
  }

  static forChild(moduleName: string): ModuleWithProviders<GeotaskI18nChildModule> {
    return {
      ngModule: GeotaskI18nChildModule,
      providers: [
        {
          provide: I18N_MODULE_NAME,
          useValue: moduleName,
        },
        provideLanguageChangeCallback(UPDATE_TRANSLATE_SERVICE_FACTORY),
      ],
    };
  }
}
