import { z } from 'zod';

export const LatitudeSchema = z.number().min(-90).max(90);
export const LongitudeSchema = z.number().gte(-180).lt(180);
export const LatLngSchema = z.object({
  lat: LatitudeSchema,
  lng: LongitudeSchema,
});

export type LatLng = z.infer<typeof LatLngSchema>;
