import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { AreaListRowWebObject } from 'geotask/backend/schemas/area-list-row-web-object.schema';
import type {
  Company,
  DispatcherNotificationStatus,
  FieldValueType,
  FormTemplateDictionary,
  FormTemplateEntity,
  NoteType,
  OrderStatus,
  PlaceType,
  Role,
  Skill,
  TaskKind,
  TaskPriority,
  TaskStatus,
  TaskType,
  UnavailabilityType,
} from 'geotask/dictionaries/models';
import { IkeaServiceKind } from 'geotask/dictionaries/models/ikea-service-kind.model';

export const MultidictionaryApiActions = createActionGroup({
  source: 'Multidictionary API',
  events: {
    'Load dictionaries success': props<{
      taskStatuses: TaskStatus[];
      taskPriorities: TaskPriority[];
      orderStatuses: OrderStatus[];
      taskTypes: TaskType[];
      unavailabilityTypes: UnavailabilityType[];
      placeTypes: PlaceType[];
      taskKinds: TaskKind[];
      ikeaServicesKind: IkeaServiceKind[];
      noteTypes: NoteType[];
      skills: Skill[];
      areas: AreaListRowWebObject[];
      roles: Role[];
      formTemplateFieldTypes: FieldValueType[];
      formTemplates: FormTemplateEntity[];
      formTemplateDictionaries: FormTemplateDictionary[];
      dispatcherNotificationStatuses: DispatcherNotificationStatus[];
      additionalFieldTypes: FieldValueType[];
      companies: Company[];
    }>(),
    'Load dictionaries failure': props<{ error: ApiError }>(),
  },
});
