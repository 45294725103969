import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createArraySelector, createEntitiesByIdsSelector } from 'geotask/core/selectors/selector-factories';
import { distinct, notNull, toEntities } from '@glb/util/functional';
import * as fromExecutor from '../reducers/executor.reducer';
import * as TaskSelectors from '../selectors/task.selectors';
import * as WorkDaySelectors from '../selectors/work-day.selectors';

export const selectExecutorsState = createFeatureSelector<fromExecutor.ExecutorsState>(
  fromExecutor.executorsFeatureKey
);
export const selectEntities = createSelector(selectExecutorsState, fromExecutor.selectEntities);
export const selectAll = createSelector(selectExecutorsState, fromExecutor.selectAll);

export const selectByAreaId = createSelector(selectEntities, (executors) =>
  Object.values(executors).reduce((byAreaId: { [areaId: number]: number[] | undefined }, executor) => {
    if (!executor) {
      return byAreaId;
    }
    for (const areaId of executor.areaIds ?? []) {
      const executorIds = new Set(byAreaId[areaId] ?? []);
      executorIds.add(executor.id);
      byAreaId = {
        ...byAreaId,
        [areaId]: Array.from(executorIds).sort(),
      };
    }
    return byAreaId;
  }, {})
);

export const selectIdByLogin = createSelector(selectEntities, (executors) =>
  Object.values(executors)
    .filter(notNull)
    .map((executor) => ({ [executor.login]: executor.id }))
    .reduce<{
      [executorLogin: string]: number | undefined;
    }>(Object.assign, {})
);

export const selectExecutorIdByLogin = (executorLogin: string) =>
  createSelector(selectIdByLogin, (byLogin) => byLogin[executorLogin]);

export const selectIsExecutorInStoreByLogin = (executorLogin: string) =>
  createSelector(selectExecutorIdByLogin(executorLogin), (executorId) => executorId != null);

export const selectNameByLogin = createSelector(
  selectIdByLogin,
  selectEntities,
  (byLogin, executors) => (login: string) => {
    const executorId = byLogin[login];
    if (executorId) {
      return executors[executorId]?.name;
    } else {
      return undefined;
    }
  }
);

export const selectByTaskId = createSelector(
  selectEntities,
  TaskSelectors.selectEntities,
  WorkDaySelectors.selectEntities,
  (executors, tasks, workDays) => (taskId: number) => {
    const workDayId = tasks[taskId]?.workDayId;
    const executorIds = (workDayId && workDays[workDayId]?.executorIds) || [];
    return executorIds.reduce(toEntities(executors), []);
  }
);

export const selectExecutorById = createSelector(selectEntities, (executor) => (executorId: number) => {
  return executor[executorId];
});

export const selectExecutorsByIds = createEntitiesByIdsSelector(selectEntities);
export const selectExecutorsSkillIds = (executorIds: readonly number[]) =>
  createArraySelector(selectExecutorsByIds(executorIds), (executors) =>
    executors
      .map((executor) => executor.skillIds)
      .filter(notNull)
      .flat()
      .filter(distinct())
  );
