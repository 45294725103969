import type { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { TeamEntity } from 'geotask/entities/models';
import type { TeamRoute } from '../models';

export const loadTeamRoutesSuccess = createAction(
  '[Route API] Load Team Routes Success',
  props<{
    readonly routes: {
      readonly [teamId: number]: TeamRoute;
    };
    readonly teams: Update<TeamEntity>[];
  }>()
);
export const loadTeamRoutesFailure = createAction('[Route API] Load Team Route Failure', props<{ error: ApiError }>());
