import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { AppNameSelectors } from '../selectors';
import { MergedRoute } from '../reducers/router';

@Injectable()
export class TitleEffects {
  private appName$ = this.store$.select(AppNameSelectors.selectAppName);

  // noinspection JSUnusedGlobalSymbols
  appTitle$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerNavigatedAction),
        map((action) => (action.payload.routerState as unknown as MergedRoute).data['title']),
        switchMap((title: any) => (!!title && typeof title === 'string' ? this.translateTitle(title) : this.appName$)),
        distinctUntilChanged(),
        concatLatestFrom(() => this.appName$),
        tap(([pageTitle, appName]) => {
          this.title.setTitle(appName === pageTitle ? appName : `${pageTitle} - ${appName}`);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private translate: TranslateService,
    private title: Title
  ) {}

  private translateTitle(title: string) {
    return this.translate.stream(title).pipe(filter((translation) => translation !== title));
  }
}
