import type { z } from 'zod';

const DECIMAL_RADIX = 10;

const parseDecimalStringToInteger = (s: string) => parseInt(s, DECIMAL_RADIX);

export const numberDeserializer =
  (parser = parseDecimalStringToInteger) =>
  (value: unknown): number | undefined => {
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string') {
      return parser(value);
    }
    return undefined;
  };

type Deserializer<T> = (state: unknown) => T;

type Config<T> = {
  [TKey in keyof T]: (defaultValue: T[TKey]) => Deserializer<T[TKey]>;
};

export const createDeserializer =
  <T extends Record<string, unknown>>(config: Config<T>, defaultValue: T): Deserializer<T> =>
  (state) => {
    if (typeof state !== 'object' || state === null) {
      return defaultValue;
    }
    const newState: any = {};
    for (const key in config) {
      if (config.hasOwnProperty(key) && state.hasOwnProperty(key)) {
        const deserialize = config[key](defaultValue[key]);
        newState[key] = deserialize((state as any)[key]);
      }
    }
    return newState;
  };

export const validatingDeserializer =
  <T>(zodType: z.ZodType<T>) =>
  (defaultValue: T): Deserializer<T> =>
  (state) => {
    const safeParseResult = zodType.safeParse(state);
    return safeParseResult.success ? safeParseResult.data : defaultValue;
  };
