import { z } from 'zod';

import { FieldValueTypeCode } from 'geotask/core/models';
import { DictionaryEntrySchema } from 'geotask/core/schemas/dictionary-entry.schema';

export const FormTemplateFieldTypeWebObjectSchema = DictionaryEntrySchema.extend({
  code: z.nativeEnum(FieldValueTypeCode),
  dictionaryRequired: z.boolean(),
});
export type FormTemplateFieldTypeWebObject = z.infer<typeof FormTemplateFieldTypeWebObjectSchema>;
