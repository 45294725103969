import { createAction, props } from '@ngrx/store';
import type { Update } from '@ngrx/entity';

import type { TaskEntity } from '../models/task.model';
import type { TasksState } from '../reducers/task.reducer';

export const syncTaskEntitiesState = createAction(
  '[Tasks] Sync Task Entities State',
  props<{ newState: TasksState }>()
);

export const updateTask = createAction('[Task/API] Update Task', props<{ task: Update<TaskEntity> }>());
export const upsertTask = createAction('[Task/API] Upsert Task', props<{ task: TaskEntity }>());
export const upsertTasks = createAction('[Task/API] Upsert Tasks', props<{ tasks: TaskEntity[] }>());
