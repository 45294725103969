import { createAction, props } from '@ngrx/store';
import type { ApiError } from 'geotask/backend/models';

export const loadTeamSuccess = createAction('[Teams API] Load Team Success');
export const loadTeamFailure = createAction('[Teams API] Load Team Failure', props<{ error: ApiError }>());

export const saveTeamSuccess = createAction('[Teams API] Save Team Success');
export const saveTeamFailure = createAction('[Teams API] Save Team Failure', props<{ error: ApiError }>());

export const loadTeamsSuccess = createAction(
  '[Teams API] Load Teams Success',
  props<{
    page: {
      pageNumber: number;
      pageSize: number;
      totalElements: number;
      ids: number[];
    };
  }>()
);
export const loadTeamsFailure = createAction('[Teams API] Load Teams Failure', props<{ error: ApiError }>());
