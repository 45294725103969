import { createAction, props } from '@ngrx/store';
import type { Update } from '@ngrx/entity';
import type { ApiError } from 'geotask/backend/models';
import type { TaskKind } from 'geotask/dictionaries/models';

export const addTaskKindSuccess = createAction(
  '[TaskKinds/TaskKinds API] Save Task Kind Success',
  props<{ taskKind: TaskKind }>()
);

export const addTaskKindFailure = createAction(
  '[TaskKinds/TaskKinds API] Save Task Kind Failure',
  props<{ error: ApiError }>()
);

export const updateTaskKindSuccess = createAction(
  '[TaskKinds/TaskKinds API] Save Task Kind Success',
  props<{ taskKind: Update<TaskKind> }>()
);

export const updateTaskKindFailure = createAction(
  '[TaskKinds/TaskKinds API] Save Task Kind Failure',
  props<{ error: ApiError }>()
);
