import { z } from 'zod';

import { IdSchema } from 'geotask/core/schemas/id.schema';

export const SkillWebObjectSchema = z.object({
  id: IdSchema,
  name: z.string(),
  description: z.string().nullable(),
});
export type SkillWebObject = z.infer<typeof SkillWebObjectSchema>;
