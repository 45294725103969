import { RouterStateSerializer } from '@ngrx/router-store';
import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot, UrlSegment } from '@angular/router';

import { RouteData } from '../../models/route-data.enum';
import { MergedRoute } from './merged-route';

export class MergedRouteSerializer implements RouterStateSerializer<MergedRoute> {
  serialize(routerState: RouterStateSnapshot): MergedRoute {
    return {
      url: routerState.url,
      params: mergeRouteParams(routerState.root, 'params'),
      queryParams: mergeRouteParams(routerState.root, 'queryParams'),
      data: mergeRouteData(routerState.root),
      urlForNavigateBack: selectUrlForNavigateBack(routerState.root),
    };
  }
}

function mergeRouteParams(route: ActivatedRouteSnapshot | null, field: 'params' | 'queryParams'): Params {
  if (!route) {
    return {};
  }
  const currentParams = route[field];
  const primaryChild = getPrimaryChild(route);
  return { ...currentParams, ...mergeRouteParams(primaryChild, field) };
}

function mergeRouteData(route: ActivatedRouteSnapshot | null): Data {
  if (!route) {
    return {};
  }
  const currentData = route.data;
  const primaryChild = getPrimaryChild(route);
  return { ...currentData, ...mergeRouteData(primaryChild) };
}

function selectUrlForNavigateBack(route: ActivatedRouteSnapshot): string {
  return `/${getUrlSegments(route)
    .map((url) => url.path)
    .join('/')}`;
}

function getUrlSegments(route: ActivatedRouteSnapshot | null): UrlSegment[] {
  if (!route || !!route.data[RouteData.OmitRouteInHistory]) {
    return [];
  }
  return [...route.url, ...getUrlSegments(getPrimaryChild(route))];
}

function getPrimaryChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot | null {
  return route.children.find((child) => child.outlet === 'primary') || route.firstChild;
}
