import { z } from 'zod';

import { Unwrapped } from '@glb/util/types';
import { DictionaryEntrySchema } from 'geotask/core/schemas/dictionary-entry.schema';
import { LatLngSchema } from 'geotask/core/schemas/lat-lng.schema';

const AreaSchema = DictionaryEntrySchema.extend({
  isCostArea: z.boolean(),
  coordinates: z.array(LatLngSchema),
}).brand<'Area'>();
type AreaSchema = typeof AreaSchema;
type AreaUnwrapped = z.TypeOf<Unwrapped<AreaSchema>>;

export type Area = z.TypeOf<AreaSchema>;

export function createArea(area: AreaUnwrapped) {
  return AreaSchema.parse(area);
}
