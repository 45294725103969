import { coerceArray } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, Input, OnDestroy, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ModuleName } from 'geotask/core/models';
import { ModulesService } from 'geotask/core/services/modules.service';
import { ConditionalBaseDirective } from '@glb/util/angular';

@Directive({
  selector: '[glbIfModuleEnabled]',
})
export class IfModuleEnabledDirective extends ConditionalBaseDirective implements OnDestroy {
  @Input()
  set glbIfModuleEnabled(moduleName: ModuleName | readonly ModuleName[] | null | undefined) {
    moduleName = coerceArray(moduleName ?? []);
    this.modulesSubject.next(moduleName);
  }

  @Input()
  set glbIfModuleEnabledThen(templateRef: TemplateRef<any> | null) {
    this.nextThenTemplate(templateRef);
  }

  @Input()
  set glbIfModuleEnabledElse(templateRef: TemplateRef<any> | null) {
    this.nextElseTemplate(templateRef);
  }

  private readonly modulesSubject = new ReplaySubject<readonly ModuleName[]>(1);

  constructor(
    private readonly modulesService: ModulesService,
    viewContainerRef: ViewContainerRef,
    @Optional() templateRef: TemplateRef<any>,
    changeDetector: ChangeDetectorRef
  ) {
    super(viewContainerRef, changeDetector, templateRef);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.modulesSubject.complete();
  }

  protected getObservableCondition() {
    return this.modulesSubject
      .asObservable()
      .pipe(switchMap((modules) => this.modulesService.areModulesEnabled(modules)));
  }
}
