import { createAction, props } from '@ngrx/store';

import type { SerializableTaskFormValue } from '../models/task-creator-form.model';

export const saveTask = createAction(
  '[Tasks/TaskCreator] Save Task',
  props<{ formValue: SerializableTaskFormValue }>()
);
export const loadCopiedTask = createAction('[Tasks/TaskCreator] Load Copied Task');

export const loadCopiedNote = createAction('[Tasks/TaskCreator] Load Copied Note');

export const taskCreatorOpened = createAction('[Tasks/TaskCreator] Task creator opened');
