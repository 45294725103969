import * as AppNameApiActions from './app-name-api.actions';
import * as AuthApiActions from './auth-api.actions';
import * as BackendApiActions from './backend-api.actions';
import * as BrowserActions from './browser.actions';
import * as ModulesActions from './modules.actions';
import * as ModulesApiActions from './modules-api.actions';
import * as AuthActions from './auth.actions';

export {
  AppNameApiActions,
  AuthApiActions,
  BackendApiActions,
  BrowserActions,
  ModulesActions,
  ModulesApiActions,
  AuthActions,
};

export const LOGOUT_ACTIONS = [
  BackendApiActions.unauthorizedResponse,
  BrowserActions.authTokenExpired,
  AuthApiActions.logoutSuccess,
  AuthApiActions.logoutFailure,
] as const;
