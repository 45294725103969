import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  createArraySelector,
  createEntitiesByIdsSelector,
  createEntityByIdSelector,
} from 'geotask/core/selectors/selector-factories';
import * as fromSkill from '../reducers/skill.reducer';

const selectSkillState = createFeatureSelector<fromSkill.SkillsState>(fromSkill.skillsFeatureKey);
export const selectEntities = createSelector(selectSkillState, fromSkill.selectEntities);
export const selectAll = createArraySelector(selectSkillState, fromSkill.selectAll);
export const selectSkillById = createEntityByIdSelector(selectEntities);
export const selectSkillsByIds = createEntitiesByIdsSelector(selectEntities);
