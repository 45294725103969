import { createActionGroup, props } from '@ngrx/store';
import type { LoaderDataType } from 'geotask/core/models/loader-data-type.enum';

export const LoaderActions = createActionGroup({
  source: 'Loader',
  events: {
    'Upload File': props<{
      fileName: string;
      dataType: LoaderDataType;
    }>(),
  },
});
