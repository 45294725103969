import { z } from 'zod';

import { IdSchema } from 'geotask/core/schemas/id.schema';
import { NonEmptyStringSchema } from 'geotask/core/schemas/non-empty-string.schema';

export const TaskAdditionalFieldWebObjectSchema = z.object({
  id: IdSchema,
  name: NonEmptyStringSchema,
  active: z.boolean(),
  visibleInMobile: z.boolean(),
  orderNumber: z.number().int().min(0),
  fieldTypeId: IdSchema,
  dictionaryTypeId: IdSchema.nullable(),
});

export type TaskAdditionalFieldWebObject = z.infer<typeof TaskAdditionalFieldWebObjectSchema>;
