import { ActionReducer, createReducer, on } from '@ngrx/store';
import { routerCancelAction, routerErrorAction, routerNavigatedAction, routerRequestAction } from '@ngrx/router-store';

export const progressBarVisibleFeatureKey = 'progressBarVisible';

export type ProgressBarVisibleState = boolean;

const initialState: ProgressBarVisibleState = false;

export const reducer: ActionReducer<ProgressBarVisibleState> = createReducer<ProgressBarVisibleState>(
  initialState,
  on(routerRequestAction, (_state, _action): ProgressBarVisibleState => true),
  on(routerNavigatedAction, routerCancelAction, routerErrorAction, (_state, _action): ProgressBarVisibleState => false)
);
