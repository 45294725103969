import { createEffect } from '@ngrx/effects';
import { Inject, Injectable, NgZone, Optional } from '@angular/core';

import { StateSyncBaseEffects } from 'geotask/core/effects/state-sync-base.effects';
import { LOCATION, LocationTokenType } from '@glb/shared/web-api-browser';
import { OptionalInjectionTokenType } from '@glb/util/types';
import * as TaskActions from '../actions/task.actions';
import { TasksState } from '../reducers/task.reducer';
import { TASKS_STATE_SYNC_CHANNEL } from '../sync-channel';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class TasksStateSyncEffects extends StateSyncBaseEffects<TasksState> {
  syncDashboardMapState$ = createEffect(() => {
    return this.getEffect();
  });

  constructor(
    ngZone: NgZone,
    @Inject(LOCATION)
    location: LocationTokenType,
    @Optional()
    @Inject(TASKS_STATE_SYNC_CHANNEL)
    broadcastChannel: OptionalInjectionTokenType<typeof TASKS_STATE_SYNC_CHANNEL>
  ) {
    super(ngZone, location, broadcastChannel);
  }

  protected stateSyncAction(newState: TasksState) {
    return TaskActions.syncTaskEntitiesState({ newState });
  }
}
