import { GridDataRow } from '@glb/shared/grid';

import { Area, TaskKind, TaskPriority, TaskStatus, TaskType } from 'geotask/dictionaries/models';
import { Place, TaskServiceOrder, TeamEntity } from 'geotask/entities/models';
import { ArrayElementTypeOf } from '@glb/util/types';
import { IkeaServiceKind } from 'geotask/dictionaries/models/ikea-service-kind.model';

export interface TaskForGrid {
  id: number;
  selected: boolean;
  appointmentStartDate?: Date;
  type: Readonly<TaskType>;
  priority: Readonly<TaskPriority>;
  // eslint-disable-next-line id-blacklist
  number?: string;
  kind?: Readonly<TaskKind>;
  serviceTypes?: readonly IkeaServiceKind[];
  servicesTooltip?: string;
  status: Readonly<TaskStatus>;
  plannedStartDate?: Date;
  requiredEndDate: Date;
  realEndDate?: Date;
  team?: Readonly<TeamEntity>;
  teamName?: string;
  place?: Readonly<Place>;
  address: string;
  area?: Readonly<Area>;
  customer: string | null;
  contactPerson: string | null;
  externalNumber: string | null;
  providerName: string | null;
  description: string | null;
  sacId: string | null;
  lockedByOptimization: boolean;
}

export type TaskGridRow = GridDataRow<TaskForGrid>;

export const TASK_LIST_COLUMN_NAMES = [
  'number',
  'externalNumber',
  'providerName',
  'appointmentStartDate',
  'type',
  'priority',
  'kind',
  'serviceTypes',
  'status',
  'plannedStartDate',
  'requiredEndDate',
  'team',
  'place',
  'address',
  'area',
  'customer',
  'contactPerson',
  'description',
  'sacId',
] as const;

export type TaskListColumnName = ArrayElementTypeOf<typeof TASK_LIST_COLUMN_NAMES>;
