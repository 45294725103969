import { Place } from 'geotask/entities/models';
import { LatLng } from '../schemas/lat-lng.schema';

export interface PlaceMarker {
  position: LatLng;
  placeId: number;
}

export const createMarker = (place: Place): PlaceMarker | null => {
  if (place.latitude != null && place.longitude != null) {
    return {
      placeId: place.id,
      position: {
        lat: place.latitude,
        lng: place.longitude,
      },
    };
  }
  if (ngDevMode) {
    console.warn(`Empty coordinates in place with id: ${place.id}`);
  }
  return null;
};
