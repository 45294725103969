import type { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { FormTemplateEntity } from 'geotask/dictionaries/models';
import type {
  Attachment,
  Customer,
  Executor,
  Note,
  OrderEntity,
  Place,
  TaskEntity,
  TeamEntity,
  WorkDayEntity,
} from 'geotask/entities/models';

export const loadLatestTaskDataSuccess = createAction(
  '[Tasks/Tasks API] Load Task Data Success',
  props<{
    task: TaskEntity;
    order: OrderEntity;
    attachments: Attachment[];
    notes: Note[];
    formTemplate: FormTemplateEntity | undefined;
    place: Place | undefined;
    workDay: WorkDayEntity | undefined;
    executors: Executor[];
    team: TeamEntity | undefined;
    customer: Customer | null;
  }>()
);
export const loadLatestTaskDataFailure = createAction(
  '[Tasks/Tasks API] Load Task Data Failure',
  props<{ error: ApiError }>()
);

export const loadCopiedTaskSuccess = createAction(
  '[Tasks/Tasks API] Load Copied Task Success',
  props<{
    task: TaskEntity;
    order: OrderEntity;
    attachments: Attachment[];
    notes: Note[];
    formTemplate: FormTemplateEntity | null;
    place: Place | null;
    workDay: WorkDayEntity | null;
  }>()
);
export const loadCopiedTaskFailure = createAction(
  '[Tasks/Tasks API] Load Copied Task Failure',
  props<{ error: ApiError }>()
);

export const saveTaskSuccess = createAction(
  '[Tasks/Tasks API] Save Task Success',
  props<{ task: Update<TaskEntity> }>()
);
export const saveTaskFailure = createAction('[Tasks/Tasks API] Save Task Failure', props<{ error: ApiError }>());

export const saveNewTaskSuccess = createAction(
  '[Tasks/Tasks API] Save NewTask Success',
  props<{
    task: TaskEntity;
    order: OrderEntity;
    attachments: Attachment[];
    notes: Note[];
    formTemplate: FormTemplateEntity | null;
    place: Place | null;
    workDay: WorkDayEntity | null;
  }>()
);
export const saveNewTaskFailure = createAction('[Tasks/Tasks API] Save NewTask Failure', props<{ error: ApiError }>());
