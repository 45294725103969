import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { TeamEntity } from '../models';

export const loadTeamsInExecutorAreasSuccess = createAction(
  '[Team API] Load Teams In Executor Areas Success',
  props<{ teams: TeamEntity[] }>()
);

export const loadTeamsInExecutorAreasFailure = createAction(
  '[Team API] Load Teams In Executor Areas Failure',
  props<{ error: ApiError }>()
);

export const loadTeamAfterExecutorSaveSuccess = createAction(
  '[Team API] Load Team After Executor Save Success',
  props<{ team: TeamEntity }>()
);

export const loadTeamAfterExecutorSaveFailure = createAction(
  '[Team API] Load Team After Executor Save Failure',
  props<{ error: ApiError }>()
);
