import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import {
  AppInfoEffects,
  AuthEffects,
  HistoryEffects,
  LanguageChangedEffects,
  LanguageEffects,
  ModulesEffects,
  RouterNavigatedEffects,
  SwUpdatesEffects,
  TitleEffects,
} from './core/effects';
import { metaReducers, reducers } from './core/reducers';
import { MergedRouteSerializer } from './core/reducers/router';
import { storeExtModules } from './build-specifics';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionWithinNgZone: true,
        strictActionSerializability: true,
      },
    }),
    EffectsModule.forRoot([
      AppInfoEffects,
      AuthEffects,
      LanguageEffects,
      LanguageChangedEffects,
      ModulesEffects,
      RouterNavigatedEffects,
      TitleEffects,
      HistoryEffects,
      SwUpdatesEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: MergedRouteSerializer,
    }),
    storeExtModules,
  ],
})
export class AppStoreModule {}
