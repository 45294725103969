import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TaskServiceOrderStatusCode } from 'geotask/core/models';
import { createArraySelector } from 'geotask/core/selectors/selector-factories';
import { TaskServiceOrderStatus } from '../models';
import * as fromTaskOrderServiceStatus from '../reducers/task-service-status.reducer';

const selectTaskServiceStatusState = createFeatureSelector<fromTaskOrderServiceStatus.TaskServiceOrderStatusesState>(
  fromTaskOrderServiceStatus.taskServiceOrderStatusesFeatureKey
);

export const selectEntities = createSelector(selectTaskServiceStatusState, fromTaskOrderServiceStatus.selectEntities);
export const selectStatusIdByCodeDictionary = createSelector(
  selectTaskServiceStatusState,
  fromTaskOrderServiceStatus.selectByCode
);

export const selectStatusesByCodes = (codes: readonly TaskServiceOrderStatusCode[]) =>
  createArraySelector(selectStatusIdByCodeDictionary, selectEntities, (byCode, statusDictionary) =>
    codes.reduce((statuses: TaskServiceOrderStatus[], code) => {
      const statusId = byCode[code];
      if (!statusId) {
        return statuses;
      }
      const status = statusDictionary[statusId];
      if (status) {
        statuses.push(status);
      }
      return statuses;
    }, [])
  );

export const selectAll = createArraySelector(selectTaskServiceStatusState, fromTaskOrderServiceStatus.selectAll);
