import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { ModuleName, RoleCode, Routes } from 'geotask/core/models';
import { AppNameSelectors } from 'geotask/core/selectors';

const nameCodePrefix = 'core.sidenav-items.modules.';

@Component({
  selector: 'glb-nav-list',
  templateUrl: './nav-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListComponent {
  @Input()
  closeAll: Observable<boolean> = of(true);

  readonly Roles = RoleCode;
  readonly Modules = ModuleName;
  readonly appName$ = this.store$.select(AppNameSelectors.selectAppName);

  readonly names = {
    dashboard: nameCodePrefix + 'dashboard',
    orders: nameCodePrefix + 'orders',
    schedule: nameCodePrefix + 'schedule',
    notes: nameCodePrefix + 'notes',
    reports: nameCodePrefix + 'reports',
    formReport: nameCodePrefix + 'form-report',
    taskReport: nameCodePrefix + 'tasks-report',
    gpsReport: nameCodePrefix + 'gps-report',
    carTrackingReport: nameCodePrefix + 'car-tracking-report',
    productivityReport: nameCodePrefix + 'productivity-report',
    rodoReport: nameCodePrefix + 'rodo-report',
    dispatcherNotificationsReport: nameCodePrefix + 'dispatcher-notifications-report',
    optimizationsReport: nameCodePrefix + 'optimizations-report',
    settings: nameCodePrefix + 'settings',
    configuration: nameCodePrefix + 'configuration',
    executors: nameCodePrefix + 'executors',
    teams: nameCodePrefix + 'teams',
    areas: nameCodePrefix + 'areas',
    customers: nameCodePrefix + 'customers',
    taskKinds: nameCodePrefix + 'task-kinds',
    skills: nameCodePrefix + 'skills',
    companies: nameCodePrefix + 'companies',
    placeTypes: nameCodePrefix + 'place-types',
    systemDictionaries: nameCodePrefix + 'dictionaries',
    formTemplates: nameCodePrefix + 'form-templates',
    optimizationPlans: nameCodePrefix + 'optimization-plans',
    capacityConfiguration: nameCodePrefix + 'capacity-configuration',
    postalCodes: nameCodePrefix + 'postal-codes',
    postalCodesKitchen: nameCodePrefix + 'postal-codes-kitchen',
    postalCodesFurniture: nameCodePrefix + 'postal-codes-furniture',
    postalCodesAfterSales: nameCodePrefix + 'postal-codes-after-sales',
    appointmentsNotifications: nameCodePrefix + 'appointments-notifications',
    appointmentSlots: nameCodePrefix + 'appointment-slots',
    loader: nameCodePrefix + 'loader',
    teamsLoader: nameCodePrefix + 'teams-loader',
    tasksLoader: nameCodePrefix + 'tasks-loader',
    placesLoader: nameCodePrefix + 'places-loader',
    customersLoader: nameCodePrefix + 'customers-loader',
    executorsLoader: nameCodePrefix + 'executors-loader',
    postalCodesLoader: nameCodePrefix + 'postal-codes-loader',
    kitchenPostalCodesLoader: nameCodePrefix + 'kitchen-postal-codes-loader',
    assemblyPostalCodesLoader: nameCodePrefix + 'assembly-postal-codes-loader',
    afterSalesPostalCodesLoader: nameCodePrefix + 'after-sales-postal-codes-loader',
  } as const;

  readonly links = {
    dashboard: ['/', Routes.Dashboard],
    orders: ['/', Routes.Orders],
    schedule: ['/', Routes.Schedule],
    notes: ['/', Routes.Notes],
    reports: ['/', Routes.Reports],
    formReport: ['/', Routes.Reports, Routes.FormsReport],
    tasksReport: ['/', Routes.Reports, Routes.TasksReport],
    gpsReport: ['/', Routes.Reports, Routes.GpsReport],
    carTrackingReport: ['/', Routes.Reports, Routes.CarTrackingReport],
    productivityReport: ['/', Routes.Reports, Routes.ProductivityReport],
    rodoReport: ['/', Routes.Reports, Routes.RodoReport],
    optimizationsReport: ['/', Routes.Reports, Routes.OptimizationsReport],
    dispatcherNotificationsReport: ['/', Routes.Reports, Routes.DispatcherNotificationsReport],
    configuration: ['/', Routes.Settings],
    executors: ['/', Routes.Executors],
    teams: ['/', Routes.Teams],
    areas: ['/', Routes.Areas],
    customers: ['/', Routes.Customers],
    taskKinds: ['/', Routes.TaskKinds],
    capacityConfiguration: ['/', Routes.CapacityConfiguration],
    postalCodes: ['/', Routes.PostalCodes],
    postalCodesKitchen: ['/', Routes.PostalCodesKitchen],
    postalCodesFurniture: ['/', Routes.PostalCodesFurniture],
    postalCodesAfterSales: ['/', Routes.PostalCodesAfterSales],
    skills: ['/', Routes.Skills],
    companies: ['/', Routes.Companies],
    placeTypes: ['/', Routes.PlaceTypes],
    systemDictionaries: ['/', Routes.SystemDictionaries],
    formTemplates: ['/', Routes.FormTemplates],
    optimizationPlans: ['/', Routes.OptimizationPlans],
    appointmentsNotifications: ['/', Routes.AppointmentNotifications],
    appointmentSlots: ['/', Routes.AppointmentSlots],
    loader: ['/', Routes.Loader],
    teamsLoader: ['/', Routes.Loader, Routes.Teams],
    tasksLoader: ['/', Routes.Loader, Routes.Tasks],
    customersLoader: ['/', Routes.Loader, Routes.Customers],
    executorsLoader: ['/', Routes.Loader, Routes.Executors],
    postalCodesLoader: ['/', Routes.Loader],
    kitchenPostalCodesLoader: ['/', Routes.Loader, Routes.PostalCodes, Routes.KitchenPostalCodesLoader],
    assemblyPostalCodesLoader: ['/', Routes.Loader, Routes.PostalCodes, Routes.FurniturePostalCodesLoader],
    afterSalesPostalCodesLoader: ['/', Routes.Loader, Routes.PostalCodes, Routes.AfterSalesPostalCodesLoader],
  };

  readonly icons = {
    orders: 'bookmarks',
    schedule: 'calendar_today',
    notes: 'notes',
    reports: 'show_chart',
    settings: 'settings',
    loader: 'cloud_upload',
  } as const;

  constructor(private readonly store$: Store) {}
}
