import { NgZone } from '@angular/core';
import { Action } from '@ngrx/store';
import { filter, fromEvent, map, noop, Observable } from 'rxjs';

import { zonefull } from '@glb/util/rx-operators';

interface EventData<TState> {
  pathname: string;
  state: TState;
}

export abstract class StateSyncBaseEffects<TState> {
  protected constructor(
    protected readonly ngZone: NgZone,
    protected readonly location: Location,
    protected readonly broadcastChannel: BroadcastChannel | null
  ) {}

  protected getEffect() {
    const { broadcastChannel } = this;
    if (!broadcastChannel) {
      return new Observable<never>(noop);
    } else {
      return fromEvent<MessageEvent<EventData<TState>>>(broadcastChannel, 'message').pipe(
        map(({ data }) => data),
        filter(({ pathname }) => pathname === this.location.pathname),
        map(({ state }) => this.stateSyncAction(state)),
        zonefull(this.ngZone)
      );
    }
  }

  protected abstract stateSyncAction(state: TState): Action;
}
