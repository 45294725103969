import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { ModuleName, RoleCode, Routes } from 'geotask/core/models';
import { SidenavFacade } from '../../services/sidenav.facade';

@Component({
  selector: 'glb-support-action-list',
  templateUrl: './support-action-list.component.html',
  styles: ['a { text-decoration: none;}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportActionListComponent {
  readonly RoleCodes = RoleCode;
  readonly Modules = ModuleName;
  readonly Routes = Routes;

  constructor(readonly sidenavFacade: SidenavFacade, private readonly matSidenav: MatSidenav) {}

  downloadApk() {
    this.sidenavFacade.downloadApk();
    return this.closeSidenav();
  }

  closeSidenav() {
    return this.matSidenav.close();
  }
}
