import { QueryParams } from '../models';

export const coerceArrayParams = (
  arrays: Record<string, readonly (string | number | boolean)[] | undefined>
): QueryParams =>
  Object.entries(arrays).reduce((params: QueryParams, [paramName, array]) => {
    if (array) {
      return {
        ...params,
        [paramName]: array,
      };
    }
    return params;
  }, {});
