import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { MultidictionaryApiActions } from '../actions';
import { NoteType } from '../models';

export const noteTypeFeatureKey = 'dictionaries.noteTypes';

export type NoteTypeState = EntityState<NoteType>;

export const adapter: EntityAdapter<NoteType> = createEntityAdapter<NoteType>();

export const initialState: NoteTypeState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) => adapter.setAll(action.noteTypes, state))
);

export const { selectEntities, selectAll } = adapter.getSelectors();
