import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { TaskServiceOrderStatusCode } from 'geotask/core/models';
import { TaskServiceOrderStatusesApiActions } from '../actions';
import { TaskServiceOrderStatus } from '../models';

export const taskServiceOrderStatusesFeatureKey = 'dictionaries.taskServiceOrderStatuses';

export type TaskServiceOrderStatusIdByCode = { [statusCode in TaskServiceOrderStatusCode]?: number };

export interface TaskServiceOrderStatusesState extends EntityState<TaskServiceOrderStatus> {
  byCode: TaskServiceOrderStatusIdByCode;
}

export const adapter: EntityAdapter<TaskServiceOrderStatus> = createEntityAdapter<TaskServiceOrderStatus>();

export const initialState: TaskServiceOrderStatusesState = adapter.getInitialState({
  byCode: {},
});

export const reducer = createReducer(
  initialState,
  on(TaskServiceOrderStatusesApiActions.loadTaskServiceOrderStatusesSuccess, (state, action) =>
    adapter.setAll(action.taskServiceOrderStatus, {
      ...state,
      byCode: Object.fromEntries(action.taskServiceOrderStatus.map((status) => [status.code, status.id])),
    })
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
export const selectByCode = (state: TaskServiceOrderStatusesState) => state.byCode;
