import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { SettingItemWebObject } from 'geotask/backend/services/settings-api.service';
import type { DispatcherNotificationType } from '../models';

export const SettingApiActions = createActionGroup({
  source: 'Settings API',
  events: {
    'Load app settings success': props<{
      settings: {
        appSettings: SettingItemWebObject[];
        dispatcherNotifications: DispatcherNotificationType[];
      };
    }>(),
    'Load app settings failure': props<{ error: ApiError }>(),
  },
});
