import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DispatcherNotificationTypeCode, SettingName } from 'geotask/core/models';

export interface SettingItemWebObject {
  name: SettingName;
  value: string | null;
}

export interface NotificationType {
  id: number;
  code: DispatcherNotificationTypeCode;
  displayName: string;
  enabled: boolean;
}

export interface SettingsWebObject {
  appConfiguration: SettingItemWebObject[];
  dispatcherNotificationConfiguration: NotificationType[] | null;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  readonly allSettings$ = this.httpClient.post<SettingsWebObject>('@backend/settings/getAppConfiguration', null);
  readonly isDistanceTypeWithVehicle$ = this.httpClient.get<boolean>('@backend/settings/isDistanceTypeWithVehicle');
  readonly isDistanceTypeEqualsAirDistance$ = this.httpClient.get<boolean>(
    '@backend/settings/isDistanceTypeEqualsAirDistance'
  );

  constructor(private readonly httpClient: HttpClient) {}

  save(
    appConfiguration: SettingItemWebObject[],
    dispatcherNotificationConfiguration: { code: DispatcherNotificationTypeCode; enabled: boolean }[] = []
  ) {
    return this.httpClient.post<SettingsWebObject>('@backend/settings/saveAppConfiguration', {
      appConfiguration,
      dispatcherNotificationConfiguration,
    });
  }
}
