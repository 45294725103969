import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as SkillsApiActions from 'geotask/skills/actions/skills-api.actions';
import { comparing } from '@glb/util/functional';
import { MultidictionaryApiActions } from '../actions';
import { Skill } from '../models';

export const skillsFeatureKey = 'dictionaries.skills';

export type SkillsState = EntityState<Skill>;

export const adapter: EntityAdapter<Skill> = createEntityAdapter<Skill>({
  sortComparer: comparing('name'),
});

export const initialState: SkillsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    MultidictionaryApiActions.loadDictionariesSuccess,
    (state, action): SkillsState => adapter.setAll(action.skills, state)
  ),
  on(SkillsApiActions.saveSkillsDataSuccess, (state, action): SkillsState => adapter.upsertOne(action.skill, state))
);

export const { selectEntities, selectAll } = adapter.getSelectors();
