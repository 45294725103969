import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { FormTemplateEntity } from 'geotask/dictionaries/models';

export const loadFormTemplatesSuccess = createAction(
  '[TaskKinds/FormTemplates API] Load Form Templates Success',
  props<{ formTemplates: FormTemplateEntity[] }>()
);

export const loadFormTemplatesFailure = createAction(
  '[TaskKinds/FormTemplates API] Load Form Templates Failure',
  props<{ error: ApiError }>()
);
