import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { TaskAdditionalFieldWebObject } from 'geotask/backend/schemas/task-additional-field-web-object.schema';

export const TaskAdditionalFieldsApiActions = createActionGroup({
  source: 'Task Additional Fields API',
  events: {
    'Load Task Additional Fields Success': props<{ taskAdditionalFields: TaskAdditionalFieldWebObject[] }>(),
    'Load Task Additional Fields Failure': props<{ error: ApiError }>(),
  },
});
