import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Executor } from 'geotask/entities/models';
import type { ExecutorStatus } from '../models/executor-status.model';

export const loadExecutorsStatusSuccess = createAction(
  '[Executors API] Load Executors Status Success',
  props<{ executors: readonly (ExecutorStatus & Pick<Executor, 'login' | 'name' | 'active'>)[] }>()
);
export const loadExecutorsStatusFailure = createAction(
  '[Executors API] Load Executors Status Failure',
  props<{ error: ApiError }>()
);
