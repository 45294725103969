import type { ActionCreator, ActionType, MetaReducer } from '@ngrx/store';

import { isInValues, isString } from '@glb/util/type-guards';
import { IGNORED_ACTIONS_ON_BROADCAST } from 'geotask/build-specifics/ignored-actions-on-broadcast';

interface StateBroadcastMetaReducerParams<
  TState,
  TSyncAction extends SyncStateAction<TActionType, TState>,
  TActionType extends string
> {
  location: Location;
  window: Window;
  broadcastChannel: BroadcastChannel;
  featureKey: string;
  initialState: TState;
  syncAction: TSyncAction;
  stateEquals?: (oldState: TState, newState: TState) => boolean;
}

type SyncStateAction<TType extends string, TState> = ActionCreator<
  TType,
  (props: { newState: TState }) => { newState: TState }
>;

export const NGRX_INITIAL_STATE_KEY = '__ngrx/initial-state';

const defaultStateEquals = (oldState: unknown, newState: unknown) =>
  oldState === newState || JSON.stringify(oldState) === JSON.stringify(newState);

export function stateBroadcastMetaReducer<TState, TActionType extends string>({
  location,
  window,
  broadcastChannel,
  featureKey,
  initialState,
  syncAction,
  stateEquals = defaultStateEquals,
}: StateBroadcastMetaReducerParams<TState, SyncStateAction<TActionType, TState>, TActionType>): MetaReducer<TState> {
  const ignoredActions = IGNORED_ACTIONS_ON_BROADCAST.concat(syncAction.type);
  const isInIgnoredActions = isInValues(ignoredActions);
  const getInitialState = (): TState | undefined => {
    let initialStateFromOpener: TState | undefined;
    try {
      const stateJSON = window.opener?.[NGRX_INITIAL_STATE_KEY];
      if (isString(stateJSON)) {
        const state = JSON.parse(stateJSON);
        initialStateFromOpener = state[featureKey];
      }
    } catch (error: unknown) {
      console.error('Error during rehydrating state from opener', error);
    }
    return initialStateFromOpener;
  };

  return (reducer) =>
    (state = getInitialState(), action) => {
      let newState: TState;
      if (action.type === syncAction.type) {
        newState = (action as ActionType<SyncStateAction<TActionType, TState>>).newState;
      } else {
        newState = reducer(state, action);
      }
      if (
        state &&
        !stateEquals(state, newState) &&
        !stateEquals(initialState, state) &&
        !isInIgnoredActions(action.type)
      ) {
        broadcastChannel.postMessage({
          pathname: location.pathname,
          state: newState,
        });
      }
      return newState;
    };
}
