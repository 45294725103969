import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { ImportErrorItem } from 'geotask/backend/schemas/import-error-item.schema';
import type { LoaderDataType } from 'geotask/core/models/loader-data-type.enum';

interface UploadResultDetails {
  rowNumber: number;
  savedRows: number;
  skippedRows: number;
  loadingState: 'finished' | 'finishedWithError';
  errorMessage: string;
  errors: ImportErrorItem[];
}

export const LoaderApiActions = createActionGroup({
  source: 'Loader API',
  events: {
    'Upload File Success': props<{
      result: UploadResultDetails;
      dataType: LoaderDataType;
    }>(),
    'Upload File Failure': props<{
      error: ApiError;
      dataType: LoaderDataType;
    }>(),
  },
});
