import { Injectable } from '@angular/core';

import { TaskPriorityCode, TaskStatusCode } from 'geotask/core/models';
import { Facade } from 'geotask/core/models/facade';
import {
  AreaSelectors,
  IkeaServiceKindSelectors,
  PlaceTypeSelectors,
  SkillSelectors,
  TaskAdditionalFieldSelectors,
  TaskAdditionalFieldTypeSelectors,
  TaskKindSelectors,
  TaskPrioritySelectors,
  TaskStatusSelectors,
  TaskTypeSelectors,
  IkeaServiceKindSelector,
} from './selectors';
import { selectPrioritiesByCodes } from 'geotask/dictionaries/selectors/task-priority.selectors';

@Injectable()
export class DictionariesFacade extends Facade {
  readonly allAreasSelectOptions$ = this.select(AreaSelectors.selectAllAreasSelectOptions);
  readonly executorAreasSorted$ = this.select(AreaSelectors.selectExecutorAreasSorted);
  readonly executorAreaIds$ = this.select(AreaSelectors.selectExecutorAreaIds);
  readonly activePlaceTypes$ = this.select(PlaceTypeSelectors.selectActivePlaceTypes);
  readonly skills$ = this.select(SkillSelectors.selectAll);
  readonly taskTypes$ = this.select(TaskTypeSelectors.selectAll);
  readonly taskKinds$ = this.select(TaskKindSelectors.selectAll);
  readonly ikeaServiceKinds$ = this.select(IkeaServiceKindSelectors.selectAll);
  readonly ikeaServiceKindsAvailableForManualTask$ = this.select(
    IkeaServiceKindSelectors.selectServiceKindsAvailableForManualTask
  );
  readonly taskPriorities$ = this.select(TaskPrioritySelectors.selectAll);
  readonly taskStatuses$ = this.select(TaskStatusSelectors.selectAll);
  readonly newTaskStatus$ = this.store$.pipe(TaskStatusSelectors.selectTaskStatusNew);
  readonly normalTaskPriorityId$ = this.select(TaskPrioritySelectors.selectNormalTaskPriorityId);
  readonly taskAdditionalFields$ = this.select(TaskAdditionalFieldSelectors.selectAll);

  getIkeaServiceKindForManualTaskByKindId(kindId: number) {
    return this.select(IkeaServiceKindSelectors.selectServiceKindsAvailableForManualTaskByKindId(kindId));
  }

  getTaskStatusByCodes(codes: readonly TaskStatusCode[]) {
    return this.select(TaskStatusSelectors.selectStatusesByCodes(codes));
  }

  getTaskStatusIdsByCodes(codes: readonly TaskStatusCode[]) {
    return this.select(TaskStatusSelectors.selectStatusIdsByCodes(codes));
  }

  getAreaById(areaId: number) {
    return this.select(AreaSelectors.selectAreaById(areaId));
  }

  getKindById(kindId: number) {
    return this.select(TaskKindSelectors.selectTaskKindById(kindId));
  }

  getTaskTypeById(typeId: number) {
    return this.select(TaskTypeSelectors.selectTaskTypeById(typeId));
  }

  getTaskPriorityById(priorityId: number) {
    return this.select(TaskPrioritySelectors.selectTaskPriorityById(priorityId));
  }

  getTaskPriorityByCode(codes: readonly TaskPriorityCode[]) {
    return this.select(TaskPrioritySelectors.selectPrioritiesByCodes(codes));
  }

  getSkillsByIds(skillIds: readonly number[]) {
    return this.select(SkillSelectors.selectSkillsByIds(skillIds));
  }
}
