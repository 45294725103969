import type { NgZone } from '@angular/core';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';

export const zonefull =
  <T>(zone: NgZone): MonoTypeOperatorFunction<T> =>
  (source) =>
    new Observable((subscriber) =>
      source.subscribe({
        next: (value) => zone.run(() => subscriber.next(value)),
        error: (err: unknown) => zone.run(() => subscriber.error(err)),
        complete: () => zone.run(() => subscriber.complete()),
      })
    );

export const zonefree =
  <T>(zone: NgZone): MonoTypeOperatorFunction<T> =>
  (source) =>
    new Observable((subscriber) =>
      source.subscribe({
        next: (value) => zone.runOutsideAngular(() => subscriber.next(value)),
        error: (err: unknown) => zone.runOutsideAngular(() => subscriber.error(err)),
        complete: () => zone.runOutsideAngular(() => subscriber.complete()),
      })
    );
