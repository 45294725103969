import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';

export const loadExecutorAreasSuccess = createAction(
  '[Area API] Load Executor Areas Success',
  props<{ areaIds: readonly number[] }>()
);

export const loadExecutorAreasFailure = createAction(
  '[Area API] Load Executor Areas Failure',
  props<{ error: ApiError }>()
);
