import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUnavailabilityType from '../reducers/unavailability-type.reducer';

const selectUnavailabilityTypesState = createFeatureSelector<fromUnavailabilityType.UnavailabilityTypesState>(
  fromUnavailabilityType.unavailabilityTypesFeatureKey
);

export const selectEntities = createSelector(selectUnavailabilityTypesState, fromUnavailabilityType.selectEntities);
export const selectAll = createSelector(selectUnavailabilityTypesState, fromUnavailabilityType.selectAll);
