export enum Routes {
  Login = 'login',
  Dashboard = 'dashboard',
  ChangePassword = 'change-password',

  TerrainTemplate = 'terrain-template',
  Orders = 'orders',
  Schedule = 'schedule',
  Notes = 'notes',
  Reports = 'reports',
  Settings = 'settings',
  Executors = 'executors',
  Teams = 'teams',
  Areas = 'areas',
  Places = 'locations',
  Customers = 'customers',
  TaskKinds = 'task-kinds',
  CapacityConfiguration = 'capacity-configuration',
  PostalCodes = 'postal-codes',
  PostalCodesKitchen = 'kitchen-postal-codes',
  PostalCodesFurniture = 'furniture-postal-codes',
  PostalCodesAfterSales = 'after-sales-postal-codes',
  Skills = 'skills',
  Companies = 'companies',
  PlaceTypes = 'place-types',
  Tasks = 'tasks',
  SystemDictionaries = 'system-dictionaries',
  Loader = 'loader',

  KitchenPostalCodesLoader = 'kitchen',
  FurniturePostalCodesLoader = 'furniture',
  AfterSalesPostalCodesLoader = 'after-sales',

  AppointmentNotifications = 'appointment-notifications',
  FormTemplates = 'form-templates',
  OptimizationPlans = 'optimization-plans',
  AppointmentSlots = 'time-windows',

  GpsReport = 'gps',
  CarTrackingReport = 'car-tracking',
  FormsReport = 'forms',
  TasksReport = 'tasks',
  ProductivityReport = 'productivity',
  OptimizationsReport = 'optimizations',
  RodoReport = 'rodo',
  DispatcherNotificationsReport = 'dispatcher-notifications',

  Help = 'help',
  Admin = 'admin',
  MobileHelp = 'mobile-help',
}

export const UnauthorizedRedirectURL = `/${Routes.Login}` as const;
