import { ArrayElementTypeOf } from '@glb/util/types';

export interface ConstantPeriodicity {
  repeat: {
    value: number;
    unit: TaskKindDurationUnit;
  };
  repeatFromType: RepeatFromType;
}

export interface SelectedWeeksOfMonth {
  weeksOfMonth: readonly WeekOfMonth[];
}

export const ICONS = [
  'place',
  'account_box',
  'assignment',
  'assistant_photo',
  'build',
  'grade',
  'local_shipping',
  'new_releases',
  'report',
  'report_problem',
  'settings',
  'support',
  'watch_later',
  'work',
] as const;

export const DEFAULT_TASK_KIND_ICON = 'place';

export type TaskKindIcon = ArrayElementTypeOf<typeof ICONS>;

export interface TaskKind {
  id: number;
  name: string;
  description: string | null;
  typeId: number;
  priorityId: number;
  formTemplateId: number | null;
  durationInMinutes: number;
  icon: TaskKindIcon | null;

  periodicity: ConstantPeriodicity | SelectedWeeksOfMonth | null;

  requiredDateValue: number;
  requiredDateUnit: TaskKindDurationUnit;

  skillIds: readonly number[];
}

export const WEEKS_OF_MONTH = [1, 2, 3, 4] as const;

export type WeekOfMonth = typeof WEEKS_OF_MONTH[number];

export enum TaskKindDurationUnit {
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years',
}

export enum RepeatFromType {
  EndDate = 1,
  PlannedDate = 2,
  RequiredDate = 3,
}
