import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

export type ToastType = 'success' | 'error' | 'warning' | 'info';

export interface OpenToastrParams {
  type: ToastType;
  messageCode: string;
  titleCode?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  interpolationParams?: object;
  config?: Partial<IndividualConfig>;
}

const defaultConfigs: Partial<Record<ToastType, () => Partial<IndividualConfig>>> = {
  error: () => NotificationsService.notDisappearingToast(),
  warning: () => ({
    tapToDismiss: false,
  }),
};

@Injectable({ providedIn: 'any' })
export class NotificationsService {
  constructor(private readonly translate: TranslateService, private readonly toastr: ToastrService) {}

  static notDisappearingToast(): Partial<IndividualConfig> {
    return {
      closeButton: true,
      tapToDismiss: false,
      disableTimeOut: true,
    };
  }

  openToastr({
    type,
    messageCode,
    titleCode,
    interpolationParams,
    config = defaultConfigs[type]?.(),
  }: OpenToastrParams): Observable<ActiveToast<any>> {
    return this.translateCodes(messageCode, titleCode, interpolationParams).pipe(
      map(({ message, actionOrTitle: title }) => this.toastr.show(message, title, config, `toast-${type}`))
    );
  }

  openInstantToastr({ type, config, interpolationParams, titleCode, messageCode }: OpenToastrParams): ActiveToast<any> {
    const { message, title } = this.translateCodesInstantly(messageCode, titleCode, interpolationParams);
    return this.toastr.show(message, title, config, `toast-${type}`);
  }

  clear(toastId?: number) {
    this.toastr.clear(toastId);
  }

  private translateCodes(
    messageCode: string,
    titleOrActionCode?: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    interpolationParams?: object
  ): Observable<{ message: string; actionOrTitle: string | undefined }> {
    const translationCodes = [messageCode];
    if (titleOrActionCode) {
      translationCodes.push(titleOrActionCode);
    }
    return this.translate.stream(translationCodes, interpolationParams).pipe(
      filter(({ [messageCode]: message }) => message !== messageCode),
      map((translations) => {
        const actionOrTitle = titleOrActionCode ? translations[titleOrActionCode] : undefined;
        return { message: translations[messageCode], actionOrTitle };
      }),
      take(1)
    );
  }

  private translateCodesInstantly(
    messageCode: string,
    titleCode?: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    interpolationParams?: object
  ): {
    message: string;
    title: string | undefined;
  } {
    const translationCodes = [messageCode];
    if (titleCode) {
      translationCodes.push(titleCode);
    }
    const translations = this.translate.instant(translationCodes, interpolationParams);
    const title = titleCode ? translations[titleCode] : undefined;
    return { message: translations[messageCode], title };
  }
}
