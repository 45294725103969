import { InjectionToken, NgModule, Optional } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreConfig, StoreModule } from '@ngrx/store';

import { LOCATION, WINDOW } from '@glb/shared/web-api-browser';
import { provideIfBroadcastChannelSupported } from '@glb/util/angular';
import * as fromAttachment from './reducers/attachment.reducer';
import * as fromContactPerson from './reducers/contact-person.reducer';
import * as fromCustomer from './reducers/customer.reducer';
import * as fromExecutor from './reducers/executor.reducer';
import * as fromNotes from './reducers/note.reducer';
import * as fromNotificationConfiguration from './reducers/notification-configuration.reducer';
import * as fromTask from './reducers/task.reducer';
import * as fromTeam from './reducers/team.reducer';
import * as fromWorkDay from './reducers/work-day.reducer';
import * as fromUnavailabilities from './reducers/team-unavailabilities.reducer';
import * as fromPlace from './reducers/place.reducer';
import * as fromOrder from './reducers/order.reducer';
import * as fromExecutorUnavailability from './reducers/executor-unavailability.reducer';
import { TeamEffects } from './effects/team.effects';
import { PlacesEffects } from './effects/places.effects';
import { TasksStateSyncEffects } from './effects/tasks-state-sync.effects';
import { WorkDaysStateSyncEffects } from './effects/work-days-state-sync.effects';
import { TASKS_STATE_SYNC_CHANNEL, WORK_DAYS_STATE_SYNC_CHANNEL } from './sync-channel';
import * as fromOptimizationSession from './reducers/optimization-session.reducer';

export const TASKS_FEATURE_CONFIG = new InjectionToken<StoreConfig<fromTask.TasksState>>('Tasks store feature config');
export const WORK_DAYS_FEATURE_CONFIG = new InjectionToken<StoreConfig<fromWorkDay.WorkDaysState>>(
  'Work days store feature config'
);

@NgModule({
  imports: [
    StoreModule.forFeature(fromTask.tasksFeatureKey, fromTask.reducer, TASKS_FEATURE_CONFIG),
    StoreModule.forFeature(fromTeam.teamsFeatureKey, fromTeam.reducer),
    StoreModule.forFeature(fromWorkDay.workDaysFeatureKey, fromWorkDay.reducer, WORK_DAYS_FEATURE_CONFIG),
    StoreModule.forFeature(fromExecutor.executorsFeatureKey, fromExecutor.reducer),
    StoreModule.forFeature(fromUnavailabilities.teamUnavailabilitiesFeatureKey, fromUnavailabilities.reducer),
    StoreModule.forFeature(fromPlace.placesFeatureKey, fromPlace.reducer),
    StoreModule.forFeature(fromOrder.ordersFeatureKey, fromOrder.reducer),
    StoreModule.forFeature(fromOptimizationSession.optimizationSessionFeatureKey, fromOptimizationSession.reducer),
    StoreModule.forFeature(fromAttachment.attachmentsFeatureKey, fromAttachment.reducer),
    StoreModule.forFeature(
      fromNotificationConfiguration.notificationConfigurationFeatureKey,
      fromNotificationConfiguration.reducer
    ),
    StoreModule.forFeature(fromNotes.notesFeatureKey, fromNotes.reducer),
    StoreModule.forFeature(
      fromExecutorUnavailability.executorUnavailabilitiesFeatureKey,
      fromExecutorUnavailability.reducer
    ),
    StoreModule.forFeature(fromCustomer.customersFeatureKey, fromCustomer.reducer),
    StoreModule.forFeature(fromContactPerson.contactPersonFeatureKey, fromContactPerson.reducer),
    EffectsModule.forFeature([TeamEffects, TasksStateSyncEffects, WorkDaysStateSyncEffects, PlacesEffects]),
  ],
  providers: [
    provideIfBroadcastChannelSupported({
      provide: TASKS_STATE_SYNC_CHANNEL,
      useFactory: () => new BroadcastChannel('tasks.__state-sync'),
    }),
    provideIfBroadcastChannelSupported({
      provide: WORK_DAYS_STATE_SYNC_CHANNEL,
      useFactory: () => new BroadcastChannel('work-days.__state-sync'),
    }),
    {
      provide: TASKS_FEATURE_CONFIG,
      useFactory: fromTask.featureConfigFactory,
      deps: [[new Optional(), TASKS_STATE_SYNC_CHANNEL], LOCATION, WINDOW],
    },
    {
      provide: WORK_DAYS_FEATURE_CONFIG,
      useFactory: fromWorkDay.featureConfigFactory,
      deps: [[new Optional(), WORK_DAYS_STATE_SYNC_CHANNEL], LOCATION, WINDOW],
    },
  ],
})
export class EntitiesModule {}
