import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createEntityByIdSelector } from 'geotask/core/selectors/selector-factories';
import * as fromTaskKind from '../reducers/task-kind.reducer';

const selectTaskKindState = createFeatureSelector<fromTaskKind.TaskKindsState>(fromTaskKind.taskKindsFeatureKey);

export const selectDictionary = createSelector(selectTaskKindState, fromTaskKind.selectEntities);

export const selectEntities = createSelector(selectTaskKindState, fromTaskKind.selectEntities);

export const selectTaskKindById = createEntityByIdSelector(selectDictionary);

export const selectAll = createSelector(selectTaskKindState, fromTaskKind.selectAll);
export const selectTotal = createSelector(selectTaskKindState, fromTaskKind.selectTotal);
