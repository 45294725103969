import { DateTime } from 'luxon';

export function toLocaleTimeWindow(timeWindow: string) {
  const timeWindowElements = timeWindow.split('-');
  if (timeWindowElements.length !== 2) {
    return '';
  }
  const [start, end] = timeWindowElements;
  const startLocalTime = DateTime.fromISO(start).toLocaleString(DateTime.TIME_SIMPLE);
  const endLocalTime = DateTime.fromISO(end).toLocaleString(DateTime.TIME_SIMPLE);
  return `${startLocalTime}-${endLocalTime}`;
}
