import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { AreaWebObject } from 'geotask/backend/schemas/area-web-object.schema';
import type { Page } from 'geotask/backend/schemas/page.schema';
import type { Id } from 'geotask/core/schemas/id.schema';
import type { Executor } from 'geotask/entities/models';
import type { AreaEditorFormRawValue } from '../models';

export const AreasApiActions = createActionGroup({
  source: 'Areas API',
  events: {
    'Load areas page success': props<{ page: Page<AreaWebObject> }>(),
    'Load areas page failure': props<{ error: ApiError }>(),

    'Load area success': props<{ area: AreaWebObject; executors: Executor[] }>(),
    'Load area failure': props<{ error: ApiError }>(),

    'Create area success': props<{ createdArea: AreaWebObject }>(),
    'Create area failure': props<{ error: ApiError }>(),

    'Update area success': props<{ area: AreaEditorFormRawValue & { id: Id } }>(),
    'Update area failure': props<{ error: ApiError }>(),
  },
});
