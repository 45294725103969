import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { comparing } from '@glb/util/functional';
import { PlaceTypesApiActions } from 'geotask/place-types/actions/place-types-api.actions';
import { MultidictionaryApiActions } from '../actions';
import { PlaceType } from '../models';

export const placeTypesFeatureKey = 'dictionaries.placeTypes';

export type PlaceTypesState = EntityState<PlaceType>;

export const adapter: EntityAdapter<PlaceType> = createEntityAdapter<PlaceType>({
  sortComparer: comparing('name'),
});

export const initialState: PlaceTypesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    MultidictionaryApiActions.loadDictionariesSuccess,
    (state, action): PlaceTypesState => adapter.setAll(action.placeTypes, state)
  ),
  on(
    PlaceTypesApiActions.savePlaceTypeDataSuccess,
    (state, action): PlaceTypesState => adapter.upsertOne(action.placeType, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
