import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { FormTemplateEntity } from 'geotask/dictionaries/models';

export const loadFormTemplatesSuccess = createAction(
  '[FormTemplates/FormTemplates API] Load Form Templates Success',
  props<{ formTemplates: FormTemplateEntity[] }>()
);
export const loadFormTemplatesFailure = createAction(
  '[FormTemplates/FormTemplates API] Load Form Templates Failure',
  props<{ error: ApiError }>()
);

export const loadFullFormTemplateSuccess = createAction(
  '[FormTemplates/FormTemplates API] Load Full Form Template Success',
  props<{ formTemplate: FormTemplateEntity }>()
);
export const loadFullFormTemplateFailure = createAction(
  '[FormTemplates/FormTemplates API] Load Full Form Template Failure',
  props<{ error: ApiError }>()
);

export const saveFormTemplateSuccess = createAction(
  '[FormTemplates/FormTemplates API] Save Form Template Success',
  props<{ formTemplate: FormTemplateEntity }>()
);
export const saveFormTemplateFailure = createAction(
  '[FormTemplates/FormTemplates API] Save Form Template Failure',
  props<{ error: ApiError }>()
);
