import { HttpErrorResponse } from '@angular/common/http';
import { Action, ActionCreator } from '@ngrx/store';
import { catchError, filter, map, of, OperatorFunction, pipe } from 'rxjs';

import { ApiError, FullApiError, isFullApiError } from './models';

type ApiErrorActionCreator<
  T extends string = string,
  TProps extends { error: ApiError } = { error: ApiError }
> = ActionCreator<T, (props: TProps) => { type: T } & TProps>;

export function catchErrorToApiFailureAction<T, TActionType extends string>(
  actionCreator: ApiErrorActionCreator<TActionType>,
  msg?: string
): OperatorFunction<T, T | ReturnType<ApiErrorActionCreator<TActionType>>> {
  // noinspection JSDeprecatedSymbols
  return catchError((error: unknown) =>
    of(
      actionCreator({
        error: handleUnknownApiError(error, msg),
      })
    )
  );
}

/**
 * @deprecated Use `catchErrorToApiFailureAction` instead
 */
export function handleUnknownApiError(err: unknown, msg?: string): ApiError {
  if (err instanceof HttpErrorResponse) {
    return createApiError(err);
  }
  if (msg) {
    console.error(msg, err);
  } else {
    console.error(err);
  }
  return {};
}

function createApiError({ error, status, message }: HttpErrorResponse): FullApiError {
  return {
    status,
    message: error?.message || message,
  };
}

export function mapToFullApiError<T extends Action & { error: ApiError }>() {
  return pipe(
    map((action: T) => action.error),
    filter(isFullApiError)
  );
}
