import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { RoleCode, Routes } from '../models';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HomePageGuard implements CanActivate, CanLoad {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  canActivate(): Observable<true | UrlTree> {
    return this.authService.hasOnlyTerrainRoleOrTaskReadRoleObject().pipe(
      map(({ terrainOnly, taskReadOnly }) => {
        if (!terrainOnly && !taskReadOnly) {
          return true;
        }
        if (terrainOnly) {
          return this.router.createUrlTree(['/', Routes.TerrainTemplate]);
        }
        return this.router.createUrlTree(['/', Routes.Orders]);
      })
    );
  }

  canLoad() {
    return this.canActivate();
  }
}
