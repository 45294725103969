import { createAction, props } from '@ngrx/store';

import type { ExecutorBaseInfoWebObject } from 'geotask/backend/schemas/executor-base-info-web-object.schema';
import type { NormalizedWorkDay } from 'geotask/backend/schemas/normalized-work-day.schema';
import type { Id } from 'geotask/core/schemas/id.schema';

export const assignAppointmentForExistingTaskSuccess = createAction(
  '[Appointments API] Assign Appointment For Existing Task Success',
  props<{
    task: {
      id: Id;
      statusId: Id;
      appointmentStartDate: string;
      appointmentEndDate: string;
      plannedStartDate: string;
      plannedEndDate: string;
      teamName: string;
      executors: string;
      workDayId: Id | null;
      teamId: Id | null;
    };
    workDay: NormalizedWorkDay | null;
    executors: ExecutorBaseInfoWebObject[];
  }>()
);
