/* eslint-disable @typescript-eslint/ban-types */
import { JwtHelperService } from '@auth0/angular-jwt';
import { createFeatureSelector, createSelector, createSelectorFactory, resultMemoize } from '@ngrx/store';

import { equalsSets } from '@glb/util/functional';
import { DecodedToken, RoleCode } from '../models';
import * as fromAuth from '../reducers/auth.reducer';

const jwtHelper = new JwtHelperService();

export const selectAuth = createFeatureSelector<fromAuth.AuthState>(fromAuth.authFeatureKey);
export const selectToken = createSelector(selectAuth, (auth) => auth.token);
export const selectDecodedToken = createSelector(selectToken, (token) =>
  token != null ? jwtHelper.decodeToken<DecodedToken>(token) : null
);
const selectRoles = createSelectorFactory<object, Set<RoleCode>>((projectionFn) =>
  resultMemoize(projectionFn, equalsSets)
)(
  selectDecodedToken,
  (decodedToken: ReturnType<typeof selectDecodedToken>): Set<RoleCode> =>
    new Set(decodedToken?.roles.map((role) => role.authority))
);
export const selectHasValidRoleConfiguration = createSelector(selectRoles, (roles) => roles.size > 0);
export const selectTokenExpirationDate = createSelector(selectToken, (token) =>
  token != null ? jwtHelper.getTokenExpirationDate(token) : null
);

export const selectUserLogin = createSelector(selectDecodedToken, (decodedToken) => decodedToken?.sub || '');

export const selectIfUserHasRole = (role: RoleCode) => createSelector(selectRoles, (roles) => roles.has(role));
export const selectIfUserHasNotRole = (role: RoleCode) => createSelector(selectRoles, (roles) => !roles.has(role));
export const selectIfUserHasRoles = (roleCodes: RoleCode[]) =>
  createSelector(selectRoles, (roles) => {
    return roleCodes.some((role) => roles.has(role));
  });
export const selectIfUserHasNotRoles = (roleCodes: RoleCode[]) =>
  createSelector(selectRoles, (roles) => roleCodes.every((role) => !roles.has(role)));

export const selectIsOnlyDispatcher = createSelector(
  selectRoles,
  (roles) => roles.has(RoleCode.Dispatcher) && !roles.has(RoleCode.Admin) && !roles.has(RoleCode.LocalAdmin)
);

export const selectIsTerrainOnly = createSelector(selectRoles, (roles) => {
  return roles.has(RoleCode.Terrain) && roles.size === 1;
});

export const selectIsNotTerrainOnly = createSelector(selectIsTerrainOnly, (terrainOnly) => {
  return !terrainOnly;
});

export const selectIsNotTerrainOnlyAndIsLoggedIn = createSelector(
  selectRoles,
  selectIsNotTerrainOnly,
  (roles, isNotTerrainOnly) => isNotTerrainOnly && roles.size !== 0
);

export const selectIsAdmin = selectIfUserHasRole(RoleCode.Admin);
export const selectIsReadOnly = selectIfUserHasRole(RoleCode.ReadOnly);
export const selectIsTaskReadOnly = selectIfUserHasRole(RoleCode.TasksReadOnly);
export const selectIsCoordinator = selectIfUserHasRole(RoleCode.Coordinator);
export const selectIsNotCoordinator = selectIfUserHasNotRole(RoleCode.Coordinator);
export const selectIsNotReadOnly = selectIfUserHasNotRole(RoleCode.ReadOnly);
export const selectIsNotAdmin = selectIfUserHasNotRole(RoleCode.Admin);
export const selectIsNotLocalAdmin = selectIfUserHasNotRole(RoleCode.LocalAdmin);
export const selectIsNotDispatcher = selectIfUserHasNotRole(RoleCode.Dispatcher);

export const selectIsTerrainRoleOrTaskReadRoleObject = createSelector(
  selectIsTaskReadOnly,
  selectIsTerrainOnly,
  (taskReadOnly, terrainOnly) => ({ taskReadOnly, terrainOnly })
);
