import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';

export const logInSuccess = createAction(
  '[Auth API] Log In Success',
  props<{ token: string; daysUntilPasswordExpires: number | null }>()
);
export const logInFailure = createAction('[Auth API] Log In Failure', props<{ error: ApiError }>());

export const changePasswordSuccess = createAction('[Auth API] Change Password Success');
export const changePasswordFailure = createAction('[Auth API] Change Password  Failure', props<{ error: ApiError }>());

export const logoutSuccess = createAction('[Auth API] Logout Success');
export const logoutFailure = createAction('[Auth API] Logout Failure', props<{ error: ApiError }>());

export const refreshAuthTokenSuccess = createAction(
  '[Auth API] Refresh auth token success',
  props<{ token: string }>()
);
export const refreshAuthTokenFailure = createAction(
  '[Auth API] Refresh auth token failure',
  props<{ error: ApiError }>()
);
