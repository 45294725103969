import { createAction, props } from '@ngrx/store';

export const selectSingleNote = createAction('[NotesMap] Select Note', props<Readonly<{ noteId: number }>>());

export const addNotesToSelection = createAction(
  '[Notes/NotesMap] Add Notes To Selection',
  props<Readonly<{ noteIds: number[] }>>()
);
export const addNoteToSelection = createAction(
  '[Notes/NotesMap] Add Note To Selection',
  props<Readonly<{ noteId: number }>>()
);

export const deselectAllNotes = createAction('[NotesMap] Deselect All Notes');
