import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Executor, OptimizationSession } from 'geotask/entities/models';

export const loadOptimizationSessionsListSuccess = createAction(
  '[Optimization Export API] Load Optimization Sessions List Success',
  props<{
    sessions: OptimizationSession[];
    executors: Executor[];
  }>()
);
export const loadOptimizationSessionsListFailure = createAction(
  '[Optimization Export API] Load Optimization Sessions List Failure',
  props<{ error: ApiError }>()
);

export const exportSessionSuccess = createAction('[Optimization Export API] Export Session Success');
export const exportSessionFailure = createAction(
  '[Optimization Export API] Export Session Failure',
  props<{ error: ApiError }>()
);
