import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromOrderStatus from '../reducers/order-status.reducer';

export const selectOrderStatusesState = createFeatureSelector<fromOrderStatus.OrderStatusesState>(
  fromOrderStatus.orderStatusesFeatureKey
);
export const selectAll = createSelector(selectOrderStatusesState, fromOrderStatus.selectAll);
export const selectEntities = createSelector(selectOrderStatusesState, fromOrderStatus.selectEntities);
export const selectOrderStatusById = (statusId: number) =>
  createSelector(selectEntities, (orderStatuses) => orderStatuses[statusId]);
