import { CompareFn } from '@glb/util/types';

export const enum Order {
  Ascending = -1,
  Equal = 0,
  Descending = 1,
}

const defaultComparator = <T>(value: T, anotherValue: T) => {
  if (value < anotherValue || (!value && anotherValue)) {
    return Order.Ascending;
  } else if (value > anotherValue || (value && !anotherValue)) {
    return Order.Descending;
  } else {
    return Order.Equal;
  }
};

export const comparing = <TModel, TKey extends keyof TModel>(
  field: TKey,
  direction: 'asc' | 'desc' = 'asc',
  valueComparator: CompareFn<TModel[TKey]> = defaultComparator
): CompareFn<TModel> => {
  const directionMultiplier = direction === 'desc' ? -1 : 1;
  return (object, anotherObject) => {
    const fieldValue = object[field];
    const anotherFieldValue = anotherObject[field];
    return valueComparator(fieldValue, anotherFieldValue) * directionMultiplier;
  };
};

export const compareMapping =
  <T, V>(mapper: (value: T) => V): CompareFn<T> =>
  (value, anotherValue) =>
    defaultComparator(mapper(value), mapper(anotherValue));

export const stringLocaleCaseInsensitiveComparator: CompareFn<string> = (a, b) =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: 'accent' });
