<mat-list-item (click)="toggle()">
  <mat-icon matListIcon>{{ iconName }}</mat-icon>
  <span matLine>{{ linkName }}</span>
  <mat-icon class="menu-button" [class.rotated]="opened">expand_more</mat-icon>
</mat-list-item>

<div *ngIf="opened">
  <mat-divider></mat-divider>
  <ng-content></ng-content>
  <mat-divider></mat-divider>
</div>
