import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

export type LocationTokenType = Location;
export const LOCATION = new InjectionToken<LocationTokenType>('Location API', {
  providedIn: 'root',
  factory: () => inject(DOCUMENT).location,
});

export type WindowTokenType = Window & typeof globalThis;
export const WINDOW = new InjectionToken<WindowTokenType>('Global object/window token', {
  providedIn: 'root',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  factory: () => inject(DOCUMENT).defaultView!,
});

export type ConsoleTokenType = Console;
export const CONSOLE = new InjectionToken<ConsoleTokenType>('Console token', {
  providedIn: 'root',
  factory: () => inject(WINDOW).console,
});

export type NavigatorTokenType = Navigator;
export const NAVIGATOR = new InjectionToken<NavigatorTokenType>('Navigator token', {
  providedIn: 'root',
  factory: () => inject(WINDOW).navigator,
});

export type ServiceWorkerContainerTokenType = ServiceWorkerContainer | null;
export const SERVICE_WORKER_CONTAINER = new InjectionToken<ServiceWorkerContainerTokenType>(
  'Service worker container token',
  {
    providedIn: 'root',
    factory: () => inject(NAVIGATOR).serviceWorker ?? null,
  }
);

export type CustomElementRegistryTokenType = CustomElementRegistry;
export const CUSTOM_ELEMENTS = new InjectionToken<CustomElementRegistryTokenType>('Custom element registry token', {
  providedIn: 'root',
  factory: () => inject(WINDOW).customElements,
});
