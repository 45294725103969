import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as ExecutorsApiActions from 'geotask/executor-unavailabilities/actions/executors-api.actions';
import * as DashboardSchedulerActions from 'geotask/dashboard-scheduler/actions/scheduler-data.actions';
import { ExecutorUnavailability } from '../models';

export const executorUnavailabilitiesFeatureKey = 'entities.executorUnavailabilities';
export type ExecutorUnavailabilityState = EntityState<ExecutorUnavailability>;
const adapter: EntityAdapter<ExecutorUnavailability> = createEntityAdapter<ExecutorUnavailability>();
export const initialState: ExecutorUnavailabilityState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    ExecutorsApiActions.loadExecutorUnavailabilitiesSuccess,
    (state, action): ExecutorUnavailabilityState => adapter.upsertMany(action.executorUnavailabilities, state)
  ),
  on(
    DashboardSchedulerActions.loadSchedulerDataSuccess,
    DashboardSchedulerActions.refreshSchedulerDataSuccess,
    (state, { data: { executorUnavailabilities } }): ExecutorUnavailabilityState =>
      adapter.upsertMany(executorUnavailabilities, state)
  ),

  on(
    ExecutorsApiActions.addUnavailabilitySuccess,
    (state, action): ExecutorUnavailabilityState => adapter.upsertMany(action.executorUnavailabilities, state)
  ),
  on(
    ExecutorsApiActions.deleteUnavailabilitiesSuccess,
    (state, { unavailabilityIds }): ExecutorUnavailabilityState =>
      adapter.removeMany(Array.from(unavailabilityIds), state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
