import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { parseWith } from '@glb/util/rx-operators';
import { TaskServiceOrderStatusCode, TaskServiceOrderTypeEnum } from 'geotask/core/models';
import { TaskServiceOrderStatusSchema } from '../schemas/task-service-order-status.schema';
import { ReasonCodeSchema } from '../schemas/reason-code.schema';

export type StateMachineByStatus = {
  [code in TaskServiceOrderStatusCode]: TaskServiceOrderStatusCode[];
};

export type StateMachineByServiceType = {
  [serviceType in TaskServiceOrderTypeEnum]: StateMachineByStatus;
};

@Injectable({
  providedIn: 'root',
})
export class TasksServiceOrderApiService {
  readonly stateMachine$ = this.httpClient.get<StateMachineByServiceType>('@backend/ikea/orderService/getStateMachine');
  readonly statusOrderDict$ = this.httpClient
    .get('@backend/ikea/orderService/getOrderServiceStatuses')
    .pipe(parseWith(TaskServiceOrderStatusSchema.array()));

  readonly reasonCodesDict$ = this.httpClient
    .get('@backend/ikea/reasonCode/getReasonCodes')
    .pipe(parseWith(ReasonCodeSchema.array()));

  constructor(private readonly httpClient: HttpClient) {}
}
