import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { NotePageWebObject } from 'geotask/backend/schemas/note-page-web-object.schema';
import type { NoteForEditorWebObject } from 'geotask/backend/services/notes-api.service';

export const NotesApiActions = createActionGroup({
  source: 'Notes API',
  events: {
    'Load Notes Page Success': props<{ page: NotePageWebObject }>(),
    'Load Notes Page Failure': props<{ error: ApiError }>(),

    'Load Note Success': props<{ noteForEditor: NoteForEditorWebObject }>(),
    'Load Note Failure': props<{ error: ApiError }>(),

    'Save Note Success': props<{ navigateBack: boolean }>(),
    'Save Note Failure': props<{ error: ApiError }>(),
  },
});
