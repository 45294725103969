import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { TaskAdditionalFieldApiService } from 'geotask/backend/services/task-additional-field-api.service';
import { AuthService } from 'geotask/core/services/auth.service';
import { TaskAdditionalFieldsApiActions } from '../actions';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class TaskAdditionalFieldsEffects {
  loadTaskAdditionalFields$ = createEffect(() => {
    return this.authService.canAccessFullApplication$.pipe(
      switchMap(() => {
        return this.taskAdditionalFieldApiService.fields$.pipe(
          map((fields) =>
            TaskAdditionalFieldsApiActions.loadTaskAdditionalFieldsSuccess({
              taskAdditionalFields: fields,
            })
          ),
          catchErrorToApiFailureAction(TaskAdditionalFieldsApiActions.loadTaskAdditionalFieldsFailure)
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly taskAdditionalFieldApiService: TaskAdditionalFieldApiService,
    private readonly authService: AuthService
  ) {}
}
