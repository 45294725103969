import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { MultidictionaryApiService } from 'geotask/backend/services/multidictionary-api.service';
import { BrowserActions } from 'geotask/core/actions';
import { AuthService } from 'geotask/core/services/auth.service';
import { LoaderApiActions } from 'geotask/loader/actions';
import { MultidictionaryActions, MultidictionaryApiActions } from '../actions';
import { Company } from 'geotask/dictionaries/models';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class DictionariesEffects {
  loadDictionaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MultidictionaryActions.loadDictionaries,
        LoaderApiActions.uploadFileSuccess,
        BrowserActions.languageChanged
      ),
      switchMap(() =>
        this.multidictionaryService.dictionaries$.pipe(
          map((dictionaries) =>
            MultidictionaryApiActions.loadDictionariesSuccess({
              taskStatuses: dictionaries.taskStatuses,
              taskPriorities: dictionaries.taskPriorities,
              orderStatuses: dictionaries.orderStatuses,
              taskTypes: dictionaries.taskTypes,
              unavailabilityTypes: dictionaries.unavailabilityTypes,
              placeTypes: dictionaries.placeTypes,
              taskKinds: dictionaries.taskKinds,
              ikeaServicesKind: dictionaries.ikeaServices,
              noteTypes: dictionaries.noteTypes ?? [],
              skills: dictionaries.skills ?? [],
              areas: dictionaries.areas ?? [],
              roles: dictionaries.roles,
              formTemplateFieldTypes: dictionaries.formTemplateFields ?? [],
              formTemplates: dictionaries.formTemplates,
              formTemplateDictionaries: dictionaries.formTemplateDictionaries,
              dispatcherNotificationStatuses: dictionaries.dispatcherNotificationStatuses ?? [],
              additionalFieldTypes: dictionaries.additionalFieldTypes ?? [],
              companies: parseCompanyObject(dictionaries.companies) ?? [],
            })
          ),
          catchErrorToApiFailureAction(MultidictionaryApiActions.loadDictionariesFailure)
        )
      )
    );
  });

  loadDictionariesOnLoggedIn$ = createEffect(() => {
    return this.authService.canAccessFullApplication$.pipe(map(() => MultidictionaryActions.loadDictionaries()));
  });

  constructor(
    private readonly authService: AuthService,
    private readonly multidictionaryService: MultidictionaryApiService,
    private readonly actions$: Actions
  ) {}
}

function parseCompanyObject(companies: { id: string; name: string }[]) {
  return companies.map(
    (company): Company => ({
      id: +company.id,
      name: company.name,
    })
  );
}
