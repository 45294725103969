import { createAction, props } from '@ngrx/store';

import type { Executor, Note, TaskEntity } from 'geotask/entities/models';
import type { ApiError } from 'geotask/backend/models';
import type { NotificationEntity } from '../models/notification.model';

export const loadNotificationsReportSuccess = createAction(
  '[Notifications Report API] Load Task Report Success',
  props<{
    page: {
      pageNumber: number;
      pageSize: number;
      totalElements: number;
      notifications: NotificationEntity[];
    };
    executors: Executor[];
    notes: Note[];
    tasks: TaskEntity[];
  }>()
);
export const loadNotificationsReportFailure = createAction(
  '[Notifications Report API] Load Task Report Failure',
  props<{ error: ApiError }>()
);

export const exportToCsvFileSuccess = createAction('[Notifications Report API] Export To Csv File Success');
export const exportToCsvFileFailure = createAction(
  '[Notifications Report API] Export To Csv File Failure',
  props<{ error: ApiError }>()
);
