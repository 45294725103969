import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as FormTemplatesApiActions from 'geotask/form-templates/actions/form-templates-api.actions';
import * as FormsReportApiActions from 'geotask/forms-report/actions/forms-report-api.actions';
import * as TaskEditorTasksApiActions from 'geotask/tasks/actions/tasks-api.actions';
import * as FormTemplateApiActions from 'geotask/task-kinds/actions/form-templates-api.actions';
import { FormTemplateActions, MultidictionaryApiActions } from '../actions';
import { FormTemplateEntity } from '../models';

export const formTemplatesFeatureKey = 'dictionaries.formTemplates';

export type FormTemplatesState = EntityState<FormTemplateEntity>;

export const adapter: EntityAdapter<FormTemplateEntity> = createEntityAdapter<FormTemplateEntity>();

export const initialState: FormTemplatesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    MultidictionaryApiActions.loadDictionariesSuccess,
    FormTemplateApiActions.loadFormTemplatesSuccess,
    FormTemplatesApiActions.loadFormTemplatesSuccess,
    (state, action) => adapter.upsertMany(action.formTemplates, state)
  ),
  on(
    FormTemplateActions.upsertFormTemplate,
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    TaskEditorTasksApiActions.loadCopiedTaskSuccess,
    TaskEditorTasksApiActions.saveNewTaskSuccess,
    FormTemplatesApiActions.loadFullFormTemplateSuccess,
    FormTemplatesApiActions.saveFormTemplateSuccess,
    (state, action) => (action.formTemplate ? adapter.upsertOne(action.formTemplate, state) : state)
  ),
  on(FormsReportApiActions.loadFormReportPageSuccess, (state, action) =>
    adapter.upsertOne(action.page.formTemplate, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
