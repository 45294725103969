import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AreasApiActions } from 'geotask/areas/actions';
import * as CarTrackingReportApiActions from 'geotask/car-tracking-report/actions/car-tracking-report-api.actions';
import * as DashboardMapExecutorsApiActions from 'geotask/dashboard-map/actions/executors-api.actions';
import * as DashboardSchedulerActions from 'geotask/dashboard-scheduler/actions/scheduler-data.actions';
import * as DispatcherNotificationsReportApiActions from 'geotask/dispatcher-notifications-report/actions/notifications-report-api.actions';
import * as ExecutorsApiActions from 'geotask/executors/actions/executors-api.actions';
import * as FormsReportApiActions from 'geotask/forms-report/actions/forms-report-api.actions';
import { NotesApiActions, NotesListActions } from 'geotask/notes/actions';
import * as OrderEditorOrdersApiActions from 'geotask/orders/actions/orders-api.actions';
import * as DeleteDataApiActions from 'geotask/root-delete-data/actions/delete-data-api.actions';
import * as OptimizationSessionsApiActions from 'geotask/root-optimization-sessions/actions/optimization-sessions-api.actions';
import * as ScheduleApiActions from 'geotask/schedule/actions/schedule-api.actions';
import * as TaskEditorTasksApiActions from 'geotask/tasks/actions/tasks-api.actions';
import * as TaskEditorExecutorsApiActions from 'geotask/tasks/actions/executors-api.actions';
import * as TasksReportApiActions from 'geotask/tasks-report/actions/tasks-report-api.actions';
import * as AppointmentsApiActions from 'geotask/tasks/actions/appointments-api.actions';
import { ExecutorActions } from '../actions';
import { Executor } from '../models';

export const executorsFeatureKey = 'entities.executors';

export type ExecutorsState = EntityState<Executor>;

export const adapter: EntityAdapter<Executor> = createEntityAdapter<Executor>();

export const initialState: ExecutorsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    CarTrackingReportApiActions.loadCarTrackingReportSuccess,
    (state, { report: { routes } }): ExecutorsState =>
      adapter.upsertMany(
        routes.map((route) => route.executor),
        state
      )
  ),
  on(
    ExecutorActions.upsertExecutors,
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    TaskEditorExecutorsApiActions.loadExecutorsSuccess,
    ScheduleApiActions.loadScheduleSuccess,
    OrderEditorOrdersApiActions.loadOrderSuccess,
    OptimizationSessionsApiActions.loadOptimizationSessionsListSuccess,
    CarTrackingReportApiActions.loadTeamsForCarTrackingReportSuccess,
    DispatcherNotificationsReportApiActions.loadNotificationsReportSuccess,
    NotesListActions.setInitExecutorsSelection,
    AreasApiActions.loadAreaSuccess,
    (state, { executors }): ExecutorsState => adapter.upsertMany(executors, state)
  ),
  on(
    DashboardSchedulerActions.loadSchedulerDataSuccess,
    DashboardSchedulerActions.refreshSchedulerDataSuccess,
    (state, { data: { executors } }): ExecutorsState => adapter.upsertMany(executors, state)
  ),
  on(
    TasksReportApiActions.loadTasksReportSuccess,
    FormsReportApiActions.loadFormReportPageSuccess,
    ExecutorsApiActions.loadExecutorsSuccess,
    (state, action): ExecutorsState => adapter.upsertMany(action.page.executors, state)
  ),
  on(
    ExecutorsApiActions.loadExecutorDataSuccess,
    (state, action): ExecutorsState => adapter.upsertOne(action.executor, state)
  ),
  on(
    NotesApiActions.loadNotesPageSuccess,
    (state, { page: { executors } }): ExecutorsState => adapter.upsertMany(executors, state)
  ),
  on(
    DashboardMapExecutorsApiActions.loadExecutorsStatusSuccess,
    AppointmentsApiActions.assignAppointmentForExistingTaskSuccess,
    (state, action): ExecutorsState =>
      adapter.upsertMany(
        action.executors.map((executor) => ({
          id: executor.id,
          name: executor.name,
          login: executor.login,
          active: executor.active,
        })),
        state
      )
  ),
  on(DeleteDataApiActions.deleteAllResourcesSuccess, (state): ExecutorsState => adapter.removeAll(state)),
  on(
    DeleteDataApiActions.deleteSelectedResourcesSuccess,
    (state, action): ExecutorsState => adapter.removeMany(action.executorIds, state)
  ),
  on(
    NotesApiActions.loadNoteSuccess,
    (state, { noteForEditor: { executor } }): ExecutorsState =>
      adapter.upsertOne(
        {
          id: executor.id,
          name: executor.name,
          login: executor.login,
          active: executor.active,
        },
        state
      )
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
