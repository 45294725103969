import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { NotificationConfigurationWebObject } from 'geotask/backend/schemas/notification-configuration-web-object.schema';

export const loadListSuccess = createAction(
  '[Notification Configuration API] Load Notification Configuration List Success',
  props<{ notificationConfigurations: NotificationConfigurationWebObject[] }>()
);
export const loadListFailure = createAction(
  '[Notification Configuration API] Load Notification Configuration List Failure',
  props<{ error: ApiError }>()
);

export const loadConfigurationsInitDataSuccess = createAction(
  '[Notification Configuration API] Load Notification Configuration Init Data Success',
  props<{ notificationConfigurations: NotificationConfigurationWebObject[]; currentSmsCount: number }>()
);

export const loadConfigurationsInitDataFailure = createAction(
  '[Notification Configuration API] Load Notification Configuration Init Data Failure',
  props<{ error: ApiError }>()
);

export const loadReportSuccess = createAction(
  '[Notification Configuration API] Load Report Success',
  props<{
    report: {
      month: number;
      smsCount: number;
    }[];
    year: number;
  }>()
);

export const loadReportFailure = createAction(
  '[Notification Configuration API] Load Report Failure',
  props<{ error: ApiError }>()
);

export const loadConfigurationSuccess = createAction(
  '[Notification Configuration API] Load Notification Configuration Success',
  props<{ notificationConfiguration: NotificationConfigurationWebObject }>()
);
export const loadConfigurationFailure = createAction(
  '[Notification Configuration API] Load Notification Configuration Failure',
  props<{ error: ApiError }>()
);

export const removeConfigurationSuccess = createAction(
  '[Notification Configuration API] Remove Notification Configuration Success'
);
export const removeConfigurationFailure = createAction(
  '[Notification Configuration API] Remove Notification Configuration Failure',
  props<{ error: ApiError }>()
);

export const saveConfigurationSuccess = createAction(
  '[Notification Configuration API] Save Notification Configuration Success',
  props<Readonly<{ id: number }>>()
);
export const saveConfigurationFailure = createAction(
  '[Notification Configuration API] Save Notification Configuration Failure',
  props<{ error: ApiError }>()
);
