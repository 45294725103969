import { inject } from '@angular/core';

import { ModuleName } from '../models';
import { ModulesService } from '../services/modules.service';

export function moduleEnabled(moduleName: ModuleName) {
  return () => {
    const modulesService = inject(ModulesService);
    return modulesService.isModuleEnabled(moduleName);
  };
}
