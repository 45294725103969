import { Injectable } from '@angular/core';
import { Action, Selector, Store } from '@ngrx/store';

@Injectable()
export abstract class Facade {
  constructor(protected readonly store$: Store) {}

  dispatch(action: Action) {
    this.store$.dispatch(action);
  }

  protected select<T>(selector: Selector<object, T>) {
    return this.store$.select(selector);
  }
}
