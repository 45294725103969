<mat-action-list>
  <h1 matSubheader>{{ 'ui-components.support-action-list.header' | translate }}</h1>

  <a target="_blank" *ngIf="sidenavFacade.vm$ | async as vm" [href]="vm.guidePath" mat-list-item>
    <mat-icon matListIcon>info</mat-icon>
    <span matLine>
      {{ 'ui-components.support-action-list.guide' | translate: { appName: vm.appName } }}
    </span>
  </a>

  <a mat-list-item [routerLink]="Routes.Help" target="_blank" (click)="closeSidenav()">
    <mat-icon matListIcon>help</mat-icon>
    <span matLine>{{ 'ui-components.support-action-list.help' | translate }}</span>
  </a>

  <mat-divider></mat-divider>

  <button
    *glbIfHasAnyOfRoles="[RoleCodes.Admin, RoleCodes.Dispatcher, RoleCodes.Terrain]"
    mat-list-item
    (click)="downloadApk()"
  >
    <mat-icon matListIcon>file_download</mat-icon>
    <span matLine>
      {{ 'ui-components.support-action-list.download-mobile' | translate }}
    </span>
  </button>

  <a mat-list-item [routerLink]="Routes.MobileHelp" target="_blank" (click)="closeSidenav()">
    <mat-icon matListIcon>smartphone</mat-icon>
    <span matLine>{{ 'ui-components.support-action-list.mobile-help' | translate }}</span>
  </a>
</mat-action-list>
