import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, of, take } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { handleUnknownApiError } from 'geotask/backend/models';
import { ExecutorsApiService } from 'geotask/backend/services/executors-api.service';
import { AuthApiActions } from 'geotask/core/actions';
import { AuthService } from 'geotask/core/services/auth.service';
import { AreaActions, AreaApiActions } from '../actions';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AreaEffects {
  loadAvailableAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AreaActions.loadExecutorAreas),
      switchMap(() =>
        this.executorsApiService.areaIdsOfLoggedExecutor$.pipe(
          map((areaIds) => AreaApiActions.loadExecutorAreasSuccess({ areaIds })),
          catchError((error: unknown) =>
            of(
              AreaApiActions.loadExecutorAreasFailure({
                error: handleUnknownApiError(error, 'Error during fetching current executor areas'),
              })
            )
          )
        )
      )
    );
  });

  authenticated$ = createEffect(() => {
    const loadExecutorAreas$ = this.authService.canAccessFullApplication$.pipe(
      take(1),
      map(() => AreaActions.loadExecutorAreas())
    );
    return concat(
      loadExecutorAreas$,
      this.actions$.pipe(
        ofType(AuthApiActions.logInSuccess),
        switchMap(() => loadExecutorAreas$)
      )
    );
  });

  constructor(
    private readonly authService: AuthService,
    private readonly actions$: Actions,
    private readonly executorsApiService: ExecutorsApiService
  ) {}
}
