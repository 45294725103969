import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { TaskStatusCode } from 'geotask/core/models';

export const taskStatusChangeSuccess = createAction(
  '[Tasks API] Task Status Change Success',
  props<{ taskId: number; statusCode: TaskStatusCode }>()
);
export const taskStatusChangeFailure = createAction(
  '[Tasks API] Task Status Change Failure',
  props<{ taskId: number; statusCode: TaskStatusCode; error: ApiError }>()
);

export const tasksLockSuccess = createAction('[Tasks API] Tasks Lock Success', props<{ taskIds: readonly number[] }>());
export const tasksLockFailure = createAction('[Tasks API] Tasks Lock Failure', props<{ error: ApiError }>());

export const tasksUnlockSuccess = createAction(
  '[Tasks API] Tasks Unlock Success',
  props<{ taskIds: readonly number[] }>()
);
export const tasksUnlockFailure = createAction('[Tasks API] Tasks Unlock Failure', props<{ error: ApiError }>());

export const updateTaskSuccess = createAction('[Tasks API] Update Task Success');
export const updateTaskFailure = createAction('[Tasks API] Update Task Failure', props<{ error: ApiError }>());
