import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Company } from 'geotask/dictionaries/models';

export const saveCompaniesDataSuccess = createAction(
  '[Companies API] Save Company Data Success',
  props<{ company: Company }>()
);
export const saveCompaniesDataFailure = createAction(
  '[Companies API] Save Company Data Failure',
  props<{ error: ApiError }>()
);
