export enum OptimizationType {
  Minimize = 1,
  LoadBalancing = 2,
}

export enum StartRouteType {
  StartLocation = 0,
  LastTaskLocation = 3,
}

export enum EndRouteType {
  StartLocation = 0,
  EndLocation = 1,
  NoRouteBack = 2,
}

export enum Vehicle {
  Car = 'car',
  NoTollMotorwayCar = 'no-toll-motorway-car',
  NoMotorwayCar = 'no-motorway-car',
  Truck = 'truck',
}
