import { createAction, props } from '@ngrx/store';

import type { WorkDayEntity } from '../models/work-day.model';
import type { WorkDaysState } from '../reducers/work-day.reducer';

export const syncWorkDayEntitiesState = createAction(
  '[WorkDays] Sync Work Day Entities State',
  props<{ newState: WorkDaysState }>()
);

export const upsertWorkDay = createAction('[WorkDay/API] Upsert WorkDay', props<{ workDay: WorkDayEntity }>());
export const upsertWorkDays = createAction('[WorkDay/API] Upsert WorkDays', props<{ workDays: WorkDayEntity[] }>());
