import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { TaskAdditionalFieldWebObject } from 'geotask/backend/schemas/task-additional-field-web-object.schema';
import type { SettingItemWebObject } from 'geotask/backend/services/settings-api.service';
import type { DispatcherNotificationType } from 'geotask/dictionaries/models';

export const SettingsApiActions = createActionGroup({
  source: 'Settings API',
  events: {
    'Save Settings Success': props<{
      settings: {
        appSettings: SettingItemWebObject[];
        dispatcherNotifications: DispatcherNotificationType[];
        taskAdditionalFields: TaskAdditionalFieldWebObject[];
      };
    }>(),
    'Save Settings Failure': props<{ error: ApiError }>(),
  },
});
