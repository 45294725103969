import { JwtConfig } from '@auth0/angular-jwt';

import firebaseConfig from './firebase-config';
import { Environment } from './types';

const jwtConfig: JwtConfig = {
  allowedDomains: [/^[\w-.]+.globema.pl(:\d{1,4})?$/i],
  disallowedRoutes: [/auth\/token$/i, /attachment\/getAttachment/i],
};

export const environment: Environment = {
  production: true,
  serviceWorker: true,
  swUpdateCheckInterval: {
    hours: 1,
  },
  jwt: jwtConfig,
  backend: {
    urlPrefix: 'rest/web',
  },
  firebaseConfig,
};
