import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { LOGOUT_ACTIONS } from 'geotask/core/actions';
import { AreasApiActions } from 'geotask/areas/actions';
import { comparing, stringLocaleCaseInsensitiveComparator } from '@glb/util/functional';
import { AreaActions, AreaApiActions, MultidictionaryApiActions } from '../actions';
import { Area, createArea } from '../models';

export const areasFeatureKey = 'dictionaries.areas';

export interface AreasState extends EntityState<Area> {
  assignedToExecutor: readonly number[];
  currentExecutorAreasState: 'NOT_LOADED' | 'LOADING' | 'LOAD_FAILURE' | 'LOAD_SUCCESS';
}

export const adapter: EntityAdapter<Area> = createEntityAdapter<Area>({
  sortComparer: comparing('name', 'asc', stringLocaleCaseInsensitiveComparator),
});

export const initialState: AreasState = adapter.getInitialState({
  assignedToExecutor: [],
  currentExecutorAreasState: 'NOT_LOADED',
});

export const reducer = createReducer<AreasState>(
  initialState,
  on(
    AreasApiActions.updateAreaSuccess,
    AreasApiActions.loadAreaSuccess,
    (state, { area }): AreasState => adapter.upsertOne(createArea(area), state)
  ),
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, { areas }): AreasState => {
    const areaEntities = areas.map((area) =>
      createArea({
        ...area,
        coordinates: [],
      })
    );
    return adapter.upsertMany(areaEntities, state);
  }),
  on(AreasApiActions.loadAreasPageSuccess, (state, { page: { content: areas } }): AreasState => {
    const areaEntities = areas.map((area) => createArea(area));
    return adapter.upsertMany(areaEntities, state);
  }),
  on(AreaActions.loadExecutorAreas, (state): AreasState => ({ ...state, currentExecutorAreasState: 'LOADING' })),
  on(
    AreaApiActions.loadExecutorAreasSuccess,
    (state, action): AreasState => ({
      ...state,
      assignedToExecutor: [...action.areaIds],
      currentExecutorAreasState: 'LOAD_SUCCESS',
    })
  ),
  on(
    AreaApiActions.loadExecutorAreasFailure,
    (state): AreasState => ({ ...state, currentExecutorAreasState: 'LOAD_FAILURE' })
  ),
  on(
    ...LOGOUT_ACTIONS,
    (state): AreasState => ({
      ...state,
      assignedToExecutor: [],
      currentExecutorAreasState: 'NOT_LOADED',
    })
  ),
  on(
    AreasApiActions.createAreaSuccess,
    (state, { createdArea }): AreasState => adapter.addOne(createArea(createdArea), state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
