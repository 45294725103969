import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as CompaniesApiActions from 'geotask/companies/actions/companies-api.actions';
import { MultidictionaryApiActions } from '../actions';
import { Company } from '../models';

export const companiesFeatureKey = 'dictionaries.companies';

export type CompaniesState = EntityState<Company>;

export const adapter: EntityAdapter<Company> = createEntityAdapter<Company>();

export const initialState: CompaniesState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    MultidictionaryApiActions.loadDictionariesSuccess,
    (state, action): CompaniesState => adapter.setAll(action.companies, state)
  ),
  on(
    CompaniesApiActions.saveCompaniesDataSuccess,
    (state, action): CompaniesState => adapter.upsertOne(action.company, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
