import { createFeatureSelector, createSelector } from '@ngrx/store';

import { comparing, stringLocaleCaseInsensitiveComparator } from '@glb/util/functional';
import {
  createArraySelector,
  createEntitiesArraySelector,
  createEntitiesByIdsSelector,
  createEntityByIdSelector,
} from 'geotask/core/selectors/selector-factories';
import { AuthSelectors } from 'geotask/core/selectors';
import { EMPTY_ELEMENT_ID } from 'geotask/core/models';
import * as fromAreas from '../reducers/area.reducer';
import { Area } from 'geotask/dictionaries/models';

const selectAreasState = createFeatureSelector<fromAreas.AreasState>(fromAreas.areasFeatureKey);

export const selectEntities = createSelector(selectAreasState, fromAreas.selectEntities);
export const selectAreaById = createEntityByIdSelector(selectEntities);
export const selectAreasByIds = createEntitiesByIdsSelector(selectEntities);

export const selectExecutorAreaIds = createArraySelector(
  selectAreasState,
  AuthSelectors.selectIsNotLocalAdmin,
  (state, isNotLocalAdmin) =>
    isNotLocalAdmin ? [EMPTY_ELEMENT_ID, ...state.assignedToExecutor] : state.assignedToExecutor
);
export const selectCurrentExecutorAreasState = createSelector(
  selectAreasState,
  (state) => state.currentExecutorAreasState
);
const selectExecutorAreas = createEntitiesArraySelector(selectExecutorAreaIds, selectEntities);
export const selectExecutorAreasSorted = createArraySelector(selectExecutorAreas, (areas) =>
  Array.from(areas).sort(comparing('name', 'asc', stringLocaleCaseInsensitiveComparator))
);

export const selectAllSortedByName = createArraySelector(selectAreasState, fromAreas.selectAll);
export const selectAllCostAreasSelectOptions = createArraySelector(
  selectExecutorAreaIds,
  selectAllSortedByName,
  (executorAreaIds, allAreas) => {
    const allowedAreas = new Set(executorAreaIds);
    return allAreas
      .filter((area) => area.isCostArea === true)
      .map((area) => ({ ...area, name: area.code, disabled: !allowedAreas.has(area.id) }));
  }
);

export const selectAllAreasSelectOptions = createArraySelector(
  selectExecutorAreaIds,
  selectAllSortedByName,
  (executorAreaIds, allAreas) => {
    const allowedAreas = new Set(executorAreaIds);
    return allAreas.map((area) => ({ ...area, disabled: !allowedAreas.has(area.id) }));
  }
);

export const selectLoggedAndEditedExecutorAreas = (areaIds: number[]) =>
  createArraySelector(selectExecutorAreas, selectAreasByIds(areaIds), (loggedExecutorAreas, editedExecutorAreas) => {
    const uniqueAreas: Area[] = [];
    for (const area of loggedExecutorAreas.concat(editedExecutorAreas)) {
      if (!uniqueAreas.includes(area)) {
        uniqueAreas.push(area);
      }
    }
    return uniqueAreas;
  });
