import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Customer, OrderEntity, Place, TaskEntity, WorkDayEntity } from 'geotask/entities/models';

export const loadTrackSummarySuccess = createAction(
  '[TrackSummary API] Load Track Summary Success',
  props<{
    readonly tasks: TaskEntity[];
    readonly places: Place[];
    readonly customers: Customer[];
    readonly orders: OrderEntity[];
    readonly workDays: WorkDayEntity[];
  }>()
);

export const loadTrackSummaryFailure = createAction(
  '[TrackSummary API] Load Track Summary Failure',
  props<{ error: ApiError }>()
);

export const exportTrackSummarySuccess = createAction('[TrackSummary API] Export Track Summary Success');

export const exportTrackSummaryFailure = createAction(
  '[TrackSummary API] Export Track Summary Failure',
  props<{ error: ApiError }>()
);
