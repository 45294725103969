import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TaskPriorityCode } from 'geotask/core/models';
import { createArraySelector, createEntityByIdSelector } from 'geotask/core/selectors/selector-factories';
import { TaskPriority } from '../models';
import * as fromTaskPriority from '../reducers/task-priority.reducer';

export const selectTaskPrioritiesState = createFeatureSelector<fromTaskPriority.TaskPrioritiesState>(
  fromTaskPriority.taskPrioritiesFeatureKey
);
export const selectEntities = createSelector(selectTaskPrioritiesState, fromTaskPriority.selectEntities);
export const selectTaskPriorityById = createEntityByIdSelector(selectEntities);
export const selectAll = createArraySelector(selectTaskPrioritiesState, fromTaskPriority.selectAll);
const selectEntitiesByCodes = createSelector(selectAll, (priorities) =>
  priorities.reduce(
    (groupedPriorities: Partial<Record<TaskPriorityCode, TaskPriority>>, priority) => ({
      ...groupedPriorities,
      [priority.code]: priority,
    }),
    {}
  )
);
export const selectPriorityByCode = (code: TaskPriorityCode) =>
  createSelector(selectEntitiesByCodes, (priorities) => priorities[code]);

export const selectPriorityByCodeFunction = createSelector(
  selectEntitiesByCodes,
  (priorities) => (code: TaskPriorityCode) => priorities[code]
);

export const selectPrioritiesByCodes = (codes: readonly TaskPriorityCode[]) =>
  createSelector(selectPriorityByCodeFunction, (priorities) =>
    codes.reduce<TaskPriority[]>((filteredPriorities, code) => {
      const priority = priorities(code);
      if (priority) {
        filteredPriorities.push(priority);
      }
      return filteredPriorities;
    }, [])
  );

export const selectNormalTaskPriority = selectPriorityByCode(TaskPriorityCode.Normal);
export const selectNormalTaskPriorityId = createSelector(selectNormalTaskPriority, (priority) => priority?.id);
