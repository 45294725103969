import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MultidictionaryApiActions } from '../actions';
import { Role } from '../models';

export const rolesFeatureKey = 'dictionaries.roles';

export type RolesState = EntityState<Role>;

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>();

export const initialState: RolesState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) => adapter.setAll(action.roles, state))
);

export const { selectEntities, selectAll } = adapter.getSelectors();
