<mat-toolbar color="primary" *ngIf="vm$ | async as vm" [class.cdk-visually-hidden]="vm.externalMapTab">
  <div class="progress-bar-wrapper" [style.display]="vm.progressBarDisplay">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <button (click)="sidenav.toggle()" mat-icon-button type="button">
    <mat-icon>{{ sidenav.opened ? 'menu_open' : 'menu' }}</mat-icon>
  </button>
  <a routerLink="/" mat-icon-button *ngIf="vm.homeButtonVisible && !sidenav.opened" title="Home">
    <mat-icon>home</mat-icon>
  </a>
  <div class="portal-outlet-wrapper" [style.visibility]="sidenav.opened ? 'hidden' : 'visible'">
    <ng-container *cdkPortalOutlet="toolbarContent$ | async"></ng-container>
  </div>
  <ng-container #notificationsToggleAnchor></ng-container>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="over"
    [opened]="terrainOnly$ | async"
    (openedChange)="sidenavMenuCloseAllSubject.next(true)"
    (closedStart)="sidenavMenuCloseAllSubject.next(false)"
    (openedStart)="sidenavMenuCloseAllSubject.next(true)"
  >
    <glb-sidenav [closeAll]="sidenavMenuCloseAllSubject"></glb-sidenav>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <mat-sidenav #notificationsSidenav class="notifications-sidenav" position="end" mode="side">
    <ng-container #notificationsAnchor></ng-container>
  </mat-sidenav>
</mat-sidenav-container>
