import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { PlaceType } from 'geotask/dictionaries/models';

export const PlaceTypesApiActions = createActionGroup({
  source: 'Place Type API',
  events: {
    'Save Place Type Data Success': props<{ placeType: PlaceType }>(),
    'Save Place Type Data Failure': props<{ error: ApiError }>(),
  },
});
