import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Customer, Note, OrderEntity } from 'geotask/entities/models';

export const loadCopiedNoteSuccess = createAction(
  '[Notes API] Load Copied Note Success',
  props<{ note: Note; order: OrderEntity | null; customer: Customer | null }>()
);
export const loadCopiedNoteFailure = createAction('[Tasks API] Load Copied Note Failure', props<{ error: ApiError }>());
