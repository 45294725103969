import { ArrayElementTypeOf } from '@glb/util/types';
import { isInValues } from '@glb/util/type-guards';

export enum TaskServiceOrderStatusCode {
  SentToServiceProvider = 'SentToServiceProvider',
  Planned = 'Planned',
  MissingInformation = 'MissingInformation',
  LockedForChange = 'LockedForChange',
  Started = 'Started',
  Failed = 'Failed',
  IncompleteService = 'IncompleteService',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  OneNineColonTwo = '19:2',
  OneNine = '19',
  NineZero = '90',
  NineZeroColonTwo = '90:2',
  NineTwo = '92',
  NineThree = '93',
  NineThreeColonTwo = '93:2',
  NineEightColonTwo = '98:2',
  NineNine = '99',
  NineNineColonTwo = '99:2',
}

export const REQUIRED_REASON_CODE_CODES = [
  TaskServiceOrderStatusCode.Failed,
  TaskServiceOrderStatusCode.MissingInformation,
  TaskServiceOrderStatusCode.IncompleteService,
  TaskServiceOrderStatusCode.NineThree,
  TaskServiceOrderStatusCode.NineThreeColonTwo,
] as const;
export const isRequiredReasonCodeCodes = isInValues(REQUIRED_REASON_CODE_CODES);
