import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';

import { GoogleMapsLoaderService } from './google-maps-loader.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private mapsLoaderService: GoogleMapsLoaderService
  ) {}

  /**
   * Main function to put application initialization logic
   */
  initApp() {
    this.addAdditionalSvgIcons();

    /**
     * Array of `ObservableInput` to put initialization logic within Observables
     */
    const initializationObservables = [this.mapsLoaderService.load()];
    return forkJoin(initializationObservables).pipe(ignoreElements());
  }

  private addAdditionalSvgIcons() {
    this.matIconRegistry.addSvgIcon(
      'schedule',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/schedule.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'globema',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logos/Globema_logo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'schedule_exclamation',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/schedule_exclamation.svg')
    );
  }
}
