import { Injectable } from '@angular/core';
import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@Injectable({
  providedIn: 'root',
})
export class MsalConfigService {
  private readonly _msalConfig!: Configuration;
  private _isConfigLoaded = false;

  constructor() {
    const data = (window as any).msalConfig;
    if (data) this._isConfigLoaded = true;

    this._msalConfig = {
      auth: {
        clientId: data?.clientId ?? '',
        authority: data?.authority ?? '',
        knownAuthorities: data?.knownAuthorities ?? [],
        redirectUri: data?.redirectUri ?? '',

        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback: (_logLevel: LogLevel, message: string) => console.log(message),
          logLevel: LogLevel.Verbose,
          piiLoggingEnabled: false,
        },
      },
    };
  }

  get isConfigLoaded() {
    return this._isConfigLoaded;
  }

  get msalConfig(): Configuration {
    return this._msalConfig;
  }
}
