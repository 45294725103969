import { ClassProvider, Injectable } from '@angular/core';
import { TimepickerIntlService } from '@glb/shared/picker/time';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GeoTaskTimepickerIntlService extends TimepickerIntlService {
  readonly pickerHeader$ = this.translate.stream('core.timepicker.header');
  readonly hourLabel$ = this.translate.stream('core.timepicker.hour');
  readonly minuteLabel$ = this.translate.stream('core.timepicker.minute');

  constructor(private readonly translate: TranslateService) {
    super();
  }
}

export const TIMEPICKER_INTL_CLASS_PROVIDER: ClassProvider = {
  provide: TimepickerIntlService,
  useClass: GeoTaskTimepickerIntlService,
};
