import type { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Note, TaskEntity } from 'geotask/entities/models';
import type { DispatcherNotification } from '../models/dispatcher-notification.model';

export const loadNotificationsGroupSuccess = createAction(
  '[DispatcherNotifications API] Load Notifications Group Success',
  props<{
    tasks: TaskEntity[];
    notes: Note[];
    notifications: DispatcherNotification[];
  }>()
);

export const assignDispatcherNotificationSuccess = createAction(
  '[DispatcherNotifications API] Assign Dispatcher Notification Success',
  props<{ dispatcherNotification: Update<DispatcherNotification> }>()
);
export const assignDispatcherNotificationFailure = createAction(
  '[DispatcherNotifications API] Assign Dispatcher Notification Failure',
  props<{ error: ApiError }>()
);

export const closeDispatcherNotificationSuccess = createAction(
  '[DispatcherNotifications API] Close Dispatcher Notification Success',
  props<{ dispatcherNotificationId: number }>()
);
export const closeDispatcherNotificationFailure = createAction(
  '[DispatcherNotifications API] Close Dispatcher Notification Failure',
  props<{ error: ApiError }>()
);

export const rejectDispatcherNotificationSuccess = createAction(
  '[DispatcherNotifications API] Reject Dispatcher Notification Success',
  props<{ dispatcherNotification: Update<DispatcherNotification> }>()
);
export const rejectDispatcherNotificationFailure = createAction(
  '[DispatcherNotifications API] Reject Dispatcher Notification Failure',
  props<{ error: ApiError }>()
);
