import { z } from 'zod';

import { DictionaryEntrySchema } from 'geotask/core/schemas/dictionary-entry.schema';
import { ICONS, RepeatFromType, TaskKindDurationUnit } from 'geotask/dictionaries/models';
import { FormTemplateSimpleWebObjectSchema } from './form-template-simple-web-object.schema';
import { TaskTypeWebObjectSchema } from './task-type-web-object.schema';
import { TaskPriorityWebObjectSchema } from './task-priority-web-object.schema';
import { SkillWebObjectSchema } from './skill-web-object.schema';

const WeeksOfMonthSchema = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4)]);
export const TaskKindWebObjectSchema = DictionaryEntrySchema.extend({
  code: z.number(),
  description: z.string().nullable(),
  repeatValue: z.number().nullable(),
  repeatUnit: z.nativeEnum(TaskKindDurationUnit).nullable(),
  type: TaskTypeWebObjectSchema,
  priority: TaskPriorityWebObjectSchema,
  requiredDateValue: z.number(),
  requiredDateUnit: z.nativeEnum(TaskKindDurationUnit),
  duration: z.number(),
  formTemplate: FormTemplateSimpleWebObjectSchema.nullable(),
  skills: SkillWebObjectSchema.array(),
  repeatFromType: z.nativeEnum(RepeatFromType).nullable(),
  weeksOfMonth: z.array(WeeksOfMonthSchema).nullable(),
  icon: z.enum(ICONS).nullable(),
});
export type TaskKindWebObject = z.infer<typeof TaskKindWebObjectSchema>;
