import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ContactPersonWebObject } from 'geotask/backend/schemas/contact-person-web-object.schema';
import {
  CustomersApiActions,
  PlacesApiActions as CustomersPlacesApiActions,
} from 'geotask/customers/actions/customers-api.actions';
import { PlacesApiActions } from 'geotask/places/actions';
import * as AutocompleteContactPersonActions from 'geotask/shared/autocomplete-contact-person/autocomplete-contact-person.actions';
import * as AutocompleteCustomerActions from 'geotask/shared/autocomplete-customer/autocomplete-customer.actions';
import * as AutocompletePlacesActions from 'geotask/shared/autocomplete-place/autocomplete-place.actions';
import { PlacesApiActions as PlacesEditorPlacesApiActions } from 'geotask/places/actions/places-api.actions';
import { ContactPerson } from '../models';

export const contactPersonFeatureKey = 'entities.contactPersons';

export type ContactPersonsState = EntityState<ContactPerson>;

export const adapter = createEntityAdapter<ContactPerson>();

export const initialState: ContactPersonsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    CustomersApiActions.loadCustomersPageSuccess,
    (state, { page: { contactPersons } }): ContactPersonsState => adapter.upsertMany(contactPersons, state)
  ),
  on(
    AutocompleteCustomerActions.loadCustomerAutocompletePromptsSuccess,
    AutocompleteContactPersonActions.loadContactPersonAutocompletePromptsSuccess,
    AutocompletePlacesActions.loadPlaceAutocompletePromptsSuccess,
    (state, { contactPersons }): ContactPersonsState => adapter.upsertMany(contactPersons, state)
  ),
  on(
    PlacesEditorPlacesApiActions.savePlaceSuccess,
    (state, { contactPersonsToUpdate, contactPersonIdsToRemove }): ContactPersonsState => {
      state = adapter.upsertMany(contactPersonsToUpdate, state);
      state = adapter.removeMany(contactPersonIdsToRemove, state);
      return state;
    }
  ),
  on(
    CustomersApiActions.loadCustomerSummarySuccess,
    CustomersApiActions.saveCustomerSuccess,
    (
      state,
      {
        customerSummary: {
          mainPlace: { contactPersons },
        },
      }
    ): ContactPersonsState => adapter.upsertMany(contactPersons.map(createContactPerson), state)
  ),
  on(CustomersPlacesApiActions.loadCustomerPlacesPageSuccess, (state, { page }): ContactPersonsState => {
    const contactPersons = page.content.flatMap((place) => place.contactPersons);
    return adapter.upsertMany(contactPersons.map(createContactPerson), state);
  }),
  on(
    PlacesApiActions.loadPlaceSuccess,
    (state, { contactPersons }): ContactPersonsState =>
      adapter.upsertMany(
        contactPersons.map((contactPerson) => ({
          id: contactPerson.id,
          name: contactPerson.name,
          email: contactPerson.email,
          phone: contactPerson.phone,
          main: contactPerson.main,
          placeId: contactPerson.placeId,
        })),
        state
      )
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();

function createContactPerson(contactPerson: ContactPersonWebObject): ContactPerson {
  return {
    id: contactPerson.id,
    name: contactPerson.name,
    email: contactPerson.email,
    phone: contactPerson.phone,
    main: contactPerson.main,
    placeId: contactPerson.placeId,
  };
}
