import { z } from 'zod';
import { DictionaryEntrySchema } from 'geotask/core/schemas/dictionary-entry.schema';
import { ReasonCode } from 'geotask/core/models';

export const ReasonCodeSchema = DictionaryEntrySchema.extend({
  code: z.nativeEnum(ReasonCode),
  isMissingInformation: z.boolean(),
  isFailed: z.boolean(),
  isIncompleteService: z.boolean(),
});
export type ReasonCodeSchemaWebObject = z.infer<typeof ReasonCodeSchema>;
