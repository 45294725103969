import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as ikeaServiceKind from '../reducers/ikea-service-kind.reducer';
import { createEntitiesByIdsSelector, createEntityByIdSelector } from 'geotask/core/selectors/selector-factories';
import { toEntities } from '@glb/util/functional';

const selectIkeaServiceKindState = createFeatureSelector<ikeaServiceKind.IkeaServiceKindsState>(
  ikeaServiceKind.ikeaServiceKindsFeatureKey
);

export const selectDictionary = createSelector(selectIkeaServiceKindState, ikeaServiceKind.selectEntities);

export const selectEntities = createSelector(selectIkeaServiceKindState, ikeaServiceKind.selectEntities);
export const selectServicesByIds = createEntitiesByIdsSelector(selectEntities);

export const selectServiceKindsByIdsSelector = createSelector(
  selectEntities,
  (entities) => (serviceTypeIds: readonly number[]) => serviceTypeIds.reduce(toEntities(entities), [])
);

export const selectServiceKindById = createEntityByIdSelector(selectDictionary);

export const selectAll = createSelector(selectIkeaServiceKindState, ikeaServiceKind.selectAll);
export const selectTotal = createSelector(selectIkeaServiceKindState, ikeaServiceKind.selectTotal);

export const selectServiceKindsAvailableForManualTask = createSelector(selectAll, (serviceKind) =>
  serviceKind.filter((kind) => kind.availableForManualTask)
);

export const selectServiceKindsAvailableForManualTaskByKindId = (kindId: number) =>
  createSelector(selectServiceKindsAvailableForManualTask, (serviceKind) =>
    serviceKind.filter((service) => service.kindId === kindId)
  );
