import { Injectable } from '@angular/core';

import { version } from 'environments/version';

@Injectable({
  providedIn: 'root',
})
export class Version {
  readonly versionString: string = version;
}
