import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { Attachment } from 'geotask/entities/models';

export const deleteTaskAttachmentSuccess = createAction(
  '[Attachments API] Delete Task Attachment Success',
  props<{ taskId: number; attachmentId: number }>()
);
export const deleteTaskAttachmentFailure = createAction(
  '[Attachments API] Delete Task Attachment Failure',
  props<{ error: ApiError }>()
);

export const uploadAttachmentsSuccess = createAction(
  '[Attachments API] Upload Attachments Success',
  props<{ taskId: number; attachments: Attachment[] }>()
);
export const uploadAttachmentsFailure = createAction(
  '[Attachments API] Upload Attachments Failure',
  props<{ error: ApiError }>()
);

export const downloadAllSuccess = createAction('[Attachments API] Download All Attachments Success');
export const downloadAllFailure = createAction(
  '[Attachments API] Download All Attachments Failure',
  props<{ error: ApiError }>()
);
