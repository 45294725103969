import { Injectable } from '@angular/core';
import { DisplayFormat, PickerHourFormat, PickerMinuteFormat, TimeModelAdapter } from '@glb/shared/picker/time/model';

export type Time = readonly [hour: number, minute: number];

@Injectable()
export class DefaultTimeModelAdapter extends TimeModelAdapter<Time> {
  parse(value: string): Time | null {
    if (!value) {
      return null;
    }
    const date = new Date(Date.parse(`1/1/1970, ${value} GMT`));
    return [date.getUTCHours(), date.getUTCMinutes()];
  }

  format(time: Time | null, displayFormat: DisplayFormat) {
    if (!time || !this.isValid(time)) {
      return '';
    }
    const [hour, minute] = time;
    const date = new Date();
    date.setUTCHours(hour, minute);
    const dtf = new Intl.DateTimeFormat(this.locale, {
      ...displayFormat,
      timeZone: 'utc',
    });
    return dtf.format(date);
  }

  isValid(value: Time | null): value is Time {
    const [hour, minute] = value ?? [];
    return hour != null && minute != null && hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59;
  }

  isSame(time: Time | null, anotherTime: Time | null): boolean {
    return (
      time === anotherTime ||
      (time != null && anotherTime != null && time.every((part, index) => part === anotherTime[index]))
    );
  }

  createTime(hour: number, minute: number): Time {
    return [hour, minute];
  }

  getHour(time: Time | null): number | null {
    return time != null ? time[0] : null;
  }

  getMinute(time: Time | null): number | null {
    return time != null ? time[1] : null;
  }

  default(): Time {
    return [0, 0];
  }

  formatHour(hourValue: number, format: PickerHourFormat) {
    const date = new Date();
    date.setUTCHours(hourValue);
    const dtf = new Intl.DateTimeFormat(this.locale, {
      ...format,
      timeZone: 'utc',
    });
    return dtf.format(date);
  }

  formatMinute(minute: number, format: PickerMinuteFormat) {
    const date = new Date();
    date.setUTCMinutes(minute);
    const dtf = new Intl.DateTimeFormat(this.locale, {
      ...format,
      hour: format.minute,
      timeZone: 'utc',
    });
    return dtf.formatToParts(date).find((part) => part.type === 'minute')?.value ?? '';
  }
}
