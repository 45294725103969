import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { NoteAttachmentsApiActions, NotesApiActions } from 'geotask/notes/actions';
import {
  AttachmentsApiActions as TaskEditorAttachmentsApiActions,
  TasksApiActions as TaskEditorTasksApiActions,
} from 'geotask/tasks/actions';
import * as TasksReportApiActions from 'geotask/tasks-report/actions/tasks-report-api.actions';
import { AttachmentActions } from '../actions';
import { Attachment } from '../models';

export const attachmentsFeatureKey = 'entities.attachments';

export type AttachmentState = EntityState<Attachment>;

export const adapter: EntityAdapter<Attachment> = createEntityAdapter<Attachment>();

export const initialState: AttachmentState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    AttachmentActions.upsertAttachments,
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    TaskEditorTasksApiActions.loadCopiedTaskSuccess,
    TaskEditorTasksApiActions.saveNewTaskSuccess,
    TaskEditorAttachmentsApiActions.uploadAttachmentsSuccess,
    (state, action): AttachmentState => adapter.upsertMany(action.attachments, state)
  ),
  on(
    NoteAttachmentsApiActions.deleteNoteAttachmentSuccess,
    TaskEditorAttachmentsApiActions.deleteTaskAttachmentSuccess,
    (state, action): AttachmentState => adapter.removeOne(action.attachmentId, state)
  ),
  on(
    TasksReportApiActions.loadTasksReportSuccess,
    (state, action): AttachmentState => adapter.upsertMany(action.page.attachments, state)
  ),
  on(NotesApiActions.loadNoteSuccess, (state, { noteForEditor: { attachments } }): AttachmentState => {
    const entities = attachments.map(
      (attachment): Attachment => ({
        id: attachment.id,
        size: attachment.size,
        caption: attachment.caption,
      })
    );
    return adapter.upsertMany(entities, state);
  })
);

export const { selectEntities, selectAll } = adapter.getSelectors();
