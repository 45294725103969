import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Facade } from 'geotask/core/models/facade';
import { NotificationsService } from 'geotask/core/services/notifications.service';
import { LanguageService } from 'geotask/core/services/language.service';
import { selectSidenavViewModel } from '../sidenav.selectors';

@Injectable()
export class SidenavFacade extends Facade implements OnDestroy {
  readonly vm$ = this.store$.select(selectSidenavViewModel(() => this.languageService.language));

  private readonly downloadApkSubject = new Subject<void>();

  constructor(
    private readonly notificationsService: NotificationsService,
    http: HttpClient,
    store$: Store,
    private readonly languageService: LanguageService
  ) {
    super(store$);
    const apk$ = http.get('@backend/files/getApk', { responseType: 'blob' });
    this.downloadApkSubject
      .asObservable()
      .pipe(
        mergeMap(() => {
          const toast = this.shotDownloadingInfo();
          return apk$.pipe(
            tapResponse(
              (apk) => saveAs(apk, 'GeoTask.apk'),
              (error) => this.handleDownloadError(error),
              () => this.notificationsService.clear(toast.toastId)
            )
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.downloadApkSubject.complete();
  }

  downloadApk() {
    this.downloadApkSubject.next();
  }

  private shotDownloadingInfo() {
    return this.notificationsService.openInstantToastr({
      type: 'info',
      messageCode: 'core.sidenav-items.downloading-apk',
      config: {
        disableTimeOut: true,
        tapToDismiss: false,
      },
    });
  }

  private handleDownloadError(error: unknown) {
    if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Conflict) {
      this.notificationsService.openInstantToastr({
        type: 'error',
        messageCode: 'core.sidenav-items.apk-not-found',
      });
    }
  }
}
