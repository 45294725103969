import { NgModule, Provider } from '@angular/core';

import { TIME_FORMATS, TimeFormats, TimeModelAdapter } from '@glb/shared/picker/time/model';
import { DefaultTimeModelAdapter } from './default-time-model-adapter';

const TIME_FORMATS_VALUE: TimeFormats = {
  display: {
    input: {
      hour: 'numeric',
      minute: 'numeric',
    },
    picker: {
      hour: {
        hour: 'numeric',
      },
      minute: {
        minute: 'numeric',
      },
    },
  },
};

export const DEFAULT_TIME_FORMATS_PROVIDER: Provider = {
  provide: TIME_FORMATS,
  useValue: TIME_FORMATS_VALUE,
};

@NgModule({
  providers: [
    {
      provide: TimeModelAdapter,
      useClass: DefaultTimeModelAdapter,
    },
    DEFAULT_TIME_FORMATS_PROVIDER,
  ],
})
export class DefaultTimeModelAdapterModule {}
