import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  createArraySelector,
  createEntitiesArraySelector,
  createEntityByIdSelector,
  createIdsNotInEntitiesSelector,
} from 'geotask/core/selectors/selector-factories';
import { ModulesSelectors } from 'geotask/core/selectors';
import { EMPTY_ELEMENT_ID } from 'geotask/core/models';
import { comparing, distinct, stringLocaleCaseInsensitiveComparator } from '@glb/util/functional';
import { TeamEntity } from '../models';
import * as fromTeam from '../reducers/team.reducer';
import * as ExecutorSelectors from './executor.selectors';
import * as TaskSelectors from './task.selectors';
import * as WorkDaySelectors from './work-day.selectors';

export const selectTeamsState = createFeatureSelector<fromTeam.TeamsState>(fromTeam.teamsFeatureKey);
const selectTeamIdsInExecutorAreas = createSelector(selectTeamsState, (state) => state.inExecutorAreas);
export const selectEntities = createSelector(selectTeamsState, fromTeam.selectEntities);
export const selectAll = createSelector(selectTeamsState, fromTeam.selectAll);

export const selectTeamsInExecutorAreas = createEntitiesArraySelector(selectTeamIdsInExecutorAreas, selectEntities);
export const selectSortedTeamsInCurrentExecutorAreas = createArraySelector(selectTeamsInExecutorAreas, (teams) =>
  teams.slice().sort(comparing('name', 'asc', stringLocaleCaseInsensitiveComparator))
);

export const selectSortedActiveTeamsInCurrentExecutorAreas = createArraySelector(
  selectSortedTeamsInCurrentExecutorAreas,
  (teams) => teams.filter((team) => team.active)
);

export const selectTeamIdsByAreaId = createSelector(selectSortedActiveTeamsInCurrentExecutorAreas, (teamEntities) => {
  const dictionary: { [areaId: number]: number[] } = {};
  for (const team of teamEntities) {
    const { id: teamId, areaIds } = team;
    if (teamId && areaIds) {
      if (areaIds.length > 0) {
        for (const areaId of areaIds) {
          dictionary[areaId] = (dictionary[areaId] ?? []).concat(teamId).filter(distinct());
        }
      } else {
        dictionary[EMPTY_ELEMENT_ID] = (dictionary[EMPTY_ELEMENT_ID] ?? []).concat(teamId).filter(distinct());
      }
    }
  }
  return dictionary;
});

export const selectTeamById = createEntityByIdSelector(selectEntities);
export const selectTeamNameById = (teamId: number) => createSelector(selectTeamById(teamId), (team) => team?.name);

export const selectTeamIdByNameDictionary = createSelector(selectAll, (teams) => {
  const dictionary: { [teamName: string]: number | undefined } = {};
  for (const team of teams) {
    dictionary[team.name] = team.id;
  }
  return dictionary;
});

export const selectTeamIdByName = (name: string) =>
  createSelector(selectTeamIdByNameDictionary, (dictionary) => dictionary[name]);

export const selectByTaskId = createSelector(
  selectEntities,
  TaskSelectors.selectEntities,
  WorkDaySelectors.selectEntities,
  (teams, tasks, workDays) =>
    (taskId: number): TeamEntity | undefined => {
      const workDayId = tasks[taskId]?.workDayId;
      const teamId = (workDayId && workDays[workDayId]?.teamId) || tasks[taskId]?.teamId;
      if (teamId) {
        return teams[teamId];
      }
      return undefined;
    }
);

export const selectIsTeamInEntities = (teamId: number) => createSelector(selectTeamById(teamId), (team) => !!team);

export const selectTeamName = createSelector(
  ModulesSelectors.selectTeamsModuleEnabled,
  ExecutorSelectors.selectNameByLogin,
  (teamModuleEnabled, selectNameByLogin) =>
    (team: TeamEntity): string => {
      if (teamModuleEnabled) {
        return team.name;
      } else {
        const executorName = selectNameByLogin(team.name);
        if (!executorName && ngDevMode) {
          console.warn(`Didn't found executor login by team name (${team.name})`);
        }
        return executorName ?? '';
      }
    }
);

export const selectTeamNameToDisplayByTeamId = (teamId: number) =>
  createSelector(selectTeamById(teamId), selectTeamName, (team, getName) => {
    if (!team) {
      return null;
    }
    return getName(team);
  });

export const selectIdsNotInEntities = createIdsNotInEntitiesSelector(selectEntities);

export const selectByWorkDayId = createSelector(
  selectEntities,
  TaskSelectors.selectEntities,
  WorkDaySelectors.selectEntities,
  (teams, tasks, workDays) =>
    (taskId: number): TeamEntity | undefined => {
      const workDayId = tasks[taskId]?.workDayId;
      const teamId = (workDayId && workDays[workDayId]?.teamId) || tasks[taskId]?.teamId;
      if (teamId) {
        return teams[teamId];
      }
      return undefined;
    }
);
