import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MultidictionaryApiActions } from '../actions';
import { DispatcherNotificationStatus } from '../models';

export const dispatcherNotificationStatusesFeatureKey = 'dictionaries.dispatcherNotificationStatuses';

export type DispatcherNotificationStatusState = EntityState<DispatcherNotificationStatus>;

export const adapter: EntityAdapter<DispatcherNotificationStatus> = createEntityAdapter<DispatcherNotificationStatus>();

export const initialState: DispatcherNotificationStatusState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) =>
    adapter.setAll(action.dispatcherNotificationStatuses, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
