import { createAction, props } from '@ngrx/store';
import type { Update } from '@ngrx/entity';
import type { ApiError } from 'geotask/backend/models';
import type { TaskKind } from 'geotask/dictionaries/models';
import { IkeaServiceKind } from '../../dictionaries/models/ikea-service-kind.model';

export const updateServiceKindSuccess = createAction(
  '[ServiceKinds/ServiceKinds API] Save Service Kind Success',
  props<{ serviceKind: Update<IkeaServiceKind> }>()
);

export const updateServiceKindFailure = createAction(
  '[ServiceKinds/ServiceKinds API] Save Service Kind Failure',
  props<{ error: ApiError }>()
);

export const loadServicesSuccess = createAction(
  '[Services Kind API] Load Services Success',
  props<{
    page: {
      pageNumber: number;
      pageSize: number;
      totalElements: number;
      ids: number[];
    };
  }>()
);
export const loaderServiceFailure = createAction(
  '[Services Kind API] Load Services Failure',
  props<{ error: ApiError }>()
);
