import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, filter, map, merge, mergeMap, NEVER, switchMap, take, concat } from 'rxjs';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { TeamsApiService } from 'geotask/backend/services/teams-api.service';
import { AuthService } from 'geotask/core/services/auth.service';
import { AuthApiActions } from 'geotask/core/actions';
import { LoaderDataType } from 'geotask/core/models/loader-data-type.enum';
import { ModulesSelectors } from 'geotask/core/selectors';
import * as TeamEditorApiActions from 'geotask/teams/actions/teams-api.actions';
import * as ExecutorEditorApiActions from 'geotask/executors/actions/executors-api.actions';
import { LoaderApiActions } from 'geotask/loader/actions';
import { filterTruthy } from '@glb/util/rx-operators';
import { TeamActions, TeamApiActions } from '../actions';
import * as TeamsSelectors from '../selectors/team.selectors';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class TeamEffects {
  /* eslint-disable @ngrx/avoid-cyclic-effects */
  readonly loadTeamsInExecutorAreas$ = createEffect(() => {
    return merge(
      this.actions$.pipe(
        ofType(
          TeamActions.loadTeamsInExecutorAreas,
          TeamEditorApiActions.saveTeamSuccess,
          ExecutorEditorApiActions.saveExecutorDataSuccess
        )
      ),
      this.actions$.pipe(
        ofType(LoaderApiActions.uploadFileSuccess),
        filter((action) => action.dataType === LoaderDataType.Executors || action.dataType === LoaderDataType.Teams)
      )
    ).pipe(
      switchMap(() =>
        this.teamsService.inExecutorAreas$.pipe(
          map((teams) => TeamApiActions.loadTeamsInExecutorAreasSuccess({ teams })),
          catchErrorToApiFailureAction(
            TeamApiActions.loadTeamsInExecutorAreasFailure,
            'Error during fetching teams in logged executor areas'
          )
        )
      )
    );
  });
  /* eslint-enable @ngrx/avoid-cyclic-effects */

  readonly loadTeamAfterExecutorSave$ = createEffect(() => {
    return this.store$.select(ModulesSelectors.selectTeamsModuleEnabled).pipe(
      switchMap((teamsModuleEnabled) => {
        const loadTeam$ = this.actions$.pipe(
          ofType(ExecutorEditorApiActions.saveExecutorDataSuccess),
          concatLatestFrom(({ login }) => this.store$.select(TeamsSelectors.selectTeamIdByName(login))),
          mergeMap(([{ login }, teamId]) => {
            if (teamId == null) {
              console.error("Couldn't find team ID for specified name", login);
              return EMPTY;
            }
            return this.teamsService.getTeamById(teamId).pipe(
              map((team) => TeamApiActions.loadTeamAfterExecutorSaveSuccess({ team })),
              catchErrorToApiFailureAction(TeamApiActions.loadTeamAfterExecutorSaveFailure)
            );
          })
        );
        return teamsModuleEnabled ? loadTeam$ : NEVER;
      })
    );
  });

  authenticated$ = createEffect(() => {
    const teamsInExecutorAreas$ = this.authService.canAccessFullApplication$.pipe(
      take(1),
      map(() => TeamActions.loadTeamsInExecutorAreas())
    );
    return concat(
      teamsInExecutorAreas$,
      this.actions$.pipe(
        ofType(AuthApiActions.logInSuccess),
        switchMap(() => teamsInExecutorAreas$)
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly teamsService: TeamsApiService,
    private readonly authService: AuthService
  ) {}
}
