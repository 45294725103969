import { Injectable } from '@angular/core';
import { Portal } from '@angular/cdk/portal';
import { asyncScheduler, BehaviorSubject } from 'rxjs';
import { observeOn } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToolbarPortalService {
  private readonly _portalSubject = new BehaviorSubject<Portal<any> | null>(null);
  readonly portal$ = this._portalSubject.asObservable().pipe(observeOn(asyncScheduler));

  get portal() {
    return this._portalSubject.getValue();
  }

  set portal(value: Portal<any> | null) {
    this._portalSubject.next(value);
  }
}
