import { Action, combineReducers } from '@ngrx/store';

import * as fromTeamUnavailabilityDefinition from './team-unavailability-definition.reducer';
import * as fromTeamUnavailabilityOccurrence from './team-unavailability-occurrence.reducer';

export const teamUnavailabilitiesFeatureKey = 'entities.teamUnavailabilities';

export interface TeamUnavailabilitiesState {
  [fromTeamUnavailabilityDefinition.teamUnavailabilityDefinitionsFeatureKey]: fromTeamUnavailabilityDefinition.TeamUnavailabilityDefinitionsState;
  [fromTeamUnavailabilityOccurrence.teamUnavailabilityOccurrencesFeatureKey]: fromTeamUnavailabilityOccurrence.TeamUnavailabilityOccurrencesState;
}

export const initialState: TeamUnavailabilitiesState = {
  [fromTeamUnavailabilityDefinition.teamUnavailabilityDefinitionsFeatureKey]:
    fromTeamUnavailabilityDefinition.initialState,
  [fromTeamUnavailabilityOccurrence.teamUnavailabilityOccurrencesFeatureKey]:
    fromTeamUnavailabilityOccurrence.initialState,
};

const unavailabilitiesReducer = combineReducers<TeamUnavailabilitiesState>({
  [fromTeamUnavailabilityDefinition.teamUnavailabilityDefinitionsFeatureKey]: fromTeamUnavailabilityDefinition.reducer,
  [fromTeamUnavailabilityOccurrence.teamUnavailabilityOccurrencesFeatureKey]: fromTeamUnavailabilityOccurrence.reducer,
});

export function reducer(state: TeamUnavailabilitiesState | undefined, action: Action): TeamUnavailabilitiesState {
  return unavailabilitiesReducer(state, action);
}

export const selectDefinitions = (state: TeamUnavailabilitiesState) =>
  state[fromTeamUnavailabilityDefinition.teamUnavailabilityDefinitionsFeatureKey];
export const selectOccurrences = (state: TeamUnavailabilitiesState) =>
  state[fromTeamUnavailabilityOccurrence.teamUnavailabilityOccurrencesFeatureKey];
