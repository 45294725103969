export enum ReasonCode {
  InsufficientCapacity = 'InsufficientCapacity',
  CustomerInformationMissing = 'CustomerInformationMissing',
  IncorrectOrMissingDrawings = 'IncorrectOrMissingDrawings',
  MissingInformation = 'MissingInformation',
  MissingDocuments = 'MissingDocuments',
  IncorrectArticles = 'IncorrectArticles',
  DamagedArticles = 'DamagedArticles',
  PreMeasurementsNotCorrect = 'PreMeasurementsNotCorrect',
  SalesError = 'SalesError',
  InstallationError = 'InstallationError',
  CustomerDemandChanged = 'CustomerDemandChanged',
  CustomerNotHome = 'CustomerNotHome',
  InappropriateServiceConditions = 'InappropriateServiceConditions',
  MissingArticles = 'MissingArticles',
}
