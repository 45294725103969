import { Inject, Injectable, Optional } from '@angular/core';
import { Actions, createEffect, ofType, OnIdentifyEffects } from '@ngrx/effects';
import { map, startWith, tap } from 'rxjs/operators';

import { BrowserActions } from '../actions';
import { LANGUAGE_CHANGE_CALLBACK, LanguageChangeCallback } from '../services/language-change-callback.token';
import { LanguageService } from '../services/language.service';
import { DEFAULT_LANGUAGE, isSupportedLang } from '../langs';

let instanceCount = 0;

@Injectable()
export class LanguageChangedEffects implements OnIdentifyEffects {
  // noinspection JSUnusedGlobalSymbols
  readonly languageChanged$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BrowserActions.languageChanged),
        map(({ language }) => language),
        startWith(this.languageService.language),
        map((lang) => (isSupportedLang(lang) ? lang : DEFAULT_LANGUAGE)),
        tap((language) => {
          for (const onLanguageChange of this.callbacks) {
            try {
              onLanguageChange(language);
            } catch (err) {
              console.error(err);
            }
          }
        })
      );
    },
    { dispatch: false }
  );

  private readonly id: string = (++instanceCount).toString();
  private readonly callbacks: LanguageChangeCallback[];

  constructor(
    private readonly actions$: Actions,
    private readonly languageService: LanguageService,
    @Inject(LANGUAGE_CHANGE_CALLBACK) @Optional() callbacks: LanguageChangeCallback[] | null
  ) {
    this.callbacks = callbacks ?? [];
  }

  ngrxOnIdentifyEffects(): string {
    return this.id;
  }
}
