import { ExecutorsFilter } from '../models/executor-filters.model';
import { QueryParams } from '../models';

export function createExecutorFilterParams({
  namePrefix,
  loginPrefix,
  startLocationAddressPrefix,
  endLocationAddressPrefix,
  areasIds,
  rolesIds,
  costAreasIds,
  active,
  domainAccount: domainAccount,
  lastTimeOnline,
}: Readonly<Partial<ExecutorsFilter>>) {
  const params: QueryParams = {};
  if (areasIds && areasIds.length > 0) {
    params['areasIds'] = areasIds;
  }
  if (costAreasIds && costAreasIds.length > 0) {
    params['costAreasIds'] = costAreasIds;
  }
  if (active != null) {
    params['active'] = [active];
  }
  if (domainAccount != null) {
    params['domainAccount'] = domainAccount;
  }
  if (rolesIds && rolesIds.length > 0) {
    params['rolesIds'] = rolesIds;
  }
  if (namePrefix) {
    params['name'] = namePrefix;
  }
  if (loginPrefix) {
    params['login'] = loginPrefix;
  }
  if (startLocationAddressPrefix) {
    params['startLocationAddress'] = startLocationAddressPrefix;
  }
  if (endLocationAddressPrefix) {
    params['endLocationAddress'] = endLocationAddressPrefix;
  }
  if (lastTimeOnline && lastTimeOnline.start) {
    params['lastTimeOnlineFrom'] = lastTimeOnline.start.valueOf();
  }
  if (lastTimeOnline && lastTimeOnline.end) {
    params['lastTimeOnlineTo'] = lastTimeOnline.end.valueOf();
  }

  return params;
}
