import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { map } from 'rxjs';

import { LatLng } from 'geotask/core/schemas/lat-lng.schema';
import { PageRequest, QueryParams } from '../models';
import { NormalizedTeamWebObject } from '../models/normalized-team-web-object';
import { Page } from '../schemas/page.schema';
import { createPageableParams } from './create-pageable-params';
import { coerceArrayParams } from './coerce-array-params';

@Injectable({
  providedIn: 'root',
})
export class TeamsApiService {
  readonly inExecutorAreas$ = this.httpClient
    .get<{ teams: NormalizedTeamWebObject[] }>('@backend/v2/teams/inExecutorAreas')
    .pipe(map((response) => response.teams));

  constructor(private readonly httpClient: HttpClient) {}

  getTeamById(teamId: number) {
    return this.httpClient.get<NormalizedTeamWebObject>(`@backend/v2/teams/${teamId}`);
  }

  updateTeam(update: UpdateTeamBody) {
    return this.httpClient.post<number>('@backend/team/updateTeam', update);
  }

  getTeamsPage({
    pageNumber,
    active,
    pageSize,
    namePrefix,
    workStart,
    workEnd,
    startLocationAddressPrefix,
    endLocationAddressPrefix,
    areasIds,
    sort,
    ids,
  }: Readonly<TeamsFilter>) {
    const params: QueryParams = {};
    Object.assign(params, coerceArrayParams({ areasIds, ids, active }));
    if (namePrefix) {
      params['name'] = namePrefix;
    }
    if (workStart) {
      params['workStart'] = workStart;
    }
    if (workEnd) {
      params['workEnd'] = workEnd;
    }
    if (startLocationAddressPrefix) {
      params['startLocationAddress'] = startLocationAddressPrefix;
    }
    if (endLocationAddressPrefix) {
      params['endLocationAddress'] = endLocationAddressPrefix;
    }

    // Pageable params
    Object.assign(params, createPageableParams({ pageNumber, pageSize, sort }));

    return this.httpClient.get<Page<NormalizedTeamWebObject>>('@backend/v2/teams', { params });
  }

  isNameUnique(name: string) {
    return this.httpClient.get<number | null>('@backend/v2/teams/nameValidation', { params: { name } });
  }
}

interface AddressData {
  address: string;
  lat: number;
  lng: number;
}

export interface UpdateTeamBody {
  id: number | null;
  name: string;
  active: boolean;
  workStartTime: string | null;
  workEndTime: string | null;
  startAddress: Partial<AddressData>;
  endAddress: Partial<AddressData>;
  areas: {
    id: number;
    name: string;
    code: string;
  }[];
  skills: {
    id: number;
    name: string;
  }[];
  volumeCapacity: number | null;
  weightCapacity: number | null;
  taskLimit: number | null;
  operatingArea: CircleOperatingArea | PolygonOperatingArea | null;
}

interface CircleOperatingArea {
  lat: number;
  lng: number;
  address: string;
  radius: number;
  polygonEnabled: false;
}

interface PolygonOperatingArea {
  operatingAreaPolygon: LatLng[];
  polygonEnabled: true;
}

export interface TeamsFilter extends Partial<PageRequest> {
  ids?: number[];
  namePrefix?: string;
  active?: readonly boolean[];
  workStart?: string;
  workEnd?: string;
  startLocationAddressPrefix?: string;
  endLocationAddressPrefix?: string;
  areasIds?: readonly number[];
  sort?: Sort;
}
