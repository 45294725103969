import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { SettingsApiService } from 'geotask/backend/services/settings-api.service';
import { AuthService } from 'geotask/core/services/auth.service';
import { NotificationsService } from 'geotask/core/services/notifications.service';
import { filterTruthy } from '@glb/util/rx-operators';
import { SettingApiActions } from '../actions';

@Injectable()
export class AppSettingEffects {
  // noinspection JSUnusedGlobalSymbols
  readonly loadAppSettingsFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SettingApiActions.loadAppSettingsFailure),
        mergeMap(() =>
          this.notificationsService.openToastr({
            messageCode: 'dictionaries.app-settings.load-error.message',
            type: 'error',
          })
        )
      );
    },
    { dispatch: false }
  );

  // noinspection JSUnusedGlobalSymbols
  readonly loadAppSettings$ = createEffect(() => {
    return this.authService.canAccessFullApplication$.pipe(
      switchMap(() =>
        this.settingsService.allSettings$.pipe(
          map((settings) =>
            SettingApiActions.loadAppSettingsSuccess({
              settings: {
                appSettings: settings.appConfiguration,
                dispatcherNotifications:
                  settings.dispatcherNotificationConfiguration?.map((notificationType) => ({
                    id: notificationType.id,
                    name: notificationType.displayName,
                    code: notificationType.code,
                    enabled: notificationType.enabled,
                  })) ?? [],
              },
            })
          ),
          catchErrorToApiFailureAction(SettingApiActions.loadAppSettingsFailure)
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly settingsService: SettingsApiService,
    private readonly notificationsService: NotificationsService,
    private readonly authService: AuthService
  ) {}
}
