import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { SettingName } from 'geotask/core/models';
import * as AppointmentSlotsSettingsApiActions from 'geotask/appointment-slots/actions/settings-api.actions';
import * as SettingsApiActions from 'geotask/notification-configuration/actions/app-settings-api.actions';
import { SettingsApiActions as ConfigurationSettingsApiActions } from 'geotask/settings/actions';
import { SettingApiActions } from '../actions';
import { AppSetting, createAppSetting } from '../models/app-setting.model';

export const appSettingsFeatureKey = 'dictionaries.appSettings';

export type AppSettingsState = EntityState<AppSetting>;

export const adapter = createEntityAdapter<AppSetting>({
  selectId: (setting) => setting.name,
});

export const initialState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(SettingApiActions.loadAppSettingsSuccess, (state, { settings }) =>
    adapter.setAll(
      settings.appSettings.map((setting) => createAppSetting(setting)),
      state
    )
  ),
  on(ConfigurationSettingsApiActions.saveSettingsSuccess, (state, { settings }) =>
    adapter.upsertMany(
      settings.appSettings.map((setting) => createAppSetting(setting)),
      state
    )
  ),
  on(SettingsApiActions.modifySmsLimitSuccess, (state, { newSmsLimit }) =>
    adapter.upsertOne(
      createAppSetting({
        name: SettingName.NotificationsSmsLimit,
        value: newSmsLimit.toFixed(),
      }),
      state
    )
  ),
  on(SettingsApiActions.modifyNotificationsHoursSuccess, (state, { notificationsHours }) =>
    adapter.upsertOne(
      createAppSetting({
        name: SettingName.NotificationsHours,
        value: notificationsHours,
      }),
      state
    )
  ),
  on(
    AppointmentSlotsSettingsApiActions.updateAppointmentsDaysForwardToShowSuccess,
    (state, { appointmentsDaysForwardToShow }) =>
      adapter.upsertOne(
        createAppSetting({
          name: SettingName.AppointmentsDaysForwardToShow,
          value: appointmentsDaysForwardToShow.toFixed(),
        }),
        state
      )
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
