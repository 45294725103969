import { createActionGroup, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type { TaskServiceOrderStatusWebObject } from 'geotask/backend/schemas/task-service-order-status.schema';
import { ReasonCodeSchemaWebObject } from 'geotask/backend/schemas/reason-code.schema';

export const TaskServiceOrderStatusesApiActions = createActionGroup({
  source: 'Load Task Service Order Statuses API',
  events: {
    'Load Task Service Order Statuses Success': props<{ taskServiceOrderStatus: TaskServiceOrderStatusWebObject[] }>(),
    'Load Task Service Order Statuses Failure': props<{ error: ApiError }>(),
  },
});

export const ReasonCodesApiActions = createActionGroup({
  source: 'Load Reason Codes API',
  events: {
    'Load Reason Codes Success': props<{ reasonCodes: ReasonCodeSchemaWebObject[] }>(),
    'Load Reason Codes Failure': props<{ error: ApiError }>(),
  },
});
