import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { TaskTypeCode } from 'geotask/core/models';
import { MultidictionaryApiActions } from '../actions';
import { TaskType } from '../models';

export const taskTypesFeatureKey = 'dictionaries.taskTypes';

type TaskTypeIdByCode = {
  [typeCode in TaskTypeCode]?: number;
};

export interface TaskTypesState extends EntityState<TaskType> {
  byCode: TaskTypeIdByCode;
}

export const adapter: EntityAdapter<TaskType> = createEntityAdapter<TaskType>();

export const initialState: TaskTypesState = adapter.getInitialState({
  byCode: {},
});

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) =>
    adapter.setAll(action.taskTypes, {
      ...state,
      byCode: Object.fromEntries(action.taskTypes.map((type) => [type.code, type.id])),
    })
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
