import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concat, of, ReplaySubject, take, timer } from 'rxjs';
import { distinctUntilChanged, map, share, switchMap } from 'rxjs/operators';

import { filterFalsy, filterTruthy } from '@glb/util/rx-operators';
import { AreaSelectors } from 'geotask/dictionaries/selectors';
import { RoleCode } from '../models';
import { AuthSelectors } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly isTokenValid$ = this.store$.select(AuthSelectors.selectToken).pipe(
    switchMap((token) => this.isTokenExpired(token).pipe(map((isTokenExpired) => !isTokenExpired))),
    distinctUntilChanged()
  );
  readonly hasValidRoleConfiguration$ = this.store$.select(AuthSelectors.selectHasValidRoleConfiguration);
  readonly canAccessApplication$ = this.isTokenValid$.pipe(
    concatLatestFrom(() => this.hasValidRoleConfiguration$),
    map(([isTokenValid, hasValidRoleConfiguration]) => isTokenValid && hasValidRoleConfiguration),
    distinctUntilChanged(),
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: false,
      resetOnError: false,
      resetOnComplete: false,
    })
  );

  readonly canAccessFullApplication$ = this.canAccessApplication$.pipe(
    filterTruthy(),
    concatLatestFrom(() => this.hasOnlyTerrainRole()),
    map(([, hasOnlyTerrainRole]) => hasOnlyTerrainRole),
    filterFalsy(),
    distinctUntilChanged()
  );
  readonly currentExecutorAreasState$ = this.store$.select(AreaSelectors.selectCurrentExecutorAreasState);

  constructor(private readonly store$: Store, private readonly jwtHelper: JwtHelperService) {}

  hasRole(role: RoleCode) {
    return this.store$.select(AuthSelectors.selectIfUserHasRole(role));
  }

  hasNotRole(role: RoleCode) {
    return this.store$.select(AuthSelectors.selectIfUserHasNotRole(role));
  }

  hasAnyOfRoles(roleCodes: RoleCode[]) {
    return this.store$.select(AuthSelectors.selectIfUserHasRoles(roleCodes));
  }

  hasNoneOfRoles(roleCodes: RoleCode[]) {
    return this.store$.select(AuthSelectors.selectIfUserHasNotRoles(roleCodes));
  }

  private isTokenExpired(token: string | null) {
    if (!token || this.jwtHelper.isTokenExpired(token)) {
      return of(true);
    }
    return concat(of(false), this.tokenExpiration(token).pipe(map(() => true)));
  }

  private tokenExpiration(token: string) {
    return timer(this.jwtHelper.getTokenExpirationDate(token) ?? 0);
  }

  hasOnlyTerrainRole() {
    return this.store$.select(AuthSelectors.selectIsTerrainOnly).pipe(take(1));
  }

  hasOnlyTerrainRoleOrTaskReadRoleObject() {
    return this.store$.select(AuthSelectors.selectIsTerrainRoleOrTaskReadRoleObject).pipe(take(1));
  }
}
