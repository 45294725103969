import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AuthActions } from '../actions';
import { AuthSelectors } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class LoggedOutGuard implements CanActivate, CanActivateChild {
  constructor(private store$: Store) {}

  canActivate() {
    return this.waitForLogout();
  }

  canActivateChild() {
    return this.waitForLogout();
  }

  private waitForLogout() {
    const token$ = this.store$.select(AuthSelectors.selectToken);
    return token$.pipe(
      take(1),
      switchMap((token) => {
        if (!token) {
          return of(true);
        }
        this.store$.dispatch(AuthActions.logout());
        return token$.pipe(
          filter((tokenAfterLogout) => !tokenAfterLogout),
          take(1),
          map(() => true)
        );
      })
    );
  }
}
