import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { z } from 'zod';

import { parseWith } from '@glb/util/rx-operators';

const GetAppNameSchema = z.object({ name: z.string() });

@Injectable({
  providedIn: 'root',
})
export class AppNameApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getAppName() {
    return this.httpClient.get('@backend/getAppName').pipe(parseWith(GetAppNameSchema));
  }
}
