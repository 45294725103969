import { concatMap, forkJoin, from, map, Observable, of, toArray } from 'rxjs';

import { range } from '@glb/util/generators';
import type { Page } from 'geotask/backend/schemas/page.schema';

type LoadPageFn<TElement> = (pageNumber: number) => Observable<Page<TElement>>;

export function loadAllPages<TElement>(loadPage: LoadPageFn<TElement>) {
  return loadPage(0).pipe(
    concatMap(({ content: firstPage, totalElements, pageSize }) => {
      const totalPages = Math.ceil(totalElements / pageSize);
      if (totalPages === 1) {
        return of(firstPage);
      }
      return from(range(1, totalPages)).pipe(
        map((pageNumber) => loadPage(pageNumber).pipe(map((page) => page.content))),
        toArray(),
        concatMap((pages) =>
          forkJoin(pages).pipe(
            map((pagesMatrix) => pagesMatrix.flat()),
            map((elements) => firstPage.concat(elements))
          )
        )
      );
    })
  );
}
