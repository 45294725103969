export enum SettingName {
  MapInitialZoom = 'mainMapPositionZoom',
  MapInitialCenterLat = 'mainMapPositionLat',
  MapInitialCenterLng = 'mainMapPositionLng',

  AutoAcceptTask = 'autoAcceptTask',
  AutoStartTask = 'autoStartTask',
  AutoStartTaskRadius = 'autoStartTaskRadius',
  AutoFinishPreviousTask = 'autoFinishPreviousTask',
  BufferBeforeShift = 'bufferBeforeShift',
  BufferAfterShift = 'bufferAfterShift',
  TaskBasketEnabled = 'taskBasketEnabled',
  ScheduleOnMobile = 'scheduleOnMobile',
  AutoCloseOrder = 'autoCloseOrder',

  ExecutorsLoggedInLastTime = 'executorsLoggedinLastTime',
  WMSAddress = 'wmsAddress',
  WMSLayers = 'wmsLayers',
  WMSStyles = 'wmsStyles',

  OptimizerDistanceType = 'optimizerDistanceType',
  CanLocalOptymUnplugTheTask = 'canLocalOptymUnplugTheTask',
  LocalOptymStartRouteType = 'localOptymStartRouteType',
  LocalOptymEndRouteType = 'localOptymEndRouteType',
  OptimizationTimeForTask = 'optimizationTimeForTask',
  OptimizationDistanceFactor = 'optimizationDistanceFactor',
  OptimizationTimeFactor = 'optimizationTimeFactor',
  OptimizationTimeFactorGH = 'optimizationTimeFactorGH',
  OptimizationVehicle = 'optimizationVehicle',
  OptimizationTrafficEnabled = 'optimizationTrafficEnabled',

  RetentionActivated = 'retentionActivated',
  RetentionDays = 'retentionDays',

  MainMapClusteringMinimumClusterSize = 'mainMapClusteringMinimumClusterSize',
  MainMapClusteringMaxZoom = 'mainMapClusteringMaxZoom',
  MainMapClusteringGridSize = 'mainMapClusteringGridSize',
  PlacesMapClusteringMinimumClusterSize = 'placesMapClusteringMinimumClusterSize',
  PlacesMapClusteringMaxZoom = 'placesMapClusteringMaxZoom',
  PlacesMapClusteringGridSize = 'placesMapClusteringGridSize',
  TaskEditorMapClusteringMinimumClusterSize = 'taskEditorMapClusteringMinimumClusterSize',
  TaskEditorMapClusteringMaxZoom = 'taskEditorMapClusteringMaxZoom',
  TaskEditorMapClusteringGridSize = 'taskEditorMapClusteringGridSize',

  AppointmentsDaysForwardToShow = 'appointmentsDaysForwardToShow',
  AppointmentsTimeWindows = 'appointmentsTimeWindows',
  NotificationsSmsLimit = 'notificationsSmsLimit',
  NotificationsHours = 'notificationsHours',

  PullbackEnabled = 'pullbackEnabled',
  PullbackRuntime = 'pullbackRuntime',
  SLAExtensionEnabled = 'slaExtensionEnabled',
  SLAExtensionRuntime = 'slaExtensionRuntime',
  SLAExtensionTime = 'slaExtensionTime',

  SelfCarePortalDaysForwardToShow = 'selfCarePortal.daysForwardToShow',
  SelfCarePortalDaysOfWeek = 'selfCarePortal.daysOfWeek',

  UnusedExecutorLimitDays = 'unusedExecutorLimitDays',

  AppLockRetries = 'appLockRetries',
  AppLockTimeout = 'appLockTimeout',

  RecommendedTotalAttachmentSize = 'recommendedTotalAttachmentSize',
  AttachmentSizeWarningThresholdPercentage = 'attachmentSizeWarningThresholdPercentage',
  MaxNumberOfCommentsAddedToACompletedTask = 'maxNumberOfCommentsAddedToACompletedTask',
  MobileAppVersion = 'mobileAppVersion',
}
