import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { tap } from 'rxjs/operators';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class RouterNavigatedEffects {
  closeAllDialogs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(routerNavigatedAction),
        tap(() => {
          this.matDialog.closeAll();
        })
      );
    },
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly matDialog: MatDialog) {}
}
