import { createReducer, on } from '@ngrx/store';

import { ModulesApiActions } from '../actions';
import { ModulesConfiguration } from '../models';

export const modulesFeatureKey = 'modules';

export type ModulesState = ModulesConfiguration | null;

export const initialState: ModulesState = null;

export const reducer = createReducer<ModulesState>(
  initialState,
  on(ModulesApiActions.loadModulesSuccess, (_state, action): ModulesState => action.modules),
  on(
    ModulesApiActions.loadModulesFailure,
    (_state): ModulesState => ({
      teams: false,
      appointments: false,
      notes: false,
      areas: false,
      places: false,
      skills: false,
      optimizations: false,
      formTemplates: false,
      reportRodo: false,
      areasEditable: false,
      carTracking: false,
      dispatcherNotifications: false,
      localOptimize: false,
      taskImporter: false,
      taskStatusHistoryEditor: false,
      companies: false,
    })
  )
);
