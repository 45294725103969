import { WeekDay } from '@angular/common';
import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { DateTime } from 'luxon';

@Injectable()
export class GeoTaskDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return WeekDay.Monday;
  }

  override parse(value: any, parseFormat?: any): Date | null {
    if (typeof value === 'number') {
      return new Date(value);
    }
    if (typeof value === 'string') {
      parseFormat = parseFormat || 'D';
      const date = DateTime.fromFormat(value, parseFormat, { locale: 'lookup' });
      if (date.isValid) {
        return date.toJSDate();
      }
    }
    return super.parse(value);
  }
}
