import { z } from 'zod';

import { TaskTypeCode } from 'geotask/core/models';
import { DictionaryEntrySchema } from 'geotask/core/schemas/dictionary-entry.schema';

export const TaskTypeWebObjectSchema = DictionaryEntrySchema.extend({
  code: z.nativeEnum(TaskTypeCode),
  isEmergency: z.boolean(),
});
export type TaskTypeWebObject = z.infer<typeof TaskTypeWebObjectSchema>;
