import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { createPlace } from 'geotask/backend/services/places-api.service';
import {
  CustomersApiActions,
  PlacesApiActions as CustomerPlacesApiActions,
  TasksApiActions as CustomerTasksApiActions,
} from 'geotask/customers/actions/customers-api.actions';
import * as TasksApiActions from 'geotask/dashboard-list/actions/tasks-api.actions';
import * as DashboardSchedulerActions from 'geotask/dashboard-scheduler/actions/scheduler-data.actions';
import * as OrderEditorOrdersApiActions from 'geotask/orders/actions/orders-api.actions';
import {
  PlacesApiActions as PlacesEditorPlacesApiActions,
  TasksApiActions as PlaceEditorTasksApiActions,
} from 'geotask/places/actions/places-api.actions';
import * as AutocompleteContactPersonActions from 'geotask/shared/autocomplete-contact-person/autocomplete-contact-person.actions';
import * as AutocompleteCustomerActions from 'geotask/shared/autocomplete-customer/autocomplete-customer.actions';
import * as AutocompletePlacesActions from 'geotask/shared/autocomplete-place/autocomplete-place.actions';
import * as TaskEditorTasksApiActions from 'geotask/tasks/actions/tasks-api.actions';
import * as TasksReportApiActions from 'geotask/tasks-report/actions/tasks-report-api.actions';
import * as TrackSummaryApiActions from 'geotask/track-summary/actions/track-summary-api.actions';
import * as PlacesApiActions from '../actions/places-api.actions';
import { Place } from '../models';
import { PlaceActions } from '../actions';

export const placesFeatureKey = 'entities.places';

export type PlacesState = EntityState<Place>;

export const adapter: EntityAdapter<Place> = createEntityAdapter<Place>();

export const initialState: PlacesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    PlaceActions.upsertPlace,
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    TaskEditorTasksApiActions.loadCopiedTaskSuccess,
    TaskEditorTasksApiActions.saveNewTaskSuccess,
    PlacesEditorPlacesApiActions.loadPlaceSuccess,
    PlacesEditorPlacesApiActions.savePlaceSuccess,
    (state, action): PlacesState => (action.place ? adapter.upsertOne(action.place, state) : state)
  ),
  on(
    PlacesEditorPlacesApiActions.togglePlaceActiveSuccess,
    (state, action): PlacesState =>
      adapter.updateOne(
        {
          id: action.id,
          changes: {
            active: action.active,
          },
        },
        state
      )
  ),
  on(
    PlaceActions.upsertPlaces,
    TrackSummaryApiActions.loadTrackSummarySuccess,
    OrderEditorOrdersApiActions.loadOrderSuccess,
    PlacesApiActions.loadAllPlacesSuccess,
    AutocompleteCustomerActions.loadCustomerAutocompletePromptsSuccess,
    AutocompleteContactPersonActions.loadContactPersonAutocompletePromptsSuccess,
    AutocompletePlacesActions.loadPlaceAutocompletePromptsSuccess,
    (state, { places }): PlacesState => adapter.upsertMany(places, state)
  ),
  on(
    DashboardSchedulerActions.loadSchedulerDataSuccess,
    DashboardSchedulerActions.refreshSchedulerDataSuccess,
    (state, { data: { places } }): PlacesState => adapter.upsertMany(places, state)
  ),
  on(
    CustomerTasksApiActions.loadOnGoingTasksSuccess,
    CustomerTasksApiActions.loadTasksHistorySuccess,
    CustomerTasksApiActions.loadNotTerminatedTasksSuccess,
    PlaceEditorTasksApiActions.loadOnGoingTasksSuccess,
    PlaceEditorTasksApiActions.loadTasksHistorySuccess,
    (state, { page: { places } }): PlacesState => adapter.upsertMany(places, state)
  ),
  on(
    CustomersApiActions.loadCustomersPageSuccess,
    TasksApiActions.loadTasksPageSuccess,
    TasksReportApiActions.loadTasksReportSuccess,
    (state, action): PlacesState => adapter.upsertMany(action.page.places, state)
  ),
  on(PlaceActions.deletePlaces, (state, action): PlacesState => adapter.removeMany(action.ids, state)),
  on(
    CustomersApiActions.loadCustomerSummarySuccess,
    CustomersApiActions.saveCustomerSuccess,
    (state, { customerSummary: { mainPlace: place } }): PlacesState => {
      const placeEntity = createPlace(place);
      return adapter.upsertOne(placeEntity, state);
    }
  ),
  on(CustomerPlacesApiActions.loadCustomerPlacesPageSuccess, (state, { page }): PlacesState => {
    const places = page.content;
    return adapter.upsertMany(places.map(createPlace), state);
  }),
  on(
    CustomerPlacesApiActions.loadAllCustomerPlacesSuccess,
    (state, { places }): PlacesState => adapter.upsertMany(places.map(createPlace), state)
  ),
  on(
    CustomersApiActions.activateCustomerSuccess,
    (state, { mainPlaceId }): PlacesState =>
      adapter.updateOne(
        {
          id: mainPlaceId,
          changes: { active: true },
        },
        state
      )
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
