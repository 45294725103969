import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MultidictionaryApiActions } from '../actions';
import { UnavailabilityType } from '../models';

export const unavailabilityTypesFeatureKey = 'dictionaries.unavailabilityTypes';

export type UnavailabilityTypesState = EntityState<UnavailabilityType>;

export const adapter: EntityAdapter<UnavailabilityType> = createEntityAdapter<UnavailabilityType>();

export const initialState: UnavailabilityTypesState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) =>
    adapter.setAll(action.unavailabilityTypes, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
