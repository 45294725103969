<mat-nav-list>
  <h1 matSubheader>{{ 'ui-components.account-action-list.header' | translate }}: {{ loggedExecutor$ | async }}</h1>

  <a mat-list-item [routerLink]="['/', Routes.Login, 'change-password']" (click)="changePasswordClicked()">
    <mat-icon matListIcon>vpn_key</mat-icon>
    <span matLine>{{ 'ui-components.account-action-list.change-password' | translate }}</span>
  </a>

  <a mat-list-item [routerLink]="['/', Routes.Login]" (click)="changePasswordClicked()">
    <mat-icon matListIcon>power_settings_new</mat-icon>
    <span matLine>{{ 'ui-components.account-action-list.logout' | translate }}</span>
  </a>
</mat-nav-list>
