import type { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';

export const changeSort = createAction('[Areas/AreasList] Change Sort', props<{ sort: Sort }>());

export const changePage = createAction(
  '[Areas/AreasList] Change Page',
  props<Readonly<{ pageNumber: number; pageSize: number }>>()
);

export const changeFilteredAreaName = createAction(
  '[Areas/AreasList] Change Filtered Area Name',
  props<Readonly<{ filteredName: string }>>()
);

export const changeFilteredAreaCode = createAction(
  '[Areas/AreasList] Change Filtered Area Code',
  props<Readonly<{ filteredCode: string }>>()
);

export const toggleAreaSelection = createAction(
  '[Areas/AreaList] Toggle Area Selection',
  props<Readonly<{ areaId: number }>>()
);

export const loadAreasPage = createAction('[Areas/AreasList] Load Areas Page');
