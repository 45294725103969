import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { catchErrorToApiFailureAction } from 'geotask/backend/error-handling';
import { AuthService } from 'geotask/core/services/auth.service';
import { TasksServiceOrderApiService } from 'geotask/backend/services/tasks-service-order-api.service';
import { ReasonCodesApiActions, TaskServiceOrderStatusesApiActions } from '../actions';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class TaskServiceOrderStatusesEffects {
  loadTaskServiceOrderStatuses$ = createEffect(() => {
    return this.authService.canAccessFullApplication$.pipe(
      switchMap(() => {
        return this.tasksServiceOrderApiService.statusOrderDict$.pipe(
          map((fields) =>
            TaskServiceOrderStatusesApiActions.loadTaskServiceOrderStatusesSuccess({
              taskServiceOrderStatus: fields,
            })
          ),
          catchErrorToApiFailureAction(TaskServiceOrderStatusesApiActions.loadTaskServiceOrderStatusesFailure)
        );
      })
    );
  });

  loadReasonCodes$ = createEffect(() => {
    return this.authService.canAccessFullApplication$.pipe(
      switchMap(() => {
        return this.tasksServiceOrderApiService.reasonCodesDict$.pipe(
          map((fields) =>
            ReasonCodesApiActions.loadReasonCodesSuccess({
              reasonCodes: fields,
            })
          ),
          catchErrorToApiFailureAction(ReasonCodesApiActions.loadReasonCodesFailure)
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly tasksServiceOrderApiService: TasksServiceOrderApiService,
    private readonly authService: AuthService
  ) {}
}
