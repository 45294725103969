import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as TaskKindApiActions from 'geotask/task-kinds/actions/task-kinds-api.actions';
import { MultidictionaryApiActions } from '../actions';
import { TaskKind } from '../models';

export const taskKindsFeatureKey = 'dictionaries.taskKinds';

export type TaskKindsState = EntityState<TaskKind>;

export const adapter: EntityAdapter<TaskKind> = createEntityAdapter<TaskKind>();

export const initialState: TaskKindsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) => adapter.setAll(action.taskKinds, state)),
  on(TaskKindApiActions.addTaskKindSuccess, (state, action) => adapter.addOne(action.taskKind, state)),
  on(TaskKindApiActions.updateTaskKindSuccess, (state, action) => adapter.updateOne(action.taskKind, state))
);

export const { selectEntities, selectTotal, selectAll } = adapter.getSelectors();
