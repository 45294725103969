import { Sort } from '@angular/material/sort';

import { PageRequest, QueryParams } from '../models';

export function createPageableParams({
  pageSize,
  sort,
  pageNumber,
}: Readonly<Partial<PageRequest> & { sort?: Sort | Sort[] }>): QueryParams {
  const params: QueryParams = {};
  if (sort != null) {
    if (Array.isArray(sort)) {
      params['sort'] = sort.map(({ active, direction }) => `${active},${direction}`);
    } else {
      params['sort'] = `${sort.active},${sort.direction}`;
    }
  }
  if (pageSize != null) {
    params['size'] = pageSize;
  }
  if (pageNumber != null) {
    params['page'] = pageNumber;
  }
  return params;
}
