import { createAction, props } from '@ngrx/store';
import type { ApiError } from 'geotask/backend/models';

export const deleteAllTasksSuccess = createAction('[Delete Data API] Delete All Tasks Success');
export const deleteSelectedTasksSuccess = createAction(
  '[Delete Data API] Delete Selected Tasks Success',
  props<{ ids: number[] }>()
);
export const deleteAllTasksFailure = createAction(
  '[Delete Data API] Delete All Tasks Failure',
  props<{ error: ApiError }>()
);
export const deleteSelectedTasksFailure = createAction(
  '[Delete Data API] Delete Selected Tasks Failure',
  props<{ error: ApiError }>()
);
export const deleteAllResourcesSuccess = createAction('[Delete Data API] Delete All Resources Success');
export const deleteSelectedResourcesSuccess = createAction(
  '[Delete Data API] Delete Selected Resources Success',
  props<{ teamIds: number[]; executorIds: number[] }>()
);
export const deleteAllResourcesFailure = createAction(
  '[Delete Data API] Delete All Resources Failure',
  props<{ error: ApiError }>()
);
export const deleteSelectedResourcesFailure = createAction(
  '[Delete Data API] Delete Resources Failure',
  props<{ error: ApiError }>()
);
