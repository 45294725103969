import { HttpStatusCode } from '@angular/common/http';

export interface FullApiError {
  status: HttpStatusCode | 0;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type ApiError = FullApiError | {};

export function isFullApiError(error: ApiError): error is FullApiError {
  return 'status' in error && 'message' in error;
}

export function isClientFaultCode(code: FullApiError['status']) {
  return code >= HttpStatusCode.BadRequest && code < HttpStatusCode.InternalServerError;
}
