import { createActionGroup, emptyProps, props } from '@ngrx/store';

import type { Id } from 'geotask/core/schemas/id.schema';
import type { ISO8601Timestamp } from 'geotask/core/schemas/iso-8601-timestamp.schema';
import type { RangeOf } from '@glb/util/types';
import type { TaskHistoryTimelineModifications } from '../models/task-history-timeline.model';
import type { AdditionalFieldsFormGroupValue } from '../models/task-form-fields.model';
import { TaskOrderServiceFormModel } from '../models/task-order-service-form.model';

export const TaskEditorActions = createActionGroup({
  source: 'Tasks/TaskEditor',
  events: {
    'Resolve task data start': emptyProps(),
    'Save task': props<{
      formValue: {
        address: {
          address: string;
          location: {
            lat: number;
            lng: number;
          };
        };
        typeId: Id;
        kindId: Id | null;
        priorityId: Id;
        placeId: Id | null;
        areaId: Id | null;
        duration: number;
        description: string;
        statusId: Id;
        statusDateChange: ISO8601Timestamp | null;
        comment: string;
        customer: {
          id: Id | null;
          name: string;
          externalOrderNumber: string;
          invoiceNumber: string;
          sacId: string;
        };
        contact: {
          contactPerson: string;
          phoneNumber: string;
          email: string;
        };
        capacityParams: {
          weight: number | null;
          volume: number | null;
          amount: number | null;
        };
        skillIds: Id[];
        terms: {
          requiredDate: RangeOf<ISO8601Timestamp>;
          originalCompletionDate: ISO8601Timestamp | null;
          appointmentDate: RangeOf<ISO8601Timestamp | null>;
          plannedDate: RangeOf<ISO8601Timestamp | null>;
          realDate: RangeOf<ISO8601Timestamp | null>;
        };
        lockedToTeam: boolean;
        additionalFields: AdditionalFieldsFormGroupValue;
        services: TaskOrderServiceFormModel[];
      };
      historyModifications: TaskHistoryTimelineModifications | null;
    }>(),
    'Task editor open': emptyProps(),
    'Delete task attachment': props<{ attachmentId: Id }>(),
    'Upload task attachments': emptyProps(),
    'Download all attachments': emptyProps(),
  },
});
