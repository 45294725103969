import { createAction, props } from '@ngrx/store';

import type { FormTemplateDictionary } from '../models';

export const upsertFormTemplateDictionary = createAction(
  '[FormTemplateDictionary/API] Upsert FormTemplateDictionary',
  props<{ formTemplateDictionary: FormTemplateDictionary }>()
);

export const deleteFormTemplateDictionaries = createAction(
  '[FormTemplateDictionary/API] Delete FormTemplateDictionaries',
  props<{ ids: number[] }>()
);
