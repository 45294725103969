import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ModuleName } from 'geotask/core/models';
import { ModulesService } from 'geotask/core/services/modules.service';
import { filterNonNullable } from '@glb/util/rx-operators';
import { ConditionalBaseDirective } from '@glb/util/angular';

@Directive({
  selector: '[glbIfModuleDisabled]',
})
export class IfModuleDisabledDirective extends ConditionalBaseDirective {
  @Input()
  set glbIfModuleDisabled(moduleName: ModuleName) {
    this.moduleNameSubject.next(moduleName);
  }

  private readonly moduleNameSubject = new BehaviorSubject<ModuleName | null>(null);

  constructor(
    viewContainer: ViewContainerRef,
    changeDetector: ChangeDetectorRef,
    template: TemplateRef<any>,
    private readonly modulesService: ModulesService
  ) {
    super(viewContainer, changeDetector, template);
  }

  protected getObservableCondition(): Observable<boolean> {
    return this.moduleNameSubject.asObservable().pipe(
      filterNonNullable(),
      switchMap((moduleName) => this.modulesService.isModuleDisabled(moduleName))
    );
  }
}
