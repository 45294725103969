/* eslint-disable @typescript-eslint/ban-types */
import { Dictionary } from '@ngrx/entity';
import { createSelector, createSelectorFactory, MemoizedSelector, resultMemoize, Selector } from '@ngrx/store';

import { deepEqualArrays, toEntities } from '@glb/util/functional';

type ResultArray = any[] | readonly any[] | null | undefined;

type ArraySelectorResult<T extends ResultArray> = T extends readonly (infer U)[] ? readonly U[] : T;

export function createArraySelector<State, Slices extends unknown[], Result extends ResultArray>(
  ...input: [...Selector<State, unknown>[], unknown] &
    [...{ [i in keyof Slices]: Selector<State, Slices[i]> }, (...s: Slices) => Result]
): MemoizedSelector<State, ArraySelectorResult<Result>, (...s: Slices) => ArraySelectorResult<Result>> {
  const isResultEqual = (a: ResultArray, b: ResultArray): boolean =>
    (a == null && b == null) || (a != null && b != null && deepEqualArrays(a, b));
  return createSelectorFactory((projectionFn) => resultMemoize(projectionFn, isResultEqual))(...input);
}

type Id = number | string;
type IdsSelector<TState = object> = Selector<TState, readonly Id[]>;
type EntitiesSelector<TEntity, TState = object> = Selector<TState, Dictionary<TEntity>>;

export function createEntityByIdSelector<TEntity, TState = object>(selectEntities: EntitiesSelector<TEntity, TState>) {
  return (id: Id) => createSelector(selectEntities, (state) => state[id]);
}

export function createEntitiesByIdsSelector<TEntity, TState = object>(
  selectEntities: EntitiesSelector<TEntity, TState>
) {
  return (ids: readonly Id[]) =>
    createArraySelector(selectEntities, (entities) => ids.reduce(toEntities(entities), []));
}

export function createEntitiesArraySelector<TEntity, TState = object>(
  selectIds: IdsSelector<TState>,
  selectEntities: EntitiesSelector<TEntity, TState>
) {
  return createArraySelector(selectIds, selectEntities, (ids, entities) => ids.reduce(toEntities(entities), []));
}

export function createIdsNotInEntitiesSelector<TEntity, TState = object>(
  selectEntities: EntitiesSelector<TEntity, TState>
) {
  return <TId extends string | number>(ids: readonly TId[]) =>
    createArraySelector(selectEntities, (entities) => ids.filter((id) => !(id in entities)));
}
