import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { SettingsApiActions as ConfigurationSettingsApiActions } from 'geotask/settings/actions';
import { SettingApiActions } from '../actions';
import { DispatcherNotificationType } from '../models';

export const dispatcherNotificationTypesFeatureKey = 'dictionaries.dispatcherNotificationTypes';

export type DispatcherNotificationTypeState = EntityState<DispatcherNotificationType>;

export const adapter = createEntityAdapter<DispatcherNotificationType>();

export const initialState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(SettingApiActions.loadAppSettingsSuccess, (state, { settings }) =>
    adapter.setAll(settings.dispatcherNotifications, state)
  ),
  on(ConfigurationSettingsApiActions.saveSettingsSuccess, (state, { settings }) =>
    adapter.upsertMany(settings.dispatcherNotifications, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
