import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concatLatestFrom, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Duration } from 'luxon';
import {
  catchError,
  concat,
  concatMap,
  EMPTY,
  exhaustMap,
  filter,
  first,
  interval,
  mergeMap,
  NEVER,
  race,
  tap,
  timer,
} from 'rxjs';

import { Environment, ENVIRONMENT } from 'geotask/core/environment.token';
import { LOCATION, LocationTokenType } from '@glb/shared/web-api-browser';
import { AppNameSelectors } from 'geotask/core/selectors';
import { NotificationsService } from '../services/notifications.service';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class SwUpdatesEffects {
  unrecoverableState$ = createEffect(
    () => {
      return this.swUpdates.unrecoverable.pipe(
        mergeMap(({ reason }) =>
          this.notificationsService.openToastr({
            type: 'error',
            messageCode: 'core.sw-updates.unrecoverable',
            interpolationParams: { reason },
            config: {
              closeButton: true,
              disableTimeOut: true,
            },
          })
        )
      );
    },
    { dispatch: false }
  );

  updateAvailable$ = createEffect(
    () => {
      return this.swUpdates.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        concatLatestFrom(() => this.store$.select(AppNameSelectors.selectAppName)),
        mergeMap(([, appName]) => {
          return this.notificationsService
            .openToastr({
              type: 'info',
              titleCode: 'core.sw-updates.available.title',
              messageCode: 'core.sw-updates.available.message',
              interpolationParams: { appName },
              config: {
                closeButton: true,
                disableTimeOut: true,
              },
            })
            .pipe(concatMap((toastr) => toastr.onTap.pipe(tap(() => this.location.reload()))));
        })
      );
    },
    { dispatch: false }
  );

  checkForUpdates$ = createEffect(
    () => {
      if (!this.swUpdates.isEnabled) {
        return NEVER;
      }
      const stable$ = this.appRef.isStable.pipe(first((isStable) => isStable));
      const checkInterval$ = interval(Duration.fromObject(this.environment.swUpdateCheckInterval).as('milliseconds'));
      return concat(race(stable$, timer(30 * 1000)), checkInterval$).pipe(
        exhaustMap(() => this.swUpdates.checkForUpdate()),
        catchError((error: unknown) => {
          console.error('Error during checking for new version of app', error);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly swUpdates: SwUpdate,
    private readonly notificationsService: NotificationsService,
    private readonly appRef: ApplicationRef,
    private readonly store$: Store,
    @Inject(LOCATION) private readonly location: LocationTokenType,
    @Inject(ENVIRONMENT) private readonly environment: Environment
  ) {}
}
