import { Injectable, NgModule, Provider } from '@angular/core';
import { DATE_FORMATS, DateFormats, DatetimeModelAdapter } from '@glb/shared/picker/datetime/model';

@Injectable()
export class NativeDatetimeModelAdapter<TTime> extends DatetimeModelAdapter<Date, Date, TTime> {
  format(value: Date, format: Record<string, unknown> | string): string {
    return this._dateAdapter.format(value, format);
  }

  isValid(value: Date): boolean {
    return this._dateAdapter.isValid(value);
  }

  parse(value: string, parseFormat: Record<string, unknown> | string): Date | null {
    return this._dateAdapter.parse(value, parseFormat);
  }

  sameDate(value: Date | null, otherValue: Date | null): boolean {
    return (
      this._dateAdapter.sameDate(value, otherValue) &&
      value?.getHours() === otherValue?.getHours() &&
      value?.getMinutes() === otherValue?.getMinutes()
    );
  }

  getDate(date: Date): Date {
    return this._dateAdapter.createDate(
      this._dateAdapter.getYear(date),
      this._dateAdapter.getMonth(date),
      this._dateAdapter.getDate(date)
    );
  }

  getTime(date: Date): TTime {
    return this._timeAdapter.createTime(date.getHours(), date.getMinutes());
  }

  createDatetime(date: Date, time: TTime) {
    return new Date(
      this._dateAdapter.getYear(date),
      this._dateAdapter.getMonth(date),
      this._dateAdapter.getDate(date),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._timeAdapter.getHour(time)!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._timeAdapter.getMinute(time)!
    );
  }
}

export function _NATIVE_DATE_FORMATS_FACTORY(): DateFormats {
  const input: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return {
    display: { input },
    parse: {
      dateInput: 'f', // short localized date and time (https://moment.github.io/luxon/#/parsing?id=table-of-tokens)
    },
  };
}

export const NATIVE_DATE_FORMATS_FACTORY_PROVIDER: Provider = {
  provide: DATE_FORMATS,
  useFactory: _NATIVE_DATE_FORMATS_FACTORY,
};

@NgModule({
  providers: [
    {
      provide: DatetimeModelAdapter,
      useClass: NativeDatetimeModelAdapter,
    },
    NATIVE_DATE_FORMATS_FACTORY_PROVIDER,
  ],
})
export class NativeDatetimeAdapterModule {}
