import { PortalModule } from '@angular/cdk/portal';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { JwtModule } from '@auth0/angular-jwt';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';

import '@angular/common/locales/global/pl';
import { environment } from 'environments/environment';
import { NativeDatetimeAdapterModule } from '@glb/shared/picker/datetime/native';
import { DefaultTimeModelAdapterModule } from '@glb/shared/picker/time/default';
import { ArrayElementTypeOf, InjectionTokenType } from '@glb/util/types';
import { MsalConfigService } from 'geotask/core/services/msal-config.service';
import { BackendRequestInterceptor } from './backend/services/backend-request.interceptor';
import { GeotaskI18nModule } from './core/geotask-i18n.module';
import { GeoTaskDateAdapter } from './core/services/geotask-date-adapter';
import { JWT_OPTIONS_FACTORY_PROVIDER } from './core/services/jwt-options';
import { MAT_DEFAULT_OPTIONS_PROVIDERS } from './core/services/mat-default-options';
import { MAT_PAGINATOR_INTL_CLASS_PROVIDER } from './core/services/mat-paginator-intl.provider';
import { TIMEPICKER_INTL_CLASS_PROVIDER } from './core/services/timepicker-intl-class-provider';
import { AppInitService } from './core/services/app-init.service';
import { DictionariesModule } from './dictionaries';
import { EntitiesModule } from './entities';
import { SidenavModule } from './sidenav';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStoreModule } from './app-store.module';
import { PageNotFoundComponent } from './page-not-found.component';
import { TerrainTemplateEmptyComponent } from './terrain-template-empty.component';

export function INIT_APPLICATION_FACTORY(
  appInitService: AppInitService
): ArrayElementTypeOf<InjectionTokenType<typeof APP_INITIALIZER>> {
  return () => appInitService.initApp();
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(msalConfigService: MsalConfigService): IPublicClientApplication {
  return new PublicClientApplication(msalConfigService.msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

const MATERIAL_MODULES = [
  MatButtonModule,
  MatIconModule,
  MatProgressBarModule,
  MatSidenavModule,
  MatToolbarModule,
  MatDialogModule,
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MsalModule,

    GeotaskI18nModule.forRoot(),
    AppRoutingModule,
    AppStoreModule,

    HttpClientModule,
    JwtModule.forRoot({ jwtOptionsProvider: JWT_OPTIONS_FACTORY_PROVIDER }),
    ToastrModule.forRoot({
      progressBar: true,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      timeOut: 10_000,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ToastContainerModule,

    MatNativeDateModule,
    DefaultTimeModelAdapterModule,
    NativeDatetimeAdapterModule,

    SidenavModule,
    EntitiesModule,
    DictionariesModule,
    PortalModule,
    MATERIAL_MODULES,
  ],
  declarations: [AppComponent, PageNotFoundComponent, TerrainTemplateEmptyComponent],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [MsalConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalBroadcastService,
    {
      provide: APP_INITIALIZER,
      useFactory: INIT_APPLICATION_FACTORY,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendRequestInterceptor,
      multi: true,
    },
    MAT_DEFAULT_OPTIONS_PROVIDERS,
    MAT_PAGINATOR_INTL_CLASS_PROVIDER,
    {
      provide: DateAdapter,
      useClass: GeoTaskDateAdapter,
    },
    TIMEPICKER_INTL_CLASS_PROVIDER,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
