/* eslint-disable @typescript-eslint/ban-types */
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, filter, map, Observable, OperatorFunction, pipe } from 'rxjs';

import { SettingName } from 'geotask/core/models/setting-name.enum';
import { toLocaleTimeWindow } from 'geotask/core/models/map-to-time-window';
import { filterNonNullable } from '@glb/util/rx-operators';
import { AppSetting } from '../models';
import * as fromAppSetting from '../reducers/app-setting.reducer';

export const selectAppSettingsState = createFeatureSelector<fromAppSetting.AppSettingsState>(
  fromAppSetting.appSettingsFeatureKey
);

export const selectEntities = createSelector(selectAppSettingsState, fromAppSetting.selectEntities);

const selectAppSetting = (settingName: SettingName) =>
  createSelector(selectEntities, (settings) => settings[settingName]);
const selectAppSettingValue = (settingName: SettingName) =>
  createSelector(selectAppSetting(settingName), (setting) => setting?.value);

export function selectSetting(settingName: SettingName): OperatorFunction<object, string>;
export function selectSetting<T>(
  settingName: SettingName,
  valueProjector: (value: string) => T
): OperatorFunction<object, T>;
export function selectSetting<T>(
  settingName: SettingName,
  valueProjector?: (value: string) => T
): OperatorFunction<object, T | string> {
  return pipe(
    select(selectAppSetting(settingName)),
    filter((setting): setting is AppSetting => !!setting),
    map(({ value }) => value),
    distinctUntilChanged(),
    map((value) => (valueProjector ? valueProjector(value) : value)),
    distinctUntilChanged()
  );
}

export const selectNumberSetting = (settingName: SettingName) => selectSetting(settingName, Number);

export const selectNotificationSmsLimit = createSelector(
  selectAppSettingValue(SettingName.NotificationsSmsLimit),
  integerProjector(0)
);

export const selectMobileAppVersion = createSelector(
  selectAppSettingValue(SettingName.MobileAppVersion),
  (mobileAppVersion) => (mobileAppVersion === undefined ? '' : mobileAppVersion)
);

export const selectMainMapCenter = createSelector(
  selectAppSettingValue(SettingName.MapInitialCenterLat),
  selectAppSettingValue(SettingName.MapInitialCenterLng),
  (lat, lng) => (lat && lng ? { lat: parseFloat(lat), lng: parseFloat(lng) } : null)
);

export const selectMapInitialZoom = createSelector(
  selectAppSettingValue(SettingName.MapInitialZoom),
  integerProjector(null)
);

export const selectNotificationHoursLabel = createSelector(
  selectAppSettingValue(SettingName.NotificationsHours),
  (time) => (time === undefined ? '' : toLocaleTimeWindow(time))
);

export function getMapInitialCenter() {
  return pipe(select(selectMainMapCenter), filterNonNullable());
}

export function getMapInitViewport() {
  return (store$: Observable<object>) =>
    combineLatest({
      center: store$.pipe(getMapInitialCenter()),
      zoom: store$.pipe(selectNumberSetting(SettingName.MapInitialZoom)),
    });
}

export const selectAppointmentsDaysForwardToShow = createSelector(
  selectAppSettingValue(SettingName.AppointmentsDaysForwardToShow),
  integerProjector(0)
);
export const selectDefaultAppointmentsTimeWindows = selectAppSettingValue(SettingName.AppointmentsTimeWindows);

const selectExecutorsLoggedInLastTime = createSelector(
  selectAppSettingValue(SettingName.ExecutorsLoggedInLastTime),
  integerProjector(0)
);
export const selectExecutorStatusesLoadEnabled = createSelector(selectExecutorsLoggedInLastTime, (value) => value > 0);

function integerProjector<TDefault>(defaultValue: TDefault, radix = 10) {
  return (value: ReturnType<ReturnType<typeof selectAppSettingValue>>) => {
    if (!value) {
      return defaultValue;
    }
    const intValue = parseInt(value, radix);
    return isNaN(intValue) ? defaultValue : intValue;
  };
}
