import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NAVIGATOR, NavigatorTokenType } from '@glb/shared/web-api-browser';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  get language() {
    return this.translateService.getBrowserLang() ?? this.navigator.language;
  }

  constructor(
    private readonly translateService: TranslateService,
    @Inject(NAVIGATOR) private readonly navigator: NavigatorTokenType
  ) {}
}
