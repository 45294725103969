import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input } from '@angular/core';

@Directive()
export class SwitchableComponent {
  @Input()
  get opened(): boolean {
    return this._opened;
  }
  set opened(value: BooleanInput) {
    this._opened = coerceBooleanProperty(value);
  }

  @Input()
  get closed(): boolean {
    return !this._opened;
  }
  set closed(value: BooleanInput) {
    this.opened = !coerceBooleanProperty(value);
  }

  get expansionState() {
    return this.opened ? 'expanded' : 'collapsed';
  }

  private _opened = true;

  toggle() {
    this.opened = !this.opened;
  }
}
