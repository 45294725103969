import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  createArraySelector,
  createEntitiesByIdsSelector,
  createEntityByIdSelector,
} from 'geotask/core/selectors/selector-factories';
import * as fromFormTemplateDictionary from '../reducers/form-template-dictionary.reducer';
import { FormTemplateDictionary } from 'geotask/dictionaries/models';

const selectFormTemplateDictionaryState =
  createFeatureSelector<fromFormTemplateDictionary.FormTemplateDictionariesState>(
    fromFormTemplateDictionary.formTemplateDictionariesFeatureKey
  );

export const selectEntities = createSelector(
  selectFormTemplateDictionaryState,
  fromFormTemplateDictionary.selectEntities
);

export const selectFormTemplateDictionaryById = createEntityByIdSelector(selectEntities);
export const selectFormTemplateDictionariesByIds = createEntitiesByIdsSelector(selectEntities);

export const selectAll = createArraySelector(selectFormTemplateDictionaryState, fromFormTemplateDictionary.selectAll);

const selectExistsByName = (name: FormTemplateDictionary['name'], currentId?: FormTemplateDictionary['id']) =>
  createSelector(selectAll, (formTemplateDictionaries) =>
    formTemplateDictionaries.some((dictionary) => dictionary.name === name && dictionary.id !== currentId)
  );

export const selectUniqueNameValidationError = (
  name: FormTemplateDictionary['name'],
  currentId?: FormTemplateDictionary['id']
) =>
  createSelector(selectExistsByName(name, currentId), (isDuplicate) =>
    isDuplicate ? ({ nameNotUnique: true } as const) : null
  );
