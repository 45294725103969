import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';

import * as fromWorkDay from '../reducers/work-day.reducer';

export const selectWorkDaysState = createFeatureSelector<fromWorkDay.WorkDaysState>(fromWorkDay.workDaysFeatureKey);

export const selectEntities = createSelector(selectWorkDaysState, fromWorkDay.selectEntities);

export const selectAll = createSelector(selectWorkDaysState, fromWorkDay.selectAll);

type WorkDayIdsByStartDate = Partial<Record<string, readonly number[]>>;
export const selectWorkDayIdsByStartDate = createSelector(selectAll, (workDays) => {
  return workDays.reduce<WorkDayIdsByStartDate>((byStartDate, workDay) => {
    const { id: workDayId, workStart } = workDay;
    const startDate = DateTime.fromISO(workStart).toISODate();
    const workDayIdsByStartDate = new Set(byStartDate[startDate]);
    if (workDayIdsByStartDate.has(workDayId)) {
      return byStartDate;
    }
    return {
      ...byStartDate,
      [startDate]: Array.from(workDayIdsByStartDate.add(workDayId)).sort(),
    };
  }, {});
});
