import { Injectable } from '@angular/core';

import { DisplayFormat, PickerHourFormat, PickerMinuteFormat } from './time-formats';

@Injectable()
export abstract class TimeModelAdapter<Time> {
  locale = 'lookup';

  readonly TIME_SEPARATOR = ':';

  abstract parse(value: string): Time | null;
  abstract format(time: Time | null, displayFormat: DisplayFormat): string;
  abstract isValid(value: Time | null): value is Time;
  abstract isSame(time: Time | null, anotherTime: Time | null): boolean;
  abstract createTime(hour: number, minute: number): Time;
  abstract getHour(time: Time | null): number | null;
  abstract getMinute(time: Time | null): number | null;
  abstract default(): Time;
  abstract formatHour(hour: number, format: PickerHourFormat): string;
  abstract formatMinute(minute: number, format: PickerMinuteFormat): string;
}
