import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface ModuleWebObject {
  taskImporterModule: boolean;
  areaModule: boolean;
  areaEditableModule: boolean;
  skillModule: boolean;
  localOptimizeModule: boolean;
  optimizeModule: boolean;
  teamModule: boolean;
  carTrackingModule: boolean;
  appointmentModule: boolean;
  formTemplatesModule: boolean;
  notesModule: boolean;
  taskStatusHistoryEditorModule: boolean;
  placeModule: boolean;
  reportRodoModule: boolean;
  dispatcherNotificationModule: boolean;
  companiesModule: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModulesApiService {
  readonly modules$ = this.httpClient.get<ModuleWebObject>('@backend/module/getModules');

  constructor(private readonly httpClient: HttpClient) {}
}
