import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { TeamUnavailabilityDefinitionActions, TeamUnavailabilityOccurrenceActions } from '../actions';
import { TeamUnavailabilityDefinition } from '../models';
import * as DashboardSchedulerActions from 'geotask/dashboard-scheduler/actions/scheduler-data.actions';

export const teamUnavailabilityDefinitionsFeatureKey = 'teamUnavailabilityDefinitions';

export type TeamUnavailabilityDefinitionsState = EntityState<TeamUnavailabilityDefinition>;

export const adapter: EntityAdapter<TeamUnavailabilityDefinition> = createEntityAdapter<TeamUnavailabilityDefinition>();

export const initialState: TeamUnavailabilityDefinitionsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    TeamUnavailabilityDefinitionActions.addTeamUnavailabilityDefinition,
    (state, action): TeamUnavailabilityDefinitionsState => adapter.addOne(action.teamUnavailabilityDefinition, state)
  ),
  on(
    TeamUnavailabilityDefinitionActions.updateTeamUnavailabilityDefinition,
    (state, action): TeamUnavailabilityDefinitionsState => adapter.updateOne(action.teamUnavailabilityDefinition, state)
  ),
  on(
    TeamUnavailabilityDefinitionActions.upsertTeamUnavailabilityDefinitions,
    (state, { teamUnavailabilityDefinitions }): TeamUnavailabilityDefinitionsState =>
      adapter.upsertMany(teamUnavailabilityDefinitions, state)
  ),
  on(
    DashboardSchedulerActions.loadSchedulerDataSuccess,
    DashboardSchedulerActions.refreshSchedulerDataSuccess,
    (state, { data: { teamUnavailabilityDefinitions } }): TeamUnavailabilityDefinitionsState =>
      adapter.upsertMany(teamUnavailabilityDefinitions, state)
  ),
  on(
    TeamUnavailabilityDefinitionActions.removeTeamUnavailabilityDefinition,
    (state, action): TeamUnavailabilityDefinitionsState =>
      adapter.removeOne(action.teamUnavailabilityDefinitionId, state)
  ),
  on(
    TeamUnavailabilityOccurrenceActions.retainTeamUnavailabilityOccurrences,
    (state, { retain }): TeamUnavailabilityDefinitionsState =>
      adapter.updateOne(
        {
          id: retain.teamUnavailabilityDefinitionId,
          changes: {
            edited: true,
          },
        },
        state
      )
  )
);

export const { selectEntities } = adapter.getSelectors();
