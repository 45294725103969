import { createFeatureSelector } from '@ngrx/store';

import { createArraySelector } from 'geotask/core/selectors/selector-factories';
import * as fromReasonCode from '../reducers/reason-code.reducer';

const selectReasonCodeState = createFeatureSelector<fromReasonCode.ReasonCodeState>(
  fromReasonCode.reasonCodesFeatureKey
);

export const selectAll = createArraySelector(selectReasonCodeState, fromReasonCode.selectAll);
