import { InjectionToken } from '@angular/core';

export type DisplayFormat = Omit<Intl.DateTimeFormatOptions, 'day' | 'era' | 'month' | 'year' | 'weekday' | 'second'>;
export type PickerHourFormat = Pick<Intl.DateTimeFormatOptions, 'hour12' | 'hour' | 'formatMatcher' | 'localeMatcher'>;
export type PickerMinuteFormat = Pick<Intl.DateTimeFormatOptions, 'minute' | 'formatMatcher' | 'localeMatcher'>;

export interface TimeFormats {
  display: {
    input: DisplayFormat;
    picker: {
      hour: PickerHourFormat;
      minute: PickerMinuteFormat;
    };
  };
}

export const TIME_FORMATS = new InjectionToken<TimeFormats>('Timepicker formats');
