import { Injectable } from '@angular/core';
import { CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { concatMap, defaultIfEmpty, filter, map, take } from 'rxjs/operators';

import { isInValues } from '@glb/util/type-guards';
import { UnauthorizedRedirectURL } from '../models';
import { AuthService } from '../services/auth.service';

const areExecutorAreasLoaded = isInValues(['LOAD_SUCCESS', 'LOAD_FAILURE']);

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivateChild, CanLoad {
  readonly isAuthenticatedOrRedirectUrl$: Observable<true | UrlTree>;

  constructor(authService: AuthService, router: Router) {
    const redirectRoute = router.createUrlTree([UnauthorizedRedirectURL]);

    const executorAreasLoaded$ = combineLatest([
      authService.hasOnlyTerrainRole(),
      authService.currentExecutorAreasState$,
    ]).pipe(
      filter(([isOnlyTerrainRole, state]) => isOnlyTerrainRole || areExecutorAreasLoaded(state)),
      map(([isOnlyTerrainRole, state]) => isOnlyTerrainRole || state === 'LOAD_SUCCESS')
    );
    this.isAuthenticatedOrRedirectUrl$ = authService.canAccessApplication$.pipe(
      concatMap((loggedIn) =>
        loggedIn
          ? executorAreasLoaded$.pipe(map((executorAreasLoaded) => executorAreasLoaded || redirectRoute))
          : of(redirectRoute)
      ),
      take(1),
      defaultIfEmpty(redirectRoute)
    );
  }

  canActivateChild() {
    return this.isAuthenticatedOrRedirectUrl$;
  }

  canLoad() {
    return this.isAuthenticatedOrRedirectUrl$;
  }
}
