import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createEntityByIdSelector } from 'geotask/core/selectors/selector-factories';
import * as fromTaskType from '../reducers/task-type.reducer';
import { TaskTypeCode } from 'geotask/core/models';
import { TaskType } from '../models';

export const selectTaskTypeState = createFeatureSelector<fromTaskType.TaskTypesState>(fromTaskType.taskTypesFeatureKey);

export const selectEntities = createSelector(selectTaskTypeState, fromTaskType.selectEntities);

export const selectTaskTypeById = createEntityByIdSelector(selectEntities);

export const selectAll = createSelector(selectTaskTypeState, fromTaskType.selectAll);

export const selectEntitiesByCodes = createSelector(selectAll, (types) =>
  types.reduce(
    (groupedTypes: Partial<Record<TaskTypeCode, TaskType>>, type) => ({
      ...groupedTypes,
      [type.code]: type,
    }),
    {}
  )
);
