import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UnavailabilityRepetitiveness } from 'geotask/core/models';
import { ISO8601Timestamp } from 'geotask/core/schemas/iso-8601-timestamp.schema';
import {
  AllDayPlaceUnavailabilityProps,
  OneTimePlaceUnavailabilityProps,
  PlaceFullInfo,
  PlaceUnavailability,
  RangePlaceAvailabilityProps,
  RepetitivePlaceUnavailabilityProps,
} from 'geotask/entities/models';
import { HTML_FMT, parseUtcTimeWithSeconds } from 'geotask/util/dates';
import { loadAllPages } from 'geotask/util/load-all-pages';
import { PageRequest, SortParams } from '../models';
import { Page } from '../schemas/page.schema';
import { PlaceFullInfoWebObject } from '../schemas/place-full-info-web-object.schema';
import { PlaceWebObject } from '../schemas/place-web-object.schema';
import { PlaceUnavailabilityWebObject } from '../schemas/place-unavailability-web-object.schema';
import { createPageableParams } from './create-pageable-params';

interface PlaceFilter extends Partial<PageRequest>, Partial<SortParams> {
  nameSearch?: string | null;
  addressSearch?: string | null;
  typeIds?: readonly number[];
  customerId?: number | null;
  active?: boolean;
  mainContactPersonName?: string | null;
  mainContactPersonPhone?: string | null;
}

export interface PlaceFromEditorWebObject {
  id: number | null;
  name: string;
  customerId: number | null;
  main: boolean;
  longitude: number;
  latitude: number;
  address: string;
  areaId: number | null;
  placeTypeId: number | null;
  details: string | null;
  skills: number[] | null;
  contactPersons: ContactPersonForUpdateOrInsertPlaceBody[];
  externalId: string | null;
  unavailabilities: UnavailabilitiesForUpdateOrInsertPlaceBody[];
}

export interface ContactPersonForUpdateOrInsertPlaceBody {
  id: number | null;
  name: string;
  phone: string | null;
  email: string | null;
  main: boolean;
}

export interface UnavailabilitiesForUpdateOrInsertPlaceBody {
  id: number | null;
  allDay: boolean;
  availabilityStart: ISO8601Timestamp | null;
  availabilityEnd: ISO8601Timestamp | null;
  day: ISO8601Timestamp | null;
  repetitiveness: UnavailabilityRepetitiveness | null;
}

const createUnavailabilityType = (
  unavailability: PlaceUnavailabilityWebObject
): AllDayPlaceUnavailabilityProps | RangePlaceAvailabilityProps => {
  if (unavailability.allDay) {
    return {
      type: 'all-day',
      allDay: true,
      availabilityStart: null,
      availabilityEnd: null,
    };
  } else {
    return {
      type: 'available-in-range',
      allDay: false,
      availabilityStart: parseUtcTimeWithSeconds(unavailability.availabilityStart).toFormat(HTML_FMT.TIME_SECONDS),
      availabilityEnd: parseUtcTimeWithSeconds(unavailability.availabilityEnd).toFormat(HTML_FMT.TIME_SECONDS),
    };
  }
};

const createUnavailabilityPeriodicity = (
  unavailability: PlaceUnavailabilityWebObject
): OneTimePlaceUnavailabilityProps | RepetitivePlaceUnavailabilityProps => {
  if (unavailability.day != null) {
    return {
      periodicity: 'one-time',
      day: unavailability.day,
      repetitiveness: null,
    };
  } else {
    return {
      periodicity: 'repetitive',
      day: null,
      repetitiveness: unavailability.repetitiveness,
    };
  }
};

export const createUnavailability = (unavailability: PlaceUnavailabilityWebObject): PlaceUnavailability => {
  return {
    id: unavailability.id,
    ...createUnavailabilityType(unavailability),
    ...createUnavailabilityPeriodicity(unavailability),
  };
};

export const createPlace = (place: PlaceFullInfoWebObject): PlaceFullInfo => {
  return {
    id: place.id,
    name: place.name,
    placeTypeId: place.placeTypeId,
    address: place.address,
    main: place.main,
    active: place.active,
    anonymized: place.anonymized,
    customerId: place.customerId,
    placeUnavailabilities: place.placeUnavailabilities.map(createUnavailability),
    skillIds: Array.from(place.skillIds),
    details: place.details,
    latitude: place.latitude,
    longitude: place.longitude,
    areaId: place.areaId,
    externalId: place.externalId,
  };
};

@Injectable({
  providedIn: 'root',
})
export class PlacesApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getPlacesPage({
    nameSearch,
    addressSearch,
    typeIds,
    customerId,
    mainContactPersonName,
    mainContactPersonPhone,
    active,
    ...pageableParams
  }: Readonly<PlaceFilter> = {}) {
    let params = new HttpParams();

    if (nameSearch) {
      params = params.set('name', nameSearch.toLowerCase());
    }

    if (addressSearch) {
      params = params.set('address', addressSearch.toLowerCase());
    }

    if (typeIds && typeIds.length > 0) {
      for (const typeId of typeIds) {
        params = params.append('typeIds', typeId);
      }
    }

    if (customerId != null) {
      params = params.set('customerId', customerId);
    }

    if (mainContactPersonName) {
      params = params.set('mainContactPersonName', mainContactPersonName);
    }

    if (mainContactPersonPhone) {
      params = params.set('mainContactPersonPhone', mainContactPersonPhone);
    }

    if (active) {
      params = params.set('active', active);
    }

    params = params.appendAll(createPageableParams(pageableParams));

    return this.httpClient.get<Page<PlaceFullInfoWebObject>>('@backend/v2/places', { params });
  }

  loadAllPlacesPages(params: Readonly<Omit<PlaceFilter, 'pageNumber'>> = {}) {
    return loadAllPages((pageNumber) =>
      this.getPlacesPage({
        ...params,
        pageNumber,
      })
    );
  }

  getPlaceById(placeId: number) {
    return this.httpClient.get<PlaceWebObject>('@backend/places/getPlaceById', {
      params: { id: placeId },
    });
  }

  togglePlaceActive(placeId: number, active: boolean) {
    return this.httpClient.post<void>(`@backend/places/${placeId}/active`, active);
  }

  updateOrInsertPlace(body: PlaceFromEditorWebObject) {
    return this.httpClient.post<PlaceWebObject>('@backend/places/updateOrInsertPlace', body);
  }

  validateName(name: string, customerId: number) {
    return this.httpClient.get<number | null>('@backend/v2/places/nameValidation', {
      params: { name, customerId },
    });
  }

  loadPlaceUnavailabilities(placeId: number) {
    return this.httpClient.get<PlaceUnavailabilityWebObject[]>(`@backend/places/${placeId}/unavailabilities`);
  }

  validateExternalId(externalId: string, placeId?: number) {
    let params = new HttpParams({ fromObject: { externalId } });
    if (placeId != null) {
      params = params.set('placeId', placeId);
    }
    return this.httpClient.get<{ unique: boolean }>('@backend/places/validation/external-id', { params });
  }
}
