import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { FormTemplateDictionaryActions, MultidictionaryApiActions } from '../actions';
import { FormTemplateDictionary } from '../models';

export const formTemplateDictionariesFeatureKey = 'dictionaries.formTemplateDictionaries';

export type FormTemplateDictionariesState = EntityState<FormTemplateDictionary>;

export const adapter: EntityAdapter<FormTemplateDictionary> = createEntityAdapter<FormTemplateDictionary>();

export const initialState: FormTemplateDictionariesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(FormTemplateDictionaryActions.upsertFormTemplateDictionary, (state, action) =>
    adapter.upsertOne(action.formTemplateDictionary, state)
  ),
  on(FormTemplateDictionaryActions.deleteFormTemplateDictionaries, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) =>
    adapter.setAll(action.formTemplateDictionaries, state)
  )
);

export const { selectEntities, selectAll } = adapter.getSelectors();
