import { createReducer, on } from '@ngrx/store';
import { RouterNavigatedAction, routerNavigatedAction } from '@ngrx/router-store';

import { MergedRoute } from './router';
import * as HistoryActions from '../actions/history.actions';

export const historyFeatureKey = 'history';

export type HistoryState = readonly string[];

export const initialState: HistoryState = [];

const HISTORY_SIZE = 20;
export const reducer = createReducer(
  initialState,
  on(routerNavigatedAction, (state, action) => {
    const navigatedAction = action as unknown as RouterNavigatedAction<MergedRoute>;
    const last = state[state.length - 1];
    if (last !== navigatedAction.payload.routerState.urlForNavigateBack) {
      return [...state, navigatedAction.payload.routerState.urlForNavigateBack].slice(-HISTORY_SIZE);
    } else {
      return state;
    }
  }),
  on(HistoryActions.navigateBack, (state) => state.slice(0, state.entries.length - 1))
);
