import { createAction, props } from '@ngrx/store';
import type { Sort } from '@angular/material/sort';

import type { DateFilter } from 'geotask/core/models';
import type { Executor } from 'geotask/entities/models';

export const notesListOpened = createAction('[Notes/NoteList] Notes List Opened');

export const changePage = createAction(
  '[Notes/NoteList] Change Page',
  props<Readonly<{ pageNumber: number; pageSize: number }>>()
);

export const changeFilteredAddressSearch = createAction(
  '[Notes/NoteList] Change Filtered Address Search',
  props<Readonly<{ addressSearch: string }>>()
);

export const changeFilteredTaskIdSearch = createAction(
  '[Notes/NoteList] Change Filtered Task Id Search',
  props<Readonly<{ taskIdSearch: string }>>()
);

export const changeFilteredTypes = createAction(
  '[Notes/NoteList] Change Filtered Types',
  props<Readonly<{ typeIds: readonly number[] }>>()
);

export const changeFilteredExecutors = createAction(
  '[Notes/NoteList] Change Filtered Executors',
  props<Readonly<{ executorIds: readonly number[] }>>()
);

export const setInitExecutorsSelection = createAction(
  '[Notes/NotesList] Set Init Executors Selection',
  props<{ executors: Executor[] }>()
);

export const changeFilteredFinished = createAction(
  '[Notes/NoteList] Change Filtered Finished',
  props<Readonly<{ finished: boolean | null }>>()
);

export const changeFilteredDate = createAction(
  '[Notes/NoteList] Change Filtered Date',
  props<{ dateFilter: DateFilter }>()
);

export const selectSingleNote = createAction('[Notes/NoteList] Select note', props<Readonly<{ noteId: number }>>());

export const addNoteToSelection = createAction(
  '[Notes/NoteList] Add Note To Selection',
  props<Readonly<{ noteId: number }>>()
);

export const addNotesToSelection = createAction(
  '[Notes/NoteList] Add Notes To Selection',
  props<Readonly<{ noteIds: readonly number[] }>>()
);

export const changeSort = createAction('[Notes/NoteList] Change Sort', props<{ sort: Sort }>());

export const updateFinished = createAction(
  '[Notes/NoteList] Update Finished',
  props<Readonly<{ finished: boolean; id: number }>>()
);
