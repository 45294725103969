import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { MultidictionaryApiActions } from '../actions';
import { FieldValueType } from '../models';

export const fieldValueTypesFeatureKey = 'dictionaries.fieldValueTypes';

export type FieldValueTypesState = EntityState<FieldValueType>;

export const adapter: EntityAdapter<FieldValueType> = createEntityAdapter<FieldValueType>();

export const initialState: FieldValueTypesState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) =>
    adapter.setAll(action.formTemplateFieldTypes, state)
  )
);

export const { selectAll, selectEntities } = adapter.getSelectors();
