export default {
  apiKey: 'AIzaSyA8Rpe7GstXhfWECFvUb0snxnyzS6rMFFY',
  authDomain: 'geotask-cloud-ikea.firebaseapp.com',
  databaseURL: 'https://geotask-cloud-ikea.firebaseio.com',
  projectId: 'geotask-cloud-ikea',
  storageBucket: 'geotask-cloud-ikea.appspot.com',
  messagingSenderId: '430775273194',
  appId: '1:430775273194:web:82d8c2c1490ba367d5b993',
  measurementId: 'G-G3RPVDS7J2',
};
