import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject, switchMap } from 'rxjs';

import { RoleCode } from 'geotask/core/models';
import { AuthService } from 'geotask/core/services/auth.service';
import { ConditionalBaseDirective } from '@glb/util/angular';

@Directive({
  selector: '[glbIfHasRole]',
  standalone: true,
})
export class IfHasRoleDirective extends ConditionalBaseDirective implements OnDestroy {
  @Input()
  set glbIfHasRole(role: RoleCode) {
    this.roleSubject.next(role);
  }

  private readonly roleSubject = new ReplaySubject<RoleCode>(1);

  constructor(
    private readonly authService: AuthService,
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<any>,
    changeDetector: ChangeDetectorRef
  ) {
    super(viewContainer, changeDetector, templateRef);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.roleSubject.complete();
  }

  protected getObservableCondition() {
    return this.roleSubject.asObservable().pipe(switchMap((role) => this.authService.hasRole(role)));
  }
}
