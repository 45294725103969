import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromTaskType from './reducers/task-type.reducer';
import * as fromTaskPriority from './reducers/task-priority.reducer';
import * as fromTaskStatus from './reducers/task-status.reducer';
import * as fromIkeaServiceKind from './reducers/ikea-service-kind.reducer';
import * as fromTaskKind from './reducers/task-kind.reducer';
import * as fromSkill from './reducers/skill.reducer';
import * as fromAppSetting from './reducers/app-setting.reducer';
import * as fromUnavailabilityType from './reducers/unavailability-type.reducer';
import * as fromArea from './reducers/area.reducer';
import * as fromPlaceType from './reducers/place-type.reducer';
import * as fromOrderStatus from './reducers/order-status.reducer';
import * as fromNoteType from './reducers/note-type.reducer';
import * as fromFormTemplateField from './reducers/field-value-type.reducer';
import * as fromRole from './reducers/role.reducer';
import * as fromFormTemplate from './reducers/form-template.reducer';
import * as fromFormTemplateDictionary from './reducers/form-template-dictionary.reducer';
import * as fromDispatcherNotificationType from './reducers/dispatcher-notification-type.reducer';
import * as fromDispatcherNotificationStatus from './reducers/dispatcher-notification-status.reducer';
import * as taskAdditionalFieldType from './reducers/task-additional-field-type.reducer';
import * as taskAdditionalField from './reducers/task-additional-field.reducer';
import * as taskServiceStatus from './reducers/task-service-status.reducer';
import * as reasonCode from './reducers/reason-code.reducer';
import * as systemDicts from './reducers/companies.reducer';
import { AppSettingEffects } from './effects/app-setting.effects';
import { AreaEffects } from './effects/area.effects';
import { DictionariesEffects } from './effects/dictionaries.effects';
import { TaskAdditionalFieldsEffects } from './effects/task-additional-fields.effects';
import { DictionariesFacade } from './dictionaries.facade';
import { TaskServiceOrderStatusesEffects } from './effects/task-service-order-statuses.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(fromArea.areasFeatureKey, fromArea.reducer),
    StoreModule.forFeature(fromSkill.skillsFeatureKey, fromSkill.reducer),
    StoreModule.forFeature(fromTaskType.taskTypesFeatureKey, fromTaskType.reducer),
    StoreModule.forFeature(fromTaskPriority.taskPrioritiesFeatureKey, fromTaskPriority.reducer),
    StoreModule.forFeature(fromTaskStatus.taskStatusesFeatureKey, fromTaskStatus.reducer),
    StoreModule.forFeature(fromTaskKind.taskKindsFeatureKey, fromTaskKind.reducer),
    StoreModule.forFeature(fromIkeaServiceKind.ikeaServiceKindsFeatureKey, fromIkeaServiceKind.reducer),
    StoreModule.forFeature(fromAppSetting.appSettingsFeatureKey, fromAppSetting.reducer),
    StoreModule.forFeature(fromUnavailabilityType.unavailabilityTypesFeatureKey, fromUnavailabilityType.reducer),
    StoreModule.forFeature(fromPlaceType.placeTypesFeatureKey, fromPlaceType.reducer),
    StoreModule.forFeature(fromOrderStatus.orderStatusesFeatureKey, fromOrderStatus.reducer),
    StoreModule.forFeature(fromNoteType.noteTypeFeatureKey, fromNoteType.reducer),
    StoreModule.forFeature(fromFormTemplateField.fieldValueTypesFeatureKey, fromFormTemplateField.reducer),
    StoreModule.forFeature(fromRole.rolesFeatureKey, fromRole.reducer),
    StoreModule.forFeature(fromFormTemplate.formTemplatesFeatureKey, fromFormTemplate.reducer),
    StoreModule.forFeature(
      fromFormTemplateDictionary.formTemplateDictionariesFeatureKey,
      fromFormTemplateDictionary.reducer
    ),
    StoreModule.forFeature(
      fromDispatcherNotificationType.dispatcherNotificationTypesFeatureKey,
      fromDispatcherNotificationType.reducer
    ),
    StoreModule.forFeature(
      fromDispatcherNotificationStatus.dispatcherNotificationStatusesFeatureKey,
      fromDispatcherNotificationStatus.reducer
    ),
    StoreModule.forFeature(taskAdditionalFieldType.taskAdditionalFieldTypesFeatureKey, taskAdditionalFieldType.reducer),
    StoreModule.forFeature(taskAdditionalField.taskAdditionalFieldsFeatureKey, taskAdditionalField.reducer),
    StoreModule.forFeature(taskServiceStatus.taskServiceOrderStatusesFeatureKey, taskServiceStatus.reducer),
    StoreModule.forFeature(reasonCode.reasonCodesFeatureKey, reasonCode.reducer),
    StoreModule.forFeature(systemDicts.companiesFeatureKey, systemDicts.reducer),
    EffectsModule.forFeature([
      AppSettingEffects,
      AreaEffects,
      DictionariesEffects,
      TaskAdditionalFieldsEffects,
      TaskServiceOrderStatusesEffects,
    ]),
  ],
  providers: [DictionariesFacade],
})
export class DictionariesModule {}
