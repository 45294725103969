import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { MultidictionaryApiActions } from '../actions';
import { TaskPriority } from '../models';

export const taskPrioritiesFeatureKey = 'dictionaries.taskPriorities';

export type TaskPrioritiesState = EntityState<TaskPriority>;

export const adapter: EntityAdapter<TaskPriority> = createEntityAdapter<TaskPriority>();

export const initialState: TaskPrioritiesState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(MultidictionaryApiActions.loadDictionariesSuccess, (state, action) => adapter.setAll(action.taskPriorities, state))
);

export const { selectEntities, selectAll } = adapter.getSelectors();
