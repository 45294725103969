import { createActionGroup, emptyProps, props } from '@ngrx/store';

import type { AreaEditorFormRawValue } from '../models';

export const AreaEditorActions = createActionGroup({
  source: 'Areas/AreaEditor',
  events: {
    'Load Area': emptyProps(),
    'Save area': props<{ area: AreaEditorFormRawValue }>(),
  },
});
