import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as DispatcherNotificationsApiActions from 'geotask/dispatcher-notifications/actions/dispatcher-notifications-api.actions';
import * as DispatcherNotificationsReportApiActions from 'geotask/dispatcher-notifications-report/actions/notifications-report-api.actions';
import { NoteAttachmentsApiActions, NotesApiActions } from 'geotask/notes/actions';
import * as TaskEditorNoteApiActions from 'geotask/tasks/actions/note-api.actions';
import * as TaskEditorTasksApiActions from 'geotask/tasks/actions/tasks-api.actions';
import { notEqualTo } from '@glb/util/functional';
import { NoteActions } from '../actions';
import { Note } from '../models';

export const notesFeatureKey = 'entities.notes';

export type NoteState = EntityState<Note>;

export const adapter: EntityAdapter<Note> = createEntityAdapter<Note>();

export const initialState: NoteState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    TaskEditorNoteApiActions.loadCopiedNoteSuccess,
    (state, action): NoteState => adapter.upsertOne(action.note, state)
  ),
  on(
    NotesApiActions.loadNoteSuccess,
    (state, { noteForEditor: { note } }): NoteState =>
      adapter.upsertOne(
        {
          id: note.id,
          executorId: note.executorId,
          taskId: note.taskId,
          address: note.address,
          creationDate: note.creationDate,
          finished: note.finished,
          latitude: note.latitude,
          longitude: note.longitude,
          noteTypeId: note.noteTypeId,
          content: note.content,
          createdTaskId: note.createdTaskId,
          orderId: note.orderId,
          attachmentIds: note.attachmentIds,
          areaId: note.areaId,
        },
        state
      )
  ),
  on(
    TaskEditorTasksApiActions.loadLatestTaskDataSuccess,
    TaskEditorTasksApiActions.loadCopiedTaskSuccess,
    TaskEditorTasksApiActions.saveNewTaskSuccess,
    DispatcherNotificationsApiActions.loadNotificationsGroupSuccess,
    DispatcherNotificationsReportApiActions.loadNotificationsReportSuccess,
    (state, action) => adapter.upsertMany(action.notes, state)
  ),
  on(
    NotesApiActions.loadNotesPageSuccess,
    (state, { page: { content: notes } }): NoteState => adapter.upsertMany(notes, state)
  ),
  on(NoteActions.updateNote, (state, action): NoteState => adapter.updateOne(action.note, state)),
  on(
    NoteAttachmentsApiActions.deleteNoteAttachmentSuccess,
    (state, action): NoteState =>
      adapter.mapOne(
        {
          id: action.noteId,
          map: (note) => ({
            ...note,
            attachmentIds: note.attachmentIds?.filter(notEqualTo(action.attachmentId)),
          }),
        },
        state
      )
  )
);

// noinspection JSUnusedGlobalSymbols
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
