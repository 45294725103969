import { createAction, props } from '@ngrx/store';

import type { ApiError } from 'geotask/backend/models';
import type {
  Executor,
  ExecutorUnavailability,
  Place,
  TaskEntity,
  TeamEntity,
  TeamUnavailabilityDefinition,
  TeamUnavailabilityOccurrence,
  WorkDayEntity,
} from 'geotask/entities/models';

interface SchedulerDataLoadSuccessProps {
  timestamp: number;
  workDayIds: readonly number[];
  workDays: WorkDayEntity[];
  tasks: TaskEntity[];
  executors: Executor[];
  teamUnavailabilityDefinitions: TeamUnavailabilityDefinition[];
  teamUnavailabilityOccurrences: TeamUnavailabilityOccurrence[];
  executorUnavailabilities: ExecutorUnavailability[];
  places: Place[];
  teams: TeamEntity[];
}

export const loadSchedulerData = createAction('[DashboardScheduler] Load Scheduler Data');

export const loadSchedulerDataSuccess = createAction(
  '[DashboardScheduler API] Load Scheduler Data Success',
  props<{ data: SchedulerDataLoadSuccessProps }>()
);
export const loadSchedulerDataFailure = createAction(
  '[DashboardScheduler API] Load Scheduler Data Failure',
  props<{ error: ApiError }>()
);

export const refreshSchedulerData = createAction('[DashboardScheduler] Refresh Scheduler Data');

export const refreshSchedulerDataSuccess = createAction(
  '[DashboardScheduler API] Refresh Scheduler Data Success',
  props<{ data: SchedulerDataLoadSuccessProps }>()
);
export const refreshSchedulerDataFailure = createAction(
  '[DashboardScheduler API] Refresh Scheduler Data Failure',
  props<{ error: ApiError }>()
);
