import { Reducer } from '@glb/util/types';

type Id = string | number;
export const toEntities =
  <T>(entities: Partial<Record<Id, T>>): Reducer<Id, T[]> =>
  (result, id) => {
    const entity = entities[id];
    if (entity) {
      result.push(entity);
    }
    return result;
  };

export const toMin =
  <T>(mapper: (value: T) => number) =>
  (currentMin: T | undefined, value: T): T | undefined => {
    if (!currentMin) {
      return value;
    }
    return mapper(value) < mapper(currentMin) ? value : currentMin;
  };
