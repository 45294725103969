import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { parseWith } from '@glb/util/rx-operators';
import { Reveal } from '@glb/util/types';
import {
  TaskAdditionalFieldWebObject,
  TaskAdditionalFieldWebObjectSchema,
} from '../schemas/task-additional-field-web-object.schema';

type NotSaveTaskAdditionalFieldWebObject = Reveal<Omit<TaskAdditionalFieldWebObject, 'id'> & { id: null }>;

@Injectable({
  providedIn: 'root',
})
export class TaskAdditionalFieldApiService {
  readonly fields$ = this.httpClient
    .get('@backend/settings/getSettingTaskDetailFields')
    .pipe(parseWith(TaskAdditionalFieldWebObjectSchema.array()));

  constructor(private readonly httpClient: HttpClient) {}

  saveOrUpdate(fields: (NotSaveTaskAdditionalFieldWebObject | TaskAdditionalFieldWebObject)[]) {
    return this.httpClient
      .post('@backend/settings/addOrUpdateSettingTaskAdditionalField', fields)
      .pipe(parseWith(TaskAdditionalFieldWebObjectSchema.array()));
  }
}
