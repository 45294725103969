import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import { ModuleName } from '../models';
import { ModulesSelectors } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  constructor(private readonly httpClient: HttpClient, private readonly store$: Store) {}

  isModuleEnabled(moduleName: ModuleName) {
    return this.store$.select(ModulesSelectors.selectIsModuleEnabled(moduleName)).pipe(
      filter((enabled): enabled is boolean => enabled != null),
      take(1)
    );
  }

  isModuleDisabled(moduleName: ModuleName) {
    return this.isModuleEnabled(moduleName).pipe(map((moduleEnabled) => !moduleEnabled));
  }

  areModulesEnabled(moduleNames: readonly ModuleName[]) {
    return this.store$.pipe(ModulesSelectors.areModulesEnabled(moduleNames), take(1));
  }
}
