import { createAction, props } from '@ngrx/store';
import type { ApiError } from 'geotask/backend/models';
import type { Executor } from 'geotask/entities/models';

export const loadExecutorsSuccess = createAction(
  '[Executors API] Load Executors Success',
  props<{ executors: Executor[] }>()
);
export const loadExecutorsFailure = createAction(
  '[Executors API] Load Executors Failure',
  props<{ error: ApiError }>()
);
